import ComponentMode from "../../../../../components/componentMode/ComponentMode";
import { FlexContainer } from "../../../../../components/containers";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { InstrumentInterface } from "../types/instrumentTypes";
import { InstrumentQRCode } from "./InstrumentQRCode";

export const QRCodeComponent = ({ instrument }: { instrument: InstrumentInterface }) => {
  const { lang } = useLangLabels();

  return (
    <ComponentMode
      icon="view"
      mode="popover"
      variant="iconButton"
      popoverTitle={lang.collectors.qrCode}
    >
      <FlexContainer flexDirection="column" width={"100%"}>
        <FlexContainer padding={"8px"} align="center" justify={"center"}>
          <InstrumentQRCode instrument={instrument} />
        </FlexContainer>
      </FlexContainer>
    </ComponentMode>
  );
};
