import React from "react";
import StatusAndMessage from "../../../shared/queries/errors/components/StatusAndMessage";
import CustomButton from "../../buttons/CustomButton";
import ComponentMode from "../../componentMode/ComponentMode";
import { DefaultFormLayoutProps } from "./types/FormLayoutTypes";

const DefaultFormLayout: React.FC<DefaultFormLayoutProps> = ({
  children,
  componentMode,
  queryStatus,
  alertStatusMessage,
  alertSeverity,
  error,
  buttonText,
  buttonIcon,
  buttonAction,
  buttonVariant,
  buttondDisabled,
  popoverTitle,
  fullwidth,
  resetForm,
  onClose,
  isDataUpdated,
}) => {
  return (
    <ComponentMode
      onClose={onClose}
      fullwidth={fullwidth}
      title={buttonText}
      mode={componentMode}
      icon={buttonIcon}
      variant={buttonVariant}
      popoverTitle={popoverTitle}
    >
      <StatusAndMessage
        status={queryStatus}
        error={error}
        message={alertStatusMessage}
        reset={resetForm}
        severity={alertSeverity}
        isDataUpdated={isDataUpdated}
      />
      {children}
      <CustomButton
        action={buttonAction}
        status={queryStatus}
        title={buttonText}
        disabled={buttondDisabled}
      />
    </ComponentMode>
  );
};

export default React.memo(DefaultFormLayout);
