import { Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TgdTabs from "../../components/tabs/TgdTabs";
import { useCriticalitiesGridModel } from "../../domains/criticalities/lang/useCriticalitiesGridModel";
import { CriticalityInterface } from "../../domains/criticalities/models/criticalityInterface";
import { usePlantCriticality } from "../../domains/criticalities/services/service.criticality";
import CreateNewForm from "../../domains/news/components/CreateNewForm";
import { NewInterface } from "../../domains/news/model/NewsInterface";
import { useNewsByTagFP } from "../../domains/news/services/service.news";
import { useDate } from "../../shared/date/useDate";
import { objectMapper } from "../../shared/globals/utils/objectMapper";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../shared/react-table/VirtualGrid/VirtualGrid";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import NewView from "../../domains/plant-notifications/news/components/NewView";

const NewList: React.FC<{
  news?: NewInterface[];
  headerActions?: React.ReactNode;
}> = ({ news, headerActions }) => {
  const { lang } = useLangLabels();

  const { data: criticalities } = usePlantCriticality();
  const criticalitiesMap = objectMapper(criticalities, "description");
  const CriticalityGridModel = useCriticalitiesGridModel();

  const criticalityField = (item: CriticalityInterface) => {
    const criticalityDescription: string | undefined = item.description;
    const color = `${
      criticalitiesMap && criticalityDescription
        ? criticalitiesMap[criticalityDescription]?.color
        : "unset"
    }`;
    return <CustomIcon icon="state" style={{ color }} />;
  };

  return (
    <VirtualGrid
      {...{
        gridModel: CriticalityGridModel,
        title: lang.news.plural,
        items: news,
        headerActions: () => (
          <>
            {headerActions && headerActions}
            <CreateNewForm />
          </>
        ),
        renderOptionColumn: { headerName: "More", renderOption: item => criticalityField(item) },
        bulkActions: items => <NewView items={items} />,
      }}
    />
  );
};

const NewsPage: React.FC<{}> = () => {
  const { nowYear, nowDay, nowMonth } = useDate();
  const defaultDate = `${nowYear}-${nowMonth}-${nowDay}`;
  const [date, setDate] = useState<any>(defaultDate);
  const { data: news } = useNewsByTagFP(date);
  const plantNews = news?.filter(n => n.type === "PLANTA");
  const placeNews = news?.filter(n => n.type === "ENTORNO");
  const { lang } = useLangLabels();

  const tabs = [
    { name: lang.news.all, news: news, onChange: () => setDate(undefined) },
    { name: lang.news.environment, news: placeNews, onChange: () => setDate(defaultDate) },
    { name: lang.news.plant, news: plantNews, onChange: () => setDate(defaultDate) },
  ];

  useEffect(() => {
    setDate(undefined);
  }, []);

  return (
    <>
      <Paper>
        <TgdTabs
          items={tabs}
          content={value => {
            return (
              <NewList
                key={value.news + date}
                news={value.news}
                headerActions={
                  <TextField
                    size="small"
                    variant="outlined"
                    type="date"
                    onChange={(e: any) => setDate(e.target.value)}
                    label=""
                    color="secondary"
                    value={date}
                  />
                }
              />
            );
          }}
          labelIterator="name"
        />
      </Paper>
    </>
  );
};

export default NewsPage;
