import { LocalStateSupply } from "../model/SupplyInterface";

export const suppliesToString = (supplies: LocalStateSupply[]): string[] | [] => {
  //from object to string
  return supplies.length > 0
    ? supplies.map(suply => {
        return `${suply.type}|${suply.supply}${suply.state ? `|${suply.state}` : ""}`;
      })
    : [];
};
