import { makeStyles, Paper, TextField, Toolbar, Typography } from "@material-ui/core";
import React from "react";

import { useSearchAll } from "../../shared/globals/hooks/useSearchAll";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { CustomIconType } from "../buttons/types/CustomButtonTypes";
import TgdList from "./TgdList";

const useStyles = makeStyles(() => ({
  FactoryListTolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 16px",
  },
  relative: {
    position: "relative",
    height: "calc(100vh - 19vh)",
    overflowX: "hidden",
  },
  MuiAccordionRoot: {
    boxShadow: "none",
    margin: "0",
    padding: 0,
  },
}));

interface Props {
  icon: CustomIconType;
  status: string;
  items: any | undefined;
  customButtons?: (item: any) => React.ReactNode;
  title: string;
  children?: React.ReactNode;
  dropDownChildren?: (item: any) => React.ReactNode;
}
const EntityList: React.FC<Props> = ({
  icon,
  status,
  items,
  customButtons,
  title,
  children,
  dropDownChildren,
}) => {
  const classes = useStyles();
  const { lang } = useLangLabels();
  const { search, handleSearch, filteredItems } = useSearchAll(items);

  return (
    <Paper className={classes.relative}>
      {children}
      <Toolbar className={classes.FactoryListTolbar}>
        <Typography variant="h6"> {title}</Typography>
        <TextField
          style={{ width: "300px", padding: "0 32px" }}
          value={search}
          type="search"
          onChange={handleSearch}
          placeholder={`${lang.filters.searchByTerm}..`}
        />
      </Toolbar>
      <TgdList
        status={status}
        icon={icon}
        items={filteredItems || []}
        customButtons={customButtons}
      >
        {item => dropDownChildren && dropDownChildren(item)}
      </TgdList>
    </Paper>
  );
};

export default EntityList;
