import React, { useEffect } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import { useDeleteElementVerification } from "./services/service.elements";

import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { ElementInterface } from "./models/ElementInterface";

interface Props {
  item: ElementInterface;
}

const DeleteElementForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();

  const { errors, status: deleteStatus, validate, query } = useDeleteElementVerification();
  const { status, message } = useStatusProcessor(query);
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${lang.elements.elementCannotBeRemoved}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, status, errors]);

  const handleDelete = (e: any) => {
    getClickPosition(e, () => validate(item));
  };

  return deleteStatus === "loading" || status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      icon="delete"
      status={deleteStatus}
      popoverTitle={lang.actionTypes.deleteItem}
      action={handleDelete}
    />
  );
};

export default DeleteElementForm;
