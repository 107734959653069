import React from "react";
import { usePlantAnomalies } from "../services/service.anomalies";
import DeleteAnomalyForm from "../DeleteAnomalyForm";
import { AnomalyInterface } from "../models/anomaliesTypes";
import CUAnomalyForm from "../CUAnomalyForm";

import { useAnomaliesGridModel } from "../lang/useAnomaliesGridModel";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageAnomaliesPageProps {
  virtualGridWidth: string;
}

const ManageAnomaliesPage: React.FC<ManageAnomaliesPageProps> = ({ virtualGridWidth }) => {
  const { data: anomalies, isFetching } = usePlantAnomalies();
  const { lang } = useLangLabels();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: AnomalyInterface) => (
        <CUAnomalyForm item={item} actionType={CrudFormType.update} isDataUpdated={!isFetching} />
      ),
    },
  ];

  const anomalyGridModel = useAnomaliesGridModel();

  return (
    <VirtualGrid
      {...{
        items: anomalies,
        gridModel: anomalyGridModel,
        width: virtualGridWidth,
        title: lang.anomalies.plural,
        entityName: lang.anomalies.plural,
        carrousellComponents,
        headerActions: () => <CUAnomalyForm actionType={CrudFormType.create} />,
        itemActions: item => <DeleteAnomalyForm item={item} />,
      }}
    />
  );
};

export default React.memo(ManageAnomaliesPage);
