import { CSSProperties } from "react";
import styled from "@emotion/styled";
import { TITLE_SIZE } from "./Title";

const SubTitle = styled("h3")`
  font-weight: 500;
  color: ${(props: CSSProperties) => props.color || "gray"};
  font-size: ${TITLE_SIZE - 2}px;
  margin: 0px;
`;

export default SubTitle;
