import { useMemo, useReducer } from "react";
import { formReducer } from "./reducers/FormReducer";
import {
  FormDataResult,
  FormReducerType,
  FormField,
  FormConfigCallbacks,
  FormErrors,
  FormValidations,
  FormFields,
} from "./types/FormTypes";
import { parseFormFieldsToData } from "./utils/FormValidation";

export const useForm = <T,>(initialFields: FormFields<T>) => {
  // State
  const initialState = useMemo(
    () => ({
      fields: initialFields,
      errors: {},
      callbacks: {
        callbackSuccess: (formData: FormDataResult<T>) => console.log("SUCCESS", formData),
        callbackError: (formError: FormErrors<T>) => console.log("ERROR", formError),
      },
    }),
    [initialFields]
  );

  const [formState, dispatch] = useReducer<FormReducerType<T>>(formReducer, initialState);
  const { fields, errors } = formState;

  // Computed Data

  /**
   * Memoized function that enhances form fields with error information.
   * It combines the original form fields with error details based on the error object.
   * @returns Enhanced form fields with error information.
   */
  const fieldsWithErrors = useMemo(() => {
    const errorObject = errors as any;

    // Use Object.keys and reduce to iterate through form fields
    return Object.keys(fields).reduce((newFields, fieldKey) => {
      const fieldName = fieldKey as Extract<keyof T, string>;

      // Update form field with error information
      return {
        ...newFields,
        [fieldName]: {
          ...fields[fieldName],
          error: errorObject[fieldName],
        },
      };
    }, fields);
  }, [errors, fields]);

  const formData = useMemo(() => parseFormFieldsToData(fields), [fields]);

  /**
   * Generates the UI model based on form fields and errors.
   * @returns {Array} An array of objects representing the UI model.
   */
  const uiModel = useMemo(() => {
    const fieldKeys = Object.keys(fields) as (keyof T)[];
    const errorObject = errors as any;

    return fieldKeys.map(key => {
      const field = fields[key];
      return {
        key,
        name: key,
        defaultValue: field?.value,
        onChange: (value: FormField<T>) => {
          setValue(key, value);
        },
        ...field,
        error: errorObject[key] ?? undefined,
      };
    });
  }, [errors, fields]);

  // Action Creators
  const setValue = (key: keyof T, value: any) => {
    dispatch({
      type: "SET_VALUE",
      payload: {
        key,
        value,
      },
    });
  };

  const setValidations = (
    key: keyof T,
    validationName: keyof FormValidations,
    validationValue: any
  ) => {
    dispatch({
      type: "SET_VALIDATIONS",
      payload: {
        key,
        validationName,
        validationValue,
      },
    });
  };

  const resetForm = () => {
    dispatch({
      type: "RESET_ALL",
      payload: initialState,
    });
  };

  const submitForm = (callbacks: FormConfigCallbacks<T>) => {
    dispatch({
      type: "SUBMIT_FORM",
      payload: callbacks,
    });
  };

  const handleSubmit = (
    onSuccess: FormConfigCallbacks<T>[0],
    onError?: FormConfigCallbacks<T>[1]
  ) => {
    return () => {
      submitForm([onSuccess, onError]);
    };
  };

  return {
    fields: fieldsWithErrors,
    formData,
    uiModel,
    errors,
    setValue,
    setValidations,
    handleSubmit,
    resetForm,
  };
};
