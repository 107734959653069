import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { useSnackbar } from "../../../../../components/snackbar/hooks/useSnackbar";
import { useClickPosition } from "../../../../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { useDeleteInstrument } from "../services/instrumentService";
import { InstrumentInterface } from "../types/instrumentTypes";

export const DeleteInstrumentForm = ({ item }: { item: InstrumentInterface }) => {
  const { lang } = useLangLabels();

  const { deleteInstrument, status, message } = useDeleteInstrument();
  const { position, getClickPosition } = useClickPosition();

  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status,
        position,
      });
    }
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position,
      });
    }
  }, [status]);

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.crudActions.delete + " " + lang.instruments.title.singular}
      action={e => getClickPosition(e, () => deleteInstrument(item))}
    />
  );
};
