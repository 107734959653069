import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useAllAuxByPlant } from "../../allAux/services/allAuxServices";
import { useUser } from "../../session/store/sessionStore";
import CUAnomalyForm from "../CUAnomalyForm";

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string | undefined;
  error?: string;
  color?: "primary" | "secondary";
}

const SelectAnomaly: React.FC<Props> = ({ onChange, defaultValue, error }) => {
  const showTitle = "anomaly";
  const { lang } = useLangLabels();
  const label = lang.anomalies.singular;
  const { data: plantAux } = useAllAuxByPlant();
  const anomalies = plantAux && plantAux.anomalies;
  const { data: user } = useUser();

  return (
    <SimpleAutocompleteSelect
      {...{
        error,
        label,
        onChange,
        items: anomalies || [],
        showTitle,
        defaultValue,
      }}
    >
      {user && (user?.type === "O" ? null : <CUAnomalyForm actionType={CrudFormType.create} />)}
    </SimpleAutocompleteSelect>
  );
};

export default SelectAnomaly;
