import { useEffect, useMemo } from "react";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import { getAllResources } from "../../../shared/axios/utils";
import {
  PrivateQuery,
  PrivateQueryData,
  PrivateQueryTagFPInterface,
} from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { Plant } from "../../plants/model/PlantInterface";
import { assignPlantToUser } from "../../plants/services/plantsService";
import { useSessionContext } from "../../session/store/sessionContext";
import { usePerson, useToken, useUser } from "../../session/store/sessionStore";
import { PersonInterface, PersonRoleTypes } from "../models/PersonInterface";

export const CreatePerson = ({
  data,
  token,
  endpoint,
}: PrivateQueryData & { endpoint: string }) => {
  return mutateApiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/${endpoint}`,
    data,
  });
};

export const updatePerson = ({
  data,
  token,
}: PrivateQueryData<{ email: string; password: string }>) => {
  //get object 'cause mutation query bring it that way
  return mutateApiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/UpdatePassword`,
    data,
  });
};

export const AssignLubricatorNumber = ({
  data,
  token,
}: PrivateQueryData<{
  email: PersonInterface["email"];
  tagFP: Plant["tagFP"];
  lubricatorNumber: number;
}>) => {
  return mutateApiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/AssignLubricatorNumber`,
    data,
  });
};

export const PlantOperators = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "get",
    url: `/PlantOperators`,
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};

export const PlantSupervisors = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "get",
    url: `/PlantSupervisors`,
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};
export const PlantClients = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "get",
    url: `/PlantClients`,
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};

export const getSubordinates = (endpoint: string, refererId: number, token: string) => {
  //get object 'cause mutation query bring it that way
  return apiCall({
    method: "get",
    url: `/${endpoint}/${refererId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AllAccountManagers = ({ token }: PrivateQuery) => {
  //get object 'cause mutation query bring it that way
  return apiCall({
    method: "get",
    url: `/AllAccountManagers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useAllPersons = () => {
  const token = useToken();
  const queryKey = "AllPerson";
  return useQuery<PersonInterface[] | []>({
    queryKey,
    queryFn: () => getAllResources(queryKey, { token }),
  });
};

export const useEmailVerificationExist = (email: string) => {
  const { data } = useAllPersons();
  useEffect(() => {
    if (email && data) {
      let query = data.filter((item: PersonInterface) => item.email === email);
      sessionStorage.setItem("emailVerification", JSON.stringify(query.length));
    }
  }, [email]);
};

export const useAllAccountManagers = () => {
  const token = useToken();
  const { tagFP } = useSessionContext();
  return useQuery({
    queryKey: [`AllAccountManagers`, tagFP],
    queryFn: () => AllAccountManagers({ token }),
  });
};

export const useUpdatePerson = () => {
  return useMutation(updatePerson);
};

export const useAssigneLubricatorNumber = () => {
  return useMutation(AssignLubricatorNumber, {
    onSuccess: () => queryClient.invalidateQueries("plantOperators"),
  });
};

export const useLubricationNumber = () => {
  const { selectedOperator } = useSessionContext();
  return useMemo(() => selectedOperator?.lubricatorNumber, [selectedOperator]);
};

export const usePlantOperators = (tagFP: string) => {
  const token = useToken();
  return useQuery<PersonInterface[]>({
    queryKey: ["PlantOperators", tagFP],
    queryFn: () => PlantOperators({ tagFP, token }),
    enabled: !!tagFP && !!token,
  });
};

export const usePlantSupervisors = (tagFP: string) => {
  const token = useToken();
  return useQuery<PersonInterface[]>({
    queryKey: ["PlantSupervisors", tagFP],
    queryFn: () => PlantSupervisors({ tagFP, token }),
    enabled: !!tagFP && !!token,
  });
};

export const usePlantClients = (tagFP: string) => {
  const token = useToken();
  return useQuery<PersonInterface[]>({
    queryKey: ["PlantClients", tagFP],
    queryFn: () => PlantClients({ tagFP, token }),
    enabled: !!tagFP && !!token,
  });
};

/**
 * si se pasa tagFP el usuario se asignara automaticamente a la planta pasada
 *
 * */
export const useCreatePersonRefactor = (tagFP?: string) => {
  const { token } = useSessionContext();

  const createPersonQuery = useMutation(CreatePerson, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantOperators");
      queryClient.invalidateQueries("PlantSupervisors");
      queryClient.invalidateQueries("PlantClients");
      queryClient.invalidateQueries("AllAccountManagers");
    },
  });

  const assignPlantQuery = useMutation(assignPlantToUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantOperators");
      queryClient.invalidateQueries("PlantSupervisors");
      queryClient.invalidateQueries("PlantClients");
      queryClient.invalidateQueries("AllAccountManagers");
    },
  });

  const status = useStatusProcessor(createPersonQuery);

  const createPerson = (data: PersonInterface & { tagF?: string }, callback?: () => void) => {
    /**
     * cliente : si se pasa tagF al usuario se le asignará la fabrica entera.
     *
     * */
    switch (data.role) {
      case "Supervisor":
        return createPersonQuery
          .mutateAsync({
            data,
            token,
            endpoint: "CreateSupervisor",
          })
          .then(() => {
            if (tagFP) {
              assignPlantQuery
                .mutateAsync({
                  data: {
                    tagFP: tagFP,
                    email: data.email,
                    role: "S",
                    state: "A",
                    actualUserEmail: data.referrer,
                  },
                  token,
                })
                .then(() => {
                  callback && callback();
                });
            }
          })
          .catch(err => console.log(err));

      case "Operario":
        return createPersonQuery
          .mutateAsync({
            data,
            token,
            endpoint: "CreateOperator",
          })
          .then(() => {
            if (tagFP) {
              //assign current Plant since tagFP is used in plant selector mode or users that dont have multiplants privileges
              assignPlantQuery.mutateAsync(
                {
                  data: {
                    tagFP: tagFP,
                    email: data.email,
                    role: "O",
                    state: "A",
                    actualUserEmail: data.referrer,
                  },
                  token,
                },
                {
                  onSuccess: () => {
                    callback && callback();
                  },
                }
              );
            }
          })
          .catch(err => console.log(err));

      case "Cliente":
        return createPersonQuery
          .mutateAsync({
            token,
            data,
            endpoint: "CreateClient",
          })
          .then(() => {
            if (!!tagFP) {
              //asign current plant if exist
              assignPlantQuery.mutateAsync(
                {
                  data: {
                    tagFP: tagFP,
                    email: data.email,
                    role: "O",
                    state: "A",
                    actualUserEmail: data.referrer,
                  },
                  token,
                },
                {
                  onSuccess: () => {
                    //manage mora plants to assign
                    callback && callback();
                  },
                }
              );
            } else {
              //asign ManualPlants
              callback && callback();
            }
          })
          .catch(err => console.log(err));

      case "Administrador de Cuentas":
        return createPersonQuery
          .mutateAsync({
            token,
            data,
            endpoint: "CreateAccountManager",
          })
          .then(() => {
            callback && callback();
          })
          .catch(err => console.log(err));

      case "Administrador":
        return createPersonQuery
          .mutateAsync({
            token,
            data,
            endpoint: "CreateAdministrator",
          })
          .then(() => {
            callback && callback();
          })
          .catch(err => console.log(err));
    }
  };

  return {
    createPerson,
    ...status,
  };
};

export const useAdministators = () => {
  const query = useAllPersons();
  const data = query.data
    ? query.data.filter((item: PersonInterface) => item.role === PersonRoleTypes.admin)
    : [];
  return { data, query };
};

export const useSubordinates = () => {
  const token = useToken();
  const { tagFP } = useSessionContext();
  const { data: person } = usePerson();
  return useQuery({
    queryKey: ["Subordinates", person?.id, token, tagFP],
    queryFn: () => getSubordinates("Subordinates", person?.id!, token),
    enabled: !!person?.id && !!token,
  });
};

//custom - Filtered users by Role
export const useClientsByReferer = () => {
  const subordinatesQuery = useSubordinates();
  const personsQuery = useAllPersons(); //admins need all persons
  const { data: user } = useUser();
  const userData = subordinatesQuery.data
    ? subordinatesQuery.data.filter((item: PersonInterface) => item.role === PersonRoleTypes.client)
    : [];
  const adminData = personsQuery.data
    ? personsQuery.data.filter((item: PersonInterface) => item.role === PersonRoleTypes.client)
    : [];
  if (user?.type === "A" || user?.type === "SA") return [adminData, personsQuery];
  else return [userData, subordinatesQuery];
};

export const useOperatorsByReferer = () => {
  const subordinatesQuery = useSubordinates();
  const personsQuery = useAllPersons(); //admins need all persons
  const { data: user } = useUser();
  const userData = subordinatesQuery.data
    ? subordinatesQuery.data?.filter(
        (item: PersonInterface) => item.role === PersonRoleTypes.operator
      )
    : [];
  const adminData = personsQuery.data
    ? personsQuery.data.filter((item: PersonInterface) => item.role === PersonRoleTypes.operator)
    : [];
  if (user?.type === "A" || user?.type === "SA") return [adminData, personsQuery];
  else return [userData, subordinatesQuery];
};

export const useSupervisorsByReferer = () => {
  const subordinatesQuery = useSubordinates();
  const personsQuery = useAllPersons(); //admins need all persons
  const { data: user } = useUser();
  const userData = subordinatesQuery.data
    ? subordinatesQuery.data?.filter(
        (item: PersonInterface) => item.role === PersonRoleTypes.supervisor
      )
    : [];
  const adminData = personsQuery.data
    ? personsQuery.data.filter((item: PersonInterface) => item.role === PersonRoleTypes.supervisor)
    : [];
  if (user?.type === "A" || user?.type === "SA") return [adminData, personsQuery];
  else return [userData, subordinatesQuery];
};

export const useAccountManagersByReferer = () => {
  const query = useSubordinates();
  const queryPersons = useAllPersons();
  const { data: user } = useUser();
  if (!query.data) return query;
  if (user?.type === "A" || user?.type === "SA")
    return (
      queryPersons.data &&
      queryPersons.data.filter(
        (item: PersonInterface) => item.role === PersonRoleTypes.accountMannager
      )
    );
  else
    return query.data.filter(
      (item: PersonInterface) => item.role === PersonRoleTypes.accountMannager
    );
};
