import { FlexContainer } from "../../../../../components/containers";
import { Grid, List, ListItem } from "@material-ui/core";
import ReScheduleRoute from "../../../../../domains/routes/components/ReScheduleRoute";
import { useAllRoutesWithInfo } from "../../../../../domains/routes/services/service.routes";
import { useDate } from "../../../../../shared/date";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { DailyRouteStateType } from "../../../../../domains/routes/services/RoutesInterface";

export const DelayedAndPendingRoutesTable = () => {
  const { data: routesWithInfo } = useAllRoutesWithInfo();
  const { getDateWithoutTime, differenceInText } = useDate();
  const { lang } = useLangLabels();

  return (
    <>
      <FlexContainer padding={"8px 16px"} style={{ borderBottom: "1px solid #f2f2f2" }}>
        <Grid container spacing={0}>
          <Grid item xs>
            <FlexContainer
              height={"100%"}
              align="center"
              justify="left"
              padding={"0px 16px 0px 0px"}
            >
              <p style={{ margin: "0" }}>{lang.routes.singular}</p>
            </FlexContainer>
          </Grid>
          <Grid item xs>
            <FlexContainer
              height={"100%"}
              align="center"
              justify="left"
              padding={"0px 16px 0px 0px"}
            >
              <p style={{ margin: "0" }}>{lang.routes.dateAccordingToSchedule}</p>
            </FlexContainer>
          </Grid>
          <Grid item xs>
            <FlexContainer
              height={"100%"}
              align="center"
              justify="left"
              padding={"0px 16px 0px 0px"}
            >
              <p style={{ margin: "0" }}>{lang.routes.lastRegistrationDate}</p>
            </FlexContainer>
          </Grid>
          <Grid item xs>
            <FlexContainer
              height={"100%"}
              align="center"
              justify="left"
              padding={"0px 16px 0px 0px"}
            >
              <p style={{ margin: "0" }}>{lang.routes.daysSinceLastLubrication}</p>
            </FlexContainer>
          </Grid>
          <Grid item xs>
            <FlexContainer
              height={"100%"}
              align="center"
              justify="left"
              padding={"0px 16px 0px 0px"}
            >
              <p style={{ margin: "0" }}>{lang.routes.state}</p>
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
      <List>
        {routesWithInfo
          ?.filter(
            route =>
              route.state !== "AL DIA" &&
              route.state !== "PROGRAMADA" &&
              route.state !== "PENDIENTE"
          )
          .map(route => (
            <ListItem key={route.routeName}>
              <Grid container spacing={0}>
                <Grid item xs>
                  <FlexContainer
                    height={"100%"}
                    align="center"
                    justify="left"
                    padding={"0px 8px 0px 0px"}
                  >
                    <ReScheduleRoute item={route} />
                    <p style={{ margin: "0" }}>{route.routeName}</p>
                  </FlexContainer>
                </Grid>
                <Grid item xs>
                  <FlexContainer
                    height={"100%"}
                    align="center"
                    justify="left"
                    padding={"0px 8px 0px 0px"}
                  >
                    <p style={{ margin: "0" }}>{getDateWithoutTime(route.scheduledDate)}</p>
                  </FlexContainer>
                </Grid>
                <Grid item xs>
                  <FlexContainer
                    height={"100%"}
                    align="center"
                    justify="left"
                    padding={"0px 8px 0px 0px"}
                  >
                    <p style={{ margin: "0" }}>
                      {route.lastDoneDate
                        ? new Date(getDateWithoutTime(route.lastDoneDate)).getFullYear() !== 2999
                          ? getDateWithoutTime(route.lastDoneDate)
                          : lang.messages.notRegistered
                        : lang.messages.notRegistered}
                    </p>
                  </FlexContainer>
                </Grid>
                <Grid item xs>
                  <FlexContainer
                    height={"100%"}
                    align="center"
                    justify="left"
                    padding={"0px 8px 0px 0px"}
                  >
                    <p style={{ margin: "0" }}>
                      {route.lastDoneDate
                        ? new Date(getDateWithoutTime(route.lastDoneDate)).getFullYear() !== 2999
                          ? differenceInText(route.lastDoneDate, new Date().toString())
                          : lang.messages.notRegistered
                        : lang.messages.notRegistered}
                    </p>
                  </FlexContainer>
                </Grid>
                <Grid item xs>
                  <FlexContainer
                    height={"100%"}
                    align="center"
                    justify="left"
                    padding={"0px 8px 0px 0px"}
                  >
                    <p style={{ margin: "0" }}>
                      {lang.routes.states[route.state as DailyRouteStateType]?.toUpperCase()}
                    </p>
                  </FlexContainer>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </>
  );
};
