import React from "react";
import { createPortal } from "react-dom";
import { usePortal } from "../hooks/usePortal";

const Portal: React.FC<{ key?: string }> = ({ children, key = "react-portal-wrapper" }) => {
  const wrapperElement = usePortal(key);

  // wrapperElement state will be null on very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export default Portal;
