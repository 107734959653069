import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { useMutation } from "../../../shared/react-query/react-query-conf";
import { useToken } from "../../session/store/sessionStore";

//--------------------------------------------
//INTERFACES
//--------------------------------------------

interface EmailWithAttachmentInterface {
  emailAddress: string;
  htmlEmailBody: string;
  fileName: string;
  attachment: string | ArrayBuffer | null;
}

//--------------------------------------------
//AXIOS
//--------------------------------------------

export const SendEmailWithAttachment = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/SendEmailWithAttachment",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  }); /* .then((res)=>res.data) */
};

//--------------------------------------------
//QUERIES
//--------------------------------------------

//--------------------------------------------
//MUTATIONS
//--------------------------------------------

export const useSendEmailWithAttachment = () => {
  const token = useToken();
  const query = useMutation(SendEmailWithAttachment);

  const { status, message, error, reset } = useStatusProcessor(query);

  const sendEmail = (data: EmailWithAttachmentInterface) => {
    if (token)
      return query.mutate({
        token,
        data,
      });
  };

  return {
    sendEmail,
    query: {
      ...query,
      status,
      message,
      error,
      clearStatus: reset,
    },
  };
};
