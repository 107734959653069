import { Popover } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { useSnackbar } from "./hooks/useSnackbar";

type SnackbarVerticalAnchord = number | "top" | "bottom" | "center";
type SnackbarHorizontalAnchord = number | "left" | "right" | "center";

export interface popoverPositionProps {
  anchorOrigin?: {
    vertical: SnackbarVerticalAnchord;
    horizontal: SnackbarHorizontalAnchord;
  };
  transformOrigin?: {
    vertical: SnackbarVerticalAnchord;
    horizontal: SnackbarHorizontalAnchord;
  };
}

const TgdSnackbar: React.FC<popoverPositionProps> = ({ anchorOrigin, transformOrigin }) => {
  const { modal, errors, severity, handleModal, position } = useSnackbar();

  return (
    <>
      <Popover
        open={modal}
        onClose={handleModal}
        anchorReference="anchorPosition"
        anchorPosition={{ top: position?.positionY, left: position?.positionX }}
        anchorOrigin={
          anchorOrigin || {
            vertical: "top",
            horizontal: "left",
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: "bottom",
            horizontal: "right",
          }
        }
      >
        <Alert onClose={handleModal} severity={severity}>
          {errors}
        </Alert>
      </Popover>
    </>
  );
};

export default TgdSnackbar;
