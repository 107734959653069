import { useUser } from "../../domains/session/store/sessionStore";
import { getDate } from "../globals/utils/utils";
import { queryClient, useQuery } from "../react-query/react-query-conf";
import { getLocalStorage, setLocalStorage } from "./localStorage";

type LocalStorageConfig = {
  unique?: boolean;
  enabled?: boolean;
  defaultUserEmail?: string;
};
export const useLocalStorage = <TResult,>(
  key: string,
  initialState?: TResult,
  config?: LocalStorageConfig
): [TResult | undefined, (item: any) => void] => {
  //instance to set enable timing to fetch
  const _enabled = config?.enabled;
  const _unique = config?.unique;
  //user that localstorage data belongs to
  const { data: user } = useUser();

  //Current date
  const date = getDate().nowDate();

  //Key with User belongning
  const _user = config?.defaultUserEmail ? config.defaultUserEmail : user?.email;
  const _Key = _unique ? `${key}-${_user}` : key;

  //localData
  const localData = getLocalStorage<TResult>(_Key);

  const setValue = (value: TResult) => {
    setLocalStorage(_Key, value);
    queryClient.setQueryData(_Key, value);
  };

  const { data } = useQuery({
    queryKey: _Key,
    queryFn: () => {
      if (initialState) {
        if (date != localData?.date) {
          setLocalStorage(_Key!, initialState);
          return initialState;
        } else {
          return localData.value;
        }
      } else {
        return localData?.value;
      }
    },
    enabled: !!date && !!_enabled,
    refetchOnWindowFocus: false,
  });

  return [data, setValue];
};
