import { Drawer as BaseDrawer, DrawerProps } from "@material-ui/core";
import React from "react";
import CustomButton from "../../buttons/CustomButton";
import { useModal } from "../modal/Modal/hooks/useModal";
import { useTgdDrawerStyles } from "./styles/useTgdDrawerStyles";
import { cssDrawerAtributesInterface, DrawerPropsAnchordTypes } from "./types/TgdDrawerTypes";
import {
  ButtonColorType,
  ButtonVariant,
  CustomIconType,
} from "../../buttons/types/CustomButtonTypes";

type DrawerVariant = "permanent" | "persistent" | "temporary" | undefined;

type conditionalDrawerTypes =
  | {
      mode: "content";
    }
  | {
      mode?: "button";
    };

type commonDrawerType = {
  styles?: cssDrawerAtributesInterface;
  icon?: CustomIconType;
  popoverTitle?: string;
  variant?: ButtonVariant;
  color?: ButtonColorType;
  anchor?: DrawerPropsAnchordTypes;
  disableEscapeKeyDown?: boolean;
  drawerVariant?: DrawerVariant;
  onClose?: () => void;
  open?: boolean;
};

export type TgdDrawerProps = conditionalDrawerTypes & commonDrawerType;

const Drawer: React.FC<TgdDrawerProps> = ({
  children,
  mode,
  open,
  drawerVariant,
  onClose,
  styles,
  variant,
  color,
  icon,
  popoverTitle,
  anchor,
  disableEscapeKeyDown,
}) => {
  //modal controller
  const { isOpen, toggleModal } = useModal(true);

  // styles
  const classes = useTgdDrawerStyles({
    ...styles,
    drawerWidth: styles?.drawerWidth || "250px",
    backgroundColor: styles?.backgroundColor || "FFF",
  });

  const defaultMode = mode || "button";

  //mode control from parent
  const _open = open !== undefined ? open : isOpen;
  //control drawerClose
  const handleClose = () => {
    onClose ? onClose() : toggleModal();
  };

  //conditional props fordrawer
  const conditionalProps: DrawerProps = {
    anchor,
    open: _open,
    onClose: handleClose,
    className: classes.drawer,
    disableEscapeKeyDown,
    children,
    variant: drawerVariant,
    classes: {
      paper: classes.drawerPaper,
    },
    style: {
      zIndex: 1200,
    },
  };

  return (
    <>
      {defaultMode === "button" && (
        <CustomButton
          {...{
            action: handleClose,
            icon: icon || "add",
            popoverTitle,
            variant,
            color,
          }}
        />
      )}
      <BaseDrawer {...conditionalProps} />
    </>
  );
};

export default Drawer;
