import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useAreasGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    area: { label: lang.areas.title.singular },
    email: { label: lang.areas.form.label.email },
    referrer: { label: lang.areas.form.label.referrer },
    tagFP: { label: lang.globalentityValues.tagFP },
    date: { label: lang.globalentityValues.date },
  };

  return gridModel;
};
