import { useEffect, useState } from "react";
import { useUser } from "../../domains/session/store/sessionStore";

import LZString from "lz-string";

import {
  BasicLocalStorageHookProps,
  CallBack,
  ItemWithTimestamp,
  SetValue,
  UseBasicLocalStorage,
} from "./types/localStorageTypes";

const useBasicLocalStorage = <T extends any>({
  key,
  initialValue,
  storeLocal = true,
  storeWithEmail = true,
  erasable = true,
  useLastUser = true,
  getStoredValueMiddleware = value => value,
  throwError,
}: BasicLocalStorageHookProps<T> & { throwError?: boolean }): UseBasicLocalStorage<T> => {
  const { data: user } = useUser();

  const lastUserString = useLastUser ? localStorage.getItem("last-user") : null;
  const lastUser = lastUserString ? JSON.parse(lastUserString) : null;

  const userEmail = user?.email ?? (useLastUser ? lastUser?.email : null);

  const cacheKey = storeLocal ? (storeWithEmail ? `${userEmail}-${key}` : `${key}`) : null;

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (cacheKey) {
        const storedItem = localStorage.getItem(cacheKey);
        const parsedItem = storedItem
          ? getStoredValueMiddleware(JSON.parse(LZString.decompress(storedItem) || "{}"))
          : initialValue;

        return parsedItem?.data ?? initialValue;
      }

      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  // Effect to update the storedValue when userEmail changes
  useEffect(() => {
    if (cacheKey) {
      try {
        const storedItem = localStorage.getItem(cacheKey);
        const parsedItem = storedItem
          ? getStoredValueMiddleware(JSON.parse(LZString.decompress(storedItem) || "{}"))
          : initialValue;

        setStoredValue(parsedItem);
      } catch (error) {
        console.error(error);
      }
    }
  }, [cacheKey]); // Execute the effect when cacheKey changes

  /*
  This function checks and removes the key from Local Storage if its value is:
  - An empty object
  - An empty array
  - An empty text string
  - An object with all attributes being falsy
  */
  const clearKeyIfEmpty = () => {
    if (cacheKey) {
      const storedItem = localStorage.getItem(cacheKey);
      const parsedItem = storedItem ? JSON.parse(LZString.decompress(storedItem) || "{}") : null;

      if (parsedItem !== null && parsedItem !== undefined) {
        if (
          // If it's an empty array
          (Array.isArray(parsedItem) && parsedItem.length === 0) ||
          // If it's an empty object
          (typeof parsedItem === "object" && Object.keys(parsedItem).length === 0) ||
          // If it's an object with all attributes being falsy
          Object.values(parsedItem).every(value => value === false) ||
          // If it's an empty text string
          (typeof parsedItem === "string" && parsedItem === "")
        ) {
          localStorage.removeItem(cacheKey);
        }
      }
    }
  };

  // Function to set the value, which can be a direct value or a transformation function
  const setValue: SetValue<T> = value => {
    try {
      const newValue =
        typeof value === "function"
          ? (value as CallBack<T>)(storedValue) // If it's a function, apply the transformation
          : value;

      setStoredValue(newValue);

      if (cacheKey) {
        const itemToStore = {
          data: newValue,
          savedAt: new Date().toISOString(),
          erasable: erasable,
        };

        const compressedValue = LZString.compress(JSON.stringify(itemToStore));

        try {
          localStorage.setItem(cacheKey, compressedValue);
          clearKeyIfEmpty();
          if (throwError) {
            console.warn("Quota exceeded, cleaning up old data...");
            cleanUpOldLocalStorage(25);
            localStorage.setItem(cacheKey, compressedValue);
          }
        } catch (error: any) {
          if (error.name === "QuotaExceededError") {
            console.warn("Quota exceeded, cleaning up old data...");
            cleanUpOldLocalStorage(25);
            localStorage.setItem(cacheKey, compressedValue);
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cleanUpOldLocalStorage = (percentage: number) => {
    const keys = Object.keys(localStorage);

    const itemsWithTimestamps: ItemWithTimestamp[] = keys
      .map(key => {
        const item = localStorage.getItem(key);
        try {
          const parsedItem = item ? JSON.parse(LZString.decompress(item) || "{}") : null;
          return parsedItem?.erasable
            ? {
                key,
                savedAt: parsedItem?.savedAt || new Date().toISOString(),
              }
            : null;
        } catch {
          return null;
        }
      })
      .filter(item => item !== null) as ItemWithTimestamp[];

    // Order the items by timestamp
    itemsWithTimestamps.sort(
      (a, b) => new Date(a.savedAt).getTime() - new Date(b.savedAt).getTime()
    );

    // Calculate the number of records to delete
    const recordsToDelete = Math.floor(itemsWithTimestamps.length * (percentage / 100));

    for (let i = 0; i < recordsToDelete; i++) {
      localStorage.removeItem(itemsWithTimestamps[i].key);
    }
  };

  return [storedValue, setValue];
};

export default useBasicLocalStorage;
