import React, { useState } from "react";
//material
import { Paper, Typography } from "@material-ui/core";
import CustomButton from "../../../components/buttons/CustomButton";
import DuoContainer from "../../../components/containers/DuoContainer";
import FlexContainer from "../../../components/containers/FlexContainer";
import TGDModal from "../../../components/containers/modal/TGDModal/TGDModal";
import PageContainer from "../../../components/containers/PageContainer";
import SidebarContainer from "../../../components/containers/SidebarContainer";
import EntityList from "../../../components/grids/EntityList";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import AssignCancelPlantsRefactor from "../../plants/AssignCancelPlantsRefactor";
import { useUser } from "../../session/store/sessionStore";
import { PersonInterface } from "../models/PersonInterface";
import { useOperatorsByReferer } from "../services/personServices";
import AddPersonForm from "./AddPersonForm";
import UpdatePersonForm from "./UpdatePersonForm";

const AddOperatorRefactor: React.FC = () => {
  const { data: user } = useUser();
  const { lang } = useLangLabels();
  const [operators, { status: operatorStatus }] = useOperatorsByReferer();
  const [open, setOpen] = useState(false);
  const [focusPersonData, setFocusPersonData] = useState<any>();

  const handleModal = () => {
    setOpen(!open);
  };

  const handlePerson = (data: PersonInterface) => {
    setFocusPersonData(data);
    handleModal();
  };

  const assignPlants = (item: any) => {
    handleModal();
    setFocusPersonData(item);
  };

  const customButtons = (item: any) => {
    if (user && user?.type !== "S" && user?.type !== "O") {
      return (
        <CustomButton
          variant="iconButton"
          icon="business"
          action={() => assignPlants(item)}
          color={"primary"}
          popoverTitle={lang.plants.asignCancelPlants}
        />
      );
    }
  };

  return (
    <OnlineOfflineMode reverse>
      <SidebarContainer>
        <EntityList
          title={lang.persons.operators}
          icon={"person"}
          status={operatorStatus}
          items={operators}
          customButtons={customButtons}
          dropDownChildren={item => (
            <PageContainer>
              <UpdatePersonForm person={item} enableFunction />
            </PageContainer>
          )}
        />
        <Paper>
          <FlexContainer border={"4px 0px 0px solid red"} />
          <PageContainer padding="16px 16px 0px">
            <Typography component={"h6"}>{lang.persons.newOperator}</Typography>
          </PageContainer>
          <AddPersonForm role="Operario" handlePerson={handlePerson} enableFunction />
        </Paper>
      </SidebarContainer>

      <TGDModal title={lang.plants.asignCancelPlants} open={open} handleClose={handleModal}>
        <DuoContainer>
          <AssignCancelPlantsRefactor
            action="assign"
            roleToEdit={"O"}
            title={lang.plants.clickToAssign}
            userData={focusPersonData}
          />

          <AssignCancelPlantsRefactor
            userData={focusPersonData}
            roleToEdit={"O"}
            action={"cancel"}
            title={lang.plants.clickToCancel}
          />
        </DuoContainer>
      </TGDModal>
    </OnlineOfflineMode>
  );
};

export default AddOperatorRefactor;
