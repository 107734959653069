import { CircularProgress, Paper } from "@material-ui/core";
import React, { useState } from "react";
import FlexContainer from "../../components/containers/FlexContainer";
import { LubricationPointInterface } from "../../domains/lubricationPoints/model/lubricationPointsInterface";
import { useLubricationPointsAndInfoByTagFP } from "../../domains/lubricationPoints/services/lubricationPointsAdapters";
import { SurveyInterface } from "../../domains/surveys/model/SurveyInterface";
import { EquipmentDetail } from "./components/EquipmentDetail";
import ExploreRevisionChangeHistory from "./components/ExploreRevisionChangeHistory";
import { LubricationPointsNavigation } from "./components/LubricationPointsNavigation";
import { SurveyFilters } from "./components/SurveyFilters";
import { SurveyList } from "./components/SurveyList";

export const ExplorePage: React.FC = () => {
  const { data: lubricationPoints } = useLubricationPointsAndInfoByTagFP();
  const [selectedPoint, setSelectedPoint] = useState<
    LubricationPointInterface | null | undefined
  >();
  const [surveys, setSurveys] = useState<SurveyInterface[]>([]);

  const handleSelectedPoints = (point: LubricationPointInterface) => {
    setSelectedPoint(point);
  };

  const handleSurveys = (surveys: SurveyInterface[]) => {
    setSurveys(surveys);
  };

  if (!lubricationPoints) {
    return (
      <Paper>
        <FlexContainer align="center" height={"200px"} justify="center">
          <CircularProgress />
        </FlexContainer>
      </Paper>
    );
  }

  return (
    <FlexContainer flexDirection="column">
      <LubricationPointsNavigation
        lubricationPoints={lubricationPoints}
        handleChange={handleSelectedPoints}
      />
      <Paper>
        <EquipmentDetail lubricationPoint={selectedPoint} />
      </Paper>
      <Paper>
        <SurveyFilters lubricationPoint={selectedPoint} handleChange={handleSurveys} />
      </Paper>
      <SurveyList surveys={surveys} lubricationPoint={selectedPoint} />
      <ExploreRevisionChangeHistory tagTGD={selectedPoint?.tagTGD || ""} />
    </FlexContainer>
  );
};
