import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { criticalityTypes } from "../models/criticalityInterface";

interface SelectColorProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectCriticalityType: React.FC<SelectColorProps> = ({
  onChange,
  defaultValue,
  error,
  disabled,
}) => {
  const showTitle = "label";
  const { lang } = useLangLabels();
  const label = lang.criticalities.form.type;

  return (
    <SimpleAutocompleteSelect
      {...{
        items: criticalityTypes,
        showTitle,
        onChange,
        defaultValue,
        error,
        label,
        disabled,
      }}
    />
  );
};

export default SelectCriticalityType;
