export const getDateWithDayPlusOne = (originalSecondDate: string = "") => {
  const dateParts = originalSecondDate.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);

  const originalDate = new Date(year, month, day);

  originalDate.setDate(originalDate.getDate() + 1);

  const newYear = originalDate.getFullYear();
  const newMonth = originalDate.getMonth() + 1;
  const newDay = originalDate.getDate();

  // Formateamos la nueva fecha
  const newSecondDate = `${newYear}-${newMonth < 10 ? "0" + newMonth : newMonth}-${
    newDay < 10 ? "0" + newDay : newDay
  }`;

  return newSecondDate;
};
