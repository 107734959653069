import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CriticalityInterface } from "../models/criticalityInterface";

export const useCriticalityForm = (tagFP: string, item?: CriticalityInterface) => {
  const { lang } = useLangLabels();

  return useForm<CriticalityInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      validations: {
        required: { value: !!item, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
    number: {
      label: lang.criticalities.form.number,
      placeholder: lang.criticalities.form.number,
      value: item?.number || 0,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.number,
    },
    description: {
      label: lang.criticalities.form.descripton,
      placeholder: lang.criticalities.form.descripton,
      value: item?.description || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.text,
    },
    days: {
      label: lang.criticalities.form.days,
      placeholder: lang.criticalities.form.days,
      value: item?.days || 0,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.number,
    },
    color: {
      label: lang.criticalities.form.color,
      placeholder: lang.criticalities.form.color,
      value: item?.color || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.color,
    },
    criticalityType: {
      label: lang.criticalities.form.type,
      placeholder: lang.criticalities.form.type,
      value: item?.criticalityType || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.criticalityType,
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
  });
};
