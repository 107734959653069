import { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { useSessionContext } from "../../session/store/sessionContext";
import UserRoleManager from "../../users/UserRoleManager";

import { RouteInterface } from "../services/RoutesInterface";
import { useCreateRoute } from "../services/service.routes";
import { useRouteExistance } from "../services/useRouteExistance";

import DefaultFormLayout from "../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../shared/form/components/FieldSelector";

import { FlexContainer } from "../../../components/containers";
import { useCreateRouteForm } from "../services/useCreateRouteForm";

interface Props {}

const CreateRouteForm: React.FC<Props> = () => {
  const { token, tagFP } = useSessionContext();

  const { lang } = useLangLabels();
  const today = new Date().toISOString().slice(0, 10);

  const query = useCreateRoute();
  const { status, message, error, reset: statusProcesorReset } = useStatusProcessor(query);

  const { formattedUiModel, formData, setValue, setValidations, handleSubmit, resetForm } =
    useCreateRouteForm({ tagFP });

  const [errorExist, seterrorExist] = useState("");
  const routeExist = useRouteExistance(formData.route, tagFP);

  const handleReset = () => {
    statusProcesorReset();
    resetForm();
    seterrorExist("");
  };

  const submit = (route: RouteInterface) => {
    query.mutate({
      data: {
        operator: route.operator,
        periodicity: route.periodicity,
        route: route.route,
        routeName: `${route.operator}-${route.periodicity}-${route.route}`,
        startDate: `${route.startDate}T12:00:00.000Z`,
        tagFP: route.tagFP,
      },
      token,
    });
  };

  useEffect(() => {
    setValidations("startDate", "validate", (value: any) => {
      if (routeExist) {
        return true;
      }
      return value >= today ? true : "no puede ser anterior a hoy";
    });

    if (routeExist) {
      setValue("operator", Number(routeExist.compatibleRoute.operator));
      setValue("periodicity", routeExist.compatibleRoute.periodicity);
      setValue("startDate", routeExist.compatibleRoute.startDate.slice(0, 10));
      seterrorExist(routeExist.message);
    } else {
      setValue("operator", "");
      setValue("periodicity", "");
      setValue("startDate", "");
      seterrorExist("");
    }
  }, [formData.route]);

  return (
    <UserRoleManager allowedUserTypes={["SA", "A", "AC"]}>
      <DefaultFormLayout
        componentMode={"modal"}
        buttonIcon={"add"}
        buttonVariant={"button"}
        buttonText={lang.actionTypes.create + " " + lang.routes.route}
        popoverTitle={lang.actionTypes.create + " " + lang.routes.route}
        {...{ queryStatus: status, error, alertStatusMessage: message }}
        buttonAction={handleSubmit(submit)}
        onClose={handleReset}
        resetForm={handleReset}
        buttondDisabled={routeExist ? !routeExist.canCreate : false}
      >
        {!!errorExist && (
          <Alert onClose={handleReset} severity="warning">
            {errorExist}
          </Alert>
        )}

        <br />

        <FlexContainer>
          <FieldSelector fieldProps={{ ...formattedUiModel.operator } as any} />
          <FieldSelector fieldProps={{ ...formattedUiModel.periodicity } as any} />
          <FieldSelector fieldProps={{ ...formattedUiModel.route } as any} />
        </FlexContainer>

        <FieldSelector
          fieldProps={
            {
              ...formattedUiModel.startDate,
              readonly: !!routeExist,
              variant: "outlined",
              autoFocus: false,
            } as any
          }
        />

        <br />

        {status === "loading" && (
          <Typography color="error">Calculando las fechas anuales...</Typography>
        )}
        <br />
      </DefaultFormLayout>
    </UserRoleManager>
  );
};

export default CreateRouteForm;
