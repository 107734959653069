import LubricationModuleApp from "./modules/lubrication/LubricationModuleApp";
import { MODE, MODES } from "./config";
import InstrumentsModuleApp from "./modules/instruments/InstrumentsModuleApp";
import { useTGDSignature } from "./shared/config/utils/useTGDsignature";

const App = () => {
  useTGDSignature();

  const renderModule = () => {
    switch (MODE) {
      case MODES.LUBRICATION:
        return LubricationModuleApp();
      case MODES.INSTRUMENTS:
        return InstrumentsModuleApp();
      default:
        return null;
    }
  };

  return <>{renderModule()}</>;
};

export default App;
