import { useCallback, useMemo, useState } from "react";

export const useCarrousell = (itemsCount: number) => {
  const [_current, setCurrent] = useState(0);
  const canPrev = useMemo(() => _current > 0, [_current]);
  const canNext = useMemo(() => _current < itemsCount - 1, [_current, itemsCount]);

  const handleNext = useCallback(() => {
    canNext && setCurrent(_current + 1);
  }, [canNext, _current]);

  const handlePrev = useCallback(() => {
    canPrev && setCurrent(_current - 1);
  }, [canPrev, _current]);

  const handleReset = () => {
    setCurrent(0);
  };

  const current = useMemo(() => (_current >= itemsCount ? 0 : _current), [_current, itemsCount]);

  return {
    current,
    handleNext,
    handlePrev,
    handleReset,
    canPrev,
    canNext,
    itemsCount,
  };
};
