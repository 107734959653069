import { useMemo } from "react";
import { flattenObjAllLevels } from "../globals/utils/dataFiltering";
import { GridModelInterface } from "../gridModel/gridModelEntity";
import createFile from "./exportXlsx";

export const useExportXlsx = (
  items: any[],
  documentTitle?: string,
  gridModel?: GridModelInterface,
  hiddenColumns?: { [key: string]: boolean }
) => {
  const _items = useMemo(
    () =>
      gridModel
        ? items?.map(i => {
            let result = {};
            Object.entries(flattenObjAllLevels(i)).forEach(([key, value]) => {
              if (gridModel[key] && (hiddenColumns ? !hiddenColumns[key] : true)) {
                const label = gridModel[key].label.toUpperCase();
                const options = gridModel[key].options;
                result = {
                  ...result,
                  [label]: options ? options.find(o => o.value === value)?.name : value,
                };
              }
            });
            return result;
          }) || []
        : items,
    [gridModel, hiddenColumns, items]
  );

  /*   const {data:user} = useUser() */

  const { file, downloadFile } = useMemo(
    () =>
      createFile({
        documentTitle,
        authorEmail: /* user?.email! */ "martin.gm.giordano@gmail.com",
        items: _items,
      }),
    [_items, documentTitle /*  user?.email */]
  );

  //
  return { downloadFile, file /* ,documentTitle */ };
};
