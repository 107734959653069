import { getLocalStorage, setLocalStorage } from "../../../shared/localStorage/localStorage";
import { layoutFactory } from "../../models/layoutFactory";
import { layoutInterface } from "../../models/layoutInterface";

export const LAYOUT_KEY = "layout";

export const getLayoutPersistance = () => {
  const state = getLocalStorage<layoutInterface>(LAYOUT_KEY)?.value;
  return layoutFactory(state);
};

export const setPersistLayoutState = (state: layoutInterface, callback: () => void) => {
  setLocalStorage(LAYOUT_KEY, state);
  callback();
};
