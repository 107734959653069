import { Route, Navigate } from "react-router-dom";
import Reports from "../../../../../../domains/powerBi/components/Reports";
import { useSessionContext } from "../../../../../../domains/session/store/sessionContext";
import ClientHome from "../../../../../../pages/backoffice/ClientVisor/home/ClientHome";
import PlantSelector from "../../../../../../pages/backoffice/ClientVisor/PlantSelector";
import ConfigPage from "../../../../../../pages/backoffice/ConfigPage";
import DemoUsersPage from "../../../../../../pages/backoffice/DemoUsersPage";
import UserPanel from "../../../../../../pages/backoffice/UserPanel";
import { USER_ROUTES } from "../../../../../../shared/routes/routes";

const useClientRoutes = () => {
  const { currentPbigroupName, user, isMultiplePlantUser } = useSessionContext();

  return (
    <Route path={USER_ROUTES.client}>
      <Route
        path={currentPbigroupName}
        element={isMultiplePlantUser ? <PlantSelector /> : <UserPanel />}
      >
        <Route path="" element={<ClientHome />} />
        <Route path="*" element={<Reports />} />
        <Route path={USER_ROUTES.config} element={<ConfigPage />} />
        {user && user?.email === "d.lavallen@tgdsa.com" && (
          <Route path={`${USER_ROUTES.demoUsers}/*`} element={<DemoUsersPage />} />
        )}
      </Route>
      <Route path="" element={<Navigate to={currentPbigroupName} />} />
      <Route path="*" element={<Navigate to={currentPbigroupName} />} />
    </Route>
  );
};

export default useClientRoutes;
