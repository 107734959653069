import React from "react";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface SelectOilAnaliysisProps {
  error?: string;
  onChange: any;
  value: any;
}

export const SelectOilAnalysis: React.FC<SelectOilAnaliysisProps> = ({
  error,
  onChange,
  value,
}) => {
  const { lang } = useLangLabels();

  const options = [
    { label: lang.words.yes.toUpperCase(), value: true },
    { label: lang.words.no.toUpperCase(), value: false },
  ];

  const parseDefaultValue = options.find(o => o.value === value || o.label === value)?.label;

  const label = lang.lubricationPoints.oilAnalysis;

  return (
    <SimpleAutocompleteSelect
      key={value}
      error={error}
      label={label}
      items={options}
      showTitle={"label"}
      onChange={onChange}
      defaultValue={parseDefaultValue}
    />
  );
};
