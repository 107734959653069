import { useEffect } from "react";
import { PersonInterface } from "../../../../../domains/person/models/PersonInterface";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import FeatureStatusContainer, {
  useFeatures,
} from "../../../../../shared/config/features/FeatureStatusContainer";
import LubricatorPanel from "../../../../../domains/surveys/components/LubricatorPanel";

interface OperatorComponentProps {
  operator: PersonInterface;
}

export const OperatorComponent = ({ operator }: OperatorComponentProps) => {
  const { selectOperator } = useSessionContext();
  const features = useFeatures();

  useEffect(() => {
    selectOperator(operator);
  }, []);

  return (
    <FeatureStatusContainer feature={features.supervisorSurvey}>
      <LubricatorPanel />
    </FeatureStatusContainer>
  );
};
