import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import { errorsLog } from "../../../../shared/globals/utils/utils";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import SelectCriticality from "../../../criticalities/components/SelectCriticality";
import { CriticalityTypes } from "../../../criticalities/models/criticalityInterface";
import SelectSector from "../../../sectors/atom/SelectSector";
import { useSessionContext } from "../../../session/store/sessionContext";
import SelectTypeBy from "../../../types/components/SelectTypeBy";
import { enumTgdTypes } from "../../../types/models/types";
import { EquipmentInterface, equipmentType } from "../../model/lubricationPointsInterface";
import {
  useEquipmentsTag,
  useUpdateEquipmentNoImpact,
} from "../../services/lubricationPointsAdapters";

interface Props {
  item: EquipmentInterface;
}

const UpdateEquipment: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { token, tagFP } = useSessionContext();
  const { register, setValue, handleSubmit, watch, errors, control } = useForm();
  const query = useUpdateEquipmentNoImpact();
  const { status, message, error } = useStatusProcessor(query);
  const { getTag } = useEquipmentsTag();

  const submit = (data: EquipmentInterface) => {
    query.mutate({
      data: {
        tagFP: item?.tagFP,
        tagTGD: `${getTag(item, equipmentType.equipment)}`,
        sector: data.sector.toUpperCase(),
        plantTag: data.plantTag.toUpperCase(),
        equipment: data.equipment.toUpperCase(),
        type: data.type.toUpperCase(),
        criticality: data.criticality.toUpperCase(),
      },
      token,
    });
  };

  useEffect(() => {
    register("criticality", {
      required: { value: true, message: lang.validations.messages.required },
    });
  }, [register]);

  errorsLog(errors);

  return (
    <>
      <DefaultFormLayout
        buttonAction={handleSubmit(submit)}
        queryStatus={status}
        alertStatusMessage={message}
        error={error}
        buttonText={lang.crudActions.update + " " + lang.STIEquipments.title.singular}
        buttonIcon="edit"
        buttonVariant="iconButton"
        popoverTitle={lang.crudActions.update + " " + lang.STIEquipments.title.singular}
      >
        <TextField
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
          })}
          name="plantTag"
          placeholder={lang.lubricationPoints.plantTag}
          defaultValue={item?.plantTag}
          variant="outlined"
          label={errors?.plantTag?.message || lang.lubricationPoints.plantTag}
          color={errors?.plantTag?.message ? "primary" : "secondary"}
          size="small"
        />

        <TextField
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
          })}
          name="equipment"
          placeholder={lang.lubricationPoints.equipmentName}
          defaultValue={item?.equipment}
          variant="outlined"
          label={errors?.equipment?.message || lang.lubricationPoints.equipmentName}
          color={errors?.equipment?.message ? "primary" : "secondary"}
          size="small"
        />

        <Controller
          as={
            <SelectSector
              error={errors?.sector?.message}
              onChange={(value: string) => setValue("sector", value)}
              defaultValue={watch("sector", item?.sector)}
            />
          }
          name="sector"
          defaultValue={item?.sector}
          rules={{ required: { value: true, message: lang.validations.messages.required } }}
          control={control}
        />

        <Controller
          as={
            <SelectTypeBy
              error={errors?.type?.message}
              by={{ key: "type", value: enumTgdTypes.equipment }}
              tagFP={tagFP}
              onChange={value => setValue("type", value)}
              defaultValue={watch("type")}
            />
          }
          name="type"
          defaultValue={item?.type}
          rules={{ required: { value: true, message: lang.validations.messages.required } }}
          control={control}
        />

        <SelectCriticality
          by={{ key: "criticalityType", value: CriticalityTypes.equipment }}
          error={errors?.criticality?.message}
          color={errors?.criticality?.message ? "primary" : "secondary"}
          onChange={value => setValue("criticality", value)}
          defaultValue={watch("criticality", item?.criticality)}
        />
      </DefaultFormLayout>
    </>
  );
};

export default UpdateEquipment;
