import { FlexContainer } from "../../../../../components/containers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { IconButton } from "@material-ui/core";
import LubricationPointsList from "../../../../lubricationPoints/components/operator/LubricationPointsList";
import { CountPendingEquipments } from "../components/CountPendingEquipments";
import { USER_ROUTES } from "../../../../../shared/routes/routes";
import LeakLevelComponent from "../../../../infoLubricationPoints/components/LeakLevelComponent";
import EquipmentInfo from "../../../../infoLubricationPoints/components/EquipmentInfo";
import LubricationPointSurvey from "../../../components/LubricationPointSurvey/LubricationPointSurvey";
import { LubricationPointInterface } from "../../../../lubricationPoints/model/lubricationPointsInterface";
import { lubricatorState } from "../../../../users/lubricator/lubricatorEntity";
import theme from "../../../../../assets/theme";
import { CustomIcon } from "../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { DailyRouteStateType } from "../../../../routes/services/RoutesInterface";

const useTabsMenuItems = (data: lubricatorState | undefined) => {
  const { lang } = useLangLabels();

  const pendingPointsDone = data?.previusPendingLubricationPoints.filter(
    point => point.info?.pending === false
  ).length;
  const totalPendingPoints = data?.previusPendingLubricationPoints.length;
  const pendingPointsTitle = data
    ? `${pendingPointsDone?.toString().padStart(2, "0")}/${totalPendingPoints
        ?.toString()
        .padStart(2, "0")}`
    : "...";

  const dailyPointsDone = data?.dailyPoints.filter(point => point.info?.pending === false).length;
  const totalDailyPoints = data?.dailyPoints.length;
  const todayPointsTitle = data
    ? `${dailyPointsDone?.toString().padStart(2, "0")}/${totalDailyPoints
        ?.toString()
        .padStart(2, "0")}`
    : "...";

  const dailyRoutes = data?.dailyRoutes.map(route => ({
    ...route,
    state: lang.routes.states[route.state as DailyRouteStateType]?.toUpperCase(),
  }));

  return [
    {
      route: "",
      label: lang.routes.plural,
      component: "",
      data: {
        items: dailyRoutes,
        primaryText: ["routeName"],
        secondaryText: [{ key: "state", label: lang.routes.state }],
        renderOption: (item: any) => (
          <FlexContainer align="center">
            <LubricationPointsList type="modal" item={item} />
            <IconButton
              size={"small"}
              color={"secondary"}
              edge="end"
              aria-label={lang.lubricationPoints.equipmentState}
            >
              {item.state !== "INICIADA" ? (
                <RadioButtonUncheckedIcon />
              ) : (
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              )}
            </IconButton>
            <CountPendingEquipments item={item} />
          </FlexContainer>
        ),
        badgeConbtent: undefined,
      },
    },
    {
      route: `${USER_ROUTES.delayedPoints}`,
      label: `${lang.lubricationPoints.delayedPoints}${data ? ": " + totalPendingPoints : ""}`,
      component: "",
      data: {
        title: `${lang.lubricationPoints.delayedPoints}: ${pendingPointsTitle}`,
        items: data?.previusPendingLubricationPoints,
        primaryText: ["tagTGD", "component", "sector", "lubricant"],
        secondaryText: [
          {
            key: "plantTag",
            label: lang.lubricationPoints.plantTag,
          },
          {
            key: "element",
            label: lang.lubricationPoints.element,
          },
          {
            key: "sector en planta",
            label: lang.sectors.form.label.sector,
          },
          {
            key: "lubricantType",
            label: lang.lubricationPoints.lubricantType,
          },
        ],
        badgeContent: data?.previusPendingLubricationPoints?.length || 0,
        bulkActions: (items: LubricationPointInterface[]) => (
          <LubricationPointSurvey outOfRoute={false} items={items} />
        ),
        renderOption: (item: any) => (
          <FlexContainer align={"center"} width={"100%"} justify="space-between">
            <FlexContainer>
              <EquipmentInfo variant="modal" items={[item]} />
              <LeakLevelComponent
                lastlubricantCuantity={item.info.lubricantQuantity}
                pointCapacity={item.capacity}
              />
            </FlexContainer>
            <FlexContainer align="center">
              {item.info?.pending && !item.info?.inaccessible && (
                <CustomIcon icon="circleOutlined" style={{ color: theme.palette.secondary.main }} />
              )}

              {item.info?.pending ? (
                item.info?.inaccessible && (
                  <CustomIcon icon="cancelOutlined" style={{ color: "red" }} />
                )
              ) : (
                <CustomIcon icon="checkedCircle" style={{ color: "green" }} />
              )}
            </FlexContainer>
          </FlexContainer>
        ),
      },
    },
    {
      route: `hoy`,
      label: `${lang.lubricationPoints.todayPoints}${data ? ": " + totalDailyPoints : ""}`,
      component: "",
      data: {
        title: `${lang.lubricationPoints.todayPoints}: ${todayPointsTitle}`,
        items: data?.dailyPoints,
        primaryText: ["tagTGD", "component", "sector", "lubricant"],
        secondaryText: [
          {
            key: "plantTag",
            label: lang.lubricationPoints.plantTag,
          },
          {
            key: "element",
            label: lang.lubricationPoints.element,
          },
          {
            key: "sector en planta",
            label: lang.sectors.form.label.sector,
          },
          {
            key: "lubricantType",
            label: lang.lubricationPoints.lubricantType,
          },
        ],
        renderOption: (item: any) => (
          <FlexContainer align={"center"} width={"100%"} justify="space-between">
            <FlexContainer>
              <EquipmentInfo variant="modal" items={[item]} />
              <LeakLevelComponent
                lastlubricantCuantity={item.info.lubricantQuantity}
                pointCapacity={item.capacity}
              />
            </FlexContainer>
            <FlexContainer align="center">
              {item.info?.pending && !item.info?.inaccessible && (
                <CustomIcon icon="circleOutlined" style={{ color: theme.palette.secondary.main }} />
              )}

              {item.info?.pending ? (
                item.info?.inaccessible && (
                  <CustomIcon icon="cancelOutlined" style={{ color: "red" }} />
                )
              ) : (
                <CustomIcon icon="checkedCircle" style={{ color: "green" }} />
              )}
            </FlexContainer>
          </FlexContainer>
        ),
        bulkActions: (items: LubricationPointInterface[]) => (
          <LubricationPointSurvey outOfRoute={false} items={items} />
        ),
        badgeContent: undefined,
      },
    },
  ];
};

export default useTabsMenuItems;
