import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { useTypeGridModel } from "../../../../domains/types/services/useTypesGridModel";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import CreateUpdateTypeForm from "../../domain/instrument-types/components/CreateUpdateTypeForm";
import { useGetAllInstrumentTypes } from "../../domain/instrument-types/services/instrumentTypeService";

export const ManageInstrumentTypesPage = ({ fixedHeight }: { fixedHeight?: number }) => {
  const { lang } = useLangLabels();

  const { tagFP } = useSessionContext();
  const gridModel = useTypeGridModel();

  const { data: types, isFetching } = useGetAllInstrumentTypes();

  return (
    <>
      <VirtualGrid
        title={lang.types.plural}
        fixedHeight={fixedHeight}
        entityName={lang.types.plural}
        items={types}
        gridModel={gridModel}
        carrousellComponents={[
          {
            icon: "edit",
            popoverTitle: lang.crudActions.update,
            component: item => (
              <CreateUpdateTypeForm
                {...{ item, tagFP }}
                actionType={CrudFormType.update}
                isDataUpdated={!isFetching}
              />
            ),
          },
        ]}
        headerActions={(item: any) => (
          <CreateUpdateTypeForm
            {...{ tagFP: tagFP }}
            item={item}
            actionType={CrudFormType.create}
          />
        )}
      />
    </>
  );
};
