import React from "react";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import CUSupplyForm from "../CUSupplyForm";
import DeleteSupplyForm from "../DeleteSupplyForm";
import {
  useConsumablesGridModel,
  useProtectionsGridModel,
  useToolsGridModel,
} from "../lang/useSuppliesGridModel";
import { EnumSupplyTypes, SupplyInterface } from "../model/SupplyInterface";
import AssignCancelMultipleSupplies from "../molecules/AssignCancelMultipleSupplies";
import { useSuppliesByType } from "../services/service.supplies";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageSuppliesPageProps {
  virtualGridWidth: string;
  type: EnumSupplyTypes;
}

const ManageSuppliesPage: React.FC<ManageSuppliesPageProps> = ({ type, virtualGridWidth }) => {
  const { data: supplies, isFetching } = useSuppliesByType(type);

  const { lang } = useLangLabels();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: SupplyInterface) => (
        <CUSupplyForm item={item} actionType={CrudFormType.update} isDataUpdated={!isFetching} />
      ),
    },
  ];

  const gridModel: { [key: string]: GridModelInterface } = {
    HERRAMIENTA: useToolsGridModel(),
    CONSUMIBLE: useConsumablesGridModel(),
    PROTECCION: useProtectionsGridModel(),
  };

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        title: lang.supplies[type].title.plural,
        entityName: lang.supplies[type].title.plural,
        items: supplies,
        carrousellComponents,
        gridModel: gridModel[type],
        headerActions: () => (
          <>
            <AssignCancelMultipleSupplies type={type} />
            <CUSupplyForm key={type} actionType={CrudFormType.create} supplyType={type} />
          </>
        ),
        itemActions: item => <DeleteSupplyForm type={type} item={item} />,
      }}
    />
  );
};

export default ManageSuppliesPage;
