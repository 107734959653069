import React, { useEffect, useState } from "react";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Box, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { ticketType } from "../CreateTicketsPage";

export const satisfied = [
  {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: "Muy Insatisfecho",
  },
  {
    icon: <SentimentDissatisfiedIcon />,
    label: "Insatisfecho",
  },
  {
    icon: <SentimentSatisfiedIcon />,
    label: "Neutral",
  },
  {
    icon: <SentimentSatisfiedAltIcon />,
    label: "Satisfecho",
  },
  {
    icon: <SentimentVerySatisfiedIcon />,
    label: "Muy Satisfecho",
  },
];

const IconSatisfaction: React.FC<{ value: number }> = props => {
  const { value, ...other } = props;
  return <span {...other}>{satisfied[value]?.icon}</span>;
};

const FaceRating: React.FC<{
  title: string;
  handleState: (type: ticketType, indicator: string) => void;
}> = ({ title, handleState }) => {
  const [value, setValue] = useState(3);

  useEffect(() => {
    switch (value) {
      case 1:
        handleState("faces", "Muy Insatisfecho");
        break;
      case 2:
        handleState("faces", "Insatisfecho");
        break;
      case 3:
        handleState("faces", "Neutral");
        break;
      case 4:
        handleState("faces", "Satisfecho");
        break;
      case 5:
        handleState("faces", "Muy Satisfecho");
        break;
      default:
        handleState("faces", "Muy Insatisfecho");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">{title}</Typography>
        <Rating
          name="customized-icons"
          defaultValue={3}
          max={5}
          onChange={(event, newValue: any) => setValue(newValue)}
          getLabelText={(value: any) => satisfied[value]?.label}
          IconContainerComponent={IconSatisfaction}
        />
      </Box>
    </>
  );
};

export default FaceRating;
