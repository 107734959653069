import { ObservationInterface } from "../../../../domains/observations/models/ObservationTypes";
import { getViewDateDay } from "../../../date/utils";
import { backupDb } from "../backup-db";

export const backupAddMultipleObservations = async (observations: ObservationInterface[]) => {
  try {
    return await backupDb.observations.bulkAdd(
      observations.map(obs => ({
        ...obs,
        backupDate: getViewDateDay(new Date().toISOString()),
      }))
    );
  } catch (err: any) {
    if (err.name === "QuotaExceededError") {
      console.warn("Quota exceeded, cleaning up old data...");
      await backupDb.cleanUpOldData(25);

      return await backupDb.observations.bulkAdd(
        observations.map(obs => ({
          ...obs,
          backupDate: getViewDateDay(new Date().toISOString()),
        }))
      );
    } else {
      console.error("Error while adding observations:", err);
      throw err;
    }
  }
};
