import styled from "@emotion/styled";
import React from "react";
import theme from "../../../assets/theme";
import FlexContainer from "../../../components/containers/FlexContainer";
import PageContainer from "../../../components/containers/PageContainer";
import VersionContainer from "../../../domains/softVersion/VersionContainer";
import LoginBackground from "./../../../assets/img/login-background.png";
import NavBarLogo from "./../../../assets/img/navBarLogo.png";

export const LoginPageContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props?.color || theme.palette.background.default};
  transition: "background-color 0.5s ease";
  background-image: url(${LoginBackground});
  background-blend-mode: luminosity;
  background-size: cover;
  background-position: center;
  filter: grayscale;
`;

const LoginCard = styled(PageContainer)`
  height: ${({ height }) => height};
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  border-radius: 8px;
  background: ${theme.palette.background.paper};
`;
export const Logo = styled("img")`
  height: 90px;
  margin: auto;
`;

const LoginFormContainer: React.FC<{}> = ({ children }) => {
  return (
    <LoginPageContainer>
      <LoginCard>
        <FlexContainer justify={"center"} height={"100px"}>
          <a target="link" href="http://tgdcompany.com">
            <Logo src={NavBarLogo} />
          </a>
        </FlexContainer>

        {children}
      </LoginCard>

      <VersionContainer company="TGD" justify="center" />
    </LoginPageContainer>
  );
};

export default LoginFormContainer;
