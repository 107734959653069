export const getSuppliesInitialState = (supplies: string) => {
  if (supplies.length > 0) {
    const suppliesObjectArray = JSON.parse(supplies).map((supply: string) => {
      const consumableArray = supply.split("|");
      return {
        type: consumableArray[0],
        supply: consumableArray[1],
        state: consumableArray[2],
      };
    });

    return suppliesObjectArray;
  }
};
