import { Badge } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import FlexContainer from "../../FlexContainer";
import PageContainer from "../../PageContainer";
import TabOption from "./TabOption";
import Tabs from "./Tabs";

interface TabToolbarProps {
  tabs?: string[];
  badge?: (number | undefined)[];
  onSelectedTab: (selected: number) => void;
  startStyle?: {};
  endStyle?: {};
}

const TabToolbar: React.FC<TabToolbarProps> = ({
  tabs,
  onSelectedTab,
  badge,
  startStyle,
  endStyle,
}) => {
  const [, setValue] = useState(0);

  const handleTabSelection = useCallback(
    (selected: number) => {
      setValue(selected);
      onSelectedTab(selected);
    },
    [onSelectedTab]
  );

  return (
    <>
      <FlexContainer padding={"12px"} style={startStyle} />
      <Tabs onTabSelected={handleTabSelection}>
        {tabs?.map((label, index) => (
          <TabOption>
            <Badge key={index} badgeContent={badge ? badge[index] : 0} color={"primary"}>
              <PageContainer padding={"0"}>{label}</PageContainer>
            </Badge>
          </TabOption>
        ))}
      </Tabs>
      <FlexContainer padding={"12px"} style={endStyle} />
    </>
  );
};

export default React.memo(TabToolbar);
