import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import { useDeleteSupplyVerification } from "./services/service.supplies";

import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { SupplyInterface, SupplyType } from "./model/SupplyInterface";

interface Props {
  item: SupplyInterface;
  type: SupplyType;
}
const DeleteSupplyForm: React.FC<Props> = ({ item, type }) => {
  const { lang } = useLangLabels();

  const { errors, status: deleteStatus, validate, query } = useDeleteSupplyVerification();
  const { status, message } = useStatusProcessor(query);
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  const deletText = `${lang.words.thisMasc}${lang.selectedLanguage === "es" ? "/a" : ""} ${type} ${
    lang.messages.cannotBeRemoved
  } ${lang.messages.becauseItsUsedOn}`;

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${deletText}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }

    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, status, errors]);

  const handleDelete = (e: any) => {
    getClickPosition(e, () => validate(item));
  };

  return status === "loading" || deleteStatus === "loading" ? (
    <CircularProgress size="20px" />
  ) : (
    <CustomButton
      popoverTitle={lang.actionTypes.deleteItem}
      icon="delete"
      action={handleDelete}
      variant="iconButton"
    />
  );
};

export default React.memo(DeleteSupplyForm);
