import React from "react";
//material
import { Button } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useSessionContext } from "../../domains/session/store/sessionContext";
//Context
//service

interface LogoutProps {
  width?: string;
  title?: string;
}

const LogOutButton: React.FC<LogoutProps> = ({ width, title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { logout } = useSessionContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <Button
      onClick={handleLogout}
      startIcon={<ExitToAppIcon />}
      color="primary"
      variant="contained"
      style={{ width: width ? width : "100%", fontSize: "10px" }}
    >
      {title}
    </Button>
  );
};

export default LogOutButton;
