import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CELL_WIDTH } from "../../TableContainer/TableContainer";

const useDefaultColumn = (): Partial<ColumnDef<any, unknown>> => {
  return React.useMemo(
    () => ({
      size: CELL_WIDTH,
    }),
    []
  );
};

export default useDefaultColumn;
