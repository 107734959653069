import React from "react";

import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { Tile } from "powerbi-report-component";

import { CircularProgress } from "@material-ui/core";
import { CustomUserPbiData } from "../../../../domains/powerBi/models/powerBiInterfaces";
import {
  usePbiAccessToken,
  usePbiHomeDashboard,
  usePbiTilesInDashboard,
} from "../../../../domains/powerBi/services/service.pbi";
import NotificationsPage from "../../NotificationsPage";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const useStyles = makeStyles(theme => ({
  content1: {
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "24px 0px",
    width: "auto",
    "& > *": {
      margin: theme.spacing(0),
      flex: "1",
      minWidth: theme.spacing(30),
      padding: theme.spacing(0),
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      flex: "1",
      margin: theme.spacing(1),
    },
  },
}));

const HomeContent: React.FC<{
  hide?: { notifications?: boolean };
  defaultConfig?: CustomUserPbiData;
}> = ({ hide, defaultConfig }) => {
  const { user } = useSessionContext();
  const { lang } = useLangLabels();
  const classes = useStyles();
  const dashboardId = usePbiHomeDashboard(defaultConfig);
  const { data: tiles, error: tileError } = usePbiTilesInDashboard(defaultConfig);
  const { data: aadToken } = usePbiAccessToken();

  const showNotif = user?.type === "C" && !hide?.notifications;

  return (
    <>
      <div className={classes.content1}>
        {tiles && Array.isArray(tiles) && aadToken ? (
          tiles.map((item: any) => (
            <Paper elevation={3} key={item.id} style={{ height: "max-content", padding: "16px" }}>
              <Tile
                tokenType="Aad"
                accessToken={aadToken}
                embedUrl={item.embedUrl}
                embedId={item.id}
                dashboardId={dashboardId}
                style={{ height: "300px", position: "relative", border: "none" }}
              />
            </Paper>
          ))
        ) : (
          <div>
            {!tileError ? (
              tiles?.response?.data && "error" in tiles.response.data ? (
                <Alert severity="error">
                  {lang.messages.anErrorHasOccurred + ". " + lang.messages.pleaseReloadThePage}
                </Alert>
              ) : (
                <CircularProgress />
              )
            ) : (
              <Alert severity="error">{lang.client.noFeaturedGraphics}</Alert>
            )}
          </div>
        )}
      </div>
      {showNotif ? <NotificationsPage /> : null}
    </>
  );
};

export default HomeContent;
