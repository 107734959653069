import { CircularProgress } from "@material-ui/core";
import { Report } from "powerbi-report-component";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CustomUserPbiData } from "../models/powerBiInterfaces";
import { usePbiAccessToken, usePbiReportsInGroup } from "../services/service.pbi";

const ReportPage: React.FC<{ aadToken: string; report: any }> = ({ aadToken, report }) => {
  return (
    <Report
      tokenType="Aad" //"Embed" // "Aad"
      accessToken={aadToken} // accessToken goes here
      embedUrl={report.embedUrl} // embedUrl goes here
      embedId={report.id} // report or dashboard Id goes here
      pageName="" // set as current page of the report
      reportMode="View" // open report in a particular mode View/Edit/Create
      datasetId={report.datasetId} // required for reportMode = "Create" and optional for dynamic databinding in `report` on `View` mode
      /*  extraSettings={extraSettings} */
      permissions="All" // View, For "Edit" mode permissions should be "All"
      style={{
        height: "100vh",
        width: "100%",
        margin: "0px",
        border: "none",
        padding: "0px",
      }}
      /* onLoad={this.handleReportLoad} */
      /* onRender={this.handleReportRender} // not allowed in "Create" mode
        onSelectData={this.handleDataSelected}
        onPageChange={this.handlePageChange}
        onTileClicked={this.handleTileClicked}
        onSave={this.handleReportSave} */ // works for "Edit" and "Create"
    />
  );
};

const Reports: React.FC<{ defaultConfig?: CustomUserPbiData; baseUrl?: string }> = ({
  defaultConfig,
}) => {
  const { data: aadToken } = usePbiAccessToken();
  const pbiReports = usePbiReportsInGroup(defaultConfig);
  const pathname = useLocation().pathname;
  const location = pathname.split("/");
  const path = location[location.length - 2];

  return (
    <>
      {!pbiReports && <CircularProgress size="24px" />}
      {pbiReports && (
        <Routes key={pathname}>
          {pbiReports.map((report: any, index: number) => (
            <Route
              key={index}
              path={`${report.name.includes(".") ? path + "/" : ""}${report.name.replace(
                / /g,
                "%20"
              )}`}
              element={<ReportPage aadToken={aadToken} report={report} />}
            />
          ))}
        </Routes>
      )}
    </>
  );
};

export default Reports;
