import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { useToken } from "../../session/store/sessionStore";
import { AreaInterface } from "../models/AreaTypes";

export const AreasCRUDDependencies = ["PlantAux"];

export const CreateArea = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/CreateArea",
    data,
  });
};

export const UpdateArea = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/UpdateArea",
    data,
  });
};

export const DeleteArea = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/DeleteArea",
    data,
  });
};

//------------------------
//AXIOS
//------------------------

export const usePlantAreas = () => {
  return useGetAllAuxByPlantData("areas");
};

//------------------------
//MUTATIONS
//------------------------

export const useCreateArea = () => {
  const token = useToken();
  const query = useMutation(CreateArea, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const createArea = (data: AreaInterface) => {
    if (token)
      return query.mutate({
        data,
        token,
      });
  };

  const status = useStatusProcessor(query);

  return {
    createArea,
    ...query,
    ...status,
  };
};

export const useUpdateArea = () => {
  const token = useToken();

  const query = useMutation(DeleteArea, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const updateArea = (data: AreaInterface) => {
    if (token)
      return query.mutate({
        data,
        token,
      });
  };
  const status = useStatusProcessor(query);

  return {
    updateArea,
    ...query,
    ...status,
  };
};

export const useDeleteArea = () => {
  const token = useToken();
  const query = useMutation(DeleteArea, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const status = useStatusProcessor(query);

  const deleteArea = (data: AreaInterface) => {
    if (token)
      return query.mutate({
        data,
        token,
      });
  };

  return {
    deleteArea,
    ...query,
    ...status,
  };
};
