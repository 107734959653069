import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import TgdSvgIcon from "./TgdSvgIcon";

const LabIcon: React.FC<any> = (props: SvgIconProps) => {
  return (
    <TgdSvgIcon {...props} style={{ color: !props.color ? "#000" : "inherit" }}>
      <path d="M13,11.33L18,18H6l5-6.67V6h2 M15.96,4H8.04C7.62,4,7.39,4.48,7.65,4.81L9,6.5v4.17L3.2,18.4C2.71,19.06,3.18,20,4,20h16 c0.82,0,1.29-0.94,0.8-1.6L15,10.67V6.5l1.35-1.69C16.61,4.48,16.38,4,15.96,4L15.96,4z" />
    </TgdSvgIcon>
  );
};

export default LabIcon;
