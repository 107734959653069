import { IconButton } from "@material-ui/core";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import { useNavigate } from "react-router-dom";
import { USER_ROUTES } from "../../shared/routes/routes";
import { usePlantContext } from "../../shared/PlantContext/PlantContext";

interface Props {
  classes: any;
}

const PlantSelectionButton: React.FC<Props> = ({ classes }) => {
  const navigate = useNavigate();
  const { clearSelectedPlant } = usePlantContext();

  const onPlantSelectionButtonClick = () => {
    clearSelectedPlant();
    navigate(`/${USER_ROUTES.home}/${USER_ROUTES.plantSelection}`);
  };

  return (
    <div className={classes.spacing}>
      <IconButton onClick={onPlantSelectionButtonClick}>
        <BusinessIcon color="inherit" />
      </IconButton>
    </div>
  );
};

export default PlantSelectionButton;
