import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { useUser } from "../../session/store/sessionStore";
import CUAddedReasonForm from "../CUAddedReasonForm";

interface props {
  tagFP: string;
  onChange: any;
  defaultValue?: string | undefined;
  dimentionType?: string;
  color?: "primary" | "secondary";
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const SelectAddedReason: React.FC<props> = ({
  tagFP: _tagFP,
  onChange,
  defaultValue,
  color: _color,
  error,
  disabled,
  clearable,
}) => {
  const showTitle = "reason";
  const { lang } = useLangLabels();
  const { data: addedReasons } = useGetAllAuxByPlantData("addedReasons");
  const { data: user } = useUser();
  const label = lang.addedReasons.singular;

  return (
    <>
      <SimpleAutocompleteSelect
        {...{
          label,
          error,
          onChange: onChange,
          items: addedReasons || [],
          showTitle,
          defaultValue,
          disabled: disabled ?? false,
          clearable: clearable ?? false,
        }}
      >
        {user &&
          (user?.type === "O"
            ? null
            : !disabled && <CUAddedReasonForm actionType={CrudFormType.create} />)}
      </SimpleAutocompleteSelect>
    </>
  );
};

export default SelectAddedReason;
