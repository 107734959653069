import { SessionInterface } from "./sessionInterface";

export const sessionFactory = (session?: SessionInterface): SessionInterface => {
  return {
    status: session?.status || "idle",
    message: session?.message || "",
    user: session?.user || undefined,
    person: session?.person || undefined,
    token: session?.token || "",
    currentPlant: session?.currentPlant || undefined,
    tagFP: session?.tagFP || "",
    isMultiplePlantUser: session?.isMultiplePlantUser || false,
    plants: session?.plants || [],
    pbiToken: session?.pbiToken || "",
    sessionStale: session?.sessionStale || false,
    selectedOperator: session?.selectedOperator || undefined,
    currentFactory: session?.currentFactory || undefined,
  };
};
