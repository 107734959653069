import { useQuery } from "react-query";
import { useSessionContext } from "../../../../session/store/sessionContext";
import { apiCall } from "../../../../../shared/axios/axios-config";
import {
  FilterInterface,
  PrivateQueryDataTagFPInterface,
} from "../../../../../shared/globals/utilsGlobalTypes";
import { PeriodInterface } from "../../../../../shared/date/usePeriod";
import { LossReportInterface } from "../types/LossReportTypes";
import { getDateWithDayPlusOne } from "../../../../../utils/getDateWithDayPlusOne";

// ----------------------------------------------------------------------------------------------------
// Interfaces

type LossReportQuery<T = any> = FilterInterface<T> & {
  firstDate?: string;
  secondDate?: string;
};

// ----------------------------------------------------------------------------------------------------
// Services

export const GetLossReportList = ({
  data,
  tagFP,
  token,
}: PrivateQueryDataTagFPInterface<LossReportQuery<LossReportInterface>>) => {
  return apiCall({
    method: "POST",
    url: "/reports/leak-reports-list",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    data,
  });
};

export const GetLossReportMap = ({
  data,
  tagFP,
  token,
}: PrivateQueryDataTagFPInterface<LossReportQuery<LossReportInterface>>) => {
  return apiCall({
    method: "POST",
    url: "/reports/leak-reports-map",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
    data,
  });
};

// ----------------------------------------------------------------------------------------------------
// Adapters

export const useGetLossReportList = (
  period: PeriodInterface | undefined,
  {
    filters,
    searchValue,
  }: {
    filters: any;
    searchValue: string;
  }
) => {
  const { token, tagFP } = useSessionContext();
  const canFetch = [
    !!period?.firstDate,
    !!period?.secondDate,
    !!tagFP,
    !!token,
    token != "",
  ].includes(false)
    ? false
    : true;

  const data = {
    filters: {
      ...filters,
      addedReason: undefined,
      reason: filters.addedReason,
      startTime: [period?.firstDate, getDateWithDayPlusOne(period?.secondDate)],
    },
    searchValue: searchValue,
  };

  return useQuery({
    queryKey: ["GetLossReportList", period, filters, searchValue, tagFP],
    queryFn: () => GetLossReportList({ data, tagFP, token }),
    enabled: canFetch,
  });
};

export const useGetLossReportMap = (
  period: PeriodInterface | undefined,
  {
    filters,
    searchValue,
  }: {
    filters: any;
    searchValue: string;
  }
) => {
  const { token, tagFP } = useSessionContext();
  const canFetch = [
    !!period?.firstDate,
    !!period?.secondDate,
    !!tagFP,
    !!token,
    token != "",
  ].includes(false)
    ? false
    : true;

  const data = {
    filters: {
      ...filters,
      addedReason: undefined,
      reason: filters.addedReason,
      startTime: [period?.firstDate, getDateWithDayPlusOne(period?.secondDate)],
    },
    searchValue: searchValue,
  };

  return useQuery({
    queryKey: ["GetLossReportMap", period, filters, searchValue, tagFP],
    queryFn: () => GetLossReportMap({ data, tagFP, token }),
    enabled: canFetch,
  });
};
