import { Button, CircularProgress, createStyles, makeStyles, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { errorsLog } from "../../../shared/globals/utils/utils";
import SelectCriticality from "../../criticalities/components/SelectCriticality";
import { CriticalityTypes } from "../../criticalities/models/criticalityInterface";
import SelectSector from "../../sectors/atom/SelectSector";
import { useSessionContext } from "../../session/store/sessionContext";
import SelectTypeBy from "../../types/components/SelectTypeBy";
import { enumTgdTypes } from "../../types/models/types";
import { EquipmentInterface } from "../model/lubricationPointsInterface";
import { useEquipmentVerification } from "../services/lubricationPointsAdapters";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const useStyles = makeStyles(theme =>
  createStyles({
    formGrid: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  handleState?: (data: EquipmentInterface) => void;
  handleNext: () => void;
  defaultState?: EquipmentInterface | null;
}

const EquipmentVerification: React.FC<Props> = ({ handleNext, handleState, defaultState }) => {
  const { lang } = useLangLabels();
  const classes = useStyles();
  const { token, tagFP } = useSessionContext();
  const { mutate: verifyEquipmentLoad, status, data, error } = useEquipmentVerification();
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    shouldUnregister: false,
  });

  const onSubmit = (data: EquipmentInterface) => {
    verifyEquipmentLoad({
      data: {
        sector: data.sector.toUpperCase(),
        plantTag: data.plantTag.toUpperCase(),
        tagFP: data.tagFP.toUpperCase(),
        equipment: data.equipment.toUpperCase(),
        type: data.type.toUpperCase(),
        criticality: data.criticality.toUpperCase(),
      },
      token,
    });
  };

  useEffect(() => {
    if (
      status === "success" &&
      handleState &&
      data !== "Existe el equipo" &&
      data !== "Existe el tag de planta"
    ) {
      handleState(data);
      handleNext();
    }
  }, [status]);

  useEffect(() => {
    if (tagFP) {
      register({ name: "tagFP" });
      register(
        { name: "criticality", value: "" },
        { required: { value: true, message: lang.validations.messages.required } }
      );
      register(
        { name: "type", value: "" },
        { required: { value: true, message: lang.validations.messages.required } }
      );
      register(
        { name: "sector", value: "" },
        { required: { value: true, message: lang.validations.messages.required } }
      );
      setValue("tagFP", tagFP);
    }
  }, [tagFP]);

  errorsLog(errors);

  if (status === "loading") return <CircularProgress size="20px" color="primary" />;
  return (
    <form className={classes.formGrid} onSubmit={handleSubmit(onSubmit)}>
      {error && <Alert severity="error">Ha ocurrido un problema. {error} </Alert>}
      {data && status === "success" && data === "Existe el equipo" && (
        <>
          <Alert severity="warning">El nombre del equipo ya existe</Alert>
          <br />
        </>
      )}
      {data && status === "success" && data === "Existe el tag de planta" && (
        <>
          <Alert severity="warning">El código de planta ya existe</Alert>
          <br />
        </>
      )}
      {data &&
        status === "success" &&
        data !== "Existe el equipo" &&
        data !== "Existe el tag de planta" && (
          <>
            <Alert severity="success">
              Los datos del equipo han sido aprobados para la carga de puntos de lubricación{" "}
            </Alert>
            <br />
          </>
        )}
      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="plantTag"
        defaultValue={defaultState?.plantTag || ""}
        variant="outlined"
        label={
          errors?.plantTag?.message
            ? `${lang.lubricationPoints.plantTag} ${errors?.plantTag?.message}`
            : lang.lubricationPoints.plantTag
        }
        color={errors?.plantTag?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />
      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="equipment"
        defaultValue={defaultState?.equipment || ""}
        variant="outlined"
        label={
          errors?.equipment?.message
            ? `${lang.lubricationPoints.equipmentName} ${errors?.equipment?.message}`
            : lang.lubricationPoints.equipmentName
        }
        color={errors?.equipment?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />
      <SelectSector
        error={errors?.sector?.message}
        onChange={(value: string) => setValue("sector", value)}
        defaultValue={watch("sector", defaultState?.sector)}
      />
      <br />
      <SelectTypeBy
        error={errors?.type?.message}
        by={{ key: "type", value: enumTgdTypes.equipment }}
        tagFP={tagFP}
        onChange={value => setValue("type", value)}
        defaultValue={watch("type")}
      />
      <br />
      <SelectCriticality
        by={{ key: "criticalityType", value: CriticalityTypes.equipment }}
        error={errors?.criticality?.message}
        onChange={value => setValue("criticality", value)}
        defaultValue={watch("criticality", defaultState?.criticality)}
      />
      <br />
      <Button type="submit" variant="outlined" color="secondary">
        {" "}
        {lang.actionTypes.configure}{" "}
      </Button>
    </form>
  );
};

export default React.memo(EquipmentVerification);
