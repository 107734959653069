import { useQuery } from "react-query";
import { mutateApiCall } from "../../../shared/axios/axios-config";
import { getAllResources, getAllResourcesByEmail } from "../../../shared/axios/utils";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf";
import { useToken, useUser } from "../../session/store/sessionStore";
import { FactoryInterface } from "../model/FactoryInterface";

const factoriesCRUDDependencies = [
  "AllFactoriesWithPlants",
  "AccountManagerFactories",
  "AllFactories",
];

//axios repo

export const CreateFactory = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/CreateFactory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const assignCancelClientAccount = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/AssignCancelClientAccount`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//react-query HOOKS
//---------------------------------------------

export const useAllFactoriesWithPlants = () => {
  //get factories that contain at list one plant
  const token = useToken();
  const queryKey = "AllFactoriesWithPlants";
  return useQuery({
    queryKey,
    queryFn: () => getAllResources(queryKey, { token }),
  });
};

export const useAccountManagerFactories = (email: string) => {
  const token = useToken();
  const queryKey = "AccountManagerFactories";
  return useQuery({
    queryKey,
    queryFn: () => getAllResourcesByEmail(queryKey, email, { token }),
  });
};

export const useAllFactories = () => {
  const token = useToken();
  const queryKey = "AllFactories";
  return useQuery({
    queryKey,
    queryFn: () => getAllResources(queryKey, { token }),
  });
};

export const useFactoriesByUser = () => {
  const { data: user } = useUser();
  const factories = useAccountManagerFactories(user?.email!);
  const allFactories = useAllFactories();

  switch (user?.type) {
    case "SA":
      if (allFactories) return allFactories;
      else return { data: [], status: "idle" };
    case "A":
      if (allFactories) return allFactories;
      else return { data: [], status: "idle" };
    case "AC":
      if (factories) return factories;
      else return { data: [], status: "idle" };
    case "S":
      if (factories) return factories;
      else return { data: [], status: "idle" };
    default:
      return { data: [], status: "idle" };
  }
};

//react-query Mutations
//---------------------------------------------

const useAssignCancelClientAccount = (config: any) => {
  return useMutation(assignCancelClientAccount, config);
};

export const useCreateFactory = () => {
  const token = useToken();

  const query = useMutation(CreateFactory, {
    onSuccess: () =>
      factoriesCRUDDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      }),
  });
  const createFactory = (data: FactoryInterface) => {
    return query.mutateAsync({
      token,
      data,
    });
  };
  const status = useStatusProcessor(query);

  return {
    createFactory,
    ...query,
    ...status,
  };
};

//custom HOOKS
//---------------------------------------------

export const useAssignCancelFactory = () => {
  const token = useToken();
  const query = useAssignCancelClientAccount({
    onSuccess: () =>
      factoriesCRUDDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      }),
  });

  const assignFactory = ({ email, tagF }: { email: string; tagF: string }) => {
    const data = {
      email,
      tagF,
      state: "A",
    };
    return query.mutateAsync({ data, token });
  };

  const cancelFactory = ({ email, tagF }: { email: string; tagF: string }) => {
    const data = {
      email,
      tagF,
      state: "C",
    };
    return query.mutateAsync({ data, token });
  };

  return {
    assignFactory,
    cancelFactory,
    query,
  };
};

//pbi.service
