import React from "react";
import { useStockByTagFP } from "../../../../domains/stock/services/service.stock";

import NewStockQuantityForm from "../../../../domains/stock/components/NewStockQuantityForm";

import { useStockGridModel } from "../../../../domains/stock/lang/useStockGridModel";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import CreatePurchaseRequestForm from "../../../../domains/stock/purchase-requests/components/CreatePurchaseRequestForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {}
const StockGrid: React.FC<Props> = () => {
  const { data: stockItems } = useStockByTagFP();
  const stockGridModel = useStockGridModel();

  const { lang } = useLangLabels();

  return (
    <>
      <VirtualGrid
        {...{
          title: lang.lubricationSection.allStockItems,
          items: stockItems,
          gridModel: stockGridModel,
          renderOptionColumn: {
            headerName: "More",
            renderOption: item => (
              <>
                <NewStockQuantityForm item={item} />
                <CreatePurchaseRequestForm mode="popover" variant="iconButton" stock={item} />
              </>
            ),
          },
        }}
      ></VirtualGrid>
    </>
  );
};

export default React.memo(StockGrid);
