import React from "react";
import { InputProps, TextField } from "@material-ui/core";
import { CriticalityTypes } from "../../../domains/criticalities/models/criticalityInterface";
import { styled } from "@mui/material";

export type FieldProps = {
  helper?: string;
  type?: InputProps["type"];
  label?: string;
  error?: string;
  onChange: (value: any) => void;
  value?: string | number;
  defaultValue?: string;
  tagFP?: string;
  complete?: true;
  name?: string;
  placeholder?: string;
  criticalityType?: CriticalityTypes;
  readonly?: boolean;
  rows?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  onKeyDown?: (e: any) => void;
  style?: {};
};

const StyledTextField = styled(TextField)(({ style }) => ({
  "& .MuiInputBase-input": {
    ...style,
  },
}));

export const TgdTextField: React.FC<FieldProps> = ({
  value,
  disabled,
  // defaultValue,
  onChange,
  label,
  error,
  type,
  placeholder,
  helper,
  readonly,
  rows,
  onKeyDown,
  style,
  autoFocus,
}) => {
  const _label = `${label} ${error || ""}`;
  const _color = error ? "primary" : "secondary";

  const multiline = rows ? true : false;

  return (
    <StyledTextField
      disabled={disabled}
      InputProps={{
        readOnly: readonly,
      }}
      multiline={multiline}
      rows={rows}
      InputLabelProps={{ shrink: true }}
      helperText={helper}
      id="_label"
      type={type || "text"}
      focused={error ? true : false}
      autoFocus={autoFocus ?? true}
      style={{ width: "100%", ...style }}
      variant={readonly ? "filled" : "outlined"}
      size="small"
      label={_label}
      color={_color}
      onChange={(e: any) => onChange && onChange(e.target.value || "")}
      //defaultValue={defaultValue} //console error controlled component choice must be set or default value or value
      value={value}
      placeholder={error ? error : placeholder || ""}
      onKeyDown={onKeyDown}
    />
  );
};

export default React.memo(TgdTextField);
