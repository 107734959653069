export const htmlAnyGridArea = (parameters: { content: string; footer: string }) => {
  if (parameters) {
    return `<div style="padding:20px; width:100%;box-sizing: border-box;">
        <div
        style="
        box-sizing: border-box;
            max-width: 650px;
            margin: auto;
            font-family: sans-serif;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        "
        >
        <div
            style="
            z-index: 99;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0 24px;
            padding: 24px;
            text-align: center;
            background-color: #fff;
            color: #002f6c;
            text-transform: uppercase;
            font-family: sans-serif;
            font-weight: 700;
            "
        >
            
        </div>
        <div
            style="
            padding:24px;
            width: auto;
            height: 100%;
            background-image: url(http://drive.google.com/uc?export=view&id=1H2zkQkDzMlBdGWCc-VlgAu3HC2y2Wles);        "
        >
            <div
            style="
                width: auto;
                height: 100%;
                background-color: white;
                padding: 24px;
            "
            >
            <a href="https://tgdsoft.tgdcompany.com/" target="aside">
                <img
                style="width: 150px;"
                alt=""
                src="http://drive.google.com/uc?export=view&id=12jLUcsWE82GfKMen-ICI4UbD4oAVJBMQ"
                />
            </a>
                <br />
                <p>
                    ${parameters.content}
                </P>
                <p>
                    ${parameters.footer}
                </P>
            </p>
            </div>
        
            <div></div>
            </div>
            <div
            style="
            position:relative;
            text-align: center;
            font-family: sans-serif;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            min-height: 64px;
            padding: 24px;
            background-image: url(http://drive.google.com/uc?export=view&id=1EDSC3jfcBk9ReoCY2jsYrMftf0OY1Pph);
            "
        >
        <div >
            <img
            style="width: 32px;height: 32px;background-color: #fff;border-radius: 100px;padding: 2px;"
            alt=""
            src="http://drive.google.com/uc?export=view&id=10EmO7Qn0EVdF5sr2D5wxrpqTF3j5-ROr"
            />
        </div>
            <div>
            <br>
            <br>
            </div>
            <div >
            <a
            style="color: #FFF; font-size: 12px;"
            href="https://tgdcompany.com/"
            >www.tgdcompany.com</a
            >
            <br>
            <p style="color:#f3f3f350; font-size: 12px;">©TGD Soft - ©TGD Company - Internacional </p>
            </div>
        </div>
        
        </div>
    </div>`;
  }
};
