import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import CreateEquipmentPage from "../components/CreateEquipmentPage";
import { useLubricationPointsGridModel } from "../lang/useLubricationPointsGridModel";
import { ManageEquipment } from "./ManageEquipmentsPageR";

interface EquipmentsGridProps {
  virtualGridWidth: string;
  equipments: any[];
}

export const EquipmentsGrid = ({ virtualGridWidth, equipments }: EquipmentsGridProps) => {
  const { lang } = useLangLabels();

  const lubricationPointsGridModel = useLubricationPointsGridModel();

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        items: equipments,
        title: lang.lubricationPoints.equipments,
        entityName: "assets-tab-" + lang.lubricationPoints.equipments,
        documentExportTitle: lang.lubricationPoints.equipments,
        gridModel: lubricationPointsGridModel,
        headerActions: () => <CreateEquipmentPage />,
        itemActions: item => <ManageEquipment item={item} />,
      }}
    />
  );
};
