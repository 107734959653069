import { Route } from "react-router-dom";
import CalendarPage from "../../../../../../pages/backoffice/Calendar/CalendarPage";
import ConfigPage from "../../../../../../pages/backoffice/ConfigPage";
import KnowledgeBasePage from "../../../../../../pages/backoffice/KnowledgeBase/KnowledgeBasePage";
import LubricationSectionPage from "../../../../../../pages/backoffice/LubricationSection/LubricationSectionPage";
import PlantManagement from "../../../../../../pages/backoffice/PlantManagement";
import PlantNotificationsPage from "../../../../../../pages/backoffice/PlantNotifications/PlantNotificationsPage";
import ReportsGenerationPage from "../../../../../../pages/backoffice/ReportsGeneration/ReportsGenerationPage";
import StockPage from "../../../../../../pages/backoffice/stock/StockPage";
import SupervisorHomePage from "../../../../../../pages/backoffice/supervisor/home/SupervisorHomePage";
import UserPanel from "../../../../../../pages/backoffice/UserPanel";
import ViewPlantAsClientPage from "../../../../../../pages/backoffice/ViewAsClient/ViewPlantAsClientPage";
import { ExplorePage } from "../../../../../../pages/explore/ExplorePage";
import { USER_ROUTES } from "../../../../../../shared/routes/routes";

const useSupervisorRoutes = () => {
  return (
    <Route path={`${USER_ROUTES.supervisor}`} element={<UserPanel />}>
      <Route path={""} element={<SupervisorHomePage />} />
      <Route path={`${USER_ROUTES.plant_notifications}/*`} element={<PlantNotificationsPage />} />
      <Route
        path={`${USER_ROUTES.lubricationSection.index}/*`}
        element={<LubricationSectionPage />}
      />
      <Route path={`${USER_ROUTES.stock}/*`} element={<StockPage />} />
      <Route path={`${USER_ROUTES.plantManagement.index}/*`} element={<PlantManagement />} />
      <Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage />} />
      <Route path={`${USER_ROUTES.explore}/*`} element={<ExplorePage />} />
      <Route path={`${USER_ROUTES.reports.index}/*`} element={<ReportsGenerationPage />} />
      <Route path={`${USER_ROUTES.calendar}/*`} element={<CalendarPage />} />
      <Route path={`${USER_ROUTES.knowledge.index}/*`} element={<KnowledgeBasePage />} />
      <Route path={USER_ROUTES.config} element={<ConfigPage />} />
      <Route path={"*"} element={<SupervisorHomePage />} />
    </Route>
  );
};

export default useSupervisorRoutes;
