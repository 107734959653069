import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { useToken } from "../../session/store/sessionStore";
import { AnomalyInterface } from "../models/anomaliesTypes";

export const AnomalyCRUDDEpendencies = ["PlantAux"];

//----------------------------
//Axios
//----------------------------

export const CreateAnomaly = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/CreateAnomaly`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const UpdateAnomaly = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/UpdateAnomaly`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DeleteAnomaly = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/DeleteAnomaly`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

//----------------------------
//ReactQuery
//----------------------------

export const usePlantAnomalies = () => {
  return useGetAllAuxByPlantData("anomalies");
};

//----------------------------
//Mutation
//----------------------------

export const useCreateAnomaly = () => {
  const token = useToken();
  const query = useMutation(CreateAnomaly, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const status = useStatusProcessor(query);
  const createAnomaly = (data: AnomalyInterface) => {
    return query.mutate({
      data,
      token,
    });
  };

  return {
    createAnomaly,
    query: {
      ...query,
      ...status,
    },
  };
};

export const useUpdateAnomaly = () => {
  const token = useToken();
  const query = useMutation(UpdateAnomaly, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const status = useStatusProcessor(query);

  const updateAnomaly = (data: AnomalyInterface) => {
    return query.mutate({
      data,
      token,
    });
  };

  return {
    updateAnomaly,
    query: {
      ...query,
      ...status,
    },
  };
};

export const useDeleteAnomaly = () => {
  const token = useToken();
  const query = useMutation(DeleteAnomaly, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const status = useStatusProcessor(query);
  const deleteAnomaly = (data: AnomalyInterface) => {
    query.mutate({
      data,
      token,
    });
  };

  return {
    deleteAnomaly,
    query: {
      ...query,
      ...status,
    },
  };
};
