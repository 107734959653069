import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import ModalButton from "../../../components/buttons/ModalButton";
import FlexContainer from "../../../components/containers/FlexContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLubricationPointsGridModel } from "../../lubricationPoints/lang/useLubricationPointsGridModel";
import { useEquipmentsByTagFP } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { useSuppliesGridModel } from "../lang/useSuppliesGridModel";
import { EnumSupplyTypes } from "../model/SupplyInterface";
import {
  useAssignMultipleSuppliesToMultipleEquipments,
  useSuppliesByType,
} from "../services/service.supplies";

const AssignCancelMultipleSupplies: React.FC<{ type: EnumSupplyTypes }> = ({ type }) => {
  const { lang } = useLangLabels();

  const [selectedEquipments, setSelectedEquipments] = useState<any[]>([]);
  const [selectedSupplies, setSelectedSupplies] = useState<any[]>([]);
  const [step, setStep] = useState(1);

  const { data: equipments } = useEquipmentsByTagFP();
  const { data: supplies } = useSuppliesByType(type);

  const { handleAssigCancel, status, reset } = useAssignMultipleSuppliesToMultipleEquipments();

  const onSelectedEquipmentsChange = (selectedData: any[]) => {
    setSelectedEquipments(selectedData);
  };

  const onSelectedSuppliesChange = (selectedData: any[]) => {
    setSelectedSupplies(selectedData);
  };

  const handleAssign = () => {
    handleAssigCancel(selectedEquipments, selectedSupplies, "A");
  };

  const handleReset = () => {
    reset();
    setSelectedEquipments([]);
    setSelectedSupplies([]);
    setStep(1);
  };

  const handleClose = () => {
    handleReset();
  };

  const title = (() => {
    switch (type) {
      case EnumSupplyTypes.consumable:
        return lang.supplies.CONSUMIBLE.assignToEquipment;
      case EnumSupplyTypes.tool:
        return lang.supplies.HERRAMIENTA.assignToEquipment;
      case EnumSupplyTypes.protection:
        return lang.supplies.PROTECCION.assignToEquipment;
    }
  })();

  const equipmentGridModel = useLubricationPointsGridModel();
  const supplyGridModel = useSuppliesGridModel(type);

  return (
    <ModalButton icon="addList" title={title} onClose={handleClose}>
      {status === "error" && <Alert severity="error">{lang.messages.anErrorHasOccurred}</Alert>}
      {status === "success" && <Alert>{lang.supplies.assignmentsMade}</Alert>}

      {step === 1 && (
        <VirtualGrid
          contained={false}
          gridModel={equipmentGridModel}
          title={lang.supplies.selectEquipmentToAssign}
          items={equipments}
          onSelectedDataChange={onSelectedEquipmentsChange}
          headerActions={() => (
            <CustomButton
              icon="next"
              popoverTitle={lang.supplies.nextStep}
              {...(selectedEquipments?.length > 0 ? { disabled: false } : { disabled: true })}
              action={() => setStep(2)}
            />
          )}
        />
      )}

      {step === 2 && (
        <VirtualGrid
          contained={false}
          gridModel={supplyGridModel}
          title={title}
          items={supplies}
          onSelectedDataChange={onSelectedSuppliesChange}
          headerActions={() => (
            <FlexContainer>
              <CustomButton
                icon="before"
                popoverTitle={lang.supplies.backReset}
                action={handleReset}
              />
              <CustomButton
                icon="save"
                popoverTitle={lang.crudActions.save}
                status={status}
                {...(selectedSupplies?.length > 0 ? { disabled: false } : { disabled: true })}
                action={handleAssign}
              />
            </FlexContainer>
          )}
        />
      )}
    </ModalButton>
  );
};
export default React.memo(AssignCancelMultipleSupplies);
