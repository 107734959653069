import { Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { Ref } from "../../../../components/print-pdf/types/ReactPDFTypes";
import { useDate } from "../../../../shared/date/useDate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { ObservationReport } from "../types/ObservationTypes";

interface ObservationItemProps {
  data: ObservationReport;
  handleVariableSizeChange: (newSize: number) => void;
  handleTriggerOnResizing: () => void;
  style?: React.CSSProperties;
  onItemRendered: () => void;
  onItemUnrendered: () => void;
}

const ObservationItem: React.FC<ObservationItemProps> = React.memo(
  ({
    data,
    handleVariableSizeChange,
    handleTriggerOnResizing,
    style,
    onItemRendered,
    onItemUnrendered,
  }) => {
    const { lang } = useLangLabels();

    const observationRef = useRef<Ref>(null);
    const { formatISODate } = useDate();

    useEffect(() => {
      onItemRendered();
    }, [onItemRendered]);

    useEffect(() => {
      return () => {
        onItemUnrendered && onItemUnrendered();
      };
    }, [onItemUnrendered]);

    useEffect(() => {
      if (observationRef.current) {
        handleVariableSizeChange(observationRef.current.clientHeight);
        handleTriggerOnResizing();
      }
    }, [observationRef, handleVariableSizeChange, handleTriggerOnResizing]);

    return (
      <div style={style} ref={observationRef}>
        <FlexContainer style={{ padding: "8px", width: "100%" }}>
          <Typography variant="body1" style={{ fontSize: "15px", fontWeight: "bold" }}>
            {`${data.id}: ${data.equipment} >> ${data.element} >> ${data.component}`}
          </Typography>
        </FlexContainer>

        <FlexContainer style={{ padding: "4px 0 16px 0", width: "100%" }}>
          <div style={{ width: "calc(30% - 24px)", padding: "8px 16px 8px 8px" }}>
            <Typography variant="body1" style={{ fontSize: "15px", overflowWrap: "break-word" }}>
              <span style={{ fontWeight: "bold" }}>{lang.infoLubricationPoints.user}: </span>{" "}
              {data.user}{" "}
            </Typography>
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.globalentityValues.date}: </span>{" "}
              {formatISODate(data.date, true)}{" "}
            </Typography>
          </div>

          <div
            style={{
              width: "calc(40% - 32px)",
              padding: "8px 16px 8px 16px",
              borderLeft: "2px solid #D9D9D9",
            }}
          >
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.observations.singular}: </span>{" "}
              {data.observation}{" "}
            </Typography>
          </div>

          <div
            style={{
              width: "calc(30% - 24px)",
              padding: "8px 8px 8px 16px",
              borderLeft: `4px solid ${data.color}`,
            }}
          >
            <Typography variant="body1" style={{ fontSize: "15px", fontWeight: "bold" }}>
              {data.criticality}
            </Typography>
            <Typography variant="body1" style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "bold" }}>{lang.areas.title.singular}: </span> {data.area}{" "}
            </Typography>
          </div>
        </FlexContainer>
      </div>
    );
  }
);

export default ObservationItem;
