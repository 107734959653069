import { FormControlLabel, FormGroup, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect } from "react";
import FlexContainer from "../../../../components/containers/FlexContainer";

interface TGDRadioButtonProps {
  items: string[];
  handleChange: (item: string) => void;
  defaultOption?: string;
  fieldsFilter?: string[];
}

const TGDRadioButton: React.FC<TGDRadioButtonProps> = ({
  fieldsFilter,
  defaultOption,
  items,
  handleChange,
}) => {
  const fields = fieldsFilter ? items.filter(i => fieldsFilter.includes(i)) : items;
  const [selected, setSelectedValue] = React.useState(defaultOption || items[0]);

  useEffect(() => {
    handleChange(selected);
  }, [selected]);

  return (
    <RadioGroup aria-label="position" name="position" defaultValue="top">
      <FormGroup row>
        <FlexContainer>
          {fields.map(item => (
            <FormControlLabel
              key={item}
              {...(defaultOption === item && defaultOption === selected ? { checked: true } : {})}
              value={item}
              control={<Radio onClick={() => setSelectedValue(item)} color="primary" />}
              label={item}
              labelPlacement="end"
            />
          ))}
        </FlexContainer>
      </FormGroup>
    </RadioGroup>
  );
};

export default React.memo(TGDRadioButton);
