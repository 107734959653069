import React from "react";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../../session/store/sessionContext";
import CUtypeForm from "../CUtypeForm";
import { useTypesByPlant } from "../services/service.types";
import { useTypeGridModel } from "../services/useTypesGridModel";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageTypesPageProps {
  virtualGridWidth: string;
}

const ManageTypesPage: React.FC<ManageTypesPageProps> = ({ virtualGridWidth }) => {
  const { tagFP } = useSessionContext();
  const { lang } = useLangLabels();
  const { data: types, isFetching } = useTypesByPlant();
  const gridModel = useTypeGridModel();

  return (
    <>
      <VirtualGrid
        width={virtualGridWidth}
        title={lang.types.plural}
        entityName={lang.types.plural}
        items={types}
        gridModel={gridModel}
        carrousellComponents={[
          {
            icon: "edit",
            popoverTitle: lang.crudActions.update,
            component: item => (
              <CUtypeForm
                {...{ item, tagFP }}
                actionType={CrudFormType.update}
                isDataUpdated={!isFetching}
              />
            ),
          },
        ]}
        headerActions={(item: any) => (
          <CUtypeForm {...{ tagFP: tagFP }} item={item} actionType={CrudFormType.create} />
        )}
      />
    </>
  );
};

export default React.memo(ManageTypesPage);
