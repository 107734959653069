import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { apiCall, mutateApiCall } from "../../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryTagFPInterface,
} from "../../../../shared/globals/utilsGlobalTypes";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../domains/session/store/sessionStore";
import { queryClient } from "../../../../shared/react-query/react-query-conf";
import { IncomeInterface } from "../types/Income";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";

export const GetAllIncomes = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/LubricantAdmission",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      tagFP,
    },
  });
};

export const GetIncomeByLubricant = ({
  token,
  tagFP,
  lubricant,
}: PrivateQueryTagFPInterface & { lubricant: string }) => {
  return apiCall({
    method: "GET",
    url: "/LubricantAdmission/lubricant",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      lubricant,
      tagFP,
    },
  });
};

export const CreateIncome = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/LubricantAdmission",
    data,
  });
};

export const UpdateIncome = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/LubricantAdmission",
    data,
  });
};

export const DeleteIncome = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/LubricantAdmission",
    data,
  });
};

export const GetReferrer = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/LubricantAdmission/person",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      tagFP,
    },
  });
};

// -------------------------------------------------------------------------------------------------

export const useGetAllIncomes = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllIncomes", tagFP],
    queryFn: () => GetAllIncomes({ token, tagFP }),
    enabled: !!token,
  });
};

export const useGetIncomeByLubricant = (lubricant: string) => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetIncomeByLubricant", lubricant, tagFP],
    queryFn: () => GetIncomeByLubricant({ token, tagFP, lubricant }),
    enabled: !!token,
  });
};

export const useGetIncome = (lubricant?: string) => {
  const { token, tagFP } = useSessionContext();

  useEffect(() => {
    query.refetch();
  }, [lubricant]);

  const query = useQuery({
    queryKey: lubricant ? ["GetIncomeByLubricant", tagFP] : ["GetAllIncomes", tagFP],
    queryFn: () => {
      if (lubricant) {
        return GetIncomeByLubricant({ token, tagFP, lubricant });
      } else {
        return GetAllIncomes({ token, tagFP });
      }
    },
    enabled: !!token,
  });

  return query;
};

export const useDeleteIncome = () => {
  const token = useToken();

  const deleteIncomeQuery = useMutation(DeleteIncome, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllIncomes");
      queryClient.invalidateQueries("GetIncomeByLubricant");
      queryClient.invalidateQueries("GetReferrer");
    },
  });

  const deleteIncome = (data: IncomeInterface) => {
    return deleteIncomeQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deleteIncomeQuery);

  return {
    deleteIncome,
    ...deleteIncomeQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useGetReferrer = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetReferrer", tagFP],
    queryFn: () => GetReferrer({ token, tagFP }),
    enabled: !!token,
  });
};
