import React from "react";
import { Alert } from "@material-ui/lab";
import { UserType } from "./UserInterface";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useUser } from "../session/store/sessionStore";

interface Props {
  allowedUserTypes: UserType[];
  showMessage?: boolean;
  customMessage?: string;
}

const UserRoleManager: React.FC<Props> = ({
  showMessage,
  customMessage,
  allowedUserTypes,
  children,
}) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();
  const allowed = user ? allowedUserTypes.includes(user?.type) : false;

  const text = customMessage || lang.messages.abilityNotAllowed;
  const message = showMessage ? <Alert severity="warning">{text}</Alert> : null;

  return <>{allowed && children ? children : message}</>;
};

export default UserRoleManager;
