import { createStyles, makeStyles, Paper } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    relative: {
      position: "relative",
      height: "calc(100vh - 19vh)",
      overflowX: "hidden",
    },
  })
);

const ListContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.relative}>{children}</Paper>;
};

export default ListContainer;
