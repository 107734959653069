import React from "react";

import styled from "@emotion/styled";
import {
  getGridModelkey,
  getGridModelValue,
  GridModelInterface,
} from "../shared/gridModel/gridModelEntity";
import FlexContainer from "./containers/FlexContainer";
import Title from "./Typography/Title";

const Text = styled("div")`
  font-size: 14px;
  margin: 0px;
`;

const EntityProperties: React.FC<{
  title?: string;
  entity: any;
  gridModel: GridModelInterface;
}> = ({ title, entity, gridModel }) => {
  const keyValues = entity ? Object.entries(entity) : [];

  return (
    <FlexContainer flexDirection="column" padding="12px">
      <Title>
        <strong>{title && title}</strong>
      </Title>
      {keyValues &&
        keyValues.map(([key, value]: any) =>
          value === null || value === undefined ? null : (
            <Text key={key}>
              <strong>{getGridModelkey(key, gridModel)}:</strong>{" "}
              {getGridModelValue(key, value, gridModel)}
            </Text>
          )
        )}
    </FlexContainer>
  );
};
export default React.memo(EntityProperties);
