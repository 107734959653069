import React from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { PersonInterface } from "../../../person/models/PersonInterface";
import { usePlantOperators } from "../../../person/services/personServices";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";

interface Props {
  error?: string;
  tagFP: string;
  onChange: (item?: any) => void;
  complete?: boolean;
  defaultValue?: any;
  disabled?: boolean;
}

const SelectOperator: React.FC<Props> = ({ tagFP, onChange, defaultValue, error, disabled }) => {
  const showTitle = "name";
  const { lang } = useLangLabels();
  const { data: operators } = usePlantOperators(tagFP);
  const filteredValues = operators?.filter((i: PersonInterface) => i.lubricatorNumber !== 0);
  const label = lang.routes.operator;
  const renderOption = (option: PersonInterface) => (
    <span>
      {option.lubricatorNumber} | {option.name}
    </span>
  );

  const handleChange = (value: any) => {
    const operatorValue = filteredValues?.find(operator => operator[showTitle] === value);

    onChange(operatorValue?.["lubricatorNumber"]);
  };

  const defaultValueState = filteredValues?.find(
    operator => operator.lubricatorNumber === defaultValue
  )?.[showTitle];

  return (
    <SimpleAutocompleteSelect
      {...{
        disabled,
        error,
        items: filteredValues || [],
        defaultValue: defaultValueState,
        showTitle,
        label,
        onChange: handleChange,
        renderOption,
        clearable: true,
      }}
    />
  );
};

export default SelectOperator;
