import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import CUSectorForm from "../CUSectorForm";
import { SectorInterface } from "../models/SectorInterface";
import { usePlantSectors } from "../services/service.sectors";

interface props {
  onChange: any;
  defaultValue?: any;
  error?: string;
  complete?: boolean;
  disabled?: boolean;
  helper?: string;
}

const SelectSector: React.FC<props> = ({
  onChange,
  defaultValue,
  error,
  complete,
  disabled,
  helper,
}) => {
  const showTitle = "sectorName";
  const { lang } = useLangLabels();
  const { data: sectors } = usePlantSectors();
  const label = lang.sectors.title.singular;

  const handleChange = (value: any) => {
    if (complete) {
      const selected = sectors?.find(
        (s: SectorInterface) => s[showTitle] === value
      ) as SectorInterface;
      onChange(selected);
    } else {
      onChange(value);
    }
  };

  return (
    <SimpleAutocompleteSelect
      {...{
        helper,
        error,
        onChange: handleChange,
        label,
        items: sectors || [],
        defaultValue,
        showTitle,
        disabled,
      }}
    >
      <CUSectorForm actionType={CrudFormType.create} />
    </SimpleAutocompleteSelect>
  );
};

export default SelectSector;
