import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useExploreRevisionChangeGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    review: { label: lang.revisionChange.review, columnSize: 90 },
    requestDate: { label: lang.revisionChange.requestDate, columnSize: 160 },
    impactDate: { label: lang.revisionChange.impactDate, columnSize: 160 },
    requestReason: { label: lang.revisionChange.requestReason, columnSize: 320 },
    requestUser: { label: lang.revisionChange.requestUser, columnSize: 180 },
    supervisionUser: { label: lang.revisionChange.supervisionUser, columnSize: 200 },
  };

  return gridModel;
};
