import { objectMapper } from "../../../shared/globals/utils/objectMapper";
import { PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes";
import { calculateLeakLevel } from "../../infoLubricationPoints/components/hooks/leakLevel";
import { LubricantInterface } from "../../lubricant/model/LubricantInterface";
import { getDailyLubricants } from "../../lubricant/services/service.lubricants";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import {
  DayPending,
  lubricationPointsByTagFPAdapter,
} from "../../lubricationPoints/services/lubricationPointsAdapters";
import {
  DayPendingLubricationPoints,
  GetAllLubricationPointsAndInfoByTagFP,
} from "../../lubricationPoints/services/lubricationPointsService";
import { PermissionInterface } from "../../permisions/model/PermissionInterface";
import {
  EquipmentsPermissionsByTagFP,
  PermissionsForEquipments,
} from "../../permisions/services/service.permission";
import { PersonInterface } from "../../person/models/PersonInterface";
import { RouteInterface } from "../../routes/services/RoutesInterface";
import { getOperatorDailyRoutes, RoutesInfo } from "../../routes/services/service.routes";
import { SessionInterface } from "../../session/model/sessionInterface";
import { getDailySupplies } from "../../supply/services/service.supplies";
import { lubricatorState } from "./lubricatorEntity";
import { lubricatorStoreFactory } from "./lubricatorStoreFactory";

const routeAdapter = (routes: any[]) => {
  return routes.map(route => ({
    ...route,
    lubricationPoints: JSON.parse(route.lubricationPoints),
  })) as RouteInterface[];
};

const routeInfoLubricationPointAdapter = (array: string[]) =>
  array.map((point: string) => ({
    tagTGD: point.split("|")[0],
    route: point.split("|")[1],
  }));

const dayPendingAdapter = (dayPending: DayPending) => {
  return {
    ...dayPending,
    pendingToday: routeInfoLubricationPointAdapter(JSON.parse(dayPending.pendingToday)),
    previousPending: routeInfoLubricationPointAdapter(JSON.parse(dayPending.previousPending)),
  };
};

const StringifySuppliesAdapter = (lubricationPoints: LubricationPointInterface[] | []) => {
  return lubricationPoints?.map(point => ({
    ...point,
    supplies: JSON.stringify(point.supplies),
    info: point.info && JSON.stringify(point.info.supplies),
  })) as unknown as LubricationPointInterface[];
};

const GetAllLubricationPointsAndInfoByTagFPAdapter = (queryParams: PrivateQueryTagFPInterface) => {
  return GetAllLubricationPointsAndInfoByTagFP(queryParams).then(AllPoints => {
    return lubricationPointsByTagFPAdapter(AllPoints).map((point: LubricationPointInterface) => ({
      ...point,
      leakLevel: calculateLeakLevel(point.info?.lubricantQuantity || 0, point.capacity),
    }));
  });
};

const getActivePoints = (points: LubricationPointInterface[]) => {
  //filter points if point is inactive
  return points.filter(point => point.state);
};

export const GetOperatorData = async (
  session: SessionInterface,
  operator?: PersonInterface,
  _allLubricationPoints?: LubricationPointInterface[] | []
): Promise<lubricatorState> => {
  let operatorData = lubricatorStoreFactory({ operator });

  if (!!operator?.lubricatorNumber && operator.lubricatorNumber > 0) {
    const date = new Date();

    const data = {
      date: date.toISOString().split("T")[0],
      tagFP: session.tagFP,
    };

    const routesInfo = await RoutesInfo({ data, token: session.token });
    /* const routesInfo = await queryClient.getQueryData('RoutesInfo') */

    const allLubricationPoints: LubricationPointInterface[] | [] =
      _allLubricationPoints ||
      (await GetAllLubricationPointsAndInfoByTagFPAdapter({
        tagFP: session.tagFP,
        token: session.token,
      }));

    /* const allLubricationPoints = queryClient.getQueryData<any>('LubricationPointsWithInfo') */
    //AllEquipments
    if (!!allLubricationPoints) {
      const lubricationPointsMapped = objectMapper(allLubricationPoints, "tagTGD");

      //rutas del dia para el operador
      const routes = await getOperatorDailyRoutes(
        operator.lubricatorNumber || 0,
        session.tagFP,
        session.token
      );

      if (!!routes && !!routesInfo) {
        const RoutesAdapted = routeAdapter(routes);

        //Puntos que tocan hoy
        const dailyPoints: LubricationPointInterface[] | [] =
          (lubricationPointsMapped &&
            RoutesAdapted?.map((route: any) => {
              const lubricationPoints = route.lubricationPoints.map((point: string) => {
                return {
                  tagTGD: point,
                  routeName: route.routeName,
                };
              });
              return lubricationPoints;
            })
              .flat()
              .map(item => {
                return {
                  ...lubricationPointsMapped[item.tagTGD],
                  routeName: item.routeName,
                };
              })) ||
          [];

        //puntos que estan pendientes hoy
        const todayPendingLubricationPoints = dailyPoints.filter(point => point.info?.pending);

        //rutas del día para el operador con estado
        const dailyRoutes = RoutesAdapted.map((route: any) => {
          const routePoints = dailyPoints.filter((point: any) => {
            const result = route.lubricationPoints.includes(point.tagTGD);
            return result;
          });

          const routeState =
            routesInfo?.filter((r: any) => r.route === route.routeName)[0]?.state || "PENDIENTE";

          return {
            ...route,
            state: routeState,
            lubricationPoints: [...routePoints],
          };
        });

        operatorData = {
          ...operatorData,
          dailyRoutes,
          dailyPoints,
          todayPendingLubricationPoints: getActivePoints(todayPendingLubricationPoints),
        };

        //acumulados pendientes
        const previusPendingPoints: DayPending = await DayPendingLubricationPoints({
          data,
          token: session.token,
        });
        if (!!previusPendingPoints) {
          const pendingPoints = dayPendingAdapter(previusPendingPoints);
          const previous = pendingPoints?.previousPending?.filter(point => {
            if (point.route === undefined) return false;
            if (point.tagTGD === "") return false;
            return parseInt(point.route.split("-")[0]) === operator?.lubricatorNumber;
          });
          const previusPendingLubricationPoints =
            lubricationPointsMapped &&
            previous?.map(item => {
              return lubricationPointsMapped[item.tagTGD];
            });

          const allDayPoints = [...dailyPoints, ...previusPendingLubricationPoints];

          const allDayPendingPoints = allDayPoints.filter(point => point.info.pending);

          operatorData = {
            ...operatorData,
            previusPendingLubricationPoints: getActivePoints(previusPendingLubricationPoints),
            allDayPoints,
            allDayPendingPoints,
          };

          const lubricationPointsPermissions: PermissionInterface[] | [] =
            await PermissionsForEquipments({
              data: StringifySuppliesAdapter(allDayPoints),
              token: session.token,
            });
          if (!!lubricationPointsPermissions) {
            const equipmentsPermissions: PermissionInterface[] = await EquipmentsPermissionsByTagFP(
              { tagFP: session.tagFP, token: session.token }
            );
            if (!!equipmentsPermissions) {
              const equipmentPermissionsMap = objectMapper(equipmentsPermissions, "permissionName");
              const dailyPermissions = lubricationPointsPermissions.map(permission => ({
                permission,
                lubricationPoints: allLubricationPoints?.filter(
                  (point: any) =>
                    point.tagTGD === equipmentPermissionsMap[permission.permissionName]?.equipment
                ),
              }));

              operatorData = {
                ...operatorData,
                permissions: dailyPermissions,
              };

              const lubricationPointsLubricants: {
                lubricant: LubricantInterface;
                lubricationPoints: LubricantInterface[];
              }[] = await getDailyLubricants({
                data: StringifySuppliesAdapter(allDayPoints),
                tagFP: session.tagFP,
                token: session.token,
              });

              const lubricants = lubricationPointsLubricants.map(l => {
                return {
                  lubricant: l.lubricant,
                  lubricationPoints:
                    allDayPoints.filter(d => d.lubricant === l.lubricant.lubricant) || [],
                };
              });

              operatorData = {
                ...operatorData,
                lubricants,
              };

              const supplies: any = await getDailySupplies({
                data: StringifySuppliesAdapter(allDayPoints),
                tagFP: session.tagFP,
                token: session.token,
              });

              if (supplies !== undefined) {
                operatorData = {
                  ...operatorData,
                  supplies,
                  message: "",
                  status: "success",
                };
              } else {
                operatorData = {
                  ...operatorData,
                  status: "error",
                  message: "no existe: insumos",
                };
              }
            } else {
              operatorData = {
                ...operatorData,
                status: "error",
                message: "no existe: equipos permisos",
              };
            }
          } else {
            operatorData = {
              ...operatorData,
              status: "error",
              message: "no existe: permisos",
            };
          }
        } else {
          operatorData = {
            ...operatorData,
            status: "error",
            message: "no existe: equipos previos pendientes",
          };
        }
      } else {
        operatorData = {
          ...operatorData,
          status: "error",
          message: "no existe: rutas o info rutas",
        };
      }
    } else {
      operatorData = {
        ...operatorData,
        status: "error",
        message: "no existe: equipos",
      };
    }
  }

  return operatorData;
};
