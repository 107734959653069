import React from "react";

interface Props {
  children: React.ReactNode;
  fallBackComponent: React.ReactNode;
  resetCondition?: any;
}

interface State {
  hasError: boolean;
  resetCondition?: any;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, resetCondition: props.resetCondition, error: null };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.resetCondition !== state.resetCondition) {
      return { hasError: false, resetCondition: props.resetCondition };
    }
    return null;
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(`error: ${error} ,errorInfo: ${errorInfo} state: ${this.state.hasError}`);
  }

  handleResetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return React.cloneElement(this.props.fallBackComponent as React.ReactElement, {
        error: this.state.error,
        onResetError: this.handleResetError,
      });
    }
    return this.props.children;
  }
}
