import { useDate } from "../../../../../shared/date/useDate";
import { InputComponentTypes } from "../../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../../shared/form/useForm";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import {
  InfoSurveyInterface,
  SurveyInterface,
  UpdateSurveyInterface,
} from "../../../model/SurveyInterface";
import { FormattedUiModel } from "../models/FormattedUiModel";
import { SurveyTasksValues } from "../../../tasks/model/TaskInterface";

export const useSurveyUpdateForm = (item?: SurveyInterface & InfoSurveyInterface) => {
  const { lang } = useLangLabels();
  const { formatISOToLocalDateTime } = useDate();

  const surveyUpdateForm = useForm<UpdateSurveyInterface>({
    id: {
      value: item?.id,
      component: InputComponentTypes.none,
    },
    tagFP: {
      value: item?.tagFP,
      component: InputComponentTypes.none,
    },
    tagTGD: {
      value: item?.tagTGD,
      component: InputComponentTypes.none,
    },
    taskType: {
      value: item?.taskType,
      component: InputComponentTypes.none,
    },
    manualRegistration: {
      value: item?.manualRegistration,
      component: InputComponentTypes.none,
    },
    startTime: {
      label: lang.surveys.startTime,
      placeholder: lang.surveys.startTime,
      value: formatISOToLocalDateTime(item?.startTime || "") || "",
      component: InputComponentTypes.dateTime,
    },
    endTime: {
      label: lang.surveys.endTime,
      placeholder: lang.surveys.endTime,
      value: formatISOToLocalDateTime(item?.endTime || "") || "",
      component: InputComponentTypes.dateTime,
    },
    review: {
      label: lang.surveys.review,
      placeholder: lang.surveys.review,
      value: item?.review || 0,
      inputConfig: {
        tagFP: item?.tagFP || "",
        tagTGD: item?.tagTGD || "",
      },
      component: InputComponentTypes.review,
    },
    temperature: {
      label: lang.surveys.temperature,
      placeholder: lang.surveys.temperature,
      value: item?.temperature || 0,
      component: InputComponentTypes.text,
      validations: {
        pattern: {
          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
          message: lang.validations.messages.pattern,
        },
      },
    },
    taskDone: {
      label: lang.surveys.taskDone,
      placeholder: lang.surveys.taskDone,
      value: item?.taskDone !== undefined ? item.taskDone : true,
      options: [
        { label: lang.words.yes.toUpperCase(), value: true },
        { label: lang.words.no.toUpperCase(), value: false },
      ],
      component: InputComponentTypes.selectWithOptions,
    },
    taskTime: {
      label: lang.surveys.taskTime,
      placeholder: lang.surveys.taskTime,
      value: item?.taskTime || 0,
      component: InputComponentTypes.text,
      validations: {
        pattern: {
          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
          message: lang.validations.messages.pattern,
        },
      },
    },
    needsLubrication: {
      label: lang.surveys.needsLubrication,
      placeholder: lang.surveys.needsLubrication,
      value: item?.needsLubrication !== undefined ? item.needsLubrication : true,
      options: [
        { label: lang.words.yes.toUpperCase(), value: true },
        { label: lang.words.no.toUpperCase(), value: false },
      ],
      component: InputComponentTypes.selectWithOptions,
    },
    consumables: {
      label: lang.surveys.consumables,
      placeholder: lang.surveys.consumables,
      value: item?.consumables || 0,
      component: InputComponentTypes.none,
    },
    lubricates: {
      label: lang.surveys.lubricates,
      placeholder: lang.surveys.lubricates,
      value: item?.lubricates !== undefined ? item.lubricates : true,
      options: [
        { label: lang.words.yes.toUpperCase(), value: true },
        { label: lang.words.no.toUpperCase(), value: false },
      ],
      component: InputComponentTypes.selectWithOptions,
    },
    impossibleToLubricate: {
      label: lang.surveys.impossibleToLubricate,
      placeholder: lang.surveys.impossibleToLubricate,
      value: item?.impossibleToLubricate !== undefined ? item.impossibleToLubricate : true,
      options: [
        {
          label: lang.surveys.options.impossibleToLubricate.toUpperCase(),
          value: true,
        },
        { label: lang.surveys.options.normal.toUpperCase(), value: false },
      ],
      component: InputComponentTypes.selectWithOptions,
    },
    reason: {
      label: lang.surveys.reason,
      placeholder: lang.surveys.reason,
      value: item?.reason || 0,
      inputConfig: {
        tagFP: item?.tagFP || "",
      },
      component: InputComponentTypes.reason,
      validations: {
        required: {
          value: item?.taskType === SurveyTasksValues.lubrication ? item?.lubricates : false,
          message: lang.validations.messages.required,
        },
      },
      clearable: true,
    },
    lubricant: {
      label: lang.surveys.lubricant,
      placeholder: lang.surveys.lubricant,
      value: item?.lubricant || "",
      inputConfig: {
        tagFP: item?.tagFP || "",
      },
      component: InputComponentTypes.lubricant,
    },
    quantity: {
      label: lang.surveys.quantity,
      placeholder: lang.surveys.quantity,
      value: item?.quantity || 0,
      component: InputComponentTypes.text,
      validations: {
        pattern: {
          value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
          message: lang.validations.messages.pattern,
        },
      },
    },
  });

  const { uiModel } = surveyUpdateForm;

  const formattedUiModel: FormattedUiModel<SurveyInterface & InfoSurveyInterface> = uiModel.reduce(
    (acc, obj) => {
      acc[obj.name] = obj;
      return acc;
    },
    {} as FormattedUiModel<SurveyInterface & InfoSurveyInterface>
  );

  return {
    ...surveyUpdateForm,
    formattedUiModel,
  };
};
