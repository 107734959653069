import React from "react";
import { CollectorInterface } from "../../models/CollectorInterfaces";
import { FlexContainer } from "../../../../components/containers";
import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { UpdateCollector } from "../../services/collectorsServices";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const DesactivateActivateCollector = ({ item }: { item: CollectorInterface }) => {
  const actionType = CrudFormType.update;
  const { lang } = useLangLabels();

  const {
    action: submitAction,
    resetStatus,
    status,
    error,
    message,
  } = useCreateUpdate(
    {
      update: UpdateCollector,
    },
    actionType,
    ["CollectorsByTagFP"]
  );

  const handleActivateDesactivate = () => {
    const updatedState = !item.state;
    submitAction({ ...item, state: updatedState });
  };

  const resetForm = () => {
    resetStatus();
  };

  return (
    <DefaultFormLayout
      componentMode="modal"
      buttonIcon={item.state ? "off" : "power"}
      buttonVariant="iconButton"
      buttonText={item.state ? `${lang.collectors.desactivate}` : `${lang.collectors.activate}`}
      buttonAction={handleActivateDesactivate}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={resetForm}
      resetForm={resetForm}
    >
      <FlexContainer
        style={{ width: "400px" }}
        flexDirection="column"
        align="center"
        gap={"16px"}
      ></FlexContainer>
    </DefaultFormLayout>
  );
};

export default React.memo(DesactivateActivateCollector);
