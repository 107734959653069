import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import TgdTable, { TgdTableInterface } from "../../../components/grids/TgdTable";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useLubricationPointByTagTGD } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useRoutesGridModel } from "../lang/useRoutesGridModel";
import { RouteInterface } from "../services/RoutesInterface";
import { useRoutesByTagFP } from "../services/service.routes";
import AssignCancelRoute from "./AssignCancelRoute";

interface Props {
  item: LubricationPointInterface;
}

const AssignCancelRouteForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { data: allRoutes, status: allRoutesStatus, error: allRoutesError } = useRoutesByTagFP();
  const { data: LubricationPoint } = useLubricationPointByTagTGD(item.tagTGD);

  const columns: TgdTableInterface[] = [
    { label: lang.globalentityValues.id, atribute: "id" },
    { label: lang.routes.operator, atribute: "operator" },
    { label: lang.routes.periodicity, atribute: "periodicity" },
    { label: lang.routes.route, atribute: "route" },
    { label: lang.routes.routeName, atribute: "routeName" },
    { label: lang.routes.startDate, atribute: "startDate" },
  ];

  const routesGridModel = useRoutesGridModel();

  return (
    <ComponentMode
      key={item.tagTGD}
      modalTitle={false}
      mode="modal"
      variant="chip"
      popoverTitle={`${lang.routes.assignRouteToEquipment}${LubricationPoint && LubricationPoint !== "" ? ": " + LubricationPoint?.plantTag : ""}`}
      icon="config"
      buttonTittle={lang.routes.plural}
      disabled={LubricationPoint === "" || !LubricationPoint}
    >
      {allRoutesError && (
        <>
          <Alert severity="error">{lang.routes.getRoutesError} </Alert>
          <br />
        </>
      )}
      {allRoutesStatus === "loading" ? (
        <CircularProgress size="small" />
      ) : (
        <TgdTable
          headerTitle={`${lang.routes.assignRouteToEquipment}: ${LubricationPoint?.plantTag}`}
          title={lang.routes.plural}
          items={allRoutes?.sort((a, b) => parseInt(a.route) - parseInt(b.route))}
          gridModel={routesGridModel}
          type={"route"}
          itemActions={(route: RouteInterface) => (
            <AssignCancelRoute equipment={LubricationPoint} route={route} />
          )}
          columns={columns}
        />
      )}
    </ComponentMode>
  );
};

export default AssignCancelRouteForm;
