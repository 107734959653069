import { CircularProgress } from "@material-ui/core";
import React from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { RouteInterface } from "../services/RoutesInterface";
import { useAssignCancelRoutes } from "../services/service.routes";

const AssignCancelRoute: React.FC<{
  equipment: LubricationPointInterface;
  route: RouteInterface;
}> = ({ equipment, route }) => {
  const {
    assign,
    cancel,
    query: { status },
  } = useAssignCancelRoutes();

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : equipment && JSON.parse(equipment.routes as unknown as string).includes(route.routeName) ? (
    <>
      <CustomButton variant="iconButton" disabled icon="check" styleProps={{ color: "green" }} />
      <CustomButton
        variant="iconButton"
        icon="delete"
        styleProps={{ color: "green" }}
        action={() => cancel(equipment, route.routeName)}
      />
    </>
  ) : (
    <CustomButton
      variant="iconButton"
      icon="add"
      styleProps={{ color: "red" }}
      action={() => assign(equipment, route.routeName)}
    />
  );
};

export default AssignCancelRoute;
