import { getPbiIndicators } from "../../../domains/powerBi/utils.ts/pbiUtils";
import { newMenuItemWithSubMenu } from "./newMenuItemWithSubMenu";

export const setNewMenu = (NewMenuItems: any[], defaultMenuItems: any[]) => {
  let newMenu: any = [];
  const { data } = getPbiIndicators(NewMenuItems);

  defaultMenuItems?.forEach((item: any) => newMenu.push(item));
  data.forEach((fMitem: string, i: number) => {
    let subItems = NewMenuItems.filter((item: any) => item.name.includes(fMitem));
    newMenu.push(newMenuItemWithSubMenu(i, subItems, fMitem, "bubble_chart"));
  });

  let filters = ["control", "privado", "usage"];
  let filteredNewMenu = newMenu.filter((item: any) => {
    if (item.name.toLowerCase().includes(filters[0])) return false;
    if (item.name.toLowerCase().includes(filters[1])) return false;
    if (item.name.toLowerCase().includes(filters[2])) return false;
    else return true;
  });

  return filteredNewMenu;
};
