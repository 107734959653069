import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  SidebarContainer: {
    width: "100%",
    display: "grid",

    gap: "24px",
  },
}));

const SidebarContainer: React.FC<{ children?: React.ReactNode; reverse?: boolean }> = ({
  children,
  reverse,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.SidebarContainer}
      style={reverse ? { gridTemplateColumns: "350px 1fr" } : { gridTemplateColumns: "1fr 350px" }}
    >
      {children}
    </div>
  );
};

export default SidebarContainer;
