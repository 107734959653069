import React, { useMemo } from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useUser } from "../../session/store/sessionStore";
import CULubricantForm from "../CULubricantForm";
import { LubricantInterface } from "../model/LubricantInterface";
import { useAllLubricantsByPlant } from "../services/service.lubricants";

type Props = {
  error?: string;
  tagFP: string;
  complete?: boolean;
  onChange: (value: any) => void;
  defaultValue?: string;
  disabled?: boolean;
  clearable?: boolean;
  by?: {
    key: string;
    value: string;
  };
};

const SelectLubricantBy: React.FC<Props> = ({
  error,
  tagFP,
  complete,
  onChange,
  defaultValue,
  by,
  disabled,
  clearable,
}) => {
  const key = "lubricant";
  const { lang } = useLangLabels();
  const { data: user } = useUser();
  const { data: lubricants } = useAllLubricantsByPlant();
  const lubricantsBy = useMemo(
    () =>
      (by
        ? lubricants?.filter(l => l[by.key as keyof LubricantInterface] === by.value)
        : lubricants) || [],
    [by, lubricants]
  );
  const label = lang.lubricantForm.singular;

  const handleChange = (value: string) => {
    if (complete) {
      const lub = lubricantsBy.find(l => l[key] === value) as LubricantInterface;
      onChange(lub);
    } else {
      onChange(value);
    }
  };

  return (
    <SimpleAutocompleteSelect
      {...{
        error,
        label,
        items: lubricantsBy,
        defaultValue,
        onChange: handleChange,
        showTitle: key,
        disabled,
        clearable: clearable ?? false,
      }}
    >
      {user?.type !== "O" && !disabled && (
        <CULubricantForm {...{ tagFP, actionType: CrudFormType.create }} />
      )}
    </SimpleAutocompleteSelect>
  );
};

export default SelectLubricantBy;
