import React, { memo, useMemo, useRef } from "react";

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
} from "@tanstack/react-table";
import { VirtualGridAdapterProps } from "../types/VirtualGridTypes";

import { useVirtualizer } from "@tanstack/react-virtual";

import { CELL_HEIGHT, SCROLLBAR_SIZE } from "../../TableContainer/TableContainer";

import { KeyboardArrowDown, KeyboardArrowUp, Sort } from "@material-ui/icons";

import useDefaultColumn from "../hooks/useDefaultColumn";
import useBasicLocalStorage from "../../../localStorage/useBasicLocalStorage";
import { RightClick } from "../../RightClick/RightClick";

const VirtualGridAdapter = <TData extends { id: string }>({
  columns,
  data,
  componentWidth,
  componentHeight,
  rowSelection,
  onRowSelectionChange,
  entityName,
  cellFormatter,
}: VirtualGridAdapterProps<TData>) => {
  const storeValue = !!entityName;
  const columnResizeMode: any = "onChange";

  const memoizedData = useMemo(() => data ?? [], [data]);

  const defaultColumn = useDefaultColumn();

  const getRowId = (row: TData, relativeIndex: number, parent?: Row<TData>) => {
    return parent ? [parent.id, row.id].join(".") : row.id;
  };

  const [columnSizing, setColumnSizing] = useBasicLocalStorage({
    key: `${entityName?.toLowerCase()}-column-sizing`,
    initialValue: {},
    storeLocal: storeValue,
  });

  const tableInstance = useReactTable({
    columns,
    data: memoizedData,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    // Row Selecting
    enableRowSelection: true,
    onRowSelectionChange,
    getRowId,
    // Sort
    getSortedRowModel: getSortedRowModel(),
    // Column Sizing
    columnResizeMode,
    columnResizeDirection: "ltr",
    onColumnSizingChange: setColumnSizing,
    state: {
      rowSelection,
      columnSizing,
    },
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCenterTotalSize,
    getState,
    options: tableOptions,
  } = tableInstance;

  const { rows } = getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => CELL_HEIGHT,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div
      className="container"
      style={{
        width: componentWidth,
        direction: tableOptions.columnResizeDirection,
      }}
    >
      <div
        ref={tableContainerRef}
        className="table-container"
        style={{ height: componentHeight() * CELL_HEIGHT + 40 + SCROLLBAR_SIZE }}
      >
        <table className="table" style={{ width: getCenterTotalSize() }}>
          <thead className="thead">
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="tr">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="th"
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            cursor: header.column.getCanSort() ? "pointer" : "default",
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: (
                              <KeyboardArrowUp
                                style={{ fontSize: "1rem", marginLeft: "4px" }}
                                fontSize="small"
                              />
                            ),
                            desc: (
                              <KeyboardArrowDown
                                style={{ fontSize: "1rem", marginLeft: "4px" }}
                                fontSize="small"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}

                          {!header.column.getIsSorted() &&
                            header.id !== "renderOptions" &&
                            header.id !== "select" && (
                              <Sort
                                style={{
                                  fontSize: "1rem",
                                  marginLeft: "6px",
                                  marginBottom: "-2px",
                                }}
                                fontSize="small"
                              />
                            )}
                        </div>
                        <div
                          onDoubleClick={() => header.column.resetSize()}
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${tableOptions.columnResizeDirection} ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`}
                          style={{
                            transform:
                              columnResizeMode === "onEnd" && header.column.getIsResizing()
                                ? `translateX(${
                                    (tableOptions.columnResizeDirection === "rtl" ? -1 : 1) *
                                    (getState().columnSizingInfo.deltaOffset ?? 0)
                                  }px)`
                                : "",
                          }}
                        />
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="tbody" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index] as Row<any>;
              return (
                <tr
                  key={row.id}
                  className={row.getIsSelected() ? "tr-selected" : "tr"}
                  data-index={virtualRow.index}
                  ref={node => rowVirtualizer.measureElement(node)}
                  style={{
                    height: CELL_HEIGHT,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    const columnId = cell.column.id;
                    const cellValue = cell.getValue();
                    const formattedValue =
                      cellFormatter && cellFormatter[columnId]
                        ? cellFormatter[columnId](cellValue as string)
                        : cellValue;

                    return (
                      <td
                        key={cell.id}
                        onClick={
                          cell.column.id !== "renderOptions"
                            ? row.getToggleSelectedHandler()
                            : undefined
                        }
                        className="td"
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        <RightClick data={cell.row.original[cell.column.id]}>
                          {flexRender(cell.column.columnDef.cell, {
                            ...cell.getContext(),
                            getValue: () => formattedValue,
                          })}
                        </RightClick>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(VirtualGridAdapter);
