import React from "react";
import { usePlantSectors } from "../services/service.sectors";
import CUSectorForm from "./../CUSectorForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useSectorsGridModel } from "../lang/useSectorsGRidModel";
import DeleteSectorFrom from "../DeleteSectorFrom";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageSectorPageProps {
  virtualGridWidth: string;
}

const ManageSectorPage: React.FC<ManageSectorPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: sectors, isFetching } = usePlantSectors();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: item => (
        <CUSectorForm actionType={CrudFormType.update} item={item} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        title: lang.sectors.title.plural,
        entityName: lang.sectors.title.plural,
        width: virtualGridWidth,
        items: sectors,
        carrousellComponents: carrousellActions,
        gridModel: useSectorsGridModel(),
        headerActions: () => <CUSectorForm actionType={CrudFormType.create} />,
        itemActions: item => <DeleteSectorFrom item={item} />,
      }}
    ></VirtualGrid>
  );
};

export default ManageSectorPage;
