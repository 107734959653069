import React from "react";
import img from "../../assets/img/login-background.png";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { USER_ROUTES } from "../../shared/routes/routes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface ErrorBoundaryPageProps {
  error?: Error | null;
  onResetError?: () => void;
}

const ErrorBoundaryPage: React.FC<ErrorBoundaryPageProps> = ({ error, onResetError }) => {
  const navigate = useNavigate();
  const { lang } = useLangLabels();

  const handleGoHome = () => {
    if (onResetError) {
      onResetError();
      navigate(`/${USER_ROUTES.home}/`);
    }
  };

  const handleGoBack = () => {
    if (onResetError) {
      onResetError();
      navigate(-1);
    }
  };

  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh", overflow: "hidden" }}>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "grayscale(100%)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        position="relative"
        zIndex={1}
      >
        <Paper elevation={3} style={{ padding: "24px 32px", textAlign: "center", maxWidth: 400 }}>
          <Typography variant="h5" gutterBottom>
            {lang.errorBoundary.errorMessage}
          </Typography>
          {error && (
            <Typography variant="body2" color="error" paragraph>
              {error.name}: {error.message}
            </Typography>
          )}
          <Typography variant="body1" paragraph>
            {lang.errorBoundary.pleaseContactSupport}
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            style={{ gap: "8px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoHome}
              style={{ minWidth: 120 }}
            >
              {lang.errorBoundary.homeButton}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleGoBack}
              style={{ minWidth: 120 }}
            >
              {lang.errorBoundary.backButton}
            </Button>
          </Box>

          <Typography
            color="textSecondary"
            variant="body2"
            style={{ marginTop: 8, fontSize: "0.9rem" }}
          >
            {lang.errorBoundary.pressTwoTimesButton}
          </Typography>
        </Paper>
      </Box>
    </div>
  );
};

export default ErrorBoundaryPage;
