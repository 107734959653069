import { useCallback, useMemo, useState } from "react";
import moment from "moment";

import { Views } from "react-big-calendar";
import { CalendarRoute } from "../../../domains/routes/services/RoutesInterface";
import { useLangLabels } from "../../lang/services/useLangLabels";

import { capitalizeText } from "../utils/capitalizeText";

export type ViewKeys = keyof typeof Views;

interface CalendarHookProps {
  selectedLanguage: string;
}

export const useCalendar = ({ selectedLanguage }: CalendarHookProps) => {
  const { lang } = useLangLabels();

  const [view, setView] = useState<(typeof Views)[ViewKeys]>(Views.WEEK);
  const [date, setDate] = useState<Date>(moment(new Date()).toDate());

  const viewOptions = [
    { id: Views.DAY, label: lang.dates.day.singular },
    { id: Views.WEEK, label: lang.dates.week.singular },
    { id: Views.MONTH, label: lang.dates.month.singular },
  ];

  const handleViewChange = (newView: (typeof Views)[ViewKeys]) => {
    setView(newView);
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const onPrevClick = useCallback(() => {
    if (view === Views.DAY) {
      setDate(moment(date).subtract(1, "d").toDate());
    } else if (view === Views.WEEK) {
      setDate(moment(date).subtract(1, "w").toDate());
    } else {
      setDate(moment(date).subtract(1, "M").toDate());
    }
  }, [view, date]);

  const onNextClick = useCallback(() => {
    if (view === Views.DAY) {
      setDate(moment(date).add(1, "d").toDate());
    } else if (view === Views.WEEK) {
      setDate(moment(date).add(1, "w").toDate());
    } else {
      setDate(moment(date).add(1, "M").toDate());
    }
  }, [view, date]);

  const onTodayClick = useCallback(() => {
    setDate(moment().toDate());
  }, []);

  const dateText = useMemo(() => {
    if (view === Views.DAY) return capitalizeText(moment(date).format("dddd, MMMM DD"));
    if (view === Views.WEEK) {
      const from = moment(date)?.startOf("week");
      const to = moment(date)?.endOf("week");
      return `${selectedLanguage === "es" ? "De" : ""} ${capitalizeText(
        from.format("MMMM DD")
      )} ${lang.dates.to.toLowerCase()} ${capitalizeText(to.format("MMMM DD"))}`;
    }
    if (view === Views.MONTH) {
      return capitalizeText(moment(date).format("MMMM YYYY"));
    }
  }, [view, date, selectedLanguage]);

  const isToday = useMemo(() => {
    const today = moment().startOf("day");
    const currentDate = moment(date).startOf("day");
    return today.isSame(currentDate);
  }, [date]);

  const getEvents = (weekRoutes?: CalendarRoute[]) => {
    let lastDate: string;
    let index = 0;
    return weekRoutes?.map(route => {
      if (lastDate !== route.calendarRouteDate) {
        lastDate = route.calendarRouteDate;
        index = 0;
      }

      const startDate = moment(route.calendarRouteDate.split("T")[0])
        .set({ hour: index, minute: 0, second: 0 })
        .toDate();
      const endDate = moment(startDate)
        .set({ hour: index + 1, minute: 0, second: 0 })
        .toDate();

      index++;

      return {
        start: startDate,
        end: endDate,
        title: route.routeName,
        route: {
          ...route,
          state: route.lastRouteState,
        },
      };
    });
  };

  return {
    view,
    viewOptions,
    date,
    dateText,
    handleViewChange,
    handleDateChange,
    getEvents,
    onPrevClick,
    onNextClick,
    onTodayClick,
    isToday,
  };
};
