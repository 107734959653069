import { DemoInterface } from "./demoInterface";

export const demoFactory = (item?: DemoInterface): DemoInterface => {
  return {
    applicantsName: item?.applicantsName || "",
    applicantsTelephone: item?.applicantsTelephone || "+54",
    applicantsCompany: item?.applicantsCompany || "",
    applicantsEmail: item?.applicantsEmail || "",
    htmlClient: item?.htmlClient || "",
    htmlAdmin: item?.htmlAdmin || "",
    latitude: item?.latitude || "",
    longitude: item?.longitude || "",
    operativeSystem: item?.operativeSystem || "",
    device: item?.device || "",
    browser: item?.browser || "",
  };
};
