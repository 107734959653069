import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";
import TgdSvgIcon from "./TgdSvgIcon";

const OilCanIcon: React.FC<any> = (props: SvgIconProps) => {
  return (
    <TgdSvgIcon {...props}>
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="5 5 70 70"
        height="100%"
        width="100%"
      >
        <title>oilCanIcon</title>
        <g>
          <path
            d="M41.64,138h80.18v80.18H41.64Zm9.1,26.75c-.75,2.92-1.51,5.79-2.22,8.66a2.6,2.6,0,0,0,1.85,3.49L58.43,180a3.34,3.34,0,0,1,2.45,3.51c0,1.28,0,2.55,0,3.82,0,2.63,1.15,3.78,3.76,3.78,8.21,0,16.42-.06,24.63,0a6.12,6.12,0,0,0,5.46-2.74c3.25-4.46,6.63-8.81,9.84-13.29a5.82,5.82,0,0,1,3.8-2.63,2,2,0,0,1,1.17.15c.85.45,1.64,1,2.5,1.54l2.67-2.86c-1.18-1.05-2.32-2-3.39-3a1.41,1.41,0,0,0-1.6-.35c-7.4,2.27-14.81,4.49-22.22,6.74-.52.16-1,.28-1.33-.3a3.92,3.92,0,0,0-4.31-1.86,3.37,3.37,0,0,1-.55,0H76v-3.43h3.42v-3.7H68.75v3.87h3.07v3.3H65.21a15.72,15.72,0,0,1-.07-1.65.91.91,0,0,0-.73-1c-1.12-.37-2.22-.81-3.32-1.23ZM112,176.55l-.23-.08a3.51,3.51,0,0,0-.91.6,33.41,33.41,0,0,0-2.38,3.4,5.17,5.17,0,0,0-.08,4.57,2.66,2.66,0,0,0,4.73.75,4.4,4.4,0,0,0,.4-5.09C112.79,179.41,111.64,178.3,112,176.55Z"
            transform="translate(-41.64 -138)"
            style={{ fill: "none" }}
          />
          <path
            d="M50.74,164.75l10.35,3.86c1.1.42,2.2.86,3.32,1.23a.91.91,0,0,1,.73,1,15.72,15.72,0,0,0,.07,1.65h6.61v-3.3H68.75v-3.87h10.7v3.7H76v3.43h5.28a3.37,3.37,0,0,0,.55,0,3.92,3.92,0,0,1,4.31,1.86c.34.58.81.46,1.33.3,7.41-2.25,14.82-4.47,22.22-6.74a1.41,1.41,0,0,1,1.6.35c1.07,1,2.21,2,3.39,3L112,174.14c-.86-.54-1.65-1.09-2.5-1.54a2,2,0,0,0-1.17-.15,5.82,5.82,0,0,0-3.8,2.63c-3.21,4.48-6.59,8.83-9.84,13.29a6.12,6.12,0,0,1-5.46,2.74c-8.21-.09-16.42,0-24.63,0-2.61,0-3.75-1.15-3.76-3.78,0-1.27,0-2.54,0-3.82A3.34,3.34,0,0,0,58.43,180l-8.06-3.07a2.6,2.6,0,0,1-1.85-3.49C49.23,170.54,50,167.67,50.74,164.75ZM61.09,177c0-1.48,0-2.81,0-4.14a.71.71,0,0,0-.32-.54c-2.23-.86-4.46-1.69-6.71-2.49-.66-.24-1.11.06-1.26.77a14.23,14.23,0,0,0-.46,2.21,1.39,1.39,0,0,0,.55,1.14C55.54,175,58.24,175.94,61.09,177Z"
            transform="translate(-41.64 -138)"
          />
          <path
            d="M112,176.55c-.37,1.75.78,2.86,1.53,4.15a4.4,4.4,0,0,1-.4,5.09,2.66,2.66,0,0,1-4.73-.75,5.17,5.17,0,0,1,.08-4.57,33.41,33.41,0,0,1,2.38-3.4,3.51,3.51,0,0,1,.91-.6Z"
            transform="translate(-41.64 -138)"
          />
          <path
            d="M61.09,177c-2.85-1-5.55-2-8.21-3a1.39,1.39,0,0,1-.55-1.14,14.23,14.23,0,0,1,.46-2.21c.15-.71.6-1,1.26-.77,2.25.8,4.48,1.63,6.71,2.49a.71.71,0,0,1,.32.54C61.1,174.17,61.09,175.5,61.09,177Z"
            transform="translate(-41.64 -138)"
            style={{ fill: "none" }}
          />
        </g>
      </svg>
    </TgdSvgIcon>
  );
};

export default OilCanIcon;
