import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import useFieldFilter from "../../../../components/filters/FieldFilter/hooks/useFieldFilter";
import { usePeriod } from "../../../../shared/date";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { usePaginationState } from "../../../../shared/react-table/Pagination";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import { TaskTypeT } from "../../../surveys/tasks/model/TaskInterface";
import CUObservationForm from "../../CUObservationForm";
import DeleteObservationForm from "../../DeleteObservationForm";
import { useObservationsGridModel } from "../../lang/useObservationsGridModel";
import { ObservationInterface, ObservationType } from "../../models/ObservationTypes";
import { useObservationAttrValues } from "../../services/observationServices";
import { usePaginatedObservationsLubricationPointByPeriod } from "../../services/paginatedObservationService";

interface KnowledgeOfTheObservationsProps {
  title: string;
}

const KnowledgeOfTheObservations = ({ title }: KnowledgeOfTheObservationsProps) => {
  const [observationItems, setObservationItems] = useState<ObservationInterface[]>();

  const periodEntityName = "reports-page";
  const { period, setPeriod } = usePeriod(7, periodEntityName);

  const { lang } = useLangLabels();
  const observationGridModel = useObservationsGridModel();

  const { paginationOptions } = usePaginationState();
  const { data: observationFilterOptions } = useObservationAttrValues();
  const filterOptions = useFieldFilter<ObservationInterface>({
    items: observationItems!,
    gridModel: observationGridModel,
    config: {
      isServerSideFiltering: true,
      fields: {
        observationDate: {
          hideField: true,
        },
        taskType: {
          options: options =>
            options.map(option => ({
              name: lang.surveys.taskTypes[option.name as TaskTypeT]?.toUpperCase(),
              value: option.value,
            })),
        },
        type: {
          options: options =>
            options.map(option => ({
              name: lang.observations.observationTypes[
                option.name as ObservationType
              ]?.toUpperCase(),
              value: option.value,
            })),
        },
      },
    },
    entityName: title,
    customOptions: observationFilterOptions,
    filterDependentActions: paginationOptions.onPaginationChange,
    filterDependentActionsArgs: {
      pageIndex: 0,
    },
  });

  const { data, isFetching, isLoading } = usePaginatedObservationsLubricationPointByPeriod(
    period,
    paginationOptions,
    {
      filters: filterOptions.filterModel,
      searchValue: filterOptions.searchValue,
    }
  );

  const { items: observations, pagination } = data || {};

  const observationsCarrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: ObservationInterface) => (
        <CUObservationForm
          item={item}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  const handlePeriodChange = (period?: PeriodInterface) => {
    setPeriod(period);
    paginationOptions.onPaginationChange({
      pageIndex: 0,
    });
  };

  useEffect(() => {
    if (!isLoading && observations?.length) {
      setObservationItems(observations);
    }
  }, [observations]);

  const VirtualGrid = useMemo(
    () => lazy(() => import("../../../../shared/react-table/VirtualGrid/VirtualGrid")),
    []
  );

  return (
    <Suspense fallback={"..."}>
      <VirtualGrid
        title={title}
        entityName={title}
        period={period}
        periodChange={handlePeriodChange}
        gridModel={observationGridModel}
        items={observations}
        paginationOptions={paginationOptions}
        totalItems={pagination?.count}
        customDynamicFilters={filterOptions}
        carrousellComponents={observationsCarrousellComponents}
        itemActions={(item: any) => <DeleteObservationForm observation={item} />}
        documentExportTitle={`reportes-${title}`}
        cellFormatter={{
          taskType: value => lang.surveys.taskTypes[value as TaskTypeT]?.toUpperCase(),
          type: value =>
            lang.observations.observationTypes[value as ObservationType]?.toUpperCase(),
        }}
      />
    </Suspense>
  );
};

export default KnowledgeOfTheObservations;
