import { useState } from "react";
import { FlexContainer } from "../../../containers";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import CustomButton from "../../../buttons/CustomButton";
import { FilterButtonsProps } from "../types/FieldFilterTypes";
import { useAutocompleteStyles } from "../../../autocomplete/useAutocompleteStyle";

const FilterButtons = ({
  onSelectedFieldAdd,
  onSelectedFieldsClear,
  selectedFields,
  notSelectedOptions,
}: FilterButtonsProps) => {
  const [selectingField, setSelectingField] = useState(false);
  const classes = useAutocompleteStyles({});
  const { lang } = useLangLabels();

  const toggleSelectingField = () => {
    setSelectingField(!selectingField);
  };

  const handleAutocompleteChange = (value: any) => {
    const newField = {
      ...value,
    };

    onSelectedFieldAdd(newField);
    toggleSelectingField();
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <FlexContainer>
        {!selectingField ? (
          <CustomButton
            popoverTitle={lang.filters.addFilter}
            icon="add"
            action={toggleSelectingField}
          />
        ) : (
          <Autocomplete
            openOnFocus
            size="small"
            options={notSelectedOptions ?? []}
            onChange={(e, value) => handleAutocompleteChange(value)}
            getOptionLabel={(option: any) => option.label}
            style={{ width: "50%" }}
            classes={{
              option: classes.option,
            }}
            renderInput={params => (
              <TextField autoFocus {...params} label={lang.filters.addFilter} variant="outlined" />
            )}
          />
        )}

        {Object.keys(selectedFields)?.length > 0 && (
          <CustomButton
            popoverTitle={lang.filters.deleteFilters}
            action={onSelectedFieldsClear}
            icon="delete"
          />
        )}
      </FlexContainer>
    </div>
  );
};

export default FilterButtons;
