import { useEffect } from "react";

export const useTGDSignature = () => {
  const firstTextStyle =
    "font-size: 16px;" +
    "background: linear-gradient(to right, #1a1a1a, #1a1a1a);" +
    "color: #75bfff;" +
    "border-left: 8px solid #75bfff;" +
    "text-align: center;" +
    "padding: 8px 16px;" +
    "width: 100%;" +
    "border-radius: 0px 16px 16px 0px;" +
    "font-family:monospace";

  const firstText = `%cTGD DEV'S! 💻`;

  const secondTextStyle =
    "font-family:monospace" +
    "background: linear-gradient(to right, #1a1a1a, #1a1a1a);" +
    "color: #75bfff;" +
    "border-left: 8px solid #75bfff;" +
    "padding: 0px 15px;";

  const secondText = `%c 
▒▒▄▀▀▀▀▀▄▒▒
▒▐░▄░░░▄░▌▒
▒▐░▀▀░▀▀░▌▒
▒▒▀▄░═░▄▀▒▒
▒▒▐░▀▄▀░▌▒▒   
  `;

  useEffect(() => {
    console.log(firstText, firstTextStyle);
    console.log(secondText, secondTextStyle);
  }, []);
};
