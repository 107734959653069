import { ObservationInterface } from "../../domains/observations/models/ObservationTypes";
import { ObservationsBySurveyId } from "../../domains/observations/services/observationServices";
import { SurveyInterface } from "../../domains/surveys/model/SurveyInterface";
import { persistedSession } from "../../domains/session/persistance/sessionPersistance";
import { EquipmentInterface } from "../../domains/lubricationPoints/model/lubricationPointsInterface";
import { apiCall } from "../../shared/axios/axios-config";

export interface SurveysWithObservations extends SurveyInterface {
  observations: ObservationInterface[];
}

// services
export const getSurveysWithObservationsAndAnomalies = async (
  surveys: SurveyInterface[]
): Promise<SurveysWithObservations[]> => {
  const surveyObservations = await Promise.all(
    surveys.map(
      async s =>
        await ObservationsBySurveyId({
          data: {
            surveyId: s.id,
            tagFP: s.tagFP,
          },
          token: persistedSession().token,
        })
    )
  );

  const observations = surveyObservations.reduce((acc: any, item: any) => {
    return [...acc, ...item];
  }, []);

  return surveys.map(s => {
    return {
      ...s,
      observations: observations.filter((o: ObservationInterface) => o.surveyId === s.id),
    };
  });
};
export interface surveysByDateAndTagTGDSearchParams {
  tagFP: EquipmentInterface["tagFP"] | undefined | null;
  tagTGD: EquipmentInterface["tagTGD"] | undefined | null;
  firstDate: string;
  secondDate: string;
}

export const getSurveysByDateAndTagTGD = (
  surveySearchData: surveysByDateAndTagTGDSearchParams,
  token: string
) => {
  return apiCall({
    method: "GET",
    url: "SurveysByPeriodAndTagTGD",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: surveySearchData,
  });
};
