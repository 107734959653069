import React, { useMemo } from "react";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import { AllowedConfigType } from "./UserPermissionTypes";

const AllowUsers: React.FC<AllowedConfigType> = ({
  children,
  allowedUserTypes,
  forbiddenUserTypes,
}) => {
  const { user } = useSessionContext();
  const type = user?.type;

  const show = useMemo(() => {
    if (type) {
      if (allowedUserTypes) {
        return allowedUserTypes.includes(type) ? true : false;
      }
      if (forbiddenUserTypes) {
        return forbiddenUserTypes.includes(type) ? false : true;
      }
    }
    return false;
  }, [allowedUserTypes, forbiddenUserTypes, type]);

  return <>{show ? (children ? children : null) : null}</>;
};

export default AllowUsers;
