// db.ts
import Dexie, { Table } from "dexie";
import { ObservationInterface } from "../../../domains/observations/models/ObservationTypes";
import { CreateSurveyInterface } from "../../../domains/surveys/model/SurveyInterface";

interface backupProps {
  backupDate: string;
}

export class TGDBackupDb extends Dexie {
  // We just tell the typing system this is the case
  surveys!: Table<CreateSurveyInterface & backupProps>;
  observations!: Table<ObservationInterface & backupProps>;

  constructor() {
    super("backup");
    this.version(1).stores({
      surveys:
        "++id, taskDone, taskTime, user, cloudSinchronized, impossibleToLubricate, startTime, endTime, quantity, inaccessible, outOfRoute, OTNumber, taskType, surveyDate, loadingDate, manualRegistration, tagTGD, tagFP, equipment, sector, review, lubricant, measureUnit, modifiedDate, modified, route, routeDate, lubricantType, sampleExtraction, lubricates, needsLubrication, reason, consumables, cleaning, cleaningTime",
      observations:
        "++id, tagFP, tagTGD, anomaly, observation, criticality, area, surveyId, equipment, modified, modifiedDate, observationDate, OTnumber, state, taskId, taskRequestId, taskType, type",
    });
  }

  private async clearDatabase() {
    await this.surveys.clear();
    await this.observations.clear();
  }

  async cleanUpOldData(percentage: number) {
    const surveys = await this.surveys.orderBy("backupDate").toArray();
    const observations = await this.observations.toArray();

    const recordsToDelete = Math.floor(surveys.length * percentage);

    const surveysToDelete = surveys.slice(0, recordsToDelete).map(survey => survey.id);

    await Promise.all(surveysToDelete.map(id => this.surveys.delete(id!)));

    const observationsToDelete = observations.filter(observation =>
      surveysToDelete.includes(observation.surveyId)
    );

    await Promise.all(observationsToDelete.map(obs => this.observations.delete(obs.id!)));
  }
}

export const backupDb = new TGDBackupDb();
