import { AxiosRequestConfig } from "axios";
import { apiCall } from "../../../shared/axios/axios-config";
import { useSessionContext } from "../../session/store/sessionContext";

export const useGenericAPICall = () => {
  const { token } = useSessionContext();

  return ({ url, headers, method, data }: AxiosRequestConfig) => {
    return apiCall({
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      url,
      data,
    });
  };
};
