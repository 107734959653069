import { useQuery, useMutation } from "react-query";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../../domains/session/store/sessionStore";
import { apiCall, mutateApiCall } from "../../../../../shared/axios/axios-config";
import {
  PrivateQueryTagFPInterface,
  PrivateQueryData,
} from "../../../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../../../shared/queries/StatusProcessor";
import { queryClient } from "../../../../../shared/react-query/react-query-conf";
import { ModelInterface } from "../types/modelTypes";

const BASE_URL = "/Model";
const AUTH_HEADER = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const GetAllModels = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: BASE_URL,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const CreateModel = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const UpdateModel = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const DeleteModel = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

// -------------------------------------------------------------------------------------------------

export const useGetAllModels = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllModels", tagFP],
    queryFn: () => GetAllModels({ token, tagFP }),
    enabled: !!token,
  });
};

export const useCreateModel = () => {
  const token = useToken();

  const createModelQuery = useMutation(CreateModel, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllModels");
    },
  });

  const createModel = (data: ModelInterface) => {
    return createModelQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(createModelQuery);

  return {
    createModel,
    ...createModelQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useUpdateModel = () => {
  const token = useToken();

  const updateModelQuery = useMutation(UpdateModel, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllModels");
    },
  });

  const updateModel = (data: ModelInterface) => {
    return updateModelQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(updateModelQuery);

  return {
    updateModel,
    ...updateModelQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useDeleteModel = () => {
  const token = useToken();

  const deleteModelQuery = useMutation(DeleteModel, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllModels");
    },
  });

  const deleteModel = (data: ModelInterface) => {
    return deleteModelQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deleteModelQuery);

  return {
    deleteModel,
    ...deleteModelQuery,
    ...status,
    resetStatus: status.reset,
  };
};
