import React, { useState, useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";

import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import CustomButton from "../../../components/buttons/CustomButton";
import { usePeriod } from "../../../shared/date/usePeriod";
import { backupDb } from "../../../shared/dexie-js/backup/backup-db";
import { databaseByNameAndPeriod } from "../../../shared/dexie-js/backup/backupRepository";
import FlexContainer from "../../../components/containers/FlexContainer";
import TgdTextField from "../../../components/fields/TextField/TgdTextField";
import { downloadJsonFile } from "../../../shared/globals/utils/downloadJsonFile";

type dataType = "database" | "firstDate" | "secondDate";

interface periodIDBDataDonload {
  database: string;
  firstDate: string;
  secondDate: string;
}

const BackUpsSelection: React.FC<{ onChange: (selection: periodIDBDataDonload) => void }> = ({
  onChange,
}) => {
  const { period, setPeriod } = usePeriod();

  const databases = useLiveQuery(() => backupDb.tables);

  const [selectedDb, selectDb] = useState<string>();

  const [error, setError] = useState<any>({});

  const handleChange = (type: dataType, value: string) => {
    switch (type) {
      case "database":
        selectDb(value);
        break;

      case "firstDate":
        period &&
          setPeriod({
            ...period,
            firstDate: value,
          });
        break;

      case "secondDate":
        period &&
          setPeriod({
            ...period,
            secondDate: value,
          });
        break;
    }
  };

  const download = () => {
    const err = (() => ({
      database: !!selectedDb ? undefined : "requerido",
      firstDate: !!period?.firstDate ? undefined : "requerido",
      secondDate: !!period?.secondDate ? undefined : "requerido",
    }))();
    setError(err);

    const isError = Object.entries(err)
      .map(item => (!!item[1] ? true : false))
      .includes(true);

    if (isError) return;
    if (period?.secondDate && period.firstDate && selectedDb) {
      onChange({
        database: selectedDb,
        firstDate: period?.firstDate,
        secondDate: period?.secondDate,
      });
    }
  };

  return (
    <FlexContainer padding="0px 24px">
      <SimpleAutocompleteSelect
        label="Base de datos"
        error={error.database}
        items={databases || [{ name: "sin datos" }]}
        showTitle={"name"}
        defaultValue={selectedDb}
        onChange={value => handleChange("database", value)}
      />

      <TgdTextField
        type="date"
        label="Desde"
        error={error.firstDate}
        value={period?.firstDate}
        onChange={date => handleChange("firstDate", date)}
      />

      <TgdTextField
        type="date"
        label="hasta"
        error={error.secondDate}
        value={period?.secondDate}
        onChange={date => handleChange("secondDate", date)}
      />

      <CustomButton icon="download" action={download} />
    </FlexContainer>
  );
};

export const BrowserBackups: React.FC<{}> = ({}) => {
  const [state, setState] = useState<periodIDBDataDonload>();

  useEffect(() => {
    if (state) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("¿Desea descargar este archivo .json?")) {
        databaseByNameAndPeriod(state).then(data => {
          downloadJsonFile(data, `backup-${state.database}`);
        });
      }
    }
  }, [state]);

  return <BackUpsSelection onChange={setState} />;
};
