import { AxiosRequestConfig } from "axios";

export const executeRecursivePromise = async (
  items: AxiosRequestConfig[],
  query: (item: AxiosRequestConfig) => Promise<any>,
  index?: number
): Promise<any> => {
  const initialIndex = index || 0;
  const nexIndex = initialIndex + 1;

  if (initialIndex < items.length) {
    return await query(items[initialIndex])
      .then(async () => {
        return await executeRecursivePromise(items, query, nexIndex);
      })
      .catch(async () => {
        return await executeRecursivePromise(items, query, nexIndex);
      });
  }

  return;
};
