import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../assets/theme";
import { usePbiAccessToken } from "../../domains/powerBi/services/service.pbi";
import { useTodayDelayedAndPendingSetter } from "../../domains/session/services/sessionAdapters";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { LoginPageContainer } from "../login/components/LoginFormContainer";
import { USER_ROUTES } from "../../shared/routes/routes";

const LoadingNecesaryData: React.FC<{}> = ({}) => {
  const { lang } = useLangLabels();
  const navigate = useNavigate();
  const { data: addToken } = usePbiAccessToken();
  const { person, currentPbigroupName, token, user, session } = useSessionContext();
  const { isSuccess } = useTodayDelayedAndPendingSetter();

  useEffect(() => {
    if (person) {
      if (session?.plants?.length > 1) {
        navigate(`${USER_ROUTES.plantSelection}`);
        return;
      }

      if (["Cliente"].includes(person.role)) {
        !!addToken &&
          !!user &&
          currentPbigroupName &&
          setTimeout(() => {
            navigate(person.role);
          }, 1000);
      }
      if (["Operario", "Supervisor"].includes(person.role)) {
        !!token &&
          !!person &&
          isSuccess &&
          !!user &&
          setTimeout(() => {
            navigate(person.role);
          }, 1000);
      }
      if (["Super Admin"].includes(person.role)) {
        !!token &&
          !!person &&
          !!user &&
          setTimeout(() => {
            navigate(person.role.replace(/ /g, "-"));
          }, 1000);
      }
    }
  }, [token, isSuccess, person]);

  return (
    <LoginPageContainer color={theme.palette.secondary.main as string}>
      <CircularProgress size={"24px"} style={{ color: "white" }} />
      <br />
      {!!token && (
        <Typography color={"inherit"} style={{ color: "white" }}>
          {lang.client.connectingServices}
        </Typography>
      )}
    </LoginPageContainer>
  );
};

export default LoadingNecesaryData;
