import React, { useEffect, useState } from "react";
import { TextField, IconButton, InputAdornment, Typography } from "@material-ui/core";
import TgdPopover from "../../../components/popovers/TgdPopover";
import EventIcon from "@material-ui/icons/Event";
import CustomButton from "../../../components/buttons/CustomButton";
import { useForm } from "react-hook-form";
import { useNearlyRouteDate, useSetNewScheduleDate } from "../services/service.routes";
import FastForwardOutlinedIcon from "@material-ui/icons/FastForwardOutlined";
import { RouteInterface } from "../services/RoutesInterface";
import theme from "../../../assets/theme";
import { usePopoverEvent } from "../../../components/popovers/hooks/usePopoverEvent";
import { dateOperation } from "../../../shared/globals/utils/utils";
import FlagOutlined from "@material-ui/icons/FlagOutlined";
import { FlexContainer } from "../../../components/containers";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

type RouteMode = "anticipate" | "reSchedule";

const useRouteRescheduleRouteProps = (item: RouteInterface) => {
  var color: string | undefined = undefined;
  var mode: RouteMode | undefined = undefined;
  var popoverTitle: string | undefined = undefined;

  const { lang } = useLangLabels();

  switch (item?.state) {
    case "ATRASADA":
      mode = "reSchedule";
      color = theme.palette.grey[500];
      popoverTitle = lang.routes.delayedRoute;
      break;

    case "PROGRAMADA":
      mode = "reSchedule";
      color = theme.palette.success.dark;
      popoverTitle = lang.routes.scheduledRoute;
      break;

    case "INCUMPLIDA":
      mode = "reSchedule";
      color = theme.palette.primary.main;
      popoverTitle = lang.routes.routeThatTheLubricatorDidNotPerform;
      break;

    case "PROGRAMADA INCUMPLIDA":
      mode = "reSchedule";
      color = theme.palette.info.main;
      popoverTitle = lang.routes.scheduledRouteThatTheLubricatorDidNotPerform;
      break;

    default:
      mode = "anticipate";
      color = theme.palette.secondary.main;
      popoverTitle = lang.routes.anticipateRoute;
      break;
  }

  return {
    color,
    mode,
    popoverTitle,
  };
};

interface Props {
  item: RouteInterface | any;
  disabled?: boolean;
  customPopoverTitle?: string;
}

const ReScheduleRoute: React.FC<Props> = ({ item, disabled, customPopoverTitle }) => {
  const { mode, color, popoverTitle } = useRouteRescheduleRouteProps(item);
  const { id, currentTarget, handlePopoverOpen, handlePopoverClose } = usePopoverEvent();
  const { register, errors, handleSubmit, watch, setValue, setError, clearErrors } = useForm();
  const { scheduleRoute, status } = useSetNewScheduleDate();
  const { lang } = useLangLabels();

  const { getNearlyDate } = useNearlyRouteDate();

  const [nextRouteDate, setNextrouteDate] = useState<string>("");
  const route = item.routeName;
  const routePeriodicity = item.periodicity || "";

  const today = new Date().toISOString().slice(0, -14);

  useEffect(() => {
    if (item) {
      register({ name: "route", value: item.routeName });
      register({ name: "tagFP", value: item.tagFP });
      register({ name: "scheduledDate", value: item.scheduledDate });
    }
  }, [item]);

  const forbiddenRescheduleRange = Math.round(parseInt(routePeriodicity) * 0.3);
  const pickedDate = watch().newScheduledDate;
  const maxDateToSchedule = dateOperation("minus", "days", nextRouteDate, forbiddenRescheduleRange)
    ?.toISOString()
    .slice(0, 10);
  const [message, setMessage] = useState<string | undefined>(undefined);

  //Effect on date selection
  useEffect(() => {
    switch (mode) {
      case "anticipate":
        if (currentTarget) {
          getNearlyDate(route.split("-")[2], {
            onSuccess: (nearlyDate: any) => {
              setNextrouteDate(nearlyDate);
              setValue("scheduledDate", nearlyDate);
            },
          });
        }
        break;

      case "reSchedule":
        if (currentTarget) {
          getNearlyDate(route.split("-")[2], {
            onSuccess: (date: any) => {
              setNextrouteDate(date);
            },
          });
        }
        break;
    }
  }, [currentTarget]);

  useEffect(() => {
    if (maxDateToSchedule && pickedDate) {
      const todayValidation = pickedDate < today;
      if (today > pickedDate)
        setError("newScheduledDate", { message: lang.routes.canNotBeBeforeToday });
      if (!(today >= pickedDate)) clearErrors("newScheduledDate");

      if (!(pickedDate < maxDateToSchedule) && !todayValidation) {
        //denegate reprogram on nextrouteDate or >
        if (pickedDate >= nextRouteDate) {
          setError("newScheduledDate", { message: lang.routes.invalid });
        }

        // anticipate an route instead reprogram - set message to show
        if (pickedDate < nextRouteDate) {
          clearErrors("newScheduledDate");
          setValue("scheduledDate", nextRouteDate);
          setMessage(lang.routes.veryCloseToRoute);
        }
      }

      if (pickedDate < maxDateToSchedule) setMessage(undefined);
    }
  }, [today, nextRouteDate, pickedDate, maxDateToSchedule]);

  const submit = (data: any) => {
    scheduleRoute(data);
  };

  const selectDate = (
    <TextField
      type="date"
      color={errors?.newScheduledDate?.message ? "primary" : "secondary"}
      label={errors?.newScheduledDate?.message || lang.routes.date}
      name="newScheduledDate"
      inputRef={register({
        required: { value: true, message: lang.routes.requireDate },
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EventIcon color="secondary" />
          </InputAdornment>
        ),
      }}
    />
  );

  if (item.periodicity === "1")
    return (
      <TgdPopover mode="hover" title={lang.routes.dailyRoute}>
        <FlexContainer padding={"3px"}>
          <FlagOutlined color="action" aria-describedby={id} />
        </FlexContainer>
      </TgdPopover>
    );

  return (
    <>
      <TgdPopover
        mode="hover"
        title={customPopoverTitle || popoverTitle || lang.routes.scheduledRoute}
      >
        <IconButton
          size="small"
          onClick={e => handlePopoverOpen(e)}
          color="primary"
          disabled={disabled}
        >
          {mode && mode === "anticipate" ? (
            <FastForwardOutlinedIcon
              {...(color ? { style: { color: color } } : null)}
              aria-describedby={id}
            />
          ) : (
            <EventIcon {...(color ? { style: { color: color } } : null)} aria-describedby={id} />
          )}
        </IconButton>
      </TgdPopover>

      <TgdPopover
        handleClose={handlePopoverClose}
        currentTargetEvent={currentTarget}
        title={`${
          mode && mode === "anticipate" ? lang.routes.anticipateRoute : lang.routes.rescheduleRoute
        }: ${route}`}
        typography={{
          variant: "h6",
        }}
      >
        <br />
        <>
          <Typography variant="body1" color="secondary">
            {lang.routes.routeWillEnd}: {nextRouteDate}
          </Typography>
          {message && (
            <Typography variant="body1" color="primary">
              {message}
            </Typography>
          )}
          <br />
          {selectDate}
        </>
        <br />
        <br />

        <CustomButton
          disabled={errors?.newScheduledDate}
          action={handleSubmit(submit)}
          icon="calendar"
          status={status}
        >
          {status !== "success" &&
            status !== "loading" &&
            status !== "error" &&
            lang.routes.schedule}
        </CustomButton>
      </TgdPopover>
    </>
  );
};

export default React.memo(ReScheduleRoute);
