import SimpleAutocompleteSelect from "../../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { useLubricationPointReviewsByTagTGDAndTagFP } from "../../../../lubricationPoints/services/lubricationPointsAdapters";

interface SelectSurveyReviewsProps {
  tagFP: string;
  tagTGD?: string;
  onChange: any;
  defaultValue?: string | undefined;
  dimentionType?: string;
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const SelectSurveyReviews = ({
  tagTGD,
  onChange,
  defaultValue,
  error,
  disabled,
  clearable,
}: SelectSurveyReviewsProps) => {
  const { lang } = useLangLabels();
  const { data: reviewsData = {} } = useLubricationPointReviewsByTagTGDAndTagFP(tagTGD || "");
  const reviewOptions = reviewsData?.reviews?.map((option: number) => ({
    label: option.toString(),
    value: option,
  }));

  const label = lang.surveys.review;

  return (
    <>
      <SimpleAutocompleteSelect
        {...{
          label,
          error,
          onChange: onChange,
          items: reviewOptions || [],
          showTitle: "label",
          defaultValue,
          disabled: disabled ?? false,
          clearable: clearable ?? false,
        }}
      />
    </>
  );
};

export default SelectSurveyReviews;
