import { useQuery, useMutation } from "react-query";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../../domains/session/store/sessionStore";
import { apiCall, mutateApiCall } from "../../../../../shared/axios/axios-config";
import {
  PrivateQueryTagFPInterface,
  PrivateQueryData,
} from "../../../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../../../shared/queries/StatusProcessor";
import { queryClient } from "../../../../../shared/react-query/react-query-conf";
import { SaveInstrumentInterface, InstrumentInterface } from "../types/instrumentTypes";

const BASE_URL = "/Instrument";
const AUTH_HEADER = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

function rangeValuesToText(instrument: SaveInstrumentInterface): string {
  const { rangeFrom, rangeTo, measureUnit } = instrument;
  return `${rangeFrom} - ${rangeTo} ${measureUnit}`;
}

export const saveInstrumentAdapter = (
  instrument: SaveInstrumentInterface
): InstrumentInterface & { rangeFrom: undefined; rangeTo: undefined; measureUnit: undefined } => {
  return {
    ...instrument,
    range: rangeValuesToText(instrument),
    rangeFrom: undefined,
    rangeTo: undefined,
    measureUnit: undefined,
  };
};

export const GetAllInstruments = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: BASE_URL,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const CreateInstrument = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const UpdateInstrument = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const DeleteInstrument = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

// -------------------------------------------------------------------------------------------------

export const useGetAllInstruments = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllInstruments", tagFP],
    queryFn: () => GetAllInstruments({ token, tagFP }),
    enabled: !!token,
  });
};

export const useCreateInstrument = () => {
  const token = useToken();

  const createInstrumentQuery = useMutation(CreateInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllInstruments");
    },
  });

  const createInstrument = (data: SaveInstrumentInterface) => {
    return createInstrumentQuery.mutate({
      data: saveInstrumentAdapter(data),
      token: token!,
    });
  };

  const status = useStatusProcessor(createInstrumentQuery);

  return {
    createInstrument,
    ...createInstrumentQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useUpdateInstrument = () => {
  const token = useToken();

  const updateInstrumentQuery = useMutation(UpdateInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllInstruments");
    },
  });

  const updateInstrument = (data: SaveInstrumentInterface) => {
    return updateInstrumentQuery.mutate({
      data: {
        ...data,
        range: rangeValuesToText(data),
      },
      token: token!,
    });
  };

  const status = useStatusProcessor(updateInstrumentQuery);

  return {
    updateInstrument,
    ...updateInstrumentQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useDeleteInstrument = () => {
  const token = useToken();

  const deleteInstrumentQuery = useMutation(DeleteInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllInstruments");
    },
  });

  const deleteInstrument = (data: InstrumentInterface) => {
    return deleteInstrumentQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deleteInstrumentQuery);

  return {
    deleteInstrument,
    ...deleteInstrumentQuery,
    ...status,
    resetStatus: status.reset,
  };
};
