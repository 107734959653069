import { useState } from "react";
import { LocalStateSupply } from "../../../../supply/model/SupplyInterface";
import { getSuppliesInitialState } from "../utils/getSuppliesInitialState";
import { suppliesToString } from "../../../../supply/utils/supplyToString";
import { FormattedUiModel } from "../models/FormattedUiModel";
import { InfoSurveyInterface, SurveyInterface } from "../../../model/SurveyInterface";

interface NeededSuppliesStateResult {
  neededSuppliesState: LocalStateSupply[];
  deleteSupply: (deleteIndex: number) => void;
  setNeededSupplies: (value: LocalStateSupply) => void;
}

const useNeededSuppliesState = (
  formattedUiModel: FormattedUiModel<SurveyInterface & InfoSurveyInterface>
): NeededSuppliesStateResult => {
  const correctedSupplies =
    typeof formattedUiModel.consumables.value === "string"
      ? formattedUiModel.consumables.value.replace(/ "/g, `,"`)
      : "";

  const [neededSuppliesState, setNeededSuppliesState] = useState<LocalStateSupply[]>(
    getSuppliesInitialState(correctedSupplies)
  );

  const deleteSupply = (deleteIndex: number) => {
    const updatedNeededSupplies = neededSuppliesState.filter(
      (item, index) => index !== deleteIndex
    );
    setNeededSuppliesState(updatedNeededSupplies);

    const updatedNeededSuppliesString = JSON.stringify(suppliesToString(updatedNeededSupplies));
    formattedUiModel.consumables.onChange(updatedNeededSuppliesString);
  };

  const setNeededSupplies = (value: LocalStateSupply) => {
    const updatedNeededSupplies = [...neededSuppliesState, value];
    setNeededSuppliesState(updatedNeededSupplies);

    const updatedNeededSuppliesString = JSON.stringify(suppliesToString(updatedNeededSupplies));
    formattedUiModel.consumables.onChange(updatedNeededSuppliesString);
  };

  return {
    neededSuppliesState,
    deleteSupply,
    setNeededSupplies,
  };
};

export default useNeededSuppliesState;
