import styled from "@emotion/styled";
import React from "react";
import FlexContainer, { FlexContainerProps } from "./FlexContainer";

export const PopoverStyles = styled(({ ...rest }) => <FlexContainer {...rest} />)`
  position: relative;
  :hover {
    cursor: pointer;
    .tooltip {
      visibility: unset;
    }
  }
  .tooltip {
    z-index: 3000;
    top: ${props => props.height};
    right: 0px;
    background-color: white;
    padding: 8px;
    box-sizing: border-box;
    /* margin: 0px -200px; */
    width: 280px;
    position: absolute;
    visibility: hidden;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    :before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      left: calc(100% - 24px);

      top: -8px;
    }
  }
`;

const Popover: React.FC<{ popoverContent: React.ReactNode } & FlexContainerProps> = ({
  popoverContent,
  children,
  ...rest
}) => {
  return (
    <>
      <PopoverStyles {...rest}>
        {children}
        <span className="tooltip">{popoverContent}</span>
      </PopoverStyles>
    </>
  );
};

export default React.memo(Popover);
