import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf";
import { useToken } from "../../session/store/sessionStore";
import { SubCategoryInterface } from "./subCategoryInterface";

export const CreateSubcategory = ({ data, token }: PrivateQueryData<any>) => {
  return mutateApiCall({
    method: "post",
    url: `/CreateSubcategory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const UpdateSubcategory = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    url: `/UpdateSubcategory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useCreateSubCategory = () => {
  const token = useToken();
  const query = useMutation(CreateSubcategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("CategoriesAndSubcategoriesByTagFP");
    },
  });

  const { status, message, error } = useStatusProcessor(query);

  const createSubcategory = (data: SubCategoryInterface) => {
    if (token)
      return query.mutate({
        token,
        data,
      });
  };

  return {
    createSubcategory,
    query: {
      ...query,
      status,
      error,
      message,
    },
  };
};

//--------------------------
//queries
//--------------------------

//--------------------------
//mutations
//--------------------------
export const useUpdateSubCategory = () => {
  const token = useToken();
  const query = useMutation(UpdateSubcategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("CategoriesAndSubcategoriesByTagFP");
    },
  });

  const { status, message, error } = useStatusProcessor(query);

  const updateSubcategory = (data: SubCategoryInterface) => {
    if (token)
      return query.mutate({
        token,
        data,
      });
  };

  return {
    updateSubcategory,
    query: {
      ...query,
      status,
      error,
      message,
    },
  };
};
