import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryTagFPInterface,
} from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useSessionContext } from "../../session/store/sessionContext";
import { useToken } from "../../session/store/sessionStore";
import {
  PurchaseRequestByStockInfoInterface,
  PurchaseRequestInterface,
} from "./models/PurchaseInterface";

//-------------------------------
//---------AXIOS------------
//-------------------------------

export const PurchaseRequestsByTagFP = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: tagFP!,
    },
    url: `/PurchaseRequestsByTagFP`,
  });
};

export const PurchaseRequestByStockInfo = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/PurchaseRequestByStockInfo`,
    data,
  });
};

export const CreatePurchaseRequest = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/CreatePurchaseRequest`,
    data,
  });
};

export const UpdatePurchaseRequest = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `/UpdatePurchaseRequest`,
    data,
  });
};

//-------------------------------
//---------QUERIES------------
//-------------------------------

export const usePurchaseRequestByStockInfo = (data: PurchaseRequestByStockInfoInterface) => {
  const token = useToken();
  const { tagFP } = useSessionContext();
  return useQuery({
    queryKey: ["PurchaseRequestByStockInfo", tagFP],
    queryFn: () => PurchaseRequestByStockInfo({ data, token: token! }),
    enabled: !!token && !!data,
  });
};

export const usePurchaseRequestsByTagFP = (tagFP?: string) => {
  const { token, currentPlant } = useSessionContext();
  const queryTagFP = tagFP || currentPlant?.tagFP!;

  return useQuery({
    queryKey: ["PurchaseRequestsByTagFP", tagFP],
    queryFn: () => PurchaseRequestsByTagFP({ tagFP: queryTagFP, token: token! }),
    enabled: !!token && !!queryTagFP,
  });
};

//-------------------------------
//---------MUTATIONs------------
//-------------------------------

export const useCreatePurchaseRequest = () => {
  const token = useToken();
  const query = useMutation(CreatePurchaseRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("PurchaseRequestByStockInfo");
      queryClient.invalidateQueries("PurchaseRequestsByTagFP");
    },
  });

  const status = useStatusProcessor(query);

  const createPurchaseRequest = (data: PurchaseRequestInterface) => {
    if (token)
      return query.mutate({
        token,
        data,
      });
  };

  return {
    createPurchaseRequest,
    ...query,
    ...status,
  };
};

export const useUpdatePurchaseRequest = () => {
  const token = useToken();
  const query = useMutation(UpdatePurchaseRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("PurchaseRequestByStockInfo");
      queryClient.invalidateQueries("PurchaseRequestsByTagFP");
    },
  });

  const status = useStatusProcessor(query);

  const updtePurchaseRequest = (data: any) => {
    if (token)
      return query.mutate({
        token,
        data,
      });
  };

  return {
    updtePurchaseRequest,
    ...query,
    ...status,
  };
};
