import {
  getUserAgentData,
  getDeviceType,
} from "../../../shared/user-agent/service.DetectUserDevice";

export const htmlClient = (parameters: {
  applicantsName: string;
  applicantsTelephone: string;
  applicantsCompany: string;
  applicantsEmail: string;
}) => {
  if (parameters) {
    return `<div style="padding:20px; width:100%;box-sizing: border-box;">
        <div
        style="
        box-sizing: border-box;
            max-width: 650px;
            margin: auto;
            font-family: sans-serif;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        "
        >
        <div
            style="
            z-index: 99;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0 24px;
            padding: 24px;
            text-align: center;
            background-color: #fff;
            color: #002f6c;
            text-transform: uppercase;
            font-family: sans-serif;
            font-weight: 700;
            "
        >
            
        </div>
        <div
            style="
            padding:24px;
            width: auto;
            height: 100%;
            background-image: url(http://drive.google.com/uc?export=view&id=1H2zkQkDzMlBdGWCc-VlgAu3HC2y2Wles);        "
        >
            <div
            style="
                width: auto;
                height: 100%;
                background-color: white;
                padding: 24px;
            "
            >
            <a href="https://tgdsoft.tgdcompany.com/" target="aside">
                <img
                style="width: 150px;"
                alt=""
                src="http://drive.google.com/uc?export=view&id=12jLUcsWE82GfKMen-ICI4UbD4oAVJBMQ"
                />
            </a>
            <br />
            <p>
                <h2 style="font-weight: 700; color: #002f6c;">
                    <strong>Bienvenido!</strong>
                    </h2>
                <strong>
                Hola ${parameters.applicantsName}!
                </strong>
                <br />
                <br />
                Hemos recibido con éxito su petición para generar una demostración
                de nuestros servicios. <strong>Muy pronto un representante se comunicará con
                usted.</strong>
            </p>
            <p>
                <strong>Sus datos permaneceran seguros en TGD Soft</strong>, tenemos una política de
                datos estricta por lo que solo usaremos sus datos para acercale
                nuestra muestra gratuita y <strong>mejorar su experiencia con nuestros
                servicios</strong>
            </p>
            <p><strong>
                ${parameters.applicantsName}
                <br />
                ${parameters.applicantsTelephone}
                <br />
                ${parameters.applicantsCompany}
                <br />
                ${parameters.applicantsEmail}
            </strong>
            </p>
            </div>
        
            <div></div>
            </div>
            <div
            style="
            position:relative;
            text-align: center;
            font-family: sans-serif;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            min-height: 64px;
            padding: 24px;
            background-image: url(http://drive.google.com/uc?export=view&id=1EDSC3jfcBk9ReoCY2jsYrMftf0OY1Pph);
            "
        >
        <div >
            <img
            style="width: 32px;height: 32px;background-color: #fff;border-radius: 100px;padding: 2px;"
            alt=""
            src="http://drive.google.com/uc?export=view&id=10EmO7Qn0EVdF5sr2D5wxrpqTF3j5-ROr"
            />
        </div>
            <div>
            <br>
            <br>
            </div>
            <div >
            <a
            style="color: #FFF; font-size: 12px;"
            href="https://tgdcompany.com/"
            >www.tgdcompany.com</a
            >
            <br>
            <p style="color:#f3f3f350; font-size: 12px;">©TGD Soft - ©TGD Company - Internacional </p>
            </div>
        </div>
        
        </div>
    </div>`;
  }
};
export const htmlAdmin = (parameters: {
  applicantsName: string;
  applicantsTelephone: string;
  applicantsCompany: string;
  applicantsEmail: string;
}) => {
  const info = getUserAgentData();
  const deviceType = getDeviceType();

  if (parameters) {
    return ` <div style="padding:20px; width:100%;box-sizing: border-box;">
            <div
            style="
            box-sizing: border-box;
                max-width: 600px;
                margin: auto;
                font-family: sans-serif;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            "
            >
            <div
                style="
                z-index: 99;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 0 24px;
                padding: 24px;
                text-align: center;
                background-color: #fff;
                color: #002f6c;
                text-transform: uppercase;
                font-family: sans-serif;
                font-weight: 700;
                "
            >
                
            </div>
            <div
                style="
                padding:24px;
                width: auto;
                height: 100%;
                background-image: url(http://drive.google.com/uc?export=view&id=1H2zkQkDzMlBdGWCc-VlgAu3HC2y2Wles);        "
            >
                <div
                style="
                    width: auto;
                    height: 100%;
                    background-color: white;
                    padding: 24px;
                "
                >
                <a href="https://tgdsoft.tgdcompany.com/" target="aside">
                    <img
                    style="width: 150px;"
                    alt=""
                    src="http://drive.google.com/uc?export=view&id=12jLUcsWE82GfKMen-ICI4UbD4oAVJBMQ"
                    />
                </a>
                <br />
                <p>
                    <h2 style="font-weight: 700; color: #002f6c;">
                        <strong>Nueva Solicitud de Demostración</strong>
                        </h2>
                    <strong>
                    Se ha dado de alta una nueva solicitud de demostración de un potencial cliente
                    </strong>
                    <br />
                    <br />
                    
                </p>
                <p>
                    <strong>Datos del Prospecto:</strong>
                </p>
                <p>
                    <strong>
                        Nombre: ${parameters.applicantsName}
                        <br />
                        teléfono: ${parameters.applicantsTelephone}
                        <br />
                        companía: ${parameters.applicantsCompany}
                        <br />
                        Email: ${parameters.applicantsEmail}
                        <br />
                        Latitud: ${sessionStorage.getItem("latitud") ? sessionStorage.getItem("latitud") : "No se aprobó"}
                        <br />
                        Longitud: ${sessionStorage.getItem("longitud") ? sessionStorage.getItem("longitud") : "No se aprobó"}
                        <br />
                        Sistema operativo: ${info ? info.OSName : "no se ha detectado un sistema operativo"}
                        <br />
                        Dispositivo: ${deviceType}
                        <br />
                        Navegador: ${info ? info.browserName : "no se ha detectado un navegador"}
                        <br />
                    </strong>
                </p>
                </div>
            
                <div></div>
                </div>
                <div
                style="
                position:relative;
                text-align: center;
                font-family: sans-serif;
                font-weight: 700;
                justify-content: space-between;
                align-items: center;
                min-height: 64px;
                padding: 24px;
                background-image: url(http://drive.google.com/uc?export=view&id=1EDSC3jfcBk9ReoCY2jsYrMftf0OY1Pph);
                "
            >
            <div >
                <img
                style="width: 32px;height: 32px;background-color: #fff;border-radius: 100px;padding: 2px;"
                alt=""
                src="http://drive.google.com/uc?export=view&id=10EmO7Qn0EVdF5sr2D5wxrpqTF3j5-ROr"
                />
            </div>
                <div>
                <br>
                <br>
                </div>
                <div >
                <a
                style="color: #FFF; font-size: 12px;"
                href="https://tgdcompany.com/"
                >www.tgdcompany.com</a
                >
                <br>
                <p style="color:#f3f3f350; font-size: 12px;">©TGD Soft - ©TGD Company - Internacional </p>
                </div>

                <div style="position: absolute; background-color: #DA291C; display:block;height:30%;width:8px;right: 0; top :0;"></div>
                <div style="position: absolute; background-color: #ED8B00; display:block;height:30%;width:8px;right: 0;bottom:0;"></div>
            </div>
            
            </div>
        </div>`;
  }
};
export const htmlDemoApproval = (parameters: {
  applicantsName: string;
  applicantsTelephone: string;
  applicantsCompany: string;
  applicantsEmail: string;
}) => {
  if (parameters) {
    return `<div style="padding:20px; width:100%;box-sizing: border-box;">
        <div
        style="
        box-sizing: border-box;
            max-width: 650px;
            margin: auto;
            font-family: sans-serif;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        "
        >
        <div
            style="
            z-index: 99;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0 24px;
            padding: 24px;
            text-align: center;
            background-color: #fff;
            color: #002f6c;
            text-transform: uppercase;
            font-family: sans-serif;
            font-weight: 700;
            "
        >
            
        </div>
        <div
            style="
            padding:24px;
            width: auto;
            height: 100%;
            background-image: url(http://drive.google.com/uc?export=view&id=1H2zkQkDzMlBdGWCc-VlgAu3HC2y2Wles);        "
        >
            <div
            style="
                width: auto;
                height: 100%;
                background-color: white;
                padding: 24px;
            "
            >
            <a href="https://tgdsoft.tgdcompany.com/" target="aside">
                <img
                style="width: 150px;"
                alt=""
                src="http://drive.google.com/uc?export=view&id=12jLUcsWE82GfKMen-ICI4UbD4oAVJBMQ"
                />
            </a>
            <br />
            <p>
                <h2 style="font-weight: 700; color: #002f6c;">
                    <strong>Bienvenido a TGD Soft!</strong>
                    </h2>
                <strong>
                Hola ${parameters.applicantsName}!
                </strong>
                <br />
                <br />
                Hemos configurdo una demostración gratuita de nuestro dashboard de trazabilidad y gestion de datos! </strong>
                Su mustra gratuita tiene una duracion de 30 días. No deje de explorar el sistema, estaremos atentos a sus consultas!
            </p>
            <p>
                <strong>Sus datos de ingreso son:</strong>
            </p>
            <p>
            <strong>
                usuario:${parameters.applicantsEmail}
                <br />
                password:${parameters.applicantsTelephone}_${parameters.applicantsName}
                <br/>
            </strong>
            </p>
            </div>
        
            <div></div>
            </div>
            <div
            style="
            position:relative;
            text-align: center;
            font-family: sans-serif;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            min-height: 64px;
            padding: 24px;
            background-image: url(http://drive.google.com/uc?export=view&id=1EDSC3jfcBk9ReoCY2jsYrMftf0OY1Pph);
            "
        >
        <div >
            <img
            style="width: 32px;height: 32px;background-color: #fff;border-radius: 100px;padding: 2px;"
            alt=""
            src="http://drive.google.com/uc?export=view&id=10EmO7Qn0EVdF5sr2D5wxrpqTF3j5-ROr"
            />
        </div>
            <div>
            <br>
            <br>
            </div>
            <div >
            <a
            style="color: #FFF; font-size: 12px;"
            href="https://tgdcompany.com/"
            >www.tgdcompany.com</a
            >
            <br>
            <p style="color:#f3f3f350; font-size: 12px;">©TGD Soft - ©TGD Company - Internacional </p>
            </div>
        </div>
        
        </div>
    </div>`;
  }
};
