import { InputComponentTypes } from "../../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../../shared/form/useForm";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { PLCInterface } from "../types/plcTypes";

export const usePLCForm = (plc?: PLCInterface) => {
  const { lang } = useLangLabels();

  const plcForm = useForm<PLCInterface>({
    id: {
      value: plc?.id || "",
      component: InputComponentTypes.none,
    },
    plc: {
      label: lang.instruments.plc,
      value: plc?.plc || "",
      placeholder: lang.instruments.plc,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    plcName: {
      label: lang.instruments.plcs.plcName,
      value: plc?.plcName || "",
      placeholder: lang.instruments.plcs.plcName,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
  });

  return plcForm;
};
