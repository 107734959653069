import React from "react";
import { Divider, Typography, Paper } from "@material-ui/core";
import PageContainer from "../../../components/containers/PageContainer";
import { USER_ROUTES } from "../../../shared/routes/routes";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";
import StockPage from "../stock/StockPage";
import IncomeLogPage from "./components/IncomeLogPage";
import { CustomIcon } from "../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const LubricationSectionPage = () => {
  const { lang } = useLangLabels();

  const lubricationSection: TabsInterface[] = [
    {
      label: lang.lubricationSection.incomeManagement,
      route: USER_ROUTES.lubricationSection.incomeLog,
      component: <IncomeLogPage />,
    },
    {
      label: lang.lubricationSection.stock,
      route: USER_ROUTES.lubricationSection.stock,
      component: <StockPage />,
    },
  ];

  const tabProps = useRouteTabProps(4, lubricationSection, {
    orientation: "vertical",
  });

  return (
    <>
      <Paper elevation={3}>
        <PageContainer>
          <Typography variant="h5">
            <CustomIcon icon="stock" style={{ margin: "0px 10px -5px 0" }} />
            {lang.lubricationSection.title}
          </Typography>
        </PageContainer>

        <Divider />

        <RouterTabs {...tabProps} style={{ overflow: "scroll" }} />
      </Paper>
    </>
  );
};

export default React.memo(LubricationSectionPage);
