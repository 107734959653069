import React, { useRef, useState } from "react";

interface usePrintStateResult {
  renderedCount: React.RefObject<number>;
  isRenderStarted: boolean;
  isLoading: boolean | null;
  onItemRendered: () => void;
  onItemUnrendered: () => void;
  resetRenderedCount: () => void;
  handleLoadingChange: (value: boolean) => void;
  handleRenderStartedChange: (value: boolean) => void;
}

const usePrintState = (): usePrintStateResult => {
  const [isRenderStarted, setRenderStarted] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const renderedCount = useRef(0);

  const handleLoadingChange = (value: boolean) => {
    setIsLoading(value);
  };

  const handleRenderStartedChange = (value: boolean) => {
    setRenderStarted(value);
  };

  const onItemRendered = () => {
    renderedCount.current += 1;
  };

  const onItemUnrendered = () => {
    renderedCount.current -= 1;
  };

  const resetRenderedCount = () => {
    renderedCount.current = 0;
  };

  return {
    renderedCount,
    isRenderStarted,
    isLoading,
    onItemRendered,
    onItemUnrendered,
    resetRenderedCount,
    handleLoadingChange,
    handleRenderStartedChange,
  };
};

export default usePrintState;
