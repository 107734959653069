export interface GridModelInterface {
  [key: string]: {
    label: string;
    width?: number;
    columnSize?: number;
    options?: {
      name: string;
      value: boolean | undefined;
    }[];
    renderOption?: (item: any) => JSX.Element;
  };
}

export const getGridModelValue = (
  key: any,
  value: any,
  gridModel: GridModelInterface
): string | number => {
  switch (typeof value) {
    case "boolean":
      return (
        gridModel[key]?.options?.find(o => {
          return o.value === value;
        })?.name || `${value}`
      );
    default:
      return `${value}`;
  }
};

export const getGridModelkey = (key: any, gridModel: GridModelInterface): string => {
  return gridModel[key]?.label || key;
};
