import { FiberManualRecord } from "@material-ui/icons";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import CUCriticalityForm from "../CUCriticalityForm";
import DeleteCriticalityForm from "../DeleteCriticalityForm";
import { useCriticalitiesGridModel } from "../lang/useCriticalitiesGridModel";
import { CriticalityInterface } from "../models/criticalityInterface";
import { usePlantCriticality } from "../services/service.criticality";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageCriticityPageProps {
  virtualGridWidth: string;
}

const ManageCriticityPage: React.FC<ManageCriticityPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: criticalities, isFetching } = usePlantCriticality();

  const icon = (item: any) => {
    return <FiberManualRecord style={{ color: `${item.color}`, marginRight: "16px" }} />;
  };

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (value: CriticalityInterface) => (
        <CUCriticalityForm
          item={value}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        height: 55,
        width: virtualGridWidth,
        title: lang.criticalities.title.plural,
        entityName: lang.criticalities.title.plural,
        items: criticalities,
        gridModel: useCriticalitiesGridModel(),
        renderOptionColumn: { headerName: "More", renderOption: icon },
        carrousellComponents,
        headerActions: () => <CUCriticalityForm actionType={CrudFormType.create} />,
        itemActions: item => <DeleteCriticalityForm item={item} />,
      }}
    />
  );
};

export default ManageCriticityPage;
