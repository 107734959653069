import { InputComponentTypes } from "../../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../../shared/form/useForm";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { BrandInterface } from "../types/brandTypes";

export const useBrandForm = (brand?: BrandInterface) => {
  const { lang } = useLangLabels();

  const brandForm = useForm<BrandInterface>({
    id: {
      value: brand?.id || "",
      component: InputComponentTypes.none,
    },
    brand: {
      label: lang.instruments.brand,
      value: brand?.brand || "",
      placeholder: lang.instruments.brand,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
  });

  return brandForm;
};
