export const pbiStringProcesorNames = (stringArray: string[]) => {
  let keys: any = [];

  stringArray.forEach(string => {
    keys.push(string.split("."));
  });
  return keys;
};

export const getPbiIndicators = (reportsArray: any) => {
  let data: any = [];
  let names: any = [];

  reportsArray.forEach((report: any) => {
    names.push(report.name);
  });

  reportsArray.forEach(() => {
    pbiStringProcesorNames(names).forEach((indicatorsArray: any) => {
      if (data.filter((dataItem: any) => dataItem === indicatorsArray[0]).length === 0) {
        data.push(indicatorsArray[0]);
      }
    });
  });

  let respuesta = {
    data,
    names,
  };

  return respuesta;
};
