import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useDeleteAnomaly } from "./services/service.anomalies";

import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { AnomalyInterface } from "./models/anomaliesTypes";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import CustomButton from "../../components/buttons/CustomButton";

interface Props {
  item: AnomalyInterface;
}

const DeleteAnomalyForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const {
    deleteAnomaly,
    query: { status, message },
  } = useDeleteAnomaly();
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status,
        position,
      });
    }
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position,
      });
    }
  }, [status]);

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.crudActions.delete + " " + lang.anomalies.singular}
      action={e => getClickPosition(e, () => deleteAnomaly(item))}
    />
  );
};

export default React.memo(DeleteAnomalyForm);
