import {
  Accordion,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import { calcDate } from "../../shared/date/utils";
import { CustomIcon } from "../buttons/CustomIcon";
import { CustomIconType } from "../buttons/types/CustomButtonTypes";
import PageContainer from "../containers/PageContainer";

const useStyles = makeStyles(() => ({
  MuiAccordionRoot: {
    boxShadow: "none",
    margin: "0",
    padding: 0,
  },
}));

interface Props {
  items: any[] | undefined;
  status: string;
  customButtons?: (item: any) => React.ReactNode;
  icon: CustomIconType;
  children?: (item: any) => React.ReactNode;
  toggleDisabled?: boolean;
  editIcon?: CustomIconType;
}
const TgdList: React.FC<Props> = ({
  items,
  status,
  customButtons,
  icon,
  children,
  toggleDisabled,
  editIcon,
}) => {
  const toggleDefault = toggleDisabled ? false : true;
  const classes = useStyles();

  if (status === "loading")
    return (
      <PageContainer>
        <CircularProgress></CircularProgress>
      </PageContainer>
    );
  if (status === "error")
    return (
      <PageContainer>
        <Alert severity={status}>{status}</Alert>
      </PageContainer>
    );
  return (
    <>
      {items &&
        items.map((item, index) => (
          <div key={index}>
            <Accordion className={classes.MuiAccordionRoot}>
              <AccordionSummary
                style={{ maxHeight: "48px" }}
                expandIcon={
                  /* <CustomButton icon={editIcon || 'edit'} variant='iconButton' color={'primary'} style={{margin:'0 16px'}}/> */
                  <CustomIcon icon={editIcon || "edit"} />
                }
                aria-label="Expand"
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <List id={item} dense={true} style={{ width: "100%" }} disablePadding={true}>
                  <ListItem>
                    <CustomIcon style={{ margin: "0px 16 0px 0px" }} icon={icon} />
                    {item.name && (
                      <ListItemText
                        style={{ width: "200px" }}
                        primary={item.name}
                        secondary={item.email}
                      />
                    )}

                    {item.applicantsName && (
                      <ListItemText
                        style={{ width: "200px" }}
                        primary={item.applicantsName}
                        secondary={
                          item.unsubscribeDate
                            ? calcDate(item.unsubscribeDate.slice(0, 10)) < 0
                              ? "Período de Prueba Vencido"
                              : `${calcDate(item.unsubscribeDate.slice(0, 10))} Dias de prueba restantes`
                            : item.applicantsEmail
                        }
                      />
                    )}

                    <Divider />
                    <ListItemSecondaryAction
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}
                      aria-label="Acknowledge"
                    >
                      {customButtons && customButtons(item)}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </AccordionSummary>
              {toggleDefault && (
                <>
                  {item.applicantsName && (
                    <PageContainer>
                      <div style={{ padding: "40px", border: "solid 1px #f3f3f3" }}>
                        <Typography variant="body2">Nombre: {item.applicantsName}</Typography>
                        <Typography variant="body2">Email: {item.applicantsEmail}</Typography>
                        <Typography variant="body2">Empresa: {item.applicantsCompany}</Typography>
                        <Typography variant="body2">
                          Contacto: {item.applicantsTelephone}
                        </Typography>
                        <Typography variant="body2">Latitud: {item.latitude}</Typography>
                        <Typography variant="body2">Longitud: {item.longitude}</Typography>
                        <Typography variant="body2">
                          Sistema Operativo: {item.operativeSystem}
                        </Typography>
                        <Typography variant="body2">Dispositivo: {item.device}</Typography>
                        <Typography variant="body2">Navegador: {item.browser}</Typography>
                      </div>
                    </PageContainer>
                  )}

                  {children && children(item)}
                </>
              )}
            </Accordion>
            <Divider variant="middle" />
          </div>
        ))}
    </>
  );
};

export default TgdList;
