import { useEffect, useState } from "react";
import { clickPositionInterface } from "../../../shared/globals/hooks/useClickPosition";
import { queryClient, useQuery } from "../../../shared/react-query/react-query-conf";

export interface snackbarInterface {
  modal?: boolean;
  errors?: string;
  severity?: "warning" | "error" | "success" | "info" | "";
  position?: clickPositionInterface;
}

export const useSnackbar = () => {
  const key = "snackbar";

  const [status, setStatus] = useState("idle");
  const initialState: snackbarInterface = {
    modal: false,
    errors: "",
    severity: "",
    position: {
      positionX: 0,
      positionY: 0,
    },
  };

  useEffect(() => {
    queryClient.setQueryData(key, initialState);
  }, []);

  const { data, status: querystatus } = useQuery<any>({
    queryKey: key,
    queryFn: () => queryClient.getQueryData(key),
    initialData: () => initialState,
  });

  const setData = ({ modal, errors, severity, position }: snackbarInterface) => {
    setStatus("loading");
    queryClient.setQueryData(key, {
      modal: modal,
      errors: errors,
      severity: severity,
      position: position,
    });
    queryClient.invalidateQueries(key);
    setStatus(querystatus);
  };

  const handleModal = () => {
    setData(initialState);
  };

  return {
    position: data?.position,
    modal: data?.modal,
    errors: data?.errors,
    severity: data?.severity,
    status,
    handleModal,
    setData,
  };
};
