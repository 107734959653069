import React from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { useAssignCancelPermission } from "../services/service.permission";
import { PermissionInterface } from "../model/PermissionInterface";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";

const AssignCancelPermission: React.FC<{
  equipment: LubricationPointInterface;
  permission: PermissionInterface;
}> = ({ equipment, permission }) => {
  const { assignCancelPermission, status } = useAssignCancelPermission();

  const assign = () => {
    assignCancelPermission([
      {
        //wrong permisson, this is permisson id not equipmentpermission id
        id: permission.id,
        permissionName: permission.permissionName,
        state: "A",
        equipment: equipment.tagTGD,
        tagFP: equipment.tagFP,
      },
    ]);
  };

  const cancel = () => {
    assignCancelPermission([
      {
        //wrong permisson, this is permisson id not equipmentpermission id
        id: permission.id,
        permissionName: permission.permissionName,
        state: "C",
        equipment: equipment.tagTGD,
        tagFP: equipment.tagFP,
      },
    ]);
  };

  const permitionName = permission.permissionName;
  const permissions: string[] = equipment.permissions;

  return permissions.includes(permitionName) ? (
    <>
      <CustomButton variant="iconButton" disabled icon="check" styleProps={{ color: "green" }} />
      <CustomButton
        variant="iconButton"
        status={status}
        action={cancel}
        icon="delete"
        color="primary"
      />
    </>
  ) : (
    <CustomButton variant="iconButton" status={status} action={assign} icon="add" />
  );
};

export default React.memo(AssignCancelPermission);
