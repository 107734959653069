import { ObservationInterface } from "../../../domains/observations/models/ObservationTypes";
import { CreateSurveyInterface } from "../../../domains/surveys/model/SurveyInterface";
import { backupDb } from "./backup-db";
import { backupAddMultipleObservations } from "./observations/observationsRepository";
import { bakupAddSurveys } from "./surveys/surveyRepository";

export interface periodIDBDataDonload {
  database: string;
  firstDate: string;
  secondDate: string;
}

export const bakupAddSurveyWithObservations = async (
  survey: CreateSurveyInterface,
  observations: (id: number) => ObservationInterface[]
) => {
  try {
    await bakupAddSurveys(survey).then(async id => {
      await backupAddMultipleObservations(observations(id as number));
    });
  } catch (error) {
    console.log(error);
  }
};

export const databaseByNameAndPeriod = async (state: periodIDBDataDonload): Promise<any[]> => {
  return await backupDb
    .table(state.database)
    .toArray()
    .then(data => {
      const filteredData = data.filter(item => {
        const firstDate = new Date(state.firstDate).getTime();
        const secondDate = new Date(state.secondDate).getTime();
        const backupDate = new Date(item.backupDate).getTime();
        return backupDate >= firstDate && backupDate <= secondDate;
      });
      return filteredData;
    });
};
