import React, { useEffect } from "react";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import LubricatorPanel from "../../../domains/surveys/components/LubricatorPanel";

const LubricatorHomePage: React.FC<{}> = ({}) => {
  const { person, selectOperator } = useSessionContext();

  useEffect(() => {
    selectOperator(person);
  }, [person]);

  return (
    <>
      <LubricatorPanel />,
    </>
  );
};

export default LubricatorHomePage;
