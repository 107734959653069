import FlexContainer from "../../containers/FlexContainer";

interface PageBodyProps {
  children: React.ReactNode;
}

const PageBody = ({ children }: PageBodyProps) => {
  return (
    <FlexContainer width={"85%"} height={"100%"} flexDirection="column" margin={"auto"}>
      {children}
    </FlexContainer>
  );
};

export default PageBody;
