import React from "react";
import FlexContainer from "../../../../components/containers/FlexContainer";
import HandleUndefined from "../../../../components/containers/handleUndefined/HandleUndefined";
import { useModal } from "../../../../components/containers/modal/Modal/hooks/useModal";
import Modal from "../../../../components/containers/modal/Modal/Modal";
import CarrousellToolbar from "../../../../components/containers/toolbar/CarrousellToolbar";
import { useCarrousell } from "../../../../components/containers/toolbar/hooks/useCarrousell";
import {
  ButtonColorType,
  CustomIconType,
} from "../../../../components/buttons/types/CustomButtonTypes";

export interface CarrousellActionsProps<T = any> {
  items?: T[];
  carrousellComponents: {
    icon: CustomIconType;
    iconColor?: ButtonColorType;
    component: (item: T) => React.ReactNode;
    popoverTitle?: string;
  }[];
}

const CarrousellActions: React.FC<CarrousellActionsProps> = ({ items, carrousellComponents }) => {
  const actionValidation = !(items && items.length > 0);
  const modalProps = useModal();
  const carrouselProps = useCarrousell(items?.length || 0);
  const item = items && items[carrouselProps.current];

  return (
    <FlexContainer flexWrap="wrap">
      {carrousellComponents.map(({ icon, iconColor, component, popoverTitle }, index) => (
        <HandleUndefined key={index} showError={actionValidation} callback={modalProps.openModal}>
          {callback => (
            <Modal
              {...{
                ...modalProps,
                openModal: callback,
                buttonIcon: icon,
                buttonColor: iconColor || undefined,
                buttonVariant: "iconButton",
                buttonPopoverTitle: popoverTitle,
              }}
            >
              <CarrousellToolbar {...carrouselProps} />
              <div key={carrouselProps.current}>{component(item)}</div>
            </Modal>
          )}
        </HandleUndefined>
      ))}
    </FlexContainer>
  );
};

export default React.memo(CarrousellActions);
