export type SuperAdminRoleType = "SuperAdmin";
export type AdminRoleType = "Administrador";
export type accountMannagerRoleType = "Administrador de Cuentas";
export type OperatorRoleType = "Operario";
export type ClientRoleType = "Cliente";
export type SupervisorRoleType = "Supervisor";

export type PersonRoleType =
  | SuperAdminRoleType
  | AdminRoleType
  | accountMannagerRoleType
  | OperatorRoleType
  | ClientRoleType
  | SupervisorRoleType;

export interface PersonInterface {
  id?: number;
  firstName?: string;
  lastName?: string;
  name: string;
  telephone?: string;
  address?: string;
  email: string;
  file: number;
  referrer: string | number;
  password: string;
  lubricatorNumber?: number;
  role: PersonRoleType;
}

export enum PersonRoleTypes {
  superAdmin = "SuperAdmin",
  admin = "Administrador",
  accountMannager = "Administrador de Cuentas",
  operator = "Operario",
  client = "Cliente",
  supervisor = "Supervisor",
}
