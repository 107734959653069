import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    DuoContainer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "24px",
    },
  })
);

const DuoContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.DuoContainer}>{children}</div>;
};

export default DuoContainer;
