import React, { useEffect, useRef, useState } from "react";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import FlexContainer from "../../../../../components/containers/FlexContainer";
import { Button, withStyles } from "@material-ui/core";
import { useLossReportGridModel } from "../hooks/useLossReportGridModel";
import { usePeriod } from "../../../../../shared/date/usePeriod";
import { useReactToPrint } from "react-to-print";
import usePrintState from "../../../../../components/print-pdf/hooks/usePrintState";
import { useModal } from "../../../../../components/containers/modal/Modal/hooks/useModal";
import { useExportXlsx } from "../../../../../shared/xlsx/useExportXlsx";
import { LossReportInterface } from "../types/LossReportTypes";
import PrintReportModal from "../../../../../components/print-pdf/components/PrintReportModal";
import LossReportGrid from "./LossReportGrid";
import Report from "./Report";
import useFieldFilter from "../../../../../components/filters/FieldFilter/hooks/useFieldFilter";
import { ObservationReport } from "../../../../observations/reports/types/ObservationTypes";

const styles = () => ({
  excel_button: {
    padding: "12px 36px",
    backgroundColor: "#1E8449",
    color: "white",
    "&:hover": {
      backgroundColor: "#176437",
    },
  },
  pdf_button: {
    padding: "12px 36px",
    backgroundColor: "#C0392B",
    color: "white",
    "&:hover": {
      backgroundColor: "#AD2D1F",
    },
  },
});

const LossReport = ({ classes }: { classes: any }) => {
  const { lang } = useLangLabels();
  const entityName = `${lang.reportsGeneration.reportsGeneration
    .toLocaleLowerCase()
    .replace(/\s/g, "-")}-${lang.surveys.loss}`.toLocaleLowerCase();
  const lossReportGridModel = useLossReportGridModel();

  const [lossItems, setLossItems] = useState<LossReportInterface[]>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedItems, setSelectedItems] = useState<ObservationReport[]>([]);

  const { period, setPeriod } = usePeriod(7, entityName);

  const fieldFilter = useFieldFilter<LossReportInterface>({
    items: lossItems || [],
    gridModel: lossReportGridModel,
    config: {
      isServerSideFiltering: true,
      fields: {
        addedReason: { component: "checkboxes" },
        date: { hideField: true },
      },
    },
    entityName,
    defaultValue: {
      addedReason: lang.observations.addedReasons.leak.toUpperCase(),
    },
  });

  const contentRef = useRef(null);

  const { isOpen, openModal, closeModal } = useModal();

  const {
    renderedCount,
    isLoading,
    onItemRendered,
    onItemUnrendered,
    resetRenderedCount,
    handleLoadingChange,
    handleRenderStartedChange,
  } = usePrintState();

  const handleItemRendered = () => {
    onItemRendered();

    const lengthToCompare =
      selectedItems.length > 0 ? selectedItems.length : fieldFilter.filteredItems?.length;

    if (renderedCount.current === lengthToCompare) {
      handleLoadingChange(false);
    }
  };

  const handlePrintClick = () => {
    resetRenderedCount();
    handleLoadingChange(true);
    handleRenderStartedChange(true);
    openModal();
  };

  useEffect(() => {
    if (isLoading === false) {
      handleRenderStartedChange(false);
    }
  }, [isLoading]);

  useEffect(() => {
    setIsDisabled(!(fieldFilter.filteredItems?.length && fieldFilter.filteredItems?.length > 0));
  }, [fieldFilter.filteredItems]);

  const documentTitle = lang.reportsGeneration.lossDocumentTitle;

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `${documentTitle}-${new Date().toISOString().split("T")[0]}`,
  });

  const { downloadFile } = useExportXlsx(
    fieldFilter.filteredItems || [],
    documentTitle,
    lossReportGridModel
  );

  const otherProps = {
    ...period,
    onItemRendered: handleItemRendered,
    onItemUnrendered,
  };

  return (
    <FlexContainer flexDirection="column">
      <PrintReportModal
        open={isOpen}
        handleClose={closeModal}
        isLoading={isLoading}
        handlePrint={handlePrint}
        disableEscapeKeyDown={true}
      />
      <LossReportGrid
        entityName={entityName}
        period={period}
        setPeriod={setPeriod}
        fieldFilter={fieldFilter}
        setLossItems={setLossItems}
        setSelectedItems={setSelectedItems}
      />
      <FlexContainer justify="center" padding={"24px"}>
        <Button
          variant="contained"
          className={classes.excel_button}
          onClick={downloadFile}
          disabled={isDisabled}
        >
          {lang.reportsGeneration.generateReport}:{" "}
          <span style={{ fontWeight: "bolder" }}>&nbsp;Excel</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.pdf_button}
          onClick={handlePrintClick}
          disabled={isDisabled}
        >
          {lang.reportsGeneration.generateReport}:{" "}
          <span style={{ fontWeight: "bolder" }}>&nbsp;PDF</span>
        </Button>
      </FlexContainer>
      <Report
        contentRef={contentRef}
        fieldFilter={fieldFilter}
        period={period}
        rendered={isOpen}
        otherProps={otherProps}
        selectedItems={selectedItems}
      />
    </FlexContainer>
  );
};

export default React.memo(withStyles(styles)(LossReport));
