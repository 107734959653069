import { LinearProgress } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { useEffect, useMemo } from "react";
import { QueryStatus } from "react-query";
import CustomButton from "../../../../components/buttons/CustomButton";
import EaseInOut from "../../../../components/containers/animation/EaseInOut";
import { useLangLabels } from "../../../lang/services/useLangLabels";
import { BackResponseMessages } from "../types/StatusTypes";

interface StatusAndMessageProps {
  status: QueryStatus;
  message: string | undefined;
  error: any;
  reset?: () => void;
  severity?: Color;
  isDataUpdated?: boolean;
}

export const TimedProgress = React.memo(() => {
  return <LinearProgress color="secondary" variant="indeterminate" />;
});

const StatusAndMessage: React.FC<StatusAndMessageProps> = ({
  status,
  message,
  error,
  reset,
  severity,
  isDataUpdated,
}) => {
  const { lang } = useLangLabels();

  const responseMessage = lang.backResponseMessages[message as BackResponseMessages] || message;

  const isTriggered = useMemo(() => status === "success" && reset, [status, reset]);

  const executeResetWithTimeout = (timeoutDuration: number) => {
    const timeout = setTimeout(() => {
      reset && reset();
    }, timeoutDuration);

    return timeout;
  };

  useEffect(() => {
    if (isTriggered) {
      if (isDataUpdated !== undefined) {
        if (isDataUpdated === true) {
          const resetTimeoutId = executeResetWithTimeout(100);
          return () => clearTimeout(resetTimeoutId);
        }
      } else {
        const resetTimeoutId = executeResetWithTimeout(2000);
        return () => clearTimeout(resetTimeoutId);
      }
    }
  }, [isTriggered, isDataUpdated]);

  const action = !!reset ? (
    <CustomButton icon={"close"} variant="iconButton" action={reset}></CustomButton>
  ) : null;

  return (
    <>
      {error && (
        <EaseInOut>
          <Alert action={action} severity={severity || "error"} onClose={reset}>
            {lang.messages.anErrorHasOccurred}. {error}
          </Alert>
        </EaseInOut>
      )}

      {responseMessage && !error && (
        <EaseInOut>
          <Alert
            action={action}
            severity={severity || (status === "error" ? "warning" : "success")}
            onClose={reset}
          >
            {responseMessage}
            <br />
            {isTriggered && <TimedProgress />}
          </Alert>
        </EaseInOut>
      )}

      {(error || responseMessage) && <div style={{ height: "4px" }} />}
    </>
  );
};

export default React.memo(StatusAndMessage);
