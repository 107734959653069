import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { CreateUpdateIncomeProps, IncomeInterface } from "../types/Income";
import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { UIFormInterface } from "../../../../shared/form/types/CRUDTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { useIncomeForm } from "../hooks/useIncomeForm";
import { CreateIncome, DeleteIncome, UpdateIncome } from "../services/IncomeService";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";

const CreateUpdateIncomeForm: React.FC<CreateUpdateIncomeProps> = ({
  actionType,
  mode,
  item,
  lubricant,
  isDataUpdated,
}) => {
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateIncome,
      update: UpdateIncome,
      delete: DeleteIncome,
    },
    actionType,
    ["GetAllIncomes", "GetIncomeByLubricant", "GetReferrer"]
  );

  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();

  const { uiModel, handleSubmit, resetForm } = useIncomeForm(tagFP, lubricant, item);

  const ui = (() => {
    const formName = lang.incomeForm.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: mode || "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: IncomeInterface) => {
    const formattedData: IncomeInterface = {
      ...data,
      admissionDate: new Date(data?.admissionDate || "").toISOString(),
    };

    action(formattedData);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      buttonAction={handleSubmit(submit)}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdateIncomeForm);
