import { useQueryPersistanceState } from "../../../shared/react-query/hooks/useQueryPersistanceState";
import { StockInterface } from "../models/StockInterface";

export const useTodayNewPurchaseRequest = () => {
  const [purchaseRequests, setLocalStorage] = useQueryPersistanceState(
    "todayNewPurchaseRequests",
    {
      quantity: 0,
      items: [],
    },
    {
      enabled: true,
      unique: true,
    }
  );

  const handleNewPurchaseRequest = () => {
    setLocalStorage({
      ...purchaseRequests,
      quantity: (purchaseRequests?.quantity || 0) + 1,
    });
  };

  const varifyNewStockPurchase = (stock: StockInterface) => {
    if (stock.minimumRequired > stock.actualStock) {
      handleNewPurchaseRequest();
    }
  };

  return {
    purchaseRequests,
    varifyNewStockPurchase,
  };
};
