import { GridModelInterface } from "../../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

export const usePLCGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    plc: { label: lang.instruments.plc },
    plcName: { label: lang.instruments.plcs.plcName },
  };

  return gridModel;
};
