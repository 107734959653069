import React, { useMemo } from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../../../shared/routes/routes";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../../components/containers/tabs/RouterTabs";
import { VirtualGridProps } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import KnowledgeOfTheSurveys from "../../../../domains/surveys/components/KnowledgeOfTheSurveys/KnowledgeOfTheSurveys";
import KnowledgeOfTheLubPoints from "../../../../domains/lubricationPoints/components/KnowledgeOfTheLubPoints/KnowledgeOfTheLubPoints";
import KnowledgeOfTheRoutes from "../../../../domains/routes/components/KnowledgeOfTheRoutes/KnowledgeOfTheRoutes";
import KnowledgeOfTheObservations from "../../../../domains/observations/components/KnowledgeOfTheObservations/KnowledgeOfTheObservations";
import { CustomIconType } from "../../../../components/buttons/types/CustomButtonTypes";

const ReportsPage: React.FC<{}> = () => {
  const { lang } = useLangLabels();

  const reportsTabs: TabsInterface<VirtualGridProps>[] = useMemo(() => {
    return [
      {
        label: lang.reports.lubricationPoints,
        route: "",
        icon: "points" as CustomIconType,
        component: <KnowledgeOfTheLubPoints title={lang.reports.lubricationPoints} />,
      },
      {
        label: lang.reports.routes,
        route: USER_ROUTES.routes,
        icon: "routes" as CustomIconType,
        component: <KnowledgeOfTheRoutes title={lang.reports.routes} />,
      },
      {
        label: lang.reports.observations,
        route: USER_ROUTES.observations,
        icon: "observations" as CustomIconType,
        component: <KnowledgeOfTheObservations title={lang.reports.observations} />,
      },
      {
        label: lang.reports.surveys,
        route: USER_ROUTES.surveys,
        icon: "survey" as CustomIconType,
        component: <KnowledgeOfTheSurveys title={lang.reports.surveys} />,
      },
    ];
  }, []);

  return <RouterTabs {...useRouteTabProps(3, reportsTabs, { variant: "chip" })} />;
};

export default ReportsPage;
