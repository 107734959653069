import { Paper } from "@material-ui/core";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useExploreRevisionChangeGridModel } from "../lang/useExploreRevisionChangeHistory";
import { useGetApprovedRevisionChangesByTagTGD } from "../../../domains/plant-notifications/revision-changes/services/revisionChangeServices";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const ExploreRevisionChangeHistory = ({ tagTGD }: { tagTGD: string }) => {
  const { lang } = useLangLabels();
  const gridModel = useExploreRevisionChangeGridModel();

  const { data } = useGetApprovedRevisionChangesByTagTGD(tagTGD);

  return (
    <Paper
      style={{
        padding: "24px",
      }}
    >
      <VirtualGrid
        fixedHeight={6}
        items={data}
        gridModel={gridModel}
        contained={false}
        showHeader={false}
        showActions={false}
        showPagination={false}
        showCheckboxColumn={false}
        noResultsMessage={lang.revisionChange.thereAreNoRevisionChanges}
      />
    </Paper>
  );
};

export default ExploreRevisionChangeHistory;
