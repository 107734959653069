import React from "react";
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useUserCompanies } from "../../domains/cluster-companies/services/clusterEnterpricesService";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import { UserType } from "../../domains/users/UserInterface";
import FeatureStatusContainer, {
  Featureinterface,
} from "../../shared/config/features/FeatureStatusContainer";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import { CustomIconType } from "../../components/buttons/types/CustomButtonTypes";

const useStyles = makeStyles(() => ({
  iconDrawer: {
    color: "#f3f3f3",
    pointerEvents: "none",
    minWidth: "32px",
  },
  listItem: {
    padding: "0 16px",
    margin: "8px 0 0",
  },
  listItemHover: {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.65)",
      cursor: "pointer",
    },
  },
  menuButton: {
    padding: "8px 8px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "5px",
    width: "100%",
  },
  pointerEvents: {
    pointerEvents: "none",
  },
}));

interface SideBarButtonProps {
  feature?: Featureinterface;
  ownerService: string;
  allowedUserTypes: UserType[] | undefined;
  title: string;
  icon: CustomIconType;
  onClick: () => void;
}

const SidebarSimpleMenuButton: React.FC<SideBarButtonProps> = React.memo(
  ({ ownerService, allowedUserTypes, title, icon, onClick, feature }) => {
    const { user } = useSessionContext();
    const classes = useStyles({});
    const _userType = user?.type!;
    const { data: userCompanies } = useUserCompanies();
    const _userCompanies = userCompanies?.map(uc => uc.company);
    const companyPermission = _userCompanies?.includes(ownerService);

    return (
      <FeatureStatusContainer feature={feature} hidden={true}>
        {companyPermission && allowedUserTypes?.includes(_userType) ? (
          <div className={classes.listItem} onClick={onClick}>
            <div className={classes.listItemHover}>
              <ListItem component={Button} className={classes.menuButton}>
                <ListItemIcon slot="start" className={classes.iconDrawer}>
                  <CustomIcon icon={icon} style={{ margin: "0px", color: "white" }} />
                </ListItemIcon>
                <ListItemText className={classes.pointerEvents}>
                  <Typography
                    component={"span"}
                    style={{ fontSize: "12px", color: "#fff" }}
                    variant="button"
                  >
                    {title}
                  </Typography>
                </ListItemText>
              </ListItem>
            </div>
          </div>
        ) : (
          <></>
        )}
      </FeatureStatusContainer>
    );
  }
);

export default SidebarSimpleMenuButton;
