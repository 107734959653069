import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import CUComponentForm from "../../CUComponentForm";
import DeleteComponent from "../../DeleteComponent";
import { useComponentsGridModel } from "../../lang/useComponentsGridModel";
import { ComponentInterface } from "../../models/ComponentInterface";
import { usePlantComponents } from "../../services/service.components";

export interface ManageComponentPageProps {
  virtualGridWidth: string;
}

const ManageComponentPage: React.FC<ManageComponentPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: components, isFetching } = usePlantComponents();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: ComponentInterface) => (
        <CUComponentForm item={item} actionType={CrudFormType.update} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        title: lang.components.title.plural,
        entityName: lang.components.title.plural,
        gridModel: useComponentsGridModel(),
        items: components,
        headerActions: () => <CUComponentForm actionType={CrudFormType.create} />,
        carrousellComponents,
      }}
    >
      {(items: any) => (items.length === 1 ? <DeleteComponent item={items[0]} /> : <></>)}
    </VirtualGrid>
  );
};

export default ManageComponentPage;
