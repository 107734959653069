import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { SESSION_DURATION } from "../../../../../domains/session/application/sessionUseCases";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";

const PrivateRoutesWrapper = ({ children }: { children: JSX.Element }) => {
  const { token, setLastInteraction } = useSessionContext();

  const isTokenValid = !!token && token !== "";

  useEffect(() => {
    window.addEventListener("focus", () => {
      setLastInteraction();
    });

    const interval = setInterval(() => {
      setLastInteraction();
    }, SESSION_DURATION + 1000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", () => {
        setLastInteraction();
      });
    };
  }, []);

  return (
    <div onClick={setLastInteraction}>{isTokenValid ? children : <Navigate to="/login" />}</div>
  );
};

export default PrivateRoutesWrapper;
