import { getViewDateDay } from "../../date/utils";

export const downloadJsonFile = async (data: any, fileName: string, callback?: () => void) => {
  const json = JSON.stringify(data, null, "\t");
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = `${fileName}-${getViewDateDay(new Date().toISOString())}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  callback && callback();
};
