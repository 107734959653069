import { Route, Outlet, Navigate } from "react-router-dom";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import ConfigPage from "../../../../../pages/backoffice/ConfigPage";
import UserPanel from "../../../../../pages/backoffice/UserPanel";
import { USER_ROUTES } from "../../../../../shared/routes/routes";
import { ConfigurationPage } from "../../../pages/configuration-page/ConfigurationPage";
import { InstrumentsPage } from "../../../pages/InstrumentsPage";
import PrivateRoutesWrapper from "./PrivateRoutesWrapper";
import LoadingInstrumentNecesaryData from "../../../domain/session/components/LoadingNecesaryData";

const usePrivateRoutes = () => {
  const { person } = useSessionContext();
  const defaultRoute = `/${USER_ROUTES.home}/${USER_ROUTES.instruments.home}`;

  return (
    <Route
      path={`${USER_ROUTES.home}/*`}
      element={
        <PrivateRoutesWrapper>
          <Outlet />
        </PrivateRoutesWrapper>
      }
    >
      <Route
        path=""
        element={<LoadingInstrumentNecesaryData internalRole={USER_ROUTES.instruments.home} />}
      />

      {/* INSTRUMENTS */}
      <Route path={USER_ROUTES.instruments.home} element={<UserPanel />}>
        <Route path="" element={<InstrumentsPage />} />
        <Route path={`${USER_ROUTES.instruments.config}/*`} element={<ConfigurationPage />} />
        <Route path={USER_ROUTES.config} element={<ConfigPage />} />
      </Route>

      {person ? (
        <Route path={"*"} element={<Navigate to={defaultRoute} />} />
      ) : (
        <Route path={"*"} element={<Navigate to={`/${USER_ROUTES.login}`} />} />
      )}
    </Route>
  );
};

export default usePrivateRoutes;
