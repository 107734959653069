import React, { useEffect, useState } from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ticketType } from "../CreateTicketsPage";

const StyledRatingHearth = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const HearthRating: React.FC<{
  title: string;
  handleState: (type: ticketType, indicator: number) => void;
}> = ({ title, handleState }) => {
  const [value, setValue] = useState(1);

  useEffect(() => {
    switch (value) {
      case 1:
        handleState("hearts", value);
        break;
      case 2:
        handleState("hearts", value);
        break;
      case 3:
        handleState("hearts", value);
        break;
      case 4:
        handleState("hearts", value);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">{title}</Typography>
        <StyledRatingHearth
          name="customized-color"
          defaultValue={1}
          getLabelText={value => `${value} Heart${value !== 1 ? "s" : ""}`}
          precision={1}
          max={5}
          onChange={(event, newValue: any) => setValue(newValue)}
          icon={<FavoriteIcon fontSize="inherit" />}
        />
      </Box>
    </>
  );
};

export default HearthRating;
