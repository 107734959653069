import React, { useCallback, useContext, useMemo, useState } from "react";
import { FactoryInterface } from "../../factories/model/FactoryInterface";
import { PersonInterface } from "../../person/models/PersonInterface";
import { Plant } from "../../plants/model/PlantInterface";
import {
  getlogedIn,
  getLogedOut,
  resetPlantSelectorUseCase,
  restoreNewSession,
  selectNewPlantUseCase,
  setSelectedOperatorController,
  validateSession,
  restoreSession,
  selectNewTokenUseCase,
} from "../application/sessionUseCases";
import LoginModal from "../components/LoginModal";
import { Auth, SessionInterface } from "../model/sessionInterface";
import { setMultiplant, setSessionFactory } from "../model/sessionModel";
import { persistedSession, PersistSession } from "../persistance/sessionPersistance";

type sessionContext = SessionInterface & {
  session: SessionInterface;
  currentPbigroupName: string;
  login: (data: Auth, callback: () => void) => void;
  logout: () => void;
  setIsMultiplant: (item: boolean) => void;
  setNewPlant: (item: Plant) => void;
  setNewToken: (token: string) => void;
  resetSelectedPlant: () => void;
  setLastInteraction: () => void;
  enableSession: () => void;
  restoreSavedSession: () => void;
  selectOperator: (operator?: PersonInterface) => void;
  selectFactory: (factory: FactoryInterface) => void;
};

const INITIAL_SESSION = persistedSession();

const SessionContext = React.createContext<sessionContext>({
  ...INITIAL_SESSION,
  session: INITIAL_SESSION,
  currentPbigroupName: "",
  login: () => {},
  logout: () => {},
  setIsMultiplant: () => {},
  setNewPlant: () => {},
  setNewToken: () => {},
  setLastInteraction: () => {},
  resetSelectedPlant: () => {},
  enableSession: () => {},
  selectOperator: () => {},
  restoreSavedSession: () => {},
  selectFactory: () => {},
});

export const SessionProvider: React.FC<{}> = ({ children }) => {
  const [session, setState] = useState<SessionInterface>(INITIAL_SESSION);

  const setSession = (newSession: SessionInterface) => {
    setState(newSession);
    PersistSession(newSession);
  };

  const sessionStore = {
    session,
    setSession,
  };

  const setIsMultiplant = (multiplant: boolean) => {
    setMultiplant(session, multiplant);
  };

  const setNewPlant = (plant: Plant) => {
    selectNewPlantUseCase(sessionStore, plant);
  };

  //Almacenar nuevo token en el store
  const setNewToken = (token: string) => {
    selectNewTokenUseCase(sessionStore, token);
  };

  const resetSelectedPlant = () => {
    resetPlantSelectorUseCase(sessionStore);
  };

  const selectOperator = (operator?: PersonInterface) => {
    setSelectedOperatorController(sessionStore, operator);
  };

  const selectFactory = (factory: FactoryInterface) => {
    setSession(setSessionFactory(session, factory));
  };

  /**
   * Manejadores del estado de la sesion
   */

  const login = async (form: Auth, onSuccess: () => void) => {
    getlogedIn(sessionStore, form, onSuccess);
  };

  const logout = () => {
    getLogedOut(sessionStore);
  };

  const setLastInteraction = () => {
    validateSession(sessionStore);
  };

  const enableSession = () => {
    restoreNewSession(sessionStore);
  };

  const restoreSavedSession = useCallback(() => {
    restoreSession(sessionStore);
  }, [sessionStore]);

  const currentPbigroupName = useMemo(
    () => `${session.currentPlant?.factory} ${session.currentPlant?.name}`.split(" ").join("-"),
    [session.currentPlant?.factory, session.currentPlant?.name]
  );

  return (
    <SessionContext.Provider
      value={{
        session,
        ...session,
        currentPbigroupName,
        selectFactory,
        restoreSavedSession,
        enableSession,
        login,
        logout,
        setIsMultiplant,
        setNewPlant,
        setNewToken,
        resetSelectedPlant,
        setLastInteraction,
        selectOperator,
      }}
    >
      {children}
      <LoginModal />
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  return useContext(SessionContext);
};
