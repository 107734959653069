import theme from "../../../assets/theme";

export enum CriticalityTypes {
  observation = "OBSERVACION",
  equipment = "EQUIPO",
  puntuation = "PUNTUACION",
}

export type CriticalityType = { label: CriticalityTypes };

export const criticalityTypes: CriticalityType[] = [
  { label: CriticalityTypes.observation },
  { label: CriticalityTypes.equipment },
  { label: CriticalityTypes.puntuation },
];

type ColorsType = { label: string };

export const criticalityColors: ColorsType[] = [
  { label: theme.palette.success.main },
  { label: theme.palette.warning.main },
  { label: theme.palette.error.main },
  { label: theme.palette.secondary.main },
  { label: "#9c27b0" } /* purple */,
  { label: "#03a9f4" } /* lightBLue */,
  { label: "#000000" } /* black */,
];

export interface CriticalityInterface {
  id?: number;
  number: number;
  description: string;
  days: number;
  color: string;
  tagFP: string;
  criticalityType: CriticalityTypes;
}
