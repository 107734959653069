import { Divider, Paper, Typography } from "@material-ui/core";
import React from "react";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import PageContainer from "../../components/containers/PageContainer";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../components/containers/tabs/RouterTabs";
import TgdSnackbar from "../../components/snackbar/TgdSnackbar";
import ManageAddedReasonsPage from "../../domains/added-reason/pages/ManageAddedReasonsPage";
import ManageAnomaliesPage from "../../domains/anomalies/pages/ManageAnomaliesPage";
import ManageAreaPage from "../../domains/areas/components/pages/ManageAreaPage";
import ManageCollectorsPage from "../../domains/collectors/CollectorsModule/pages/ManageCollectorsPage";
import ManageComponentPage from "../../domains/components/components/pages/ManageComponentPage";
import ManageContainersPage from "../../domains/containers/pages/ManageContainersPage";
import ManageCriticityPage from "../../domains/criticalities/pages/ManageCriticalityPage";
import ManageElementsPage from "../../domains/elements/pages/ManageElementsPage";
import ManageLubricantsPage from "../../domains/lubricant/pages/ManageLubricantsPage";
import ManageEquipmentsPageR from "../../domains/lubricationPoints/pages/ManageEquipmentsPageR";
import ManagePermissionsPage from "../../domains/permisions/pages/ManagePermissionsPage";
import ManageRoutesPage from "../../domains/routes/pages/ManageRoutesPage";
import ManageSectorPage from "../../domains/sectors/pages/ManageSectorPage";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import { EnumSupplyTypes } from "../../domains/supply/model/SupplyInterface";
import ManageSuppliesPage from "../../domains/supply/pages/ManageSuppliesPage";
import ManageTypesPage from "../../domains/types/pages/ManageTypesPage";
import ManageUsersPage from "../../domains/users/pages/ManageUsersPage";
import { useLayoutStore } from "../../layout/store/layoutStore";
import FeatureStatusContainer, {
  useFeatures,
} from "../../shared/config/features/FeatureStatusContainer";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../shared/routes/routes";
import { DrawerWidth } from "./ViewAsClient/components/UserDashboard";

interface TabPanelProps {
  children?: React.ReactNode;
  index?: any;
  value?: any;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: `calc( 100vw - ${DrawerWidth} - 48px)` }}
    >
      {value === index && children}
    </div>
  );
};

interface Props {}

const PlantManagementPage: React.FC<Props> = ({}) => {
  const { lang } = useLangLabels();
  const { currentPlant } = useSessionContext();
  const plant = currentPlant!;
  const features = useFeatures();
  const { layout } = useLayoutStore();

  const virtualGridWidth = `calc( 100vw - ${layout?.sidebarWidth}px - 144px - 124px)`;

  const PlantManagementConfig: TabsInterface[] = [
    {
      label: lang.plantConfig.users,
      route: USER_ROUTES.plantManagement.users,
      component: <ManageUsersPage plant={plant} />,
    },
    {
      label: lang.collectors.plural,
      route: USER_ROUTES.plantManagement.colectors,
      component: (
        <FeatureStatusContainer feature={features.collectorFeature}>
          <ManageCollectorsPage {...{ virtualGridWidth }} />
        </FeatureStatusContainer>
      ),
    },
    {
      label: lang.types.plural,
      route: USER_ROUTES.plantManagement.types,
      component: <ManageTypesPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.lubricantForm.plural,
      route: USER_ROUTES.plantManagement.lubricants,
      component: <ManageLubricantsPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.containers.title.plural,
      route: USER_ROUTES.plantManagement.containers,
      component: <ManageContainersPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.sectors.title.plural,
      route: USER_ROUTES.plantManagement.sectores,
      component: <ManageSectorPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.areas.title.plural,
      route: USER_ROUTES.plantManagement.areas,
      component: <ManageAreaPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.components.title.plural,
      route: USER_ROUTES.plantManagement.components,
      component: <ManageComponentPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.elements.title.plural,
      route: USER_ROUTES.plantManagement.elements,
      component: <ManageElementsPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.supplies.HERRAMIENTA.title.plural,
      route: USER_ROUTES.plantManagement.tools,
      component: <ManageSuppliesPage {...{ type: EnumSupplyTypes.tool, virtualGridWidth }} />,
    },
    {
      label: lang.supplies.CONSUMIBLE.title.plural,
      route: USER_ROUTES.plantManagement.consumables,
      component: <ManageSuppliesPage {...{ type: EnumSupplyTypes.consumable, virtualGridWidth }} />,
    },
    {
      label: lang.supplies.PROTECCION.title.plural,
      route: USER_ROUTES.plantManagement.protections,
      component: <ManageSuppliesPage {...{ type: EnumSupplyTypes.protection, virtualGridWidth }} />,
    },
    {
      label: lang.supplies.PERMISO.title.plural,
      route: USER_ROUTES.plantManagement.permissions,
      component: <ManagePermissionsPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.criticalities.title.plural,
      route: USER_ROUTES.plantManagement.criticalities,
      component: <ManageCriticityPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.routes.plural,
      route: USER_ROUTES.plantManagement.routes,
      component: <ManageRoutesPage {...{ virtualGridWidth }} />,
    },

    {
      label: lang.components.assets.plural,
      route: `${USER_ROUTES.plantManagement.lubricationPoints}/*`,
      component: <ManageEquipmentsPageR {...{ virtualGridWidth }} />,
    },

    {
      label: lang.anomalies.plural,
      route: USER_ROUTES.plantManagement.anomalies,
      component: <ManageAnomaliesPage {...{ virtualGridWidth }} />,
    },
    {
      label: lang.addedReasons.plural,
      route: USER_ROUTES.plantManagement.addedReasons,
      component: <ManageAddedReasonsPage {...{ virtualGridWidth }} />,
    },
    /*
        {
            label:'Observaciones',
            route:USER_ROUTES.plantManagement.observations,
            component:<ManageObservationsPage {...{virtualGridWidth}}/>
        }¨
        */
  ];

  const tabProps = useRouteTabProps(4, PlantManagementConfig, {
    orientation: "vertical",
  });

  return (
    <>
      {!!plant && (
        <>
          <TgdSnackbar />
          <Paper elevation={3}>
            <PageContainer>
              <Typography variant="h5">
                <CustomIcon icon="config" style={{ margin: "0px 10px -5px 0" }} />
                {lang.plantConfig.configurations}
              </Typography>
            </PageContainer>
            <Divider />

            <RouterTabs {...tabProps} />
          </Paper>
        </>
      )}
    </>
  );
};

export default React.memo(PlantManagementPage);
