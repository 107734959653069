import styled from "@emotion/styled";
import { CSSProperties } from "react";

export const TITLE_SIZE = 16;

const Title = styled("h4")`
  font-weight: 500;
  font-size: ${(props: CSSProperties) => props.fontSize || TITLE_SIZE}px;
  color: ${(props: CSSProperties) => props.color || "inherit"};
  margin: 0px;
`;

export default Title;
