import { Chip, InputAdornment, TextField } from "@material-ui/core";
import { Lock, MailOutline } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/buttons/CustomButton";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import FlexContainer from "../../components/containers/FlexContainer";
import Form from "../../components/form/Form";
import { Auth } from "../../domains/session/model/sessionInterface";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import OnlineOfflineMode from "../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../shared/routes/routes";
import LoginFormContainer from "./components/LoginFormContainer";

const LoginPage: React.FC<{}> = () => {
  const { lang } = useLangLabels();
  const { status, message, login, restoreSavedSession } = useSessionContext();
  const navigate = useNavigate();
  const actualTextDate = `${new Date().getFullYear() - 1990} ${lang.login.yearText} - ${new Date().getFullYear()}`;

  const [form, setData] = useState<Auth>({
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    login(form, () => navigate(`/${USER_ROUTES.home}`));
  };

  const handleInputChange = (e: any) => {
    setData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    restoreSavedSession();
  }, []);

  return (
    <>
      <LoginFormContainer>
        <Form onSubmit={handleSubmit}>
          <TextField
            color="secondary"
            variant="outlined"
            placeholder={lang.login.form.label.user}
            required
            autoComplete="off"
            type="email"
            name="email"
            value={form.email}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            color="secondary"
            variant="outlined"
            autoComplete="off"
            placeholder={lang.login.form.label.password}
            required
            type="password"
            name="password"
            value={form.password}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          <CustomButton
            styleProps={{ height: "40px" }}
            type="submit"
            color="secondary"
            status={status}
          >
            {" "}
            login{" "}
          </CustomButton>
        </Form>

        <FlexContainer
          justify={"space-between"}
          padding="0px 16px"
          flexDirection="column"
          /* height={'140px'} */ align={"center"}
        >
          {status === "error" ? (
            <>
              <FlexContainer gap={"24px"}>
                <Alert style={{ width: "100%" }} severity="error">
                  {message}
                </Alert>
              </FlexContainer>
            </>
          ) : null}

          <OnlineOfflineMode warning={false}>
            <Chip
              onDelete={() => true}
              deleteIcon={<CustomIcon icon="next" />}
              size="small"
              label={lang.login.navigation.name}
              clickable
              color="secondary"
              component={Link}
              to={`${USER_ROUTES.demoRequest}`}
            />
          </OnlineOfflineMode>

          <p>TGD S.A © - {actualTextDate}</p>
        </FlexContainer>
      </LoginFormContainer>
    </>
  );
};

export default LoginPage;
