import React from "react";
import { useState } from "react";
import {
  useAssignMultipleRoutesToMultipleEquipments,
  useRoutesByTagFP,
} from "../services/service.routes";
import { Alert } from "@material-ui/lab";
import CustomButton from "../../../components/buttons/CustomButton";
import { useEquipmentsByTagFP } from "../../lubricationPoints/services/lubricationPointsAdapters";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import { useLubricationPointsGridModel } from "../../lubricationPoints/lang/useLubricationPointsGridModel";
import { useRoutesGridModel } from "../lang/useRoutesGridModel";
import FlexContainer from "../../../components/containers/FlexContainer";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const AssignCancelMultipleRoutes: React.FC<{}> = () => {
  const [selectedEquipments, setSelectedEquipments] = useState<any[]>([]);
  const [selectedRoutes, setSelectedRoutes] = useState<any[]>([]);
  const [step, setStep] = useState(1);

  const { data: equipments } = useEquipmentsByTagFP();
  const { data: routes } = useRoutesByTagFP();

  const { lang } = useLangLabels();

  const { handleAssigCancel, status, reset } = useAssignMultipleRoutesToMultipleEquipments();

  const lubricationPointsGridModel = useLubricationPointsGridModel();
  const routesGRidModel = useRoutesGridModel();

  const onSelectedEquipmentsChange = (selectedData: any[]) => {
    setSelectedEquipments(selectedData);
  };
  const onSelectedRoutesChange = (selectedData: any[]) => {
    setSelectedRoutes(selectedData);
  };

  const handleAssign = () => {
    handleAssigCancel(selectedEquipments, selectedRoutes, "A");
  };

  const handleReset = () => {
    reset();
    setSelectedEquipments([]);
    setSelectedRoutes([]);
    setStep(1);
  };

  return (
    <ComponentMode icon="map" popoverTitle={lang.routes.assignRouteToEquipment}>
      {status === "error" && <Alert severity="error">{lang.messages.anErrorHasOccurred}</Alert>}
      {status === "success" && <Alert>{lang.supplies.assignmentsMade}</Alert>}

      {step === 1 && (
        <VirtualGrid
          contained={false}
          gridModel={lubricationPointsGridModel}
          title={lang.supplies.selectEquipmentToAssign}
          items={equipments}
          onSelectedDataChange={onSelectedEquipmentsChange}
          headerActions={() => (
            <CustomButton
              icon="next"
              popoverTitle={lang.supplies.nextStep}
              {...(selectedEquipments?.length > 0 ? { disabled: false } : { disabled: true })}
              action={() => setStep(2)}
            />
          )}
        />
      )}

      {step === 2 && (
        <VirtualGrid
          contained={false}
          gridModel={routesGRidModel}
          title={lang.routes.assignRouteToEquipment}
          items={routes}
          onSelectedDataChange={onSelectedRoutesChange}
          headerActions={() => (
            <FlexContainer>
              <CustomButton
                icon="before"
                popoverTitle={lang.supplies.backReset}
                action={handleReset}
              />
              <CustomButton
                icon="save"
                popoverTitle={lang.crudActions.save}
                action={handleAssign}
                {...(selectedRoutes?.length > 0 ? { disabled: false } : { disabled: true })}
                status={status}
              />
            </FlexContainer>
          )}
        />
      )}
    </ComponentMode>
  );
};
export default React.memo(AssignCancelMultipleRoutes);
