import React from "react";
import QRCode from "react-qr-code";
import { qrValue } from "../../../shared/qrCode/utils/qrValue";
import { CollectorsSync, ip } from "../../SyncBox/model/CollectorsSyncByTagFPType";
import { CollectorInterface } from "../models/CollectorInterfaces";

const CollectorQrCode: React.FC<{
  colector: (CollectorInterface & ip) | (CollectorInterface & CollectorsSync & ip);
  size?: number;
}> = ({ colector, size }) => {
  const _size = size || 100;

  return (
    <QRCode
      value={qrValue(
        colector.name,
        colector.collectorCode,
        colector.serialNumber,
        colector.state,
        colector.ip,
        colector.tagFP
      )}
      size={_size}
    />
  );
};

export default CollectorQrCode;
