import { LubricationPointInterface } from "../model/lubricationPointsInterface";

export const escapeInnerQuotes = (supplies: string) => {
  const quotesCount = (supplies.match(/\"/g) || []).length;

  if (quotesCount === 2) {
    return supplies;
  }

  let result = supplies.split("");

  let quoteIndices = [];
  for (let i = 0; i < result.length; i++) {
    if (result[i] === '"') {
      quoteIndices.push(i);
    }
  }

  for (let i = 1; i < quoteIndices.length - 1; i++) {
    result[quoteIndices[i]] = '\\"';
  }

  return result.join("");
};

export const suppliesAdapter = (supplies: any): LubricationPointInterface["supplies"] => {
  const escapedSupplies = escapeInnerQuotes(supplies);

  try {
    return JSON.parse(escapedSupplies || '["SIN INSUMOS"]');
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return JSON.parse('["SIN INSUMOS"]');
  }
};
