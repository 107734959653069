import { useMemo } from "react";
import { getValueFromFlatObjectKey } from "../../../globals/utils/valueFromFlatObjectkey";
import { useDate } from "../../../date";
import { IndeterminateCheckbox } from "../components/IndeterminateCheckbox";
import { UseVirtualGridColumnsProps, VirtualGridColumn } from "../types/VirtualGridTypes";

const useVirtualGridColumns = ({
  flatItem,
  gridModel,
  hiddenColumns,
  customColumns,
  showCheckboxColumn,
}: UseVirtualGridColumnsProps): VirtualGridColumn[] => {
  const { formatISODateIfValid } = useDate();

  const getCellValue = (row: any, key: string) => {
    return key.includes(".") ? `${getValueFromFlatObjectKey(row, key)}` : `${row[key]}`;
  };

  const columns = useMemo(() => {
    let columns: any[] = [];

    if (showCheckboxColumn) {
      columns.push({
        id: "select",
        header: ({ table }: any) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: any) => (
          <div>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 20,
      });
    }
    columns.push(...customColumns);

    const entries = Object.entries(flatItem);

    entries.forEach(([key]: [string, any]) => {
      if (gridModel.hasOwnProperty(key)) {
        const { label, options } = gridModel[key];

        const columnConfig: VirtualGridColumn = {
          id: key,
          header: label,
          accessorFn: row =>
            options
              ? gridModel[key].options?.find(
                  (option: any) => option.value === JSON.parse(getCellValue(row, key))
                )?.name || "Value not found"
              : getCellValue(row, key),
          cell: info => {
            const value = info.getValue();

            return value === undefined || value === null || value === "undefined"
              ? "Undefined value"
              : formatISODateIfValid(value);
          },
          key: key,
          size: gridModel[key].columnSize ?? undefined,
        };

        columns.push(columnConfig);
      }
    });

    const finalColumns = columns.filter(col => !hiddenColumns[col.key]);

    return finalColumns.length === 1 && finalColumns[0].id === "select" ? [] : finalColumns;
  }, [flatItem, gridModel, hiddenColumns]);

  return columns;
};

export default useVirtualGridColumns;
