import { useMutation, useQuery } from "react-query";
import { apiCall, mutateApiCall } from "../../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryTagFPInterface,
} from "../../../../shared/globals/utilsGlobalTypes";
import { useSessionContext } from "../../../session/store/sessionContext";
import { useToken } from "../../../session/store/sessionStore";
import { queryClient } from "../../../../shared/react-query/react-query-conf";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { RevisionChangeInterface, RevisionChangeState } from "../types/revisionChangeType";

const BASE_URL = "/ReviewChanges";
const AUTH_HEADER = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const GetAllRevisionChanges = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: BASE_URL,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const GetAllRevisionChangesByTagTGD = ({
  token,
  tagFP,
  tagTGD,
}: PrivateQueryTagFPInterface & { tagTGD: string }) => {
  return apiCall({
    method: "GET",
    url: `${BASE_URL}/${tagTGD}`,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const GetPendingRevisionChanges = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: `${BASE_URL}/pending`,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const GetSupervisedRevisionChanges = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: `${BASE_URL}/supervised`,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const CreateRevisionChange = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const UpdateRevisionChange = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const DeleteRevisionChange = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

// -------------------------------------------------------------------------------------------------

const revisionChangeDependencies = [
  "GetAllRevisionChanges",
  "GetAllRevisionChangesByTagTGD",
  "GetPendingRevisionChanges",
  "GetSupervisedRevisionChanges",
  "DaoEquipmentsByTagFP",
  "DaoEquipmentsElementsByTagFP",
  "EquipmentsByTagFP",
];

export const useGetAllRevisionChanges = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllRevisionChanges", tagFP],
    queryFn: () => GetAllRevisionChanges({ token, tagFP }),
    enabled: !!token,
  });
};

export const useGetAllRevisionChangesByTagTGD = (tagTGD: string) => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllRevisionChangesByTagTGD", tagFP],
    queryFn: () => GetAllRevisionChangesByTagTGD({ token, tagFP, tagTGD }),
    enabled: !!token,
  });
};

export const useGetPendingRevisionChanges = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetPendingRevisionChanges", tagFP],
    queryFn: () => GetPendingRevisionChanges({ token, tagFP }),
    enabled: !!token,
  });
};

export const useGetSupervisedRevisionChanges = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetSupervisedRevisionChanges", tagFP],
    queryFn: () => GetSupervisedRevisionChanges({ token, tagFP }),
    enabled: !!token,
  });
};

export const useGetApprovedRevisionChangesByTagTGD = (tagTGD: string) => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetAllRevisionChangesByTagTGD", tagTGD, tagFP],
    queryFn: () => GetAllRevisionChangesByTagTGD({ token, tagFP, tagTGD }),
    enabled: !!token && !!tagTGD,
    select: data =>
      data.filter((item: RevisionChangeInterface) => item.state === RevisionChangeState.APROBADO),
  });
};

export const useCreateRevisionChange = () => {
  const token = useToken();

  const createRevisionChangeQuery = useMutation(
    (data: any) => CreateRevisionChange({ data, token }),
    {
      onSuccess: () => {
        revisionChangeDependencies.forEach(dependency => {
          queryClient.invalidateQueries(dependency);
        });
      },
    }
  );

  const status = useStatusProcessor(createRevisionChangeQuery);

  return {
    ...createRevisionChangeQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useUpdateRevisionChange = () => {
  const token = useToken();

  const updateRevisionChangeQuery = useMutation(UpdateRevisionChange, {
    onSuccess: () => {
      revisionChangeDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });

  const updateRevisionChange = (data: RevisionChangeInterface) => {
    return updateRevisionChangeQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(updateRevisionChangeQuery);

  return {
    updateRevisionChange,
    ...updateRevisionChangeQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useDeleteRevisionChange = () => {
  const token = useToken();

  const deleteRevisionChangeQuery = useMutation(DeleteRevisionChange, {
    onSuccess: () => {
      revisionChangeDependencies.forEach(dependency => {
        queryClient.invalidateQueries(dependency);
      });
    },
  });

  const deleteRevisionChange = (data: RevisionChangeInterface) => {
    return deleteRevisionChangeQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deleteRevisionChangeQuery);

  return {
    deleteRevisionChange,
    ...deleteRevisionChangeQuery,
    ...status,
    resetStatus: status.reset,
  };
};
