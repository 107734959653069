import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { ComponentUIModes } from "../../../components/componentMode/types/componentModesInterfaces";
import { useCreateNew } from "../services/service.news";
import { NewInterface } from "../model/NewsInterface";
import { useSessionContext } from "../../session/store/sessionContext";
import { errorsLog } from "../../../shared/globals/utils/utils";
import DefaultFormLayout from "../../../components/form/DefaultFormLayout/DefaultFormLayout";
import SelectSimpleEntity from "../../../components/autocomplete/old/SelectSimpleEntity";
import SelectSector from "../../sectors/atom/SelectSector";
import SelectArea from "../../areas/components/SelectArea";
import { TgdTextField } from "../../../components/fields/TextField/TgdTextField";
import { CriticalityTypes } from "../../criticalities/models/criticalityInterface";
import SelectCriticality from "../../criticalities/components/SelectCriticality";
import SelectLubricationPoint from "../../lubricationPoints/components/actions/SelectLubricationPoint";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  mode?: ComponentUIModes;
}

export const CreateNewForm: React.FC<Props> = ({ mode }) => {
  const { lang } = useLangLabels();
  const { user, tagFP } = useSessionContext();
  const [withEq, setWithEq] = useState<boolean | undefined>();
  const [equipment, setEquipment] = useState<any>();

  const { createNew, status, error, message, reset: clearStatus } = useCreateNew();

  const { register, handleSubmit, setValue, watch, control, errors } = useForm();

  const onSubmit = (data: NewInterface) => {
    createNew(data);
  };

  errorsLog(errors);

  useEffect(() => {
    register({ name: "tagFP", value: tagFP });

    register({ name: "equipment", value: "" });
    register(
      { name: "newsDate", value: "" },
      {
        required: lang.validations.messages.required,
      }
    );
    register(
      { name: "description", value: "" },
      {
        required: lang.validations.messages.required,
      }
    );
    register(
      { name: "sector", value: "" },
      {
        required: lang.validations.messages.required,
      }
    );
    register(
      { name: "area", value: "" },
      {
        required: lang.validations.messages.required,
      }
    );
    register({ name: "user", value: user?.email });
    register(
      { name: "criticality", value: "" },
      { required: { value: true, message: lang.validations.messages.required } }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equipment) {
      setValue(
        "equipment",
        `${equipment?.equipment} >> ${equipment?.element} >> ${equipment?.component}`
      );
      setValue("sector", equipment.sector);
    }
  }, [equipment]);

  useEffect(() => {
    //Clear Form
    if (status === "success") {
      setValue("criticality", "");
      setValue("sector", "");
      setValue("area", "");
      setValue("type", "");
      setValue("newsDate", "");
      setValue("description", "");
      setWithEq(undefined);
      setEquipment(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const newTypes = {
    plant: "PLANTA",
    enviroment: "ENTORNO",
  };

  const newsTypes =
    user?.type === "O"
      ? [{ name: newTypes.enviroment }]
      : [{ name: newTypes.enviroment }, { name: newTypes.plant }];

  const newsDefaultValue = user?.type === "O" ? newTypes.enviroment : "";

  const handleEqAttached = () => {
    if (withEq) {
      setWithEq(false);
      setEquipment(undefined);
      setValue("equipment", "");
      setValue("sector", "");
    } else {
      setWithEq(true);
    }
  };

  return (
    <DefaultFormLayout
      componentMode={mode}
      buttonIcon="add"
      buttonAction={handleSubmit(onSubmit)}
      onClose={clearStatus}
      resetForm={clearStatus}
      queryStatus={status}
      alertStatusMessage={message}
      error={error}
      buttonText={lang.news.createNew}
    >
      <Controller
        as={
          <SelectSimpleEntity
            error={errors?.type?.message}
            defaultValue={watch("type", newsDefaultValue)}
            entityList={newsTypes}
            onChange={setValue}
            label={lang.lubricationPoints.type}
            showTitle={"name"}
          />
        }
        rules={{ required: { value: true, message: "Campo Obligatorio" } }}
        name="type"
        defaultValue={newsDefaultValue}
        control={control}
      />

      <SelectCriticality
        by={{ key: "criticalityType", value: CriticalityTypes.observation }}
        error={errors?.criticality?.message}
        onChange={value => setValue("criticality", value)}
        defaultValue={watch("criticality")}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={withEq}
            onChange={handleEqAttached}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
          />
        }
        label={lang.news.linkToAEquipment}
      />

      {withEq ? (
        <SelectLubricationPoint
          onChange={item => {
            setEquipment(item);
          }}
          complete={true}
        />
      ) : (
        <>
          <SelectSector
            error={errors?.sector?.message}
            onChange={(value: string) => setValue("sector", value)}
            defaultValue={watch("sector")}
          />
        </>
      )}

      <SelectArea
        error={errors?.area?.message}
        onChange={value => setValue("area", value)}
        defaultValue={watch("area")}
      />

      <TgdTextField
        error={errors?.newsDate?.message}
        type="date"
        onChange={(value: string) => setValue("newsDate", value)}
        value={watch("newsDate")}
        label={lang.news.newsDate}
      />

      <TgdTextField
        error={errors?.description?.message}
        rows={5}
        onChange={(value: string) => setValue("description", value)}
        value={watch("description")}
        label={lang.news.description}
      />
    </DefaultFormLayout>
  );
};

export default CreateNewForm;
