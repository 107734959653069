import { getTodayDay } from "../../../shared/date/getTodayDay";
import { getLocalStorage, setLocalStorage } from "../../../shared/localStorage/localStorage";
import { sessionFactory } from "../model/sessionFactory";
import { SessionInterface } from "../model/sessionInterface";

const LAST_INTERACTION_KEY = "last-interaction";
const LOCALSTORAGE_KEY = "session";

export const persistedSession = (): SessionInterface => {
  const currentSession = getLocalStorage<SessionInterface>(LOCALSTORAGE_KEY);
  const persistedCreationDate = currentSession?.date;
  const existPersistedSession = currentSession?.value;

  const today = getTodayDay();

  if (existPersistedSession && persistedCreationDate === today) {
    return sessionFactory(existPersistedSession);
  } else {
    return sessionFactory();
  }
};

export const getLastinteraction = () => {
  const last = getLocalStorage<any>(LAST_INTERACTION_KEY);
  return {
    miliseconds: last?.miliseconds || 0,
    value: {
      pathname: last?.value.pathname || "",
    },
  };
};

export const persistLastIntercation = () => {
  const currentLocation = window.location.pathname;
  setLocalStorage(LAST_INTERACTION_KEY, {
    pathname: currentLocation,
  });
};

export const PersistSession = (session: SessionInterface) => {
  return setLocalStorage(LOCALSTORAGE_KEY, session);
};

export const DeletePersistedSession = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY);
};
