import React from "react";
import { Fab, Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: (props: any) => ({
      position: "absolute",
      bottom: theme.spacing(props.bottom || 2),
      right: theme.spacing(props.right || 2),
      cursor: "pointer",
      //left:theme.spacing(props.left),
      //top:theme.spacing(props.top)
    }),
  })
);

interface Props {
  disabled?: boolean;
  onClick: () => void;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  children: any;
  color: "primary" | "secondary" | "inherit";
}

const TgdFab: React.FC<Props> = ({
  left,
  top,
  right,
  bottom,
  onClick,
  children,
  color,
  disabled,
}) => {
  const classes = useStyles({
    bottom: bottom,
    right: right,
    left: left,
    top: top,
  });

  const _color = disabled ? "inherit" : color;

  const handleClick = () => {
    disabled ? console.log("campos requeridos") : onClick();
  };

  return (
    <Fab className={classes.fab} color={_color} onClick={handleClick}>
      <>{children}</>
    </Fab>
  );
};
export default TgdFab;
