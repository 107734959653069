import { TableCell, TableCellProps } from "@mui/material";

export const CustomTableCell: React.FC<TableCellProps> = ({ children, ...props }) => {
  return (
    <TableCell
      style={{
        border: "1px solid #d4d4d4",
        padding: "8px",
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
};
