import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useAddedReasonGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    reason: { label: lang.addedReasons.reason },
    date: { label: lang.globalentityValues.date },
    tagFP: { label: lang.globalentityValues.tagFP },
  };

  return gridModel;
};
