import React from "react";
import { useForm } from "react-hook-form";
import { useFactoriesByUser } from "./services/service.factory";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { FactoryInterface } from "./model/FactoryInterface";
import SelectSimpleEntity from "../../components/autocomplete/old/SelectSimpleEntity";

interface Props {
  onChange: (item: FactoryInterface) => void;
  label: string;
  value: string;
}

const SelectFactory: React.FC<Props> = ({ label, onChange, value }) => {
  const key = "factory";
  const factories = useFactoriesByUser();
  const { watch, setValue, register } = useForm();
  const selection = watch(key);

  useEffect(() => {
    if (!!selection) {
      const result: FactoryInterface = factories?.data?.find(
        (f: FactoryInterface) => f.tagF === selection
      );
      onChange(result);
    }
  }, [selection]);

  useEffect(() => {
    register({ name: key });
    value && setValue(key, value);
  }, []);

  return factories?.status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <SelectSimpleEntity
      width="250px"
      label={label}
      showTitle={"tagF"}
      entityList={factories?.data}
      onChange={(value: string) => setValue(key, value)}
      defaultValue={watch(key)}
    />
  );
};

export default React.memo(SelectFactory);
