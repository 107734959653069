export const USER_ROUTES = {
  login: "login",
  home: "admin",
  plantSelection: "seleccion-planta",
  notifications: "notificaciones",
  plant_notifications: "notificaciones-planta",
  verification: "verification",
  superAdmin: "super-Admin",
  administrator: "administrador",
  accountManager: "administrador-de-cuentas",
  client: "cliente",
  supervisor: "supervisor",
  operator: "operario",
  addUser: "usuarios",
  plantSelector: "plant-selector",
  factories: "fabricas",
  plants: "plantas",
  addClient: "agregar-cliente",
  config: "configuracion",
  demoUsers: "prospectos",
  RejectedDemoUsers: "archivados",
  demoRequest: "demo",
  sensoringManagement: "gestionar-sensores-sti",
  factoryManagement: "gestionar-fábricas",
  revisionChange: {
    index: "cambios-de-revision",
    supervised: "supervisados",
    pending: "pendientes",
  },
  plantManagement: {
    index: "gestionar-planta",
    users: "usuarios",
    colectors: "Colectores",
    types: "tipos",
    lubricants: "lubricantes",
    containers: "recipientes",
    sectores: "sectores",
    areas: "areas",
    components: "componentes",
    elements: "elementos",
    tools: "herramientas",
    consumables: "consumibles",
    protections: "protecciones",
    permissions: "permisos",
    criticalities: "criticidades",
    lubricationPoints: "activos",
    routes: "rutas",
    anomalies: "anomalias",
    addedReasons: "motivos-agregados",
    observations: "observaciones",
  },
  reports: {
    index: "reportes",
    observations: "observaciones",
    losses: "perdidas",
  },
  knowledge: {
    index: "base-de-conocimiento",
    lubricationPoints: "puntos-de-lubricacion",
    routes: "rutas",
    observations: "observaciones",
    surveys: "relevamientos",
  },
  calendar: "calendario",
  stock: "stock",
  categories: "categorias",
  purchaseRequest: "solicitudes-de-compra",
  allStock: "todo-el-stock",

  realtimeSensors: "sensores-en-tiempo-real",
  news: "novedades",
  clientVisor: "visor-de-cliente",
  discover: "descubrir",
  equipments: "equipos",
  elements: "elementos",
  components: "componentes",
  devTools: "herramientas",
  testing: "testing",
  routes: "rutas",
  observations: "observaciones",
  surveys: "relevamientos",

  //colector
  delayedPoints: "puntos-atrasados",
  dailyTools: "herramientas",
  dailyConsumables: "consumibles",
  dailyProtection: "elementos-de-proteccion",
  dailyLubricants: "lubricantes",
  usedConsumables: "consumibles",
  dailyPermissions: "permisos",
  dailyNews: "novedades",
  dailyTasks: "tareas",
  dailyRoutes: "rutas",
  DailyLubricantPoints: "activos",
  desktopDataCollect: "carga-de-datos",

  //V 3.3
  explore: "explorar",

  // Lubrication Section
  lubricationSection: {
    index: "sala-lubricacion",
    stock: "stock",
    incomeLog: "gestion-ingresos",
  },

  // Instruments:
  instruments: {
    home: "instruments",
    config: "config",
    brands: "brands",
    models: "models",
    plc: "plc",
  },
};
