import { Typography } from "@material-ui/core";
import FlexContainer from "../../../../../components/containers/FlexContainer";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

interface TotalLossComponentProps {
  isLastitem: boolean;
  totalLeak: number;
  sector: string;
}

const TotalLossComponent = ({ isLastitem, totalLeak, sector }: TotalLossComponentProps) => {
  const { lang } = useLangLabels();

  return (
    <>
      {isLastitem === true && (
        <FlexContainer
          justify="end"
          padding={"8px 0 16px 0"}
          style={{ borderTop: "2px solid #D9D9D9" }}
        >
          <Typography variant="h2" style={{ fontSize: "15px" }}>
            <span style={{ fontWeight: "bold" }}>
              {lang.reportsGeneration.totalLeak} {sector}:
            </span>{" "}
            {totalLeak.toFixed(2)} lts
          </Typography>
        </FlexContainer>
      )}
    </>
  );
};

export default TotalLossComponent;
