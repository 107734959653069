import { ColectorCode, ColectorName } from "../../collectors/models/CollectorInterfaces";

export type ip = { ip: string };

export type lastDBSync = string;

export interface CollectorsSync {
  id: number;
  collector: ColectorName;
  collectorCode: ColectorCode;
  date: string;
  sync: boolean;
  lastDBSync: lastDBSync;
  tagFP: string;
}

export enum SyncFileErrors {
  Corrupted = "CORRUPTED",
  expired = "EXPIRED",
  failed = "FAILED",
}

export interface localIp {
  hostname: string;
  ip: string;
}
