import React from "react";

import { BrandInterface, CreateUpdateBrandProps } from "../types/brandTypes";
import { CreateBrand, DeleteBrand, UpdateBrand } from "../services/brandService";
import { useBrandForm } from "../hooks/useBrandForm";
import DefaultFormLayout from "../../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import FieldSelector from "../../../../../shared/form/components/FieldSelector";
import { useCreateUpdate } from "../../../../../shared/form/hooks/useCreateUpdate";
import { UIFormInterface } from "../../../../../shared/form/types/CRUDTypes";
import { CrudFormType } from "../../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

const CreateUpdateBrandForm: React.FC<CreateUpdateBrandProps> = ({
  actionType,
  item: brand,
  isDataUpdated,
}) => {
  const {
    action: submitAction,
    resetStatus,
    status,
    error,
    message,
  } = useCreateUpdate(
    {
      create: CreateBrand,
      update: UpdateBrand,
      delete: DeleteBrand,
    },
    actionType,
    ["GetAllBrands"]
  );

  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();

  const { uiModel, handleSubmit, resetForm } = useBrandForm(brand);

  const ui = (() => {
    const formName = lang.instruments.brands.title.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: "content",
        };

        return update;
    }
  })();

  //Methods
  const submitData = (data: BrandInterface) => {
    const brandToSave = {
      ...data,
      tagFP,
      date: new Date(),
    };

    submitAction(brandToSave);
  };

  const resetData = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      buttonAction={handleSubmit(submitData)}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={resetData}
      resetForm={resetData}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdateBrandForm);
