import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomButton from "../../components/buttons/CustomButton";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { AddedReasonInterface } from "./models/AdedReasonTypes";
import { useDeleteAddedReason } from "./services/service.addedReasons";

interface Props {
  item: AddedReasonInterface;
}

const DeleteAddedReasonForm: React.FC<Props> = ({ item }) => {
  const {
    deleteAddedReason,
    query: { status, message },
  } = useDeleteAddedReason();
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status,
        position,
      });
    }
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position,
      });
    }
  }, [status]);

  const { lang } = useLangLabels();

  return status === "loading" ? (
    <CircularProgress size="20px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.actionTypes.deleteItem}
      action={e => getClickPosition(e, () => deleteAddedReason(item))}
    />
  );
};

export default React.memo(DeleteAddedReasonForm);
