import { Routes } from "react-router-dom";
import usePrivateRoutes from "./shared/routes/private/usePrivateRoutes";
import usePublicRoutes from "./shared/routes/public/usePublicRoutes";

const InstrumentsModuleApp = () => {
  const PublicRoutes = usePublicRoutes();
  const PrivateRoutes = usePrivateRoutes();

  return (
    <Routes>
      {/* PUBLIC ROUTES */}
      {PublicRoutes}

      {/* PRIVATE ROUTES */}
      {PrivateRoutes}
    </Routes>
  );
};

export default InstrumentsModuleApp;
