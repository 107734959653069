import React from "react";

import { useTypeForm } from "./services/useTypeForm";
import { enumTgdTypes } from "./models/types";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { TypeInterface } from "./models/TypeInterface";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { CreateType, deleteType, UpdateType } from "./services/service.types";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../shared/form/components/FieldSelector";
import { useSessionContext } from "../session/store/sessionContext";

type props = CUEntityFormPropsType<TypeInterface> & {
  defaultType?: enumTgdTypes;
  item?: TypeInterface;
  isDataUpdated?: boolean;
  customTitle?: string;
};

const CUtypeForm: React.FC<props> = ({
  actionType,
  item,
  defaultType,
  isDataUpdated,
  customTitle,
}) => {
  const { tagFP } = useSessionContext();

  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateType,
      update: UpdateType,
      delete: deleteType,
    },
    actionType,
    ["PlantAux"]
  );

  //UI
  const { lang } = useLangLabels();

  const byValue = defaultType ? lang.enumTgdTypes[defaultType] : "";

  const createTypeLabel =
    lang.selectedLanguage === "es"
      ? `${lang.crudActions.create} ${lang.types.singular} ${byValue}`
      : `${lang.crudActions.create} ${byValue} ${lang.types.singular}`;

  const ui = (() => {
    const formName = lang.types.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${customTitle ? customTitle : createTypeLabel}`,
          variant: "button",
          mode: "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: "iconButton",
          mode: "content",
        };
        return update;
    }
  })();

  const { uiModel, handleSubmit, resetForm } = useTypeForm(
    tagFP,
    item ||
      (defaultType && {
        id: 0,
        name: "",
        type: defaultType,
        tagFP: tagFP,
      })
  );

  //Methods
  const submit = (data: TypeInterface) => {
    let type;

    switch (data.type) {
      case lang.enumTgdTypes.EQUIPO.toUpperCase():
        type = enumTgdTypes.equipment;
        break;
      case lang.enumTgdTypes.LUBRICANTE.toUpperCase():
        type = enumTgdTypes.lubricant;
        break;
      case lang.enumTgdTypes.OBSERVACION.toUpperCase():
        type = enumTgdTypes.observation;
        break;
      case lang.enumTgdTypes.UNIDAD.toUpperCase():
        type = enumTgdTypes.unity;
        break;
      case lang.enumTgdTypes.INSTRUMENTO.toUpperCase():
        type = enumTgdTypes.instrument;
        break;
    }

    const typeData = { ...data, type: type ?? data.type };

    action(typeData);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUtypeForm;
