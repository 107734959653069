import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/buttons/CustomButton";
import { ComponentUIModes } from "../../../components/componentMode/types/componentModesInterfaces";
import FlexContainer from "../../../components/containers/FlexContainer";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import TGDModal from "../../../components/containers/modal/TGDModal/TGDModal";
import PageContainer from "../../../components/containers/PageContainer";
import { errorsLog } from "../../../shared/globals/utils/utils";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useSessionContext } from "../../session/store/sessionContext";
import { useLubricationPointSurveyController } from "../../surveys/services/controllers/useLubricationPointSurveyController";
import { SurveyTasksValues, TaskType } from "../../surveys/tasks/model/TaskInterface";
import SelectSupplyType from "../atom/SelectSuplyType";
import SelectSupply from "../atom/SelectSupply";
import { LocalStateSupply, SupplyStateDimention } from "../model/SupplyInterface";
import { suppliesToObject } from "../utils/supplyToObject";
import SupplyList from "./SupplyList";

interface Props {
  outOfRoute: boolean;
  listTitle?: string;
  buttonTitle?: string;
  formTitle?: string;
  variant?: "modal" | "content" | "form" | "list";
  buttonType: "next" | "add";
  lubricationPoint: LubricationPointInterface;
  disableUsedSupplies?: boolean;
  taskType?: TaskType;
  inmutableValues?: string[];
  propSupplies?: LocalStateSupply[];
  propDeleteSupply?: (deleteIndex: number) => void;
  propSetNeededSupplies?: (value: LocalStateSupply) => void;
}

const AddSupply: React.FC<Props> = ({
  variant,
  listTitle,
  formTitle,
  buttonType,
  lubricationPoint,
  taskType,
  disableUsedSupplies,
  inmutableValues,
  outOfRoute,
  propSupplies,
  propDeleteSupply,
  propSetNeededSupplies,
}) => {
  const { lang } = useLangLabels();
  const { register, watch, handleSubmit, errors, setValue } = useForm();
  const { isOpen, toggleModal } = useModal();
  const { tagFP } = useSessionContext();
  const [supplyState, setSupplyState] = useState<SupplyStateDimention>(SupplyStateDimention.needed);

  const {
    survey,
    deleteSupply: deleteSupplyLubricationPoint,
    setNeededSupplies: setNeededSuppliesLubricationPoint,
  } = useLubricationPointSurveyController(lubricationPoint, outOfRoute);
  const suppliesLubricationPoint = survey?.neededSupplies || [];

  const supplies = propSupplies ?? suppliesLubricationPoint;
  const deleteSupply = propDeleteSupply ?? deleteSupplyLubricationPoint;
  const setNeededSupplies = propSetNeededSupplies ?? setNeededSuppliesLubricationPoint;

  useEffect(() => {
    if (supplyState) {
      setValue("type", "CONSUMIBLE");
    }
  }, [setValue, supplyState]);

  const onSubmit = (data: LocalStateSupply) => {
    setNeededSupplies({
      ...data,
      state: supplyState,
    });
  };

  useEffect(() => {
    setValue("supply", undefined);
    supplyState === SupplyStateDimention.needed && setValue("type", undefined);
  }, [supplies]);

  errorsLog(errors);

  useEffect(() => {
    register(
      { name: "supply", value: "" },
      { required: { value: true, message: lang.validations.messages.required } }
    );
    register(
      { name: "type", value: "" },
      { required: { value: true, message: lang.validations.messages.required } }
    );
  }, [register, survey, supplies]);

  const form = (
    <PageContainer>
      <Typography variant="h6">
        {formTitle}
        {supplyState === SupplyStateDimention.needed
          ? ` ${lang.supplies.missing}`
          : ` ${lang.supplies.used}`}
      </Typography>
      <br />
      <br />
      <FlexContainer flexDirection="column" width="100%">
        {supplyState === SupplyStateDimention.needed && (
          <SelectSupplyType
            onChange={(value: string) => setValue("type", value)}
            defaultValue={watch("type")}
            error={errors?.type?.message}
          />
        )}

        <SelectSupply
          error={errors?.type?.supply}
          by={{ key: "type", value: watch("type") }}
          defaultValue={watch("supply")}
          onChange={(value?: string) => setValue("supply", value?.toUpperCase())}
          tagFP={tagFP}
          extendInput={true}
        />

        <CustomButton icon="save" action={handleSubmit(onSubmit)} />
      </FlexContainer>
    </PageContainer>
  );

  const handleTaskSupplies = (supplyState: SupplyStateDimention) => {
    setSupplyState(supplyState);
    toggleModal();
  };

  const neededSuppliesButton = (
    <CustomButton
      popoverTitle={
        taskType === SurveyTasksValues.puntuation
          ? lang.supplies.requestAddedInput
          : lang.supplies.missingInputsForTheTask
      }
      icon="add"
      action={() => handleTaskSupplies(SupplyStateDimention.needed)}
      title={
        taskType === SurveyTasksValues.puntuation
          ? lang.supplies.request
          : lang.supplies.missingPlural
      }
    />
  );
  const usedSuppliesButton = (
    <CustomButton
      popoverTitle={lang.supplies.suppliesUsed}
      icon="add"
      action={() => handleTaskSupplies(SupplyStateDimention.used)}
      title={lang.supplies.suppliesUsed}
    />
  );

  const headerActions = (
    <FlexContainer>
      {neededSuppliesButton}
      {taskType !== SurveyTasksValues.puntuation && !disableUsedSupplies && usedSuppliesButton}
    </FlexContainer>
  );

  const list = (mode?: ComponentUIModes) => (
    <SupplyList
      tasktype={taskType}
      handleDeleteItem={deleteSupply}
      supplies={supplies}
      headerAction={variant === "list" ? headerActions : <></>}
      title={listTitle}
      mode={mode}
      inmutableValues={inmutableValues ? suppliesToObject(inmutableValues) : undefined}
    />
  );

  return (
    <>
      {variant === "form" && form}
      {variant === "content" && (
        <>
          {form}
          {list()}
        </>
      )}
      {variant === "list" && list()}
      {variant === "modal" && (
        <>
          {buttonType === "next" && (
            <CustomButton color="primary" variant="fab" action={toggleModal} icon="next" />
          )}
        </>
      )}

      <TGDModal fullWidth open={isOpen} handleClose={toggleModal}>
        <>
          {form}
          {list("modal")}
        </>
      </TGDModal>
    </>
  );
};
export default React.memo(AddSupply);
