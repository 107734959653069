export const filterAccessTo = (role: string, arrayObject: any, roleAccessKey: string) => {
  let range: any = [];
  let accessTo: any = [];

  switch (
    role //specific filter
  ) {
    case "SA":
      range = roleAccesCombinations(100);
      break;
    case "A":
      range = roleAccesCombinations(10);
      break;
    case "AC":
      range = roleAccesCombinations(50);
      break;
    case "S":
      range = roleAccesCombinations(4);
      break;
    case "C":
      range = roleAccesCombinations(3);
      break;
    case "O":
      range = roleAccesCombinations(5);
      break;
  }

  arrayObject.forEach((object: any) => {
    const rangeFilter = range.filter((i: any) => i === object[roleAccessKey]);
    if (rangeFilter.length > 0) {
      accessTo.push(object);
    }
  });

  return accessTo;
};

//Calculate all numbers posible by role (return an array of numbers)
export const roleAccesCombinations = (role: number) => {
  let roleValues: any = [100, 10, 50, 3, 4, 5];
  let n: number = 0;
  let allPosibleAccess: any = [];
  let roleResult: any = [];

  //all simple level variants
  for (n; n < 6; n + 1) {
    if (role !== roleValues[n]) {
      roleResult.push(role + roleValues[n]);
    }
    n = n + 1;
  }

  //all Complex level variants
  mixedResoults(role).forEach((i: any) => {
    roleResult.push(i);
  });

  //set Acces by role in an Array
  allPosibleAccess = roleResult;

  return allPosibleAccess;
};

//Part of roleAccesCombinations make the  bidimentional array calculations
export const mixedResoults = (position: any) => {
  let roleValues: any = [100, 10, 50, 3, 4, 5];
  let roleAccess: any = [];
  let mixedRoleValues = position;

  let counter = 0;
  roleValues.forEach((role: number) => {
    if (position !== role) {
      mixedRoleValues = mixedRoleValues + role;
      roleAccess.push(mixedRoleValues);
      let n = counter;

      for (n = counter; n < 6; n++) {
        if (position !== roleValues[n]) {
          if (role !== roleValues[n]) {
            let result = mixedRoleValues + roleValues[n];
            roleAccess.push(result);
          }
        }
      }
    }

    counter++;
  });
  return roleAccess;
};
