import React from "react";
import {
  List,
  ListItem,
  Typography,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { ObservationInterface, ObservationType } from "../models/ObservationTypes";
import { usePlantCriticality } from "../../criticalities/services/service.criticality";
import FlexContainer from "../../../components/containers/FlexContainer";

interface Props {
  type: ObservationType;
  observations: ObservationInterface[];
  title?: string;
  headerAction?: React.ReactNode;
  handleDeleteItem?: (item?: any) => void;
}

const ObservationsList: React.FC<Props> = ({
  type,
  observations,
  headerAction,
  title,
  handleDeleteItem,
}) => {
  const { data: Criticalities } = usePlantCriticality();

  return (
    <>
      <List style={{ width: "100%" }}>
        <ListItem>
          <Typography variant="h6">{title}</Typography>
          <ListItemSecondaryAction>{headerAction && headerAction}</ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {observations?.map((observation: any, index: number) => (
          <ListItem key={index}>
            {type === "ANOMALIA" ? (
              <ListItemText
                primary={observation.anomaly}
                secondary={observation.observation}
              ></ListItemText>
            ) : (
              <ListItemText primary={observation.observation}></ListItemText>
            )}
            <Divider />
            <ListItemSecondaryAction>
              <FlexContainer align="center">
                {
                  <FiberManualRecordIcon
                    fontSize="large"
                    style={{
                      color: Criticalities?.find(c => c.description === observation.criticality)
                        ?.color,
                    }}
                  />
                }
                {handleDeleteItem && (
                  <IconButton onClick={() => handleDeleteItem({ type, deleteIndex: index })}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </FlexContainer>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ObservationsList;
