import SimpleAutocompleteSelect from "../../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import { instrumentTypeOptions } from "../types/instrumentType";

interface SelectInstrumentTypeProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectInstrumentType: React.FC<SelectInstrumentTypeProps> = ({
  onChange,
  defaultValue,
  error,
  disabled,
}) => {
  const { lang } = useLangLabels();
  const label = lang.types.form.type;

  const options = instrumentTypeOptions.map(value => ({
    label: lang.enumTgdTypes[value.label].toUpperCase(),
  }));

  return (
    <SimpleAutocompleteSelect
      {...{
        items: options,
        showTitle: "label",
        onChange,
        defaultValue,
        error,
        label,
        disabled,
      }}
    />
  );
};

export default SelectInstrumentType;
