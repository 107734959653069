import { Divider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QueryStatus } from "react-query";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
import DoNotDoButton from "../../../../../../../components/buttons/DoNotDoButton";
import TgdFab from "../../../../../../../components/buttons/TgdFab";
import FlexContainer from "../../../../../../../components/containers/FlexContainer";
import PageContainer from "../../../../../../../components/containers/PageContainer";
import { useDate } from "../../../../../../../shared/date/useDate";
import { bakupAddSurveyWithObservations } from "../../../../../../../shared/dexie-js/backup/backupRepository";
import { errorsLog } from "../../../../../../../shared/globals/utils/utils";
import { LubricationPointInterface } from "../../../../../../lubricationPoints/model/lubricationPointsInterface";
import AddObservation from "../../../../../../observations/components/AddObservation";
import { ObservationInterface } from "../../../../../../observations/models/ObservationTypes";
import AddSupply from "../../../../../../supply/molecules/AddSupply";
import { suppliesToString } from "../../../../../../supply/utils/supplyToString";
import { useLubricationPointSurveyController } from "../../../../../services/controllers/useLubricationPointSurveyController";
import { TaskType } from "../../../../../tasks/model/TaskInterface";
import { CustomIcon } from "../../../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";

interface SurveyRepairmentProps {
  item: LubricationPointInterface;
  taskType: TaskType;
  statusController: (status: QueryStatus) => void;
  outOfRoute: boolean;
}

const SurveyRepairment: React.FC<SurveyRepairmentProps> = ({
  outOfRoute,
  statusController,
  taskType,
  item,
}) => {
  const { lang } = useLangLabels();

  const { register, handleSubmit, errors } = useForm();
  const { isToday } = useDate();

  // Survey Data
  const {
    survey,
    setTaskDone,
    setTaskTime,
    createSurvey,
    createObservation,
    nextTaskStep,
    backTaskStep,
    updateEquipmentInfo,
  } = useLubricationPointSurveyController(item, outOfRoute);

  const observations = survey?.observations || [];
  const taskDone = survey?.repairment.taskDone;
  const taskTime = survey?.repairment.taskTime || 0;
  const supplies = survey?.neededSupplies || [];

  // Component State
  const [step] = useState<any>();

  // Component Methods
  useEffect(() => {
    register({ name: "taskDone", value: taskDone });
    register(
      { name: "taskTime", value: taskTime },
      {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
        validate: value => value > 0 || lang.validations.messages.required,
      }
    );
  }, [taskType, taskDone, register, taskTime]);

  const submit = () => {
    statusController("loading");
    const { surveyDone: _lubricationSurveyDone, ...restLubrication } = survey?.lubrication || {};
    const { surveyDone: _repairmentSurveyDone, ...restRepairment } = survey?.repairment || {};
    const newSurvey = {
      ...survey?.inspection,
      ...restLubrication,
      ...restRepairment,

      // Task Data
      taskType: taskType,
      taskDone: !!taskDone,
      taskTime: `${taskTime}`,
      endTime: isToday(survey?.inspection.startTime!)
        ? new Date().toISOString()
        : survey?.inspection.startTime!,

      //Lubrication Data
      lubricates: survey?.lubrication.lubricates,
      sampleExtraction: false,
      consumables: JSON.stringify(suppliesToString(supplies)),
    } as any;

    const newObservations = (id: number) => {
      return [
        ...observations.map((o: ObservationInterface) => ({
          ...o,
          surveyId: id,
        })),
      ];
    };

    bakupAddSurveyWithObservations(newSurvey, newObservations);

    createSurvey(newSurvey)
      .then(surveyData => {
        if (survey) {
          survey.repairment.surveyDone = true;
        }
        createObservation(newObservations(surveyData.id)).then(observationData => {
          if (observationData.error) {
            statusController("error");
          } else {
            updateEquipmentInfo({
              ...item.info,
              lastInspectionDate: survey?.inspection.startTime!,
              lastLubrication: !!survey?.lubrication.lubricates
                ? survey?.inspection.startTime!
                : item?.info?.lastLubrication!,
              updateReview:
                survey?.inspection.updateReview === false
                  ? item.info?.updateReview
                  : survey?.inspection.updateReview!,
              labelReplacement:
                survey?.inspection.labelReplacement === false
                  ? item.info?.labelReplacement
                  : survey?.inspection.labelReplacement!,
              lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId,
              lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
            } as any).then(async updateData => {
              if (updateData.error) {
                statusController("error");
              } else {
                statusController("success");
                nextTaskStep(taskType);
              }
            });
          }
        });
      })
      .catch(err => {
        statusController("error");
        console.error(err);
      });
  };

  errorsLog(errors);

  return (
    <>
      {outOfRoute && (
        <div>
          <CustomButton
            action={() => backTaskStep(taskType)}
            icon="before"
            variant="iconButton"
            title={lang.actionTypes.before}
          />
        </div>
      )}

      {taskDone === undefined && (
        <FlexContainer justify="center" align="center" gap="24px" height="100%" width="100%">
          <DoNotDoButton
            action={submit}
            icon="close"
            title={lang.surveys.tasks.repairment.noRepairment}
          />
          <DoNotDoButton
            action={() => setTaskDone(taskType, true)}
            icon="check"
            title={lang.surveys.tasks.repairment.title}
          />
        </FlexContainer>
      )}

      {!outOfRoute && taskDone === true && (
        <div>
          <CustomButton
            action={() => setTaskDone(taskType, false)}
            icon="cancelOutlined"
            variant="iconButton"
            popoverTitle={lang.surveys.tasks.repairment.cancelRepairment}
          />
        </div>
      )}

      <>
        {taskDone && (
          <>
            <PageContainer>
              <TextField
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
                type="number"
                name={"taskTime"}
                onChange={event => {
                  setTaskTime(taskType, JSON.parse(event.target.value));
                }}
                label={
                  `${lang.surveys.taskTime} ${errors?.taskTime?.message || ""}` ||
                  lang.surveys.taskTime
                }
                color={errors?.taskTime?.message ? "primary" : "secondary"}
                helperText={lang.surveys.inMinutes}
                inputRef={register({
                  required: {
                    value: !!taskDone,
                    message: lang.validations.messages.required,
                  },
                })}
              />
            </PageContainer>

            <br />
            <Divider />

            <AddObservation
              outOfRoute={outOfRoute}
              taskType={taskType}
              lubricationPoint={item}
              type="OBSERVACION"
              buttonTitle={lang.observations.plural}
              formTitle={lang.actionTypes.add + " " + lang.observations.singular}
              variant={survey?.observations && survey.observations.length > 0 ? "list" : "form"}
              listTitle={step?.observation || lang.observations.plural}
              buttonType="add"
            />

            <AddSupply
              outOfRoute={outOfRoute}
              listTitle={lang.supplies.title.plural}
              formTitle={lang.actionTypes.new + " " + lang.supplies.title.singular}
              buttonTitle={lang.supplies.title.plural}
              variant={"list"}
              buttonType={"add"}
              lubricationPoint={item}
            />

            <TgdFab
              disabled={survey?.observations && survey.observations.length > 0 ? false : true}
              bottom={4}
              right={4}
              color="primary"
              onClick={handleSubmit(submit)}
              children={<CustomIcon icon={"next"} />}
            />
          </>
        )}
      </>
    </>
  );
};

export default React.memo(SurveyRepairment);
