import styled from "@emotion/styled";
import React from "react";

export interface PointerContainerInterface {
  children: React.ReactNode;
  onClick?: (item?: any) => void;
  disabledPointer?: boolean;
  align?: "center";
  justify?: "center";
}

const PointerWrapper = styled("div")`
  cursor: ${(props: PointerContainerInterface) => (props.disabledPointer ? "unset" : "pointer")};
  align-items: ${(props: PointerContainerInterface) => props.align};
  justify-contet: ${(props: PointerContainerInterface) => props.justify};
`;

const PointerContainer: React.FC<PointerContainerInterface> = ({ children, ...rest }) => {
  return <PointerWrapper {...rest}>{children}</PointerWrapper>;
};

export default React.memo(PointerContainer);
