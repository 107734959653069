import { apiCall } from "../../../shared/axios/axios-config";
import { PrivateQuery } from "../../../shared/globals/utilsGlobalTypes";
import { Auth } from "../model/sessionInterface";

export const ValidateToken = ({ token }: PrivateQuery) => {
  return apiCall({
    method: "POST",
    url: "/ValidateJwtToken",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetLogin = (data: Auth) =>
  apiCall({
    method: "POST",
    url: "/authenticate",
    data,
  });

export const GetUser = ({ token }: PrivateQuery) =>
  apiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/User",
  });

export const GetPlants = ({ token }: PrivateQuery) =>
  apiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/Plants",
  });

export const GetPerson = ({ token }: PrivateQuery) =>
  apiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/Person",
  });
