interface PageBodyVariableContentProps {
  children: React.ReactNode;
  contentRef: React.RefObject<HTMLDivElement>;
}

const PageBodyVariableContent = ({ children, contentRef }: PageBodyVariableContentProps) => {
  return (
    <div style={{ height: "100%" }} ref={contentRef}>
      {children}
    </div>
  );
};

export default PageBodyVariableContent;
