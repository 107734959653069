import { queryClient, useQuery } from "../../../shared/react-query/react-query-conf";
import { useSessionContext } from "../../session/store/sessionContext";
import { GetOperatorData } from "./lubricatorstoreService";

export const LUBRICATOR_DATA_KEY = "lubricatorData";

export const useLubricatorStore = () => {
  const { session, tagFP } = useSessionContext();
  const operator = session.selectedOperator;
  const queryKey = `${LUBRICATOR_DATA_KEY}-${session.person?.email}-${
    operator?.lubricatorNumber || 0
  }`;

  const lubricatorData = useQuery({
    queryKey: [queryKey, tagFP],
    queryFn: () => GetOperatorData(session, operator),
    staleTime: 60 * 60 * 1000,
    enabled: !!operator && !!session.token && !!session.tagFP,
  });

  const finishSurvey = (callback: () => void) => {
    lubricatorData.refetch().then(() => {
      queryClient.invalidateQueries("LubricationPointsWithInfo");
      callback();
    });
  };

  return {
    finishSurvey,
    ...lubricatorData,
  };
};
