import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import CreateUpdateModelForm from "../../domain/instrument-models/components/CreateUpdateModelForm";
import { DeleteModelForm } from "../../domain/instrument-models/components/DeleteModelForm";
import { useModelGridModel } from "../../domain/instrument-models/lang/useModelGridModel";
import { useGetAllModels } from "../../domain/instrument-models/services/modelService";

export const ManageModelPage = ({ fixedHeight }: { fixedHeight?: number }) => {
  const { lang } = useLangLabels();
  const { data: models, isFetching } = useGetAllModels();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: item => (
        <CreateUpdateModelForm
          item={item}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      gridModel={useModelGridModel()}
      title={lang.instruments.models.title.plural}
      fixedHeight={fixedHeight}
      entityName={lang.instruments.models.title.plural}
      items={models}
      carrousellComponents={carrousellActions}
      headerActions={() => <CreateUpdateModelForm actionType={CrudFormType.create} />}
      itemActions={item => <DeleteModelForm item={item} />}
    />
  );
};
