import { RevisionChangeInterface } from "../types/revisionChangeType";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import useSuperviseRevisionChangeForm from "../hooks/useSuperviseRevisionChangeForm";
import { useUpdateRevisionChange } from "../services/revisionChangeServices";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import { FlexContainer } from "../../../../components/containers";
import LubricationPointInfo from "./LubricationPointInfo";
import { Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import theme from "../../../../assets/theme";
import { useDate } from "../../../../shared/date";
const RevisionChangeSupervisionForm = ({
  item,
  isDataUpdated,
}: {
  item: RevisionChangeInterface;
  isDataUpdated?: boolean;
}) => {
  const { formatISODateIfValid } = useDate();
  const { lang } = useLangLabels();
  const formName = lang.revisionChange.title.singular;

  const { formattedUiModel, handleSubmit, resetForm } = useSuperviseRevisionChangeForm(item);

  // Update
  const { updateRevisionChange, resetStatus, status, error, message } = useUpdateRevisionChange();

  // Methods
  const onSubmit = (data: RevisionChangeInterface) => {
    updateRevisionChange({
      ...data,
      state: formattedUiModel.isApproved.value ? "APROBADO" : "RECHAZADO",
    });
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode="content"
      buttonIcon="edit"
      buttonVariant="iconButton"
      buttonText={`${lang.actionTypes.update} ${formName.toLowerCase()}`}
      popoverTitle={`${lang.actionTypes.update} ${formName.toLowerCase()}`}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(onSubmit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      <FlexContainer
        flexDirection="column"
        gap={"0px"}
        padding={2}
        style={{ marginBottom: "20px" }}
      >
        <LubricationPointInfo tagTGD={item.tagTGD} />

        <Divider style={{ margin: "16px 0 24px 0" }} />

        <Typography variant="body1">
          <strong>{lang.revisionChange.request.toUpperCase()}: </strong>
          {`${lang.words.from} ${Number(item.review) - 1} ${lang.words.to.toLowerCase()} ${Number(
            item.review
          )}`}
        </Typography>

        <Divider style={{ margin: "8px 0 16px 0" }} />

        <FlexContainer>
          <FlexContainer flexDirection="column" width={"50%"}>
            <Typography variant="body1" style={{ marginBottom: "4px" }}>
              <strong>{lang.revisionChange.requestDate}: </strong>
              {formatISODateIfValid(item.requestDate) ||
                lang.words.without + " " + lang.revisionChange.requestDate.toLowerCase()}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "4px" }}>
              <strong>{lang.revisionChange.requestReason}: </strong>
              {item.requestReason ||
                lang.words.without + " " + lang.revisionChange.requestReason.toLowerCase()}
            </Typography>
          </FlexContainer>
          <FlexContainer flexDirection="column" width={"50%"}>
            <Typography variant="body1" style={{ marginBottom: "4px" }}>
              <strong>{lang.revisionChange.requestUser}: </strong>
              {item.requestUser ||
                lang.words.without + " " + lang.revisionChange.requestUser.toLowerCase()}
            </Typography>
          </FlexContainer>
        </FlexContainer>

        <Typography variant="body1" style={{ marginTop: "32px" }}>
          <strong>{lang.revisionChange.supervision.toUpperCase()} </strong>
        </Typography>

        <Divider style={{ margin: "8px 0 16px 0" }} />

        <RadioGroup
          row
          style={{ width: "100%", marginBottom: "20px" }}
          value={formattedUiModel.isApproved.value}
          onChange={e => {
            const newValue = e.target.value === "true";
            formattedUiModel.isApproved.onChange(newValue);
          }}
        >
          <FlexContainer
            width={"calc(50% - 44px)"}
            border={`2px solid ${
              formattedUiModel.isApproved.value ? theme.palette.success.main : "transparent"
            }`}
            borderRadius={"24px"}
            padding={"0 16px"}
            style={{
              backgroundColor: `${formattedUiModel.isApproved.value ? "#f0fff0" : "transparent"}`,
            }}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  style={{
                    color: formattedUiModel.isApproved.value && theme.palette.success.main,
                  }}
                />
              }
              label={lang.revisionChange.approve}
            />
          </FlexContainer>
          <FlexContainer
            width={"calc(50% - 44px)"}
            border={`2px solid ${
              !formattedUiModel.isApproved.value ? theme.palette.primary.main : "transparent"
            }`}
            borderRadius={"24px"}
            padding={"0 16px"}
            style={{
              marginLeft: "16px",
              backgroundColor: `${!formattedUiModel.isApproved.value ? "#ffe6e6" : "transparent"}`,
            }}
          >
            <FormControlLabel
              value={false}
              control={
                <Radio
                  style={{
                    color: !formattedUiModel.isApproved.value
                      ? theme.palette.primary.main
                      : undefined,
                  }}
                />
              }
              label={lang.revisionChange.reject}
            />
          </FlexContainer>
        </RadioGroup>

        <FlexContainer flexDirection="column" gap={"12px"}>
          <FieldSelector fieldProps={{ ...formattedUiModel.supervisionReason }} />
          <FieldSelector
            fieldProps={{
              ...formattedUiModel.impactDate,
              disabled: !formattedUiModel.isApproved.value,
              value: formattedUiModel.isApproved.value
                ? formattedUiModel.impactDate.value
                : "2999-01-01",
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </DefaultFormLayout>
  );
};

export default RevisionChangeSupervisionForm;
