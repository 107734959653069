import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useMutation } from "../../../shared/react-query/react-query-conf";

//-------------------------
//AXIOS REPOSITORY
//-------------------------
const CreateSupportTicket = ({ data, token }: PrivateQueryData<any>) => {
  return mutateApiCall({
    method: "post",
    url: `/HelpDeskEmail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

//-------------------------
//USE QUERY
//-------------------------

//-------------------------
//MUTATIONS
//-------------------------

export const useCreateSupportTicket = () => {
  return useMutation(CreateSupportTicket);
};

//-------------------------
//CUSTOM HOOKS
//-------------------------
