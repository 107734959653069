import { useEffect, useState } from "react";

export function useKeyStatus(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);

  // If pressed key is our target key then set to true
  function handleKeyDown({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setIsKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const handleKeyUp = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setIsKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isKeyPressed;
}
