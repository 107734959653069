import { Divider, Typography } from "@material-ui/core";
import TGDCompany from "../../../assets/img/compressed/tgdcompany.png";
import TGDSoft from "../../../assets/img/compressed/tgdsoft.png";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import FlexContainer from "../../containers/FlexContainer";

const PageHeaderContent = () => {
  const { lang } = useLangLabels();

  return (
    <>
      <img
        style={{
          height: "50px",
        }}
        src={TGDCompany}
        alt="TGD Company"
      />
      <FlexContainer justify="end" width={"100%"}>
        <div style={{ width: "208px", textAlign: "right" }}>
          <Typography variant="body2" style={{ fontSize: "13px" }}>
            {lang.reportsGeneration.lubricationDepartmentReport}
          </Typography>
        </div>
      </FlexContainer>
      <Divider
        orientation="vertical"
        variant="middle"
        style={{ margin: 0, backgroundColor: "#D9D9D9" }}
      />
      <img
        style={{
          height: "65px",
        }}
        src={TGDSoft}
        alt="TGD Company"
      />
    </>
  );
};

export default PageHeaderContent;
