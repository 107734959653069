import { Route } from "react-router-dom";
import FactoryList from "../../../../../../domains/factories/FactoryList";
import AddUser from "../../../../../../pages/backoffice/AddUser";
import ConfigPage from "../../../../../../pages/backoffice/ConfigPage";
import DiscoverPage from "../../../../../../pages/backoffice/DiscoverPage";
import HomePage from "../../../../../../pages/backoffice/super-admin/HomePage";
import UserPanel from "../../../../../../pages/backoffice/UserPanel";
import ViewPlantAsClientPage from "../../../../../../pages/backoffice/ViewAsClient/ViewPlantAsClientPage";
import { USER_ROUTES } from "../../../../../../shared/routes/routes";

const useAdminRoutes = () => {
  return (
    <Route path={USER_ROUTES.administrator} element={<UserPanel />}>
      <Route path={""} element={<HomePage />} />
      <Route path={`${USER_ROUTES.factories}`} element={<FactoryList />} />
      <Route path={`${USER_ROUTES.addUser}/*`} element={<AddUser />} />
      <Route path={`${USER_ROUTES.discover}/*`} element={<DiscoverPage />} />
      <Route path={`${USER_ROUTES.clientVisor}/*`} element={<ViewPlantAsClientPage />} />
      <Route path={"*"} element={<HomePage />} />
      <Route path={USER_ROUTES.config} element={<ConfigPage />} />
    </Route>
  );
};

export default useAdminRoutes;
