import React, { useEffect, useMemo, useState } from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { useEquipmentTypesByTagFP } from "../../../domains/types/services/service.types";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import { usePlantSectors } from "../../../domains/sectors/services/service.sectors";
import { useRoutesByTagFP } from "../../../domains/routes/services/service.routes";
import { usePlantComponents } from "../../../domains/components/services/service.components";
import { usePlantElements } from "../../../domains/elements/services/service.elements";
import FlexContainer from "../../../components/containers/FlexContainer";
import CustomButton from "../../../components/buttons/CustomButton";
import { Divider, Grid, Paper } from "@material-ui/core";
import { LubricationPointInterface } from "../../../domains/lubricationPoints/model/lubricationPointsInterface";
import SelectLubricationPoint from "../../../domains/lubricationPoints/components/actions/SelectLubricationPoint";
import { isEqual } from "lodash";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface LubricationPointsNavigationProp {
  lubricationPoints: LubricationPointInterface[] | undefined;
  handleChange: (value: LubricationPointInterface) => void;
}

interface SearchEquipmentInterface {
  state: string | null;
  equipment: string | null;
  type: string | null;
  sector: string | null;
  route: string | null;
  component: string | null;
  element: string | null;
  singleEquipment: LubricationPointInterface["tagTGD"] | null;
}

enum FilterTypes {
  specific = "ESPECIFICO",
  acumulative = "ACUMULATIVO",
}

const initialSearchState: SearchEquipmentInterface = {
  state: null,
  equipment: null,
  type: null,
  sector: null,
  route: null,
  component: null,
  element: null,
  singleEquipment: null,
};

type SearchEntries = [string, string | null][];

const specificFilterStrategyReducer = (item: any, searchEntries: SearchEntries): boolean => {
  return searchEntries.reduce((acc, [key, value]) => {
    if (!acc) return false;
    if (value !== "" && value !== null) {
      if (key === "route") {
        const route = item.routes as string;
        if (!route.includes(value)) return false;
        return true;
      }
      if (key === "state") {
        const searchState = value === "ACTIVO" ? true : false;
        if (searchState !== item[key]) return false;
        return true;
      }

      if (value !== item[key]) return false;
    }
    return true;
  }, true);
};

const AcumulativeFilterStrategyReducer = (item: any, searchEntries: SearchEntries): boolean => {
  return searchEntries.reduce((acc, [key, value]) => {
    if (acc) return true;
    if (value !== "" && value !== null) {
      if (key === "state") {
        const searchState = value === "ACTIVO" ? true : false;
        if (searchState === item[key]) return true;
      } else {
        if (value === item[key]) return true;
      }
    }
    return false;
  }, false);
};
const filterStrategy = (strategy: FilterTypes, item: any, searchEntries: SearchEntries) => {
  if (strategy === FilterTypes.acumulative)
    return AcumulativeFilterStrategyReducer(item, searchEntries);
  if (strategy === FilterTypes.specific) return specificFilterStrategyReducer(item, searchEntries);
};

const filterLubricationPoints = (
  lubricationPoints: LubricationPointInterface[],
  search: SearchEquipmentInterface,
  strategy: FilterTypes
): LubricationPointInterface[] => {
  const searchEntries = Object.entries(search);
  const isEmptySearch = isEqual(initialSearchState, search);

  if (isEmptySearch) return lubricationPoints;

  if (search.singleEquipment) {
    const finded = lubricationPoints.find(p => p.tagTGD === search.singleEquipment);
    return finded ? [finded] : [];
  }

  const filteredPoints = lubricationPoints.filter((point: any) => {
    const include = filterStrategy(strategy, point, searchEntries);
    return include;
  });

  return filteredPoints;
};

export const LubricationPointsNavigation: React.FC<LubricationPointsNavigationProp> = ({
  lubricationPoints,
  handleChange,
}) => {
  const { lang } = useLangLabels();

  const filteredListOfEquipments = useMemo(() => {
    const points =
      lubricationPoints &&
      lubricationPoints.reduce(
        (acc, lp) => {
          const existInList = acc.equipments.includes(lp.equipment);
          if (existInList) {
            return acc;
          }
          return {
            items: [...acc.items, lp],
            equipments: [...acc.equipments, lp.equipment],
          };
        },
        {
          items: [],
          equipments: [],
        } as any
      );
    return points;
  }, [lubricationPoints]).items;

  const { tagFP } = useSessionContext();
  const { data: types } = useEquipmentTypesByTagFP(tagFP);
  const { data: sectors } = usePlantSectors();
  const { data: routes } = useRoutesByTagFP();
  const { data: components } = usePlantComponents();
  const { data: elements } = usePlantElements();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [search, setSearch] = useState(initialSearchState);
  const [filterType, _setFilterType] = useState<FilterTypes>(FilterTypes.specific);

  const handleSearch = (field: keyof SearchEquipmentInterface) => (value: any) => {
    if (field === "singleEquipment") {
      setSearch({
        ...initialSearchState,
        [field]: value,
      });
    } else {
      setSearch(old => ({
        ...old,
        singleEquipment: initialSearchState.singleEquipment,
        [field]: value,
      }));
    }
    setSelectedIndex(0);
  };

  const handleClearSearch = () => {
    setSearch({ ...initialSearchState });
    setSelectedIndex(0);
  };

  const handleNext = () => {
    setSelectedIndex(old => old + 1);
  };

  const handleBack = () => {
    setSelectedIndex(old => old - 1);
  };

  const filteredEquipments = filterLubricationPoints(lubricationPoints || [], search, filterType);

  const selectedEquipment = filteredEquipments[selectedIndex];
  useEffect(() => {
    handleChange(selectedEquipment);
  }, [selectedEquipment]);

  const searchSingle = (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <SelectLubricationPoint
          clearable
          defaultValue={search.singleEquipment}
          onChange={handleSearch("singleEquipment")}
          fromItems={filteredEquipments}
        ></SelectLubricationPoint>
      </Grid>
      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={[
            { name: "ACTIVO", value: true },
            { name: "INACTIVO", value: false },
          ]}
          defaultValue={search.state}
          label={lang.lubricationPoints.state}
          showTitle="name"
          onChange={handleSearch("state")}
        />
      </Grid>
    </Grid>
  );

  const searchMultiple = (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={filteredListOfEquipments || []}
          defaultValue={search.equipment}
          label={lang.lubricationPoints.equipment}
          showTitle="equipment"
          onChange={handleSearch("equipment")}
        />
      </Grid>

      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={types || []}
          defaultValue={search.type}
          label={lang.lubricationPoints.type}
          showTitle="name"
          onChange={handleSearch("type")}
        />
      </Grid>

      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={sectors || []}
          defaultValue={search.sector}
          label={lang.lubricationPoints.sector}
          showTitle="sector"
          onChange={handleSearch("sector")}
        />
      </Grid>

      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={routes || []}
          defaultValue={search.route}
          label={lang.routes.singular}
          showTitle="routeName"
          onChange={handleSearch("route")}
        />
      </Grid>

      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={components || []}
          defaultValue={search.component}
          label={lang.lubricationPoints.component}
          showTitle="component"
          onChange={handleSearch("component")}
        />
      </Grid>

      <Grid item xs={2}>
        <SimpleAutocompleteSelect
          clearable
          items={elements || []}
          defaultValue={search.element}
          label={lang.lubricationPoints.element}
          showTitle="element"
          onChange={handleSearch("element")}
        />
      </Grid>
    </Grid>
  );

  return (
    <Paper style={{ padding: "24px" }}>
      <FlexContainer flexDirection="column">
        {searchSingle}
        {searchMultiple}
        <FlexContainer justify="right" align="center" width={"100%"}>
          <FlexContainer width={"100px"}>
            {selectedIndex + 1} / {filteredEquipments.length}
          </FlexContainer>
          <Divider />
          <CustomButton
            icon="before"
            variant="iconButton"
            color="secondary"
            action={handleBack}
            disabled={selectedIndex === 0}
            popoverTitle={lang.explore.previous}
          />
          <CustomButton
            icon="next"
            variant="iconButton"
            color="secondary"
            action={handleNext}
            disabled={selectedIndex >= filteredEquipments.length - 1}
            popoverTitle={lang.explore.next}
          />
          <CustomButton
            icon="delete"
            variant="iconButton"
            popoverTitle={lang.explore.cleanSearch}
            action={handleClearSearch}
          />
        </FlexContainer>
      </FlexContainer>
    </Paper>
  );
};
