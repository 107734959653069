import React, { useEffect } from "react";
import { useDeleteSectorVerification } from "./services/service.sectors";
import CustomButton from "../../components/buttons/CustomButton";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { SectorInterface } from "./models/SectorInterface";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { CircularProgress } from "@material-ui/core";
import FlexContainer from "../../components/containers/FlexContainer";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: SectorInterface;
}
const DeleteSectorFrom: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();

  const {
    status: deleteStatus,
    validate,
    errors,
    query: { status, message },
  } = useDeleteSectorVerification();

  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";

      setData({
        modal: true,
        errors: `${lang.sectors.sectorCannotBeRemoved}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }

    if (deleteStatus === "success") {
      setData({
        modal: true,
        errors: message || lang.sectors.sectorRemovedSuccessfully,
        severity: deleteStatus ? "success" : "warning",
        position: position,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, errors]);

  return deleteStatus === "loading" || status === "loading" ? (
    <FlexContainer justify={"center"} align="center">
      <CircularProgress size="24px" />
    </FlexContainer>
  ) : (
    <CustomButton
      status={status}
      icon="delete"
      variant="iconButton"
      action={e => getClickPosition(e, () => validate(item))}
      popoverTitle={lang.crudActions.delete + " " + lang.sectors.title.singular}
    />
  );
};

export default React.memo(DeleteSectorFrom);
