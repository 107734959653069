import React, { useEffect, useState } from "react";
import { List, Divider, TextField } from "@material-ui/core";
import { useDate } from "../../../../../../../shared/date/useDate";
import { LubricationPointInterface } from "../../../../../../lubricationPoints/model/lubricationPointsInterface";
import { SurveyTasksValues, TaskType } from "../../../../../tasks/model/TaskInterface";
import { useLubricationPointSurveyController } from "../../../../../services/controllers/useLubricationPointSurveyController";
import { useForm } from "react-hook-form";
import { errorsLog } from "../../../../../../../shared/globals/utils/utils";
import { useSetStartedRoute } from "../../../../../../routes/services/service.routes";
import { suppliesToString } from "../../../../../../supply/utils/supplyToString";
import { ObservationInterface } from "../../../../../../observations/models/ObservationTypes";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
import FlexContainer from "../../../../../../../components/containers/FlexContainer";
import DoNotDoButton from "../../../../../../../components/buttons/DoNotDoButton";
import ClickableForm from "../../../../../../../components/form/ClickableForm";
import { TypeFormRegex } from "../../../../../../../shared/regExp/regExp";
import AddObservation from "../../../../../../observations/components/AddObservation";
import TgdFab from "../../../../../../../components/buttons/TgdFab";
import { useLubricatorStore } from "../../../../../../users/lubricator/lubricatorStore";
import { QueryStatus } from "react-query";
import TgdTextField from "../../../../../../../components/fields/TextField/TgdTextField";
import { CustomIcon } from "../../../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";
import { useCreateRevisionChange } from "../../../../../../plant-notifications/revision-changes/services/revisionChangeServices";
import { Alert } from "@mui/material";
import { useUser } from "../../../../../../session/store/sessionStore";
import { getTodayDay } from "../../../../../../../shared/date";

interface SurveyInspectionProps {
  item: LubricationPointInterface;
  onFinish: () => void;
  taskType: TaskType;
  outOfRoute: boolean;
  statusController: (status: QueryStatus) => void;
}

const SurveyInspection: React.FC<SurveyInspectionProps> = ({
  item,
  onFinish,
  taskType,
  outOfRoute,
  statusController,
}) => {
  const { lang } = useLangLabels();
  const { nowDay, nowMonth, nowYear, formatISOToLocalDateTime, isToday } = useDate();
  const { register, handleSubmit, errors } = useForm();

  const [requestReason, setRequestReason] = useState<string>("");

  const { data: user } = useUser();

  const {
    mutateAsync: createRevisionChange,
    status: revisionChangeStatus,
    error: revisionChangeError,
  } = useCreateRevisionChange();

  // Survey Data
  const {
    survey,
    backTaskStep,
    setInaccessible,
    nextTaskStep,
    setNewComponent,
    setNeedsLubrication,
    setLeak,
    setLabelReplacement,
    setTemperature,
    createSurvey,
    updateEquipmentInfo,
    createObservation,
    setStartTime,
  } = useLubricationPointSurveyController(item, outOfRoute);

  const inaccessible = survey?.inspection?.inaccessible;
  const newComponent = survey?.inspection?.newComponent;
  const needsLubrication = survey?.inspection?.needsLubrication;
  const leak = survey?.inspection?.leak;
  const labelReplacement = survey?.inspection?.labelReplacement;
  const temperature = survey?.inspection?.temperature;
  const anomalies = survey?.anomalies || [];
  const observations = survey?.observations || [];
  const supplies = survey?.neededSupplies || [];
  const currentEquipmentinfoSupplies = item.info?.supplies?.filter(s => s !== "SIN INSUMOS") || [];
  const startTime = survey?.inspection.startTime;

  const { finishSurvey } = useLubricatorStore();

  // Component State
  const [step, setStep] = useState<any>({});

  useEffect(() => {
    register({ name: "newComponent", value: newComponent });
    register(
      { name: "needsLubrication", value: needsLubrication },
      {
        required: {
          value: needsLubrication === undefined,
          message: lang.validations.messages.required,
        },
      }
    );
    register({ name: "leak", value: leak });
    register({ name: "labelReplacement", value: labelReplacement });
    register({ name: "temperature", value: temperature });
  }, [newComponent, needsLubrication, leak, labelReplacement, temperature]);

  errorsLog(errors);

  // Actions
  const { startRoute } = useSetStartedRoute();

  // Aplication State Actions
  const handleFinish = () => {
    if (survey?.inspection.inaccessible) {
      return;
    } else {
      onFinish();
    }
  };

  // Component Methods
  const handleAccesibility = (accesibility: boolean) => {
    // SetAtom inaccesible to proced on survey process
    setInaccessible(accesibility);
    // startRoute to set pending equipments and started routes
    if (item.routeName) {
      startRoute({
        date: `${nowYear}-${nowMonth}-${nowDay}`,
        tagFP: item.tagFP,
        route: item.routeName,
      });
    }
  };

  const submit = async () => {
    const data = {
      tagTGD: item.tagTGD,
      tagFP: item.tagFP,
      requestReason: requestReason,
      requestUser: user?.email || "",
      requestDate: getTodayDay(),
      /* review: item.review, */
    };

    if (newComponent) {
      try {
        const result = await createRevisionChange(data);

        if (result && !result.error) {
          nextTaskStep(taskType);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      nextTaskStep(taskType);
    }
  };

  const handleCustomDate = (selectedDateTime: string): void => {
    const selectedDate = new Date(selectedDateTime);
    const selectedMiliseconds = selectedDate.getTime();
    const nowMiliseconds = new Date().getTime();

    if (selectedMiliseconds > nowMiliseconds) {
      return alert(lang.routes.futureDatesAreNotAllowed);
    }

    const selectedIsoDate = selectedDate.toISOString();
    setStartTime(selectedIsoDate);
  };

  const handleInaccesiblePoint = () => {
    statusController("loading");

    createSurvey({
      taskTime: "0",
      taskDone: false,
      impossibleToLubricate: false,
      needsLubrication: false,
      inaccessible: true,
      lubricates: false,
      lubricantType: item.lubricantType,
      lubricant: item.lubricant,
      taskType: SurveyTasksValues.lubrication,
      sampleExtraction: false,
      startTime: startTime,
      endTime: isToday(startTime!) ? new Date().toISOString() : startTime,
      tagTGD: item.tagTGD,
      equipment: item.equipment,
      sector: item.sector,
      measureUnit: item.measureUnit,
      tagFP: item.tagFP,
      review: item.review,
      criticality: `${item.criticality}`,
      manualRegistration: true,
      outOfRoute: outOfRoute || false,
      route: outOfRoute ? "" : item?.routeName!,
      routeDate: new Date().toISOString(),
      quantity: 0,
      reason: "",
      cleaning: false,
      consumables: JSON.stringify(suppliesToString(supplies)),
    } as any)
      .then(data => {
        createObservation([
          ...anomalies.map((o: ObservationInterface) => ({
            ...o,
            surveyId: data.id,
          })),
          ...observations.map((o: ObservationInterface) => ({
            ...o,
            surveyId: data.id,
          })),
        ]).then(() => {
          updateEquipmentInfo({
            ...item.info,
            ...survey?.inspection,

            // Data To Modify
            tagTGD: data.tagTGD,
            tagFP: data.tagFP,
            temperature: data.temperature,
            lastSurveyId: data.id,
            updatedToday: true,
            user: data.user,
            lastInspectionDate: startTime!,
            inaccessible: data.inaccessible,
            labelReplacement: !item?.info?.labelReplacement,

            //Inmutable data
            supplies:
              currentEquipmentinfoSupplies.length === 0
                ? ["SIN INSUMOS"]
                : currentEquipmentinfoSupplies,
            lubricantQuantity: item?.info?.lubricantQuantity!,
            leak: item?.info?.leak!,
            assetCriticality: item?.info?.assetCriticality!,
            lastLubrication: item?.info?.lastLubrication!,
          } as any).then(async () => {
            finishSurvey(() => {
              statusController("success");
              nextTaskStep(taskType);
              handleFinish();
            });
          });
        });
      })
      .catch(err => console.log(err));
  };

  errorsLog(errors);

  return (
    <>
      {
        <div>
          {outOfRoute ? (
            <CustomButton
              action={() => {
                backTaskStep(taskType);
              }}
              icon="before"
              variant="iconButton"
              title={lang.actionTypes.before}
            />
          ) : (
            inaccessible !== undefined && (
              <CustomButton
                action={() => {
                  backTaskStep(taskType);
                }}
                icon="before"
                variant="iconButton"
                title={lang.actionTypes.before}
              />
            )
          )}
        </div>
      }

      {inaccessible === undefined && (
        <>
          <FlexContainer
            justify="center"
            align="center"
            height="100%"
            width="100%"
            padding={"50px 0px 0px"}
          >
            <DoNotDoButton
              action={() => handleAccesibility(true)}
              icon="close"
              title={lang.surveys.tasks.lubrication.inaccessible}
            />
            <DoNotDoButton
              action={() => handleAccesibility(false)}
              icon="check"
              title={lang.surveys.tasks.lubrication.accessible}
            />
          </FlexContainer>

          <FlexContainer
            justify="center"
            align="center"
            padding={"50px 0px 0px"}
            height="100%"
            width="100%"
          >
            <FlexContainer width={"308px"}>
              <TgdTextField
                label={lang.routes.startDate}
                onChange={handleCustomDate}
                value={formatISOToLocalDateTime(startTime!)}
                type={"datetime-local"}
                autoFocus={false}
                onKeyDown={(e: any) => e.preventDefault()}
                style={{
                  color: "#bfbfbf",
                }}
              />
            </FlexContainer>
          </FlexContainer>
        </>
      )}

      {inaccessible === false && (
        <>
          <List>
            <ClickableForm
              title={lang.surveys.tasks.inspection.componentChange}
              value={newComponent}
              handleValue={item => {
                setNewComponent(item);
              }}
            />
            {newComponent && (
              <FlexContainer padding="0px 16px" flexDirection="column">
                <TextField
                  name="requestReason"
                  value={requestReason}
                  inputRef={register()}
                  variant="outlined"
                  size="small"
                  color={errors.requestReason?.message ? "primary" : "secondary"}
                  label={errors.requestReason?.message || lang.revisionChange.requestReason}
                  onChange={(e: any) => {
                    setRequestReason(e.target.value);
                  }}
                  style={{ width: "calc(100%)" }}
                />
                {revisionChangeError && <Alert severity="warning">{revisionChangeError}</Alert>}
                {revisionChangeStatus === "error" && (
                  <Alert severity="warning">{lang.messages.anErrorHasOccurred}</Alert>
                )}
              </FlexContainer>
            )}
            <ClickableForm
              title={lang.surveys.tasks.inspection.needLubricant}
              value={needsLubrication}
              handleValue={item => {
                setNeedsLubrication(item);
              }}
              validationError={errors.needsLubrication}
            />
            <ClickableForm
              title={lang.surveys.tasks.inspection.isLossIdentified}
              value={leak}
              handleValue={item => {
                setLeak(item);
              }}
              validationError={errors.leak}
            />
            <ClickableForm
              title={lang.surveys.tasks.inspection.replaceLabel}
              value={labelReplacement}
              handleValue={item => {
                setLabelReplacement(item);
              }}
            />

            <br />

            <FlexContainer padding="0px 16px" justify="space-between">
              <TextField
                name="temperature"
                value={temperature}
                inputRef={register({
                  pattern: {
                    value: TypeFormRegex.float,
                    message: "Numeros/Decimales con punto",
                  },
                })}
                variant="outlined"
                size="small"
                color={errors.temperature?.message ? "primary" : "secondary"}
                label={errors.temperature?.message || lang.surveys.tasks.inspection.temperatureInC}
                onChange={e => setTemperature(e.target.value)}
              />
            </FlexContainer>
          </List>

          <br />
          <Divider />
          <br />

          <FlexContainer width="100%" align="center" justify="flex-end">
            {!step?.anomaly && anomalies?.length === 0 && (
              <CustomButton
                icon="close"
                title={lang.surveys.tasks.inspection.noAnomalies}
                action={() => setStep({ anomaly: lang.surveys.tasks.inspection.noAnomalies })}
              />
            )}
            <AddObservation
              outOfRoute={outOfRoute}
              taskType={SurveyTasksValues.lubrication}
              lubricationPoint={item}
              type="ANOMALIA"
              buttonTitle={lang.anomalies.plural}
              formTitle={lang.surveys.tasks.inspection.newAnomaly}
              variant={step?.anomaly || (anomalies && anomalies.length > 0) ? "list" : "modal"}
              listTitle={step?.anomaly || lang.anomalies.plural}
              buttonType="add"
            />
          </FlexContainer>

          <TgdFab
            disabled={!(step?.anomaly || anomalies?.length !== 0)}
            bottom={3}
            right={4.5}
            color="primary"
            onClick={handleSubmit(submit)}
            children={<CustomIcon icon={"next"} />}
          />
        </>
      )}

      {inaccessible === true && (
        <>
          <AddObservation
            outOfRoute={outOfRoute}
            taskType={SurveyTasksValues.lubrication}
            type="INACCESIBLE"
            lubricationPoint={item}
            listTitle={lang.observations.plural}
            variant="content"
            formTitle={lang.surveys.tasks.inspection.whyWasTheEquipmentInaccessible}
            buttonType="add"
          />

          <TgdFab
            disabled={!(observations && observations.length > 0)}
            bottom={4}
            right={4}
            color="primary"
            onClick={handleInaccesiblePoint}
            children={<CustomIcon icon={"next"} />}
          />
        </>
      )}
    </>
  );
};

export default React.memo(SurveyInspection);
