import React, { createContext, useContext, useState } from "react";
import { Plant } from "../../domains/plants/model/PlantInterface";
import { useSessionContext } from "../../domains/session/store/sessionContext";
import useBasicLocalStorage from "../localStorage/useBasicLocalStorage";

interface PlantContextType {
  selectedPlant: string | null;
  setSelectedPlant: (plant: Plant) => void;
  clearSelectedPlant: () => void;
  newToken: string;
  setNewToken: any;
}

const PlantContext = createContext<PlantContextType | undefined>(undefined);

export const PlantProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { setNewPlant, setNewToken } = useSessionContext();
  //setNewPlant recibe la planta como tal y la actualiza en la session
  //setNewToken es una funcion que tiene que recibir el nuevo token para actualizarlo;

  const [selectedPlant, setSelectedPlant] = useBasicLocalStorage<string | null>({
    key: "selected-plant",
    initialValue: null,
    useLastUser: false,
  });

  const [newTokenState, setNewTokenState] = useState<string>("");

  const setSelectedPlantFn = (plant: Plant) => {
    setNewPlant(plant);
    setSelectedPlant(plant.tagFP);
  };

  const setNewTokenFn = (token: string) => {
    setNewTokenState(token);
    setNewToken(token);
  };

  const clearSelectedPlant = () => {
    setSelectedPlant(null);
  };

  return (
    <PlantContext.Provider
      value={{
        selectedPlant,
        setSelectedPlant: setSelectedPlantFn,
        clearSelectedPlant,
        newToken: newTokenState,
        setNewToken: setNewTokenFn,
      }}
    >
      {children}
    </PlantContext.Provider>
  );
};

export const usePlantContext = () => {
  const context = useContext(PlantContext);
  if (!context) {
    throw new Error("usePlantContext debe ser usado dentro de PlantProvider");
  }
  return context;
};
