import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import theme from "../../../assets/theme";

const InputStyles = styled("div")`
  .textField {
    border: 1px solid gray;
    background: ${theme.palette.background.paper};
    height: 40px;
    border-radius: 4px;
    width: -webkit-fill-available;
    padding: 4px 8px;
  }
`;

export type TextFieldType = "email" | "password" | "text" | "tel" | "search" | "date";

export interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  value?: any;
  name?: string;
  type: TextFieldType;
  required?: boolean;
  match?: RegExp;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  minLength?: number;
}

const TextField: React.FC<TextFieldProps> = props => {
  return (
    <InputStyles>
      <input className="textField" {...props} />
    </InputStyles>
  );
};

export default TextField;
