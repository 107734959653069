import { TextField } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import DefaultFormLayout from "../../../components/form/DefaultFormLayout/DefaultFormLayout";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { emailFormRegex } from "../../../shared/regExp/regExp";
import { useFactoriesByUser } from "../../factories/services/service.factory";
import { usePerson } from "../../session/store/sessionStore";
import {
  accountMannagerRoleType,
  AdminRoleType,
  ClientRoleType,
  OperatorRoleType,
  PersonInterface,
  SupervisorRoleType,
} from "../models/PersonInterface";
import { useCreatePersonRefactor, useEmailVerificationExist } from "../services/personServices";

type Props = {
  title?: string;
  role:
    | ClientRoleType
    | SupervisorRoleType
    | OperatorRoleType
    | accountMannagerRoleType
    | AdminRoleType;
  handlePerson?: (data: PersonInterface) => void;
  tagF?: string;
  enableFunction?: boolean;
};
const AddPersonForm: React.FC<Props> = ({ role, handlePerson, tagF, enableFunction }) => {
  const { lang } = useLangLabels();
  const { register, watch, errors, handleSubmit, setValue } = useForm();
  const { createPerson, status, error, message, reset } = useCreatePersonRefactor();
  const emailVerification = sessionStorage.getItem("emailVerification");
  const { data: person } = usePerson();
  const { data: _factoriesByUser } = useFactoriesByUser();

  const submit = (data: PersonInterface & { tagF?: string }) => {
    createPerson(
      {
        name: `${data.firstName} ${data.lastName}`,
        telephone: data.telephone,
        address: data.address,
        email: data.email,
        file: 0,
        referrer: person?.id || 0,
        password: data.password,
        role: role,
        tagF: tagF || "PERSONALIZADO",
      },
      () => {
        handlePerson && handlePerson(data);
      }
    );
  };

  useEmailVerificationExist(watch("email"));

  const handleReset = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("address", "");
    setValue("telephone", "");
    setValue("email", "");
    setValue("password", "");
    reset();
  };

  return (
    <OnlineOfflineMode reverse={enableFunction}>
      <DefaultFormLayout
        componentMode="content"
        buttonIcon="add"
        buttonText={lang.actionTypes.create}
        popoverTitle={lang.actionTypes.create}
        {...{ queryStatus: status, error, alertStatusMessage: message }}
        buttonAction={handleSubmit(submit)}
        onClose={handleReset}
        resetForm={handleReset}
      >
        <TextField
          variant={"outlined"}
          name="firstName"
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
            min: { value: 3, message: lang.validations.messages.minLength },
          })}
          size="small"
          label={errors?.firstName?.message || lang.persons.name}
          color={errors?.firstName?.message ? "primary" : "secondary"}
        />

        <TextField
          variant={"outlined"}
          name="lastName"
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
            min: { value: 3, message: lang.validations.messages.minLength },
          })}
          size="small"
          label={errors?.lastName?.message || lang.persons.lastName}
          color={errors?.lastName?.message ? "primary" : "secondary"}
        />

        <TextField
          size={"small"}
          variant={"outlined"}
          inputRef={register()}
          name="address"
          label={lang.persons.adress}
          color={"secondary"}
        />
        <TextField
          variant={"outlined"}
          name="telephone"
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
          })}
          size="small"
          label={errors?.telephone?.message || lang.persons.phone}
          color={errors?.telephone?.message ? "primary" : "secondary"}
        />

        <TextField
          variant={"outlined"}
          name="email"
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
            pattern: { value: emailFormRegex, message: lang.validations.messages.pattern },
            validate: {
              existance: () =>
                !!emailVerification && JSON.parse(emailVerification) === 0
                  ? true
                  : lang.validations.messages.allReadyExist,
            },
          })}
          size="small"
          label={errors?.email?.message || "Email"}
          color={errors?.email ? "primary" : "secondary"}
        />

        <TextField
          variant={"outlined"}
          name="password"
          inputRef={register({
            required: { value: true, message: lang.validations.messages.required },
            min: { value: 6, message: lang.validations.messages.minLength },
          })}
          size="small"
          label={errors?.password?.message || lang.persons.password}
          color={errors?.password?.message ? "primary" : "secondary"}
        />

        {/* {role === 'Cliente' &&
                <SimpleAutocompleteSelect
                    label={errors?.tagF?.message || 'Fabrica'} 
                    items={[{tagF:'PERSONALIZADO'},...(factoriesByUser || [])]}
                    showTitle={'tagF'}
                    onChange={(value)=>setValue('tagF',value)}
                    defaultValue={watch('tagF','PERSONALIZADO')}
                    helper={'has que este cliente pueda ver todas las plantas de una fábrica'}
                />
            } */}

        <TextField
          variant={"outlined"}
          disabled
          inputRef={register()}
          defaultValue={watch("referrer", person?.id)}
          name="referrer"
          size="small"
          label={lang.persons.referer}
          color={errors?.password?.message ? "primary" : "secondary"}
        />
      </DefaultFormLayout>
    </OnlineOfflineMode>
  );
};

export default AddPersonForm;
