import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import PageContainer from "../../../components/containers/PageContainer";
import { usePlants } from "../../../domains/session/store/sessionStore";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import ClientVisor from "../ClientVisor/ClientVisor";

const ViewPlantAsClientPage: React.FC = () => {
  const { lang } = useLangLabels();
  const plants = usePlants();

  return (
    <>
      <Paper>
        <PageContainer>
          <Typography variant="h6">
            {lang.plants.plural} {lang.words.availables}
          </Typography>
          <List>
            <Divider />
            {plants.length > 0 ? (
              plants.map(plant => (
                <div key={plant.tagFP}>
                  <ListItem>
                    <ListItemText
                      primary={plant.name}
                      secondary={lang.factories.singular + ": " + plant.factory}
                    />
                    <ListItemSecondaryAction>
                      <ClientVisor {...{ plant }} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              ))
            ) : (
              <PageContainer>{lang.messages.status.loading}...</PageContainer>
            )}
          </List>
        </PageContainer>
      </Paper>
    </>
  );
};

export default ViewPlantAsClientPage;
