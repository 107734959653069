import React from "react";
import CustomButton from "./buttons/CustomButton";
import TgdPopover from "./popovers/TgdPopover";
import { useLangLabels } from "../shared/lang/services/useLangLabels";

const IconStatus: React.FC<{ state: boolean | undefined }> = ({ state }) => {
  const { lang } = useLangLabels();

  return state === undefined ? null : (
    <TgdPopover
      title={`${state ? lang.components.activePoint : lang.components.inactivePoint}`}
      mode="hover"
    >
      <CustomButton
        variant="iconButton"
        icon="state"
        styleProps={{ color: state ? "green" : "gray" }}
      />
    </TgdPopover>
  );
};

export default React.memo(IconStatus);
