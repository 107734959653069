import { useState } from "react";

export interface clickPositionInterface {
  positionX: number | string;
  positionY: number | string;
}

export const useClickPosition = () => {
  const [position, setPosition] = useState<clickPositionInterface>({
    positionX: "",
    positionY: "",
  });

  const getClickPosition = (e: any, _callback?: (position: clickPositionInterface) => void) => {
    const clickedPosition = {
      positionX: e.clientX,
      positionY: e.clientY,
    };
    setPosition(clickedPosition);
    _callback && _callback(clickedPosition);
  };

  const resetClickPosition = (_callback?: () => void) => {
    setPosition({
      positionX: "",
      positionY: "",
    });
    _callback && _callback();
  };

  return {
    resetClickPosition,
    getClickPosition,
    position,
  };
};
