import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useTypeGridModel = () => {
  const { lang } = useLangLabels();
  const gridModelTypes: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    name: { label: lang.types.form.name },
    type: { label: lang.types.form.type },
    date: { label: lang.globalentityValues.date },
    tagFP: { label: lang.globalentityValues.tagFP },
  };
  return gridModelTypes;
};
