import { Paper } from "@material-ui/core";
import React, { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FlexContainer from "../../../components/containers/FlexContainer";
import PageContainer from "../../../components/containers/PageContainer";
import TabToolbar from "../../../components/containers/tabContainer/components/TabToolbar";
import { useTodayNewPurchaseRequest } from "../../../domains/stock/persistance/useTodayNewPurchaseRequest";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../../shared/routes/routes";
import PurchaseRequestsGrid from "./tabs/PurchaseRequestsGrid";
import StockCategoriesPage from "./tabs/StockCategoriesPage";
import StockGrid from "./tabs/StockGrid";

interface Props {}

const StockPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { lang } = useLangLabels();
  const { purchaseRequests } = useTodayNewPurchaseRequest();

  const tabs = useMemo(
    () => [
      {
        title: lang.lubricationSection.purchaseRequests,
        route: "",
        badge: purchaseRequests?.quantity,
      },
      {
        title: lang.lubricationSection.categories,
        route: USER_ROUTES.categories,
      },
      {
        title: lang.lubricationSection.allStock,
        route: USER_ROUTES.allStock,
      },
    ],
    [purchaseRequests]
  );

  return (
    <>
      <Paper style={{ padding: "0px 0px 0px 16px" }}>
        <PageContainer padding={/*'16px 16px 0px 16px'*/ "0px 16px 0px 0px"}>
          <FlexContainer align="center" gap={"0"}>
            <TabToolbar
              tabs={tabs.map(i => i.title)}
              badge={tabs.map(t => t.badge)}
              onSelectedTab={selected => navigate(tabs[selected].route)}
              startStyle={{ padding: "0", height: "47px" }}
              endStyle={{ padding: "0" }}
            />
          </FlexContainer>
        </PageContainer>
        <Routes>
          <Route path={""} element={<PurchaseRequestsGrid />} />
          <Route path={USER_ROUTES.categories} element={<StockCategoriesPage />} />
          <Route path={USER_ROUTES.allStock} element={<StockGrid />} />
        </Routes>
      </Paper>
    </>
  );
};

export default React.memo(StockPage);
