import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useContainersGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    id: { label: lang.globalentityValues.id },
    name: { label: lang.containers.form.label.name },
    capacity: { label: lang.containers.form.label.capacity },
    coefficient: { label: lang.containers.form.label.coefficient },
    code: { label: lang.containers.form.label.code },
    lubricantType: { label: lang.containers.form.label.lubricantType },
    lubricant: { label: lang.containers.form.label.lubricant },
    type: { label: lang.containers.form.label.type },
    tagFP: { label: lang.globalentityValues.tagFP },
  };

  return gridModel;
};
