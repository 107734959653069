import { Route, Outlet, Navigate } from "react-router-dom";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import LoadingNecesaryData from "../../../../../pages/backoffice/LoadingNecesaryData";
import { USER_ROUTES } from "../../../../../shared/routes/routes";
import PrivateRoutesWrapper from "./PrivateRoutesWrapper";
import useAccountManagerRoutes from "./subpaths/useAccountManagerRoutes";
import useAdminRoutes from "./subpaths/useAdminRoutes";
import useClientRoutes from "./subpaths/useClientRoutes";
import useOperatorRoutes from "./subpaths/useOperatorRoutes";
import useSuperAdminRoutes from "./subpaths/useSuperAdminRoutes";
import useSupervisorRoutes from "./subpaths/useSupervisorRoutes";
import PlantSelectionPage from "../../../../../domains/plants/PlantSelectionPage/PlantSelectionPage";

const usePrivateRoutes = () => {
  const { person } = useSessionContext();

  const defaultRoute = `/${USER_ROUTES.home}/${person?.role?.replace(/ /g, "-")}`;

  const SupervisorRoutes = useSupervisorRoutes();
  const OperatorRoutes = useOperatorRoutes();
  const ClientRoutes = useClientRoutes();
  const AccountManagerRoutes = useAccountManagerRoutes();
  const AdminRoutes = useAdminRoutes();
  const SuperAdminRoutes = useSuperAdminRoutes();

  return (
    <Route
      path={`${USER_ROUTES.home}/*`}
      element={
        <PrivateRoutesWrapper>
          <Outlet />
        </PrivateRoutesWrapper>
      }
    >
      <Route path={USER_ROUTES.plantSelection} element={<PlantSelectionPage />} />

      <Route path={""} element={<LoadingNecesaryData />} />

      {/* SUPERVISOR */}
      {SupervisorRoutes}

      {/* OPERATOR */}
      {OperatorRoutes}

      {/* CLIENT */}
      {ClientRoutes}

      {/* ADMIN CUENTAS */}
      {AccountManagerRoutes}

      {/* ADMIN */}
      {AdminRoutes}

      {/* SUPER ADMIN */}
      {SuperAdminRoutes}

      {person ? (
        <Route path={"*"} element={<Navigate to={defaultRoute} />} />
      ) : (
        <Route path={"*"} element={<Navigate to={`/${USER_ROUTES.login}`} />} />
      )}
    </Route>
  );
};

export default usePrivateRoutes;
