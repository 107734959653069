import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useStockGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    product: { label: lang.stock.product },
    brand: { label: lang.stock.brand },
    actualStock: { label: lang.stock.actualStock },
    minimumRequired: { label: lang.stock.minimumRequired },
    category: { label: lang.stock.category },
    subcategory: { label: lang.stock.subcategory },
    measureUnit: { label: lang.stock.measureUnit },
    categoryArea: { label: lang.stock.categoryArea },
    providerArea: { label: lang.stock.providerArea },
    complex: {
      label: lang.stock.complex,
      options: [
        { name: lang.stock.options.simple, value: false },
        { name: lang.stock.options.complex, value: true },
      ],
    },
    hidden: {
      label: lang.stock.hidden,
      options: [
        { name: lang.stock.options.archived, value: true },
        { name: lang.stock.options.unArchived, value: false },
      ],
    },
    tagFP: { label: lang.globalentityValues.tagFP },
    id: { label: lang.globalentityValues.id },
  };

  return gridModel;
};
