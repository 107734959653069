import styled from "@emotion/styled";
import { CSSProperties } from "react";

export interface FlexContainerProps {
  border?: CSSProperties["border"];
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: CSSProperties["gap"];
  flexWrap?: CSSProperties["flexWrap"];
  height?: CSSProperties["height"];
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  width?: CSSProperties["width"];
  background?: CSSProperties["background"];
  color?: CSSProperties["color"];
  borderRadius?: CSSProperties["borderRadius"];
  flexDirection?: CSSProperties["flexDirection"];
  overflowX?: CSSProperties["overflowX"];
  overflowY?: CSSProperties["overflowY"];
  overflow?: CSSProperties["overflow"];
  maxWidth?: CSSProperties["maxWidth"];
  borderColor?: CSSProperties["borderColor"];
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const FlexContainer = styled("div")`
  color: ${(props: FlexContainerProps) => props.color || ""};
  height: ${(props: FlexContainerProps) => props.height};
  display: flex;
  gap: ${(props: FlexContainerProps) => props.gap || "8px"};
  justify-content: ${(props: FlexContainerProps) => props.justify};
  align-content: ${(props: FlexContainerProps) => props.align};
  width: ${(props: FlexContainerProps) => props.width || "auto"};
  align-items: ${(props: FlexContainerProps) => props.align};
  flex-wrap: ${(props: FlexContainerProps) => props.flexWrap};
  padding: ${(props: FlexContainerProps) => props.padding || "0"};
  margin: ${(props: FlexContainerProps) => props.margin};
  background-color: ${(props: FlexContainerProps) => props.background || "unset"};
  border-radius: ${(props: FlexContainerProps) => props.borderRadius};
  flex-direction: ${(props: FlexContainerProps) => props.flexDirection || "row"};
  overflow-x: ${(props: FlexContainerProps) => props.overflowX};
  overflow-y: ${(props: FlexContainerProps) => props.overflowY};
  overflow: ${(props: FlexContainerProps) => props.overflow};
  max-width: ${(props: FlexContainerProps) => props.maxWidth};
  border: ${(props: FlexContainerProps) => props.border || "none"};
  bordercolor: ${(props: FlexContainerProps) => props.borderColor || "none"};
`;

export default FlexContainer;
