import { TypeInterface } from "../../../../../domains/types/models/TypeInterface";
import { enumTgdTypes } from "../../../../../domains/types/models/types";
import { useTypesByPlant } from "../../../../../domains/types/services/service.types";

export const instrumentTypesFilter = (types: TypeInterface[]) => {
  return types.filter(
    type => type.type === enumTgdTypes.instrument || type.type === enumTgdTypes.unity
  );
};

export const useGetAllInstrumentTypes = () => {
  const typesQuery = useTypesByPlant();

  const data = instrumentTypesFilter(typesQuery.data || []);

  return { ...typesQuery, data };
};
