import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { PersonInterface } from "../../../../../domains/person/models/PersonInterface";
import { Plant } from "../../../../../domains/plants/model/PlantInterface";
import { Auth } from "../../../../../domains/session/model/sessionInterface";
import {
  GetLogin,
  GetUser,
  GetPlants,
  GetPerson,
} from "../../../../../domains/session/services/sessionServices";
import { useSessionContext } from "../../../../../domains/session/store/sessionContext";
import User from "../../../../../domains/users/UserInterface";
import { apiCall } from "../../../../../shared/axios/axios-config";
import { useDate } from "../../../../../shared/date";
import { PrivateQueryData } from "../../../../../shared/globals/utilsGlobalTypes";
import { useSetSyncBoxDailySync } from "../../sync/services/syncService";

export const loginAdapter = async (loginData: Auth) => {
  return GetLogin(loginData).then((sesion: { token: string }) => ({
    token: sesion.token,
  }));
};

export const userAdapter = async (token: string) => {
  return GetUser({ token }).then((user: User) => ({
    id: user.id,
    name: user.name,
    /* password:res.data.password, */
    email: user.email,
    type: user.type,
  }));
};

export const plantsAdapter = async (token: string) => {
  return GetPlants({ token }).then((plants: Plant[]) =>
    plants.map(plant => ({
      id: plant.id,
      name: plant.name,
      tagFP: plant.tagFP,
      factory: plant.factory,
      factoryId: plant.factoryId,
      location: plant.location,
      plantId: plant.plantId,
      referrer: plant.referrer,
      state: plant.referrer,
    }))
  );
};

export const personAdapter = async (token: string) => {
  return GetPerson({ token }).then((data: PersonInterface) => {
    const person = Array.isArray(data) ? data[0] : data;

    return {
      id: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      name: person.name,
      telephone: person.telephone,
      address: person.address,
      email: person.email,
      file: person.file,
      referrer: person.referrer,
      password: person.password,
      lubricatorNumber: person.lubricatorNumber,
      role: person.role,
    };
  });
};

const getTodayDelayedAndPendingSetter = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "post",
    url: `/TodayDelayedAndPendingSetter`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getDelayedRoutes = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "post",
    url: `/DelayedRoutes`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useTodayDelayedAndPendingSetter = () => {
  //this set necesary pending routes of the operator, daily once
  const { token, tagFP, user } = useSessionContext();
  const queryTodayPending = useMutation(getTodayDelayedAndPendingSetter);
  const queryDelayed = useMutation(getDelayedRoutes);
  const { nowYear, nowMonth, nowDay } = useDate();
  const status = user?.type === "O" || user?.type === "S" ? queryTodayPending.status : "success";
  useSetSyncBoxDailySync([queryTodayPending.isSuccess]);

  const setDelayedRoutes = () => {
    //set routes that wasn't started and adds to today routes
    if (tagFP) {
      queryDelayed.mutate({
        token,
        data: {
          tagFP,
        },
      });
    }
  };

  const setPendingRoutes = () => {
    //set today routes to pending status
    if (token && tagFP && (user?.type === "O" || user?.type === "S")) {
      if (queryTodayPending.status === "idle") {
        queryTodayPending.mutate(
          {
            token,
            data: {
              date: `${nowYear}-${nowMonth}-${nowDay}`,
              tagFP,
            },
          },
          {
            onSuccess: () => setDelayedRoutes(),
          }
        );
      }
    }
  };

  useEffect(() => {
    setPendingRoutes();
  }, [tagFP]);

  return { ...queryTodayPending, status };
};

export const useGetUserData = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["GetUserData", tagFP],
    queryFn: () => GetUser({ token }),
    select: user => ({
      id: user.userId,
      name: user.name,
      email: user.email,
      type: user.type,
    }),
  });
};
