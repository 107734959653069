import { TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import FlexContainer from "../../containers/FlexContainer";
import { useAutocompleteStyles } from "../useAutocompleteStyle";

interface Props {
  onChange: any;
  onClose?: (item?: any) => void;
  defaultValue?: any;
  label: string;
  entityList: any[] | undefined;
  showTitle: string;
  children?: React.ReactNode;
  renderOption?: (option: any) => React.ReactNode;
  loading?: boolean;
  error?: string;
  color?: "primary" | "secondary";
  selector?: string;
  autoHighlight?: boolean;
  disabled?: boolean;
  width?: string;
  complete?: boolean;
  disableClearable?: boolean;
  multiple?: boolean;
  onInputChange?: (value?: any) => void;
  value?: any;
}

const SelectSimpleEntity: React.FC<Props> = ({
  onClose,
  onInputChange,
  value,
  multiple,
  complete,
  width,
  disabled,
  autoHighlight,
  disableClearable,
  selector,
  onChange,
  defaultValue,
  label,
  error,
  entityList,
  showTitle,
  children,
  renderOption,
}) => {
  const [, setVal] = useState("");

  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value]);

  const { lang } = useLangLabels();

  const classes = useAutocompleteStyles({
    width: width,
  });
  const _label = error ? `${label} ${error}` : label;
  const _color = error ? "primary" : "secondary";

  const handleChange = (event: any, newValue: any) => {
    !complete
      ? onChange(
          newValue
            ? newValue[selector || showTitle] === "loading..."
              ? ""
              : newValue[selector || showTitle]
            : ""
        )
      : onChange(newValue);
  };

  const _defaultValue = defaultValue
    ? defaultValue[showTitle]
      ? defaultValue[showTitle]
      : `${_label}: ${defaultValue}`
    : _label;

  const input = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      focused={error ? true : false}
      autoFocus
      label={_defaultValue}
      color={_color}
      variant="outlined"
    />
  );

  return (
    <FlexContainer gap="16px">
      <Autocomplete
        multiple={multiple}
        disableClearable={disableClearable}
        {...(onClose ? { onClose } : {})}
        loading={!entityList}
        clearOnEscape
        color={_color}
        disabled={disabled}
        {...(autoHighlight === false ? {} : { autoHighlight })}
        id={showTitle}
        options={entityList || []}
        getOptionLabel={(option: any) => option[showTitle]}
        renderOption={renderOption}
        renderInput={input}
        className={classes.input}
        onChange={handleChange}
        size="small"
        loadingText={lang.inputFields.autocomplete.loadingText}
        clearText={lang.inputFields.autocomplete.clearText}
        openText={lang.inputFields.autocomplete.openText}
        closeText={lang.inputFields.autocomplete.closeText}
        // {...(value ? val : null)}
        onInputChange={
          onInputChange ? (event: any) => onInputChange(event.target.value) : undefined
        }
        classes={{
          option: classes.option,
        }}
      />
      {children && children}
    </FlexContainer>
  );
};

export default React.memo(SelectSimpleEntity);
