import { Badge, Typography } from "@material-ui/core";
import ComponentMode from "../../componentMode/ComponentMode";
import { PageContainer } from "../../containers";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import FieldFilterForm from "./components/FieldFilterForm";
import { FieldFilterProps } from "./types/FieldFilterTypes";

const FieldFilter = ({
  period,
  setPeriod,
  searchValue,
  handleSearchValueChange,
  selectedFields = {},
  handleFilterValueChange,
  onSelectedFieldAdd,
  onSelectedFieldDelete,
  onSelectedFieldsClear,
  notSelectedOptions,
}: FieldFilterProps) => {
  const { lang } = useLangLabels();
  const fieldFilterTitle = lang.filters.title;
  const searchValueNumber = searchValue ? 1 : 0;
  const badgeContent = Object.keys(selectedFields)?.length + searchValueNumber;

  return (
    <Badge badgeContent={badgeContent} color="primary">
      <ComponentMode mode="popover" icon="filter" popoverTitle={fieldFilterTitle}>
        <PageContainer width="450px" padding="0px">
          <Typography variant="h6">{fieldFilterTitle}</Typography>
          <FieldFilterForm
            searchValue={searchValue}
            handleSearchValueChange={handleSearchValueChange}
            selectedFields={selectedFields}
            onSelectedFieldAdd={onSelectedFieldAdd}
            onSelectedFieldsClear={onSelectedFieldsClear}
            handleFilterValueChange={handleFilterValueChange}
            notSelectedOptions={notSelectedOptions}
            onSelectedFieldDelete={onSelectedFieldDelete}
            period={period}
            setPeriod={setPeriod}
          />
        </PageContainer>
      </ComponentMode>
    </Badge>
  );
};

export default FieldFilter;
