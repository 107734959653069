import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getValueFromFlatObjectKey } from "../globals/utils/valueFromFlatObjectkey";
import UserInterface from "../../domains/users/UserInterface";
import { flattenObjAllLevels } from "../globals/utils/dataFiltering";
//process data structure to create excel file
export const data = (items: any[], keys: string[]) => {
  if (items[0])
    return [
      keys,
      ...(items.map(item => [
        ...keys.map(key => {
          //New manual methodRecursion over flatten keys
          return getValueFromFlatObjectKey(item, key);
        }),
      ]) || [""]),
    ];
  else return [[""]];
};

interface FileConfigProps {
  documentTitle?: string;
  authorEmail: UserInterface["email"];
  items: any[];
}

const createFile = ({ documentTitle, authorEmail, items }: FileConfigProps) => {
  const keys: string[] = items[0] ? Object.keys(flattenObjAllLevels(items[0])) : [""];

  //create data workbook
  //------------------------
  var wb = XLSX.utils.book_new();

  wb.Props = {
    Title: `${documentTitle}`,
    // Subject: "Test",
    Author: authorEmail,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Hoja 1");

  var ws_data = data(items, keys); //[['hello' , 'world']];  //a row with 2 columns

  var ws = XLSX.utils.aoa_to_sheet(ws_data);

  wb.Sheets["Hoja 1"] = ws;

  //exporting
  //------------------------
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  function s2ab(s: any) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  const file = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  const downloadFile = () => {
    saveAs(file, `${documentTitle || "unnamed"}-${new Date().toISOString().split("T")[0]}.xlsx`);
  };

  return {
    file,
    downloadFile,
  };
};

export default createFile;
