import React from "react";
import { useClipboardContext } from "./context/ClipboardContext";

export const RightClick: React.FC<{ data: any }> = ({ data, children }) => {
  const { copy } = useClipboardContext();

  const handleData = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(data);
    copy(data);
  };

  return (
    <div style={{ display: "flex" }} onContextMenu={handleData}>
      {children}
    </div>
  );
};
