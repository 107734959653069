import TGDModal from "../../containers/modal/TGDModal/TGDModal";
import FlexContainer from "../../containers/FlexContainer";
import { Alert } from "@material-ui/lab";
import { Button, CircularProgress } from "@material-ui/core";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface PrintReportModalProps {
  open: boolean;
  handleClose: () => void;
  isLoading: boolean | null;
  handlePrint: any;
  disableEscapeKeyDown?: boolean;
}

const PrintReportModal = ({
  open,
  handleClose,
  isLoading,
  handlePrint,
  disableEscapeKeyDown,
}: PrintReportModalProps) => {
  const { lang } = useLangLabels();

  return (
    <TGDModal
      open={open}
      handleClose={handleClose}
      disableBackdropClick={true}
      disableCloseButton={isLoading || false}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <FlexContainer style={{ width: "400px" }} flexDirection="column" align="center" gap={"16px"}>
        {isLoading ? (
          <Alert severity="info" icon={<CircularProgress color="inherit" size="16px" />}>
            {`${lang.reportsGeneration.generatingReport}...`}
          </Alert>
        ) : (
          <Alert severity="success"> {lang.reportsGeneration.reportGenerated} </Alert>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePrint}
          disabled={isLoading || false}
        >
          🖨️ {lang.reportsGeneration.printReport}
        </Button>
      </FlexContainer>
    </TGDModal>
  );
};

export default PrintReportModal;
