import { CreateSurveyInterface } from "../../../../domains/surveys/model/SurveyInterface";
import { getViewDateDay } from "../../../date/utils";
import { backupDb } from "../backup-db";

export const bakupAddSurveys = async (survey: CreateSurveyInterface) => {
  try {
    return await backupDb.surveys.add({
      ...survey,
      backupDate: getViewDateDay(new Date().toISOString()),
    });
  } catch (err: any) {
    if (err.name === "QuotaExceededError") {
      console.warn("Quota exceeded, cleaning up old data...");
      await backupDb.cleanUpOldData(25);

      return await backupDb.surveys.add({
        ...survey,
        backupDate: getViewDateDay(new Date().toISOString()),
      });
    } else {
      console.error("Error while adding survey:", err);
      throw err;
    }
  }
};
