import { Route, Navigate } from "react-router-dom";
import DemoPage from "../../../../../pages/login/DemoPage";
import LoginPage from "../../../../../pages/login/LoginPage";
import { USER_ROUTES } from "../../../../../shared/routes/routes";

const usePublicRoutes = () => {
  return (
    <Route path={"/*"}>
      <Route path={""} element={<Navigate to={`/${USER_ROUTES.login}`} />} />
      <Route path={USER_ROUTES.login}>
        <Route path={""} element={<LoginPage />} />
        <Route path={USER_ROUTES.demoRequest} element={<DemoPage />} />
        <Route path={"*"} element={<Navigate to={`/${USER_ROUTES.login}`} />} />
      </Route>
      <Route path={"*"} element={<Navigate to={`/${USER_ROUTES.login}`} />} />
    </Route>
  );
};

export default usePublicRoutes;
