import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAllFactories, useCreateFactory } from "./services/service.factory";

import { QueryStatus } from "react-query";
import { FactoryInterface } from "./model/FactoryInterface";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { errorsLog } from "../../shared/globals/utils/utils";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import TgdTextField from "../../components/fields/TextField/TgdTextField";
import { capitalize } from "../../shared/globals/utils/capitalize";

export interface CreateFactoryFormProps {
  onSuccess?: (value?: any) => void;
  handleFactory?: (value?: FactoryInterface) => void;
}

const CreateFactoryForm: React.FC<CreateFactoryFormProps> = ({ handleFactory }) => {
  const [_isFactoryCreated, setFactoryCreated] = useState<boolean>(false);

  const { register, setValue, handleSubmit, errors, watch } = useForm();
  const { createFactory, status, error, message, isSuccess, isLoading, isError } =
    useCreateFactory();
  const { data: factories, refetch } = useAllFactories();

  const { lang } = useLangLabels();

  const required = {
    required: lang.validations.messages.required.toLowerCase(),
  };

  const factoryExist = {
    validate: (newFactoryName: string) => {
      const exist = factories?.find(
        (f: FactoryInterface) => f.name.toUpperCase() === newFactoryName.toUpperCase()
      );
      return !!exist ? `${lang.validations.messages.allReadyExist.toLowerCase()}` : true;
    },
  };

  useEffect(() => {
    //creating entity Object
    register("id");
    register("factoryId");
    register("name", { ...required, ...factoryExist });
    register("location", required);
    register("businessName", required);
    register("referrer");
    register("tagF");

    //defaultValues
    setValue("id", 0);
    setValue("referrer", "desarrollo@tgdsa.com");
    setValue("factoryId", "");
    setValue("tagF", "");
  }, []);

  const submit = (data: FactoryInterface) => {
    createFactory(data)
      .then((data: any) => {
        refetch();
        setFactoryCreated(true);
        handleFactory && handleFactory(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  errorsLog(errors);

  const _status = (isLoading && {
    status: "loading" as QueryStatus,
    message: undefined,
    error: undefined,
  }) ||
    (isSuccess && {
      status: "success" as QueryStatus,
      message: "Creado con éxito",
      error: undefined,
    }) ||
    (isError && {
      status: "error" as QueryStatus,
      message: "ha ocurrido un error",
      error: undefined,
    }) || {
      status,
      error,
      message,
    };

  return (
    <>
      <DefaultFormLayout
        componentMode="content"
        buttonText={lang.actionTypes.create}
        buttonAction={handleSubmit(submit)}
        {...{
          queryStatus: _status.status,
          error: _status.error,
          alertStatusMessage: _status.message,
        }}
      >
        <TgdTextField
          error={errors?.name?.message}
          label={lang.factories.name}
          value={watch("name")}
          onChange={(value: string) => setValue("name", capitalize(value))}
        />

        <TgdTextField
          error={errors?.businessName?.message}
          label={lang.factories.businessName}
          value={watch("businessName")}
          onChange={value => setValue("businessName", value)}
        />
        <TgdTextField
          error={errors?.location?.message}
          label={lang.factories.location}
          value={watch("location")}
          onChange={value => setValue("location", value)}
        />
        <TgdTextField
          readonly={true}
          error={errors?.referrer?.message}
          label={lang.factories.referrer}
          value={watch("referrer")}
          onChange={value => setValue("referrer", value)}
        />
      </DefaultFormLayout>
    </>
  );
};

export default CreateFactoryForm;
