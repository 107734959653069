import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { ContainersInterface, containersTypes } from "../model/ContainerInterface";

export const useContainerForm = (tagFP: string, item?: ContainersInterface) => {
  const { lang } = useLangLabels();

  return useForm<ContainersInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      component: InputComponentTypes.none,
    },
    name: {
      label: lang.containers.form.label.name,
      placeholder: lang.containers.form.label.name,
      value: item?.name || "",
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
        min: {
          value: 1,
          message: lang.validations.messages.minLength,
        },
        max: {
          value: 50,
          message: lang.validations.messages.maxLength,
        },
      },
    },
    coefficient: {
      label: lang.containers.form.label.coefficient,
      placeholder: lang.containers.form.label.coefficient,
      value: item?.coefficient || 0,
      component: InputComponentTypes.number,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    code: {
      label: lang.containers.form.label.code,
      placeholder: lang.containers.form.label.code,
      value: item?.code || "",
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    lubricant: {
      label: lang.containers.form.label.lubricant,
      placeholder: lang.containers.form.label.code,
      value: item?.lubricant || "",
      component: item ? InputComponentTypes.none : InputComponentTypes.lubricant,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      inputConfig: {
        //complete:true,
        tagFP: tagFP,
      },
    },
    capacity: {
      label: lang.containers.form.label.capacity,
      placeholder: lang.containers.form.label.capacity,
      value: item?.capacity || 0,
      helper: lang.containers.form.label.capacityHelper,
      component: InputComponentTypes.number,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    lubricantType: {
      label: lang.containers.form.label.lubricantType,
      placeholder: lang.containers.form.label.code,
      value: item?.lubricantType || "",
      component: InputComponentTypes.none,
      //required value but setted befor request by complete selected lubricant
      /* validations:{
                required:{
                    value:true,
                    message:lang.validations.messages.required
                },
            }, */
    },
    type: {
      label: lang.containers.form.label.type,
      placeholder: lang.containers.form.label.type,
      value: item?.type || containersTypes.dispenser,
      component: InputComponentTypes.none,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      component: InputComponentTypes.none,
    },
  });
};
