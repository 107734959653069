import {
  Accordion,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import { useSearchAll } from "../../shared/globals/hooks/useSearchAll";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { CustomIcon } from "../buttons/CustomIcon";
import PageContainer from "../containers/PageContainer";
import ListToolbar from "../toolbar/ListToolbar";

const useStyles = makeStyles(() => ({
  MuiAccordionRoot: {
    overflow: "hidden",
    boxShadow: "none",
    margin: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "#f3f3f3",
    },
  },
  MuiAccordionSummaryRoot: {},
  icon: {
    margin: "0 16px",
  },
}));

interface Props {
  showLabel: string;
  displaySubNameHandler?: any;
  alternativeColumn?: (item?: any) => React.ReactNode;
  items: any[] | undefined | null;
  status: string | null | undefined;
  customButtons?: (item: any) => React.ReactNode;
  icon?: (item: any) => React.ReactNode;
  children?: (item: any) => React.ReactNode;
  toggleDisabled?: Boolean;
  title?: string;
  toolbarActions?: React.ReactNode;
  searchBar?: boolean;
}

const TgdAuxList: React.FC<Props> = ({
  showLabel,
  searchBar,
  items,
  status,
  customButtons,
  icon,
  children,
  toggleDisabled,
  displaySubNameHandler,
  title,
  toolbarActions,
  alternativeColumn,
}) => {
  const toggleDefault = toggleDisabled ? false : true;
  const { lang } = useLangLabels();
  const classes = useStyles();
  const { search, handleSearch, filteredItems } = useSearchAll(items);

  const EditIcon = toggleDefault && (
    <>
      <CustomIcon icon="edit" />
    </>
  );

  if (status === "error")
    return (
      <PageContainer>
        <Alert severity={status}>{status}</Alert>
      </PageContainer>
    );

  return (
    <>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "calc(60vh)",
          minHeight: "230px",
        }}
      >
        <ListToolbar
          searchBar={!searchBar ? searchBar : true}
          searchValue={search}
          handleSearch={handleSearch}
          title={title}
          actions={toolbarActions}
        />
        <br />
        {status === "loading" ? (
          <PageContainer>
            <CircularProgress></CircularProgress>
          </PageContainer>
        ) : (
          <>
            {items && items.length > 0 ? (
              filteredItems &&
              filteredItems.map((item: any, index: number) => (
                <div key={index}>
                  <Accordion className={classes.MuiAccordionRoot}>
                    <AccordionSummary
                      style={{ maxHeight: "48px" }}
                      expandIcon={EditIcon}
                      aria-label="Expand"
                      aria-controls="panel1c-content"
                      id="panel1bh-header"
                    >
                      <List dense={true} style={{ width: "100%" }} disablePadding={true}>
                        <ListItem>
                          {icon && icon(item)}
                          <ListItemText
                            style={{ width: "250px" }}
                            primary={item[showLabel] ? item[showLabel] : ""}
                            secondary={!!displaySubNameHandler ? item[displaySubNameHandler] : ""}
                          />
                          {item["type"] && (
                            <ListItemText style={{ textAlign: "left" }} primary={item["type"]} />
                          )}
                          {alternativeColumn && alternativeColumn(item)}
                        </ListItem>
                        <ListItemSecondaryAction
                          onClick={event => event.stopPropagation()}
                          onFocus={event => event.stopPropagation()}
                          aria-label="Acknowledge"
                        >
                          {customButtons && customButtons(item)}
                        </ListItemSecondaryAction>
                      </List>
                    </AccordionSummary>
                    <PageContainer color="#f3f3f3">
                      <PageContainer color="white">{children && children(item)}</PageContainer>
                    </PageContainer>
                  </Accordion>
                  <Divider variant="middle" />
                </div>
              ))
            ) : (
              <Alert severity={"warning"}>{lang.table.noResults}</Alert>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(TgdAuxList);
