import { Route } from "react-router-dom";
import ConfigPage from "../../../../../../pages/backoffice/ConfigPage";
import LubricatorHomePage from "../../../../../../pages/backoffice/lubricator/LubricatorHomePage";
import NewsPage from "../../../../../../pages/backoffice/NewsPage";
import UserPanel from "../../../../../../pages/backoffice/UserPanel";
import { ExplorePage } from "../../../../../../pages/explore/ExplorePage";
import { USER_ROUTES } from "../../../../../../shared/routes/routes";

const useOperatorRoutes = () => {
  return (
    <Route path={`${USER_ROUTES.operator}`} element={<UserPanel />}>
      <Route path={""} element={<LubricatorHomePage />} />
      <Route path={`${USER_ROUTES.explore}/*`} element={<ExplorePage />} />
      <Route path={USER_ROUTES.news} element={<NewsPage />} />
      <Route path={USER_ROUTES.config} element={<ConfigPage />} />
      <Route path={"*"} element={<LubricatorHomePage />} />
    </Route>
  );
};

export default useOperatorRoutes;
