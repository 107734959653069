import React, { useMemo, useState } from "react";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import {
  useEquipmentsByTagFP,
  useEquipmentsTag,
  useProcesedEquipmentsByTagFPRefactor,
} from "../services/lubricationPointsAdapters";
import { equipmentType, LubricationPointInterface } from "../model/lubricationPointsInterface";
import CustomButton from "../../../components/buttons/CustomButton";
import FlexContainer from "../../../components/containers/FlexContainer";
import ViewLubricationPoint from "../components/actions/ViewLubricationPoint";
import DesactivateActivateEquipment from "../components/actions/DesactivateActivateEquipment";
import { AppBar, Box, Divider, Grid, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import TgdEquipmentsTable from "../components/TgdEquipmentsTable";
import CreateLubricationPoint from "../components/actions/CreateLubricationPoint";
import { USER_ROUTES } from "../../../shared/routes/routes";
import FullscreenSurface from "../../../components/containers/modal/FullScreenSurface/FullscreenSurface";
import PageContainer from "../../../components/containers/PageContainer";
import TabPanel from "../../../components/tabs/TabPanel";
import UpdateEquipment from "../components/actions/UpdateEquipment";
import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";

import { ComponentsGrid } from "./ComponentsGrid";
import { ElementsGrid } from "./ElementsGrid";
import { EquipmentsGrid } from "./EquipmentsGrid";

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

interface ManageEquipmentsPageProps {
  virtualGridWidth: string;
}

const ManageEquipmentsPage: React.FC<ManageEquipmentsPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();

  const { equipments, lubricationPoints, elements, isFetchinglubricationPoints } =
    useProcesedEquipmentsByTagFPRefactor();

  const tabs: TabsInterface[] = [
    {
      label: lang.components.title.plural,
      route: USER_ROUTES.components,
      component: (
        <ComponentsGrid
          virtualGridWidth={virtualGridWidth}
          lubricationPoints={lubricationPoints}
          isFetchinglubricationPoints={isFetchinglubricationPoints}
        />
      ),
    },
    {
      label: lang.elements.title.plural,
      route: USER_ROUTES.elements,
      component: <ElementsGrid virtualGridWidth={virtualGridWidth} elements={elements} />,
    },
    {
      label: lang.lubricationPoints.equipments,
      route: `${USER_ROUTES.equipments}/*`,
      component: <EquipmentsGrid virtualGridWidth={virtualGridWidth} equipments={equipments} />,
    },
  ];

  const tabProps = useRouteTabProps(5, tabs);

  return <RouterTabs {...tabProps} />;
};

const EquipmentFileInfoVertical: React.FC<{ item: any }> = ({ item }) => {
  const { lang } = useLangLabels();

  return (
    <>
      <FlexContainer>
        <UpdateEquipment item={item} />
        <DesactivateActivateEquipment
          tagTGD={item.tagTGD}
          item={item}
          type={equipmentType.equipment}
        />
      </FlexContainer>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box
            style={{
              backgroundColor: "#f6f6f6",
              maxWidth: "300px",
              maxHeight: "300px",
              minHeight: "200px",
              minWidth: "200px",
              width: "auto",
              height: "auto",
            }}
          ></Box>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              {lang.lubricationPoints.equipmentName}:
              <Typography variant="body2">
                <strong>{item?.equipment}</strong>
              </Typography>
              <Divider />
              <br />
              {lang.lubricationPoints.equipmentTag}:
              <Typography variant="body2">
                <strong>{item?.tagTGD}</strong>
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={12}>
              {lang.lubricationPoints.type}:
              <Typography variant="body2">
                <strong>{item?.type}</strong>
              </Typography>
              <Divider />
              <br />
              {lang.lubricationPoints.sector}:
              <Typography variant="body2">
                <strong>{item?.sector}</strong>
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={12}>
              {lang.lubricationPoints.plantTag}:
              <Typography variant="body2">
                <strong>{item?.plantTag}</strong>{" "}
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={12}>
              {lang.lubricationPoints.criticality}:
              <Typography variant="body2">
                <strong>{item?.criticality}</strong>
              </Typography>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

const ElementSection: React.FC<{ elementsTabItems: TabsInterface[]; currentItem: any }> = ({
  elementsTabItems,
  currentItem,
}) => {
  const [value, setValue] = useState(0);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {elementsTabItems.map((item, index: number) => (
            <Tab key={index} label={item?.label} {...a11yProps(index)} />
          ))}
        </Tabs>
        <CreateLubricationPoint item={currentItem} />
      </div>

      {elementsTabItems.map((item, index: number) => (
        <TabPanel value={value} index={index} key={index}>
          {item.component}
        </TabPanel>
      ))}
    </>
  );
};

export const ManageEquipment: React.FC<{ item: any }> = React.memo(({ item }) => {
  const { getTag } = useEquipmentsTag();
  const { data: Equipments } = useEquipmentsByTagFP();
  const { lang } = useLangLabels();

  const lubricationPoints = Equipments?.filter(eq => eq.tagTGD.includes(item.tagTGD));

  const elements = useMemo(() => {
    let flag: string[] = [];
    let result: any = {};

    lubricationPoints?.forEach((lp: any) => {
      const element = lp.element;
      if (!!flag.includes(element)) {
        result[element] = {
          ...result[element],
          lubricationPoints: [...result[element].lubricationPoints, ...(lp.state ? [lp] : [])],
        };
      } else if (true) {
        flag.push(element);
        result = {
          ...result,
          [element]: {
            element: element,
            tagFP: lp.tagFP,
            sector: lp.sector,
            plantTag: lp.plantTag,
            type: lp.type,
            criticality: lp.criticality,
            tagTGD: getTag(lp, equipmentType.element),
            equipment: lp.equipment,
            lubricationPoints: lp.state ? [lp] : [],
          },
        };
      }
    });

    return flag.map(key => result[key]);
  }, [lubricationPoints]);

  const currentItem = {
    ...item,
    lubricationPoints,
  };

  const { isOpen, toggleModal } = useModal();

  const elementsTabItems = useMemo(
    () =>
      elements.map(el => ({
        label: `${el.element}`,
        route: el.element,
        component: (
          <TgdEquipmentsTable
            height="auto"
            title={`${lang.components.title.plural}: ${el.lubricationPoints?.length}`}
            items={el.lubricationPoints || []}
            type={equipmentType.component}
            itemActions={(item: LubricationPointInterface) => <ViewLubricationPoint item={item} />}
          />
        ),
      })),
    [elements, item]
  );

  return (
    <>
      <CustomButton
        variant="iconButton"
        icon="config"
        action={toggleModal}
        popoverTitle={lang.STIEquipments.manageEquipments}
      />

      <FullscreenSurface isOpen={isOpen}>
        <AppBar>
          <Toolbar>
            <CustomButton icon="close" color="inherit" action={toggleModal} variant="iconButton" />
            <Typography variant="h6">{item.equipment}</Typography>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <PageContainer>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography variant="h6">
                {item?.tagFP ? item?.tagFP.split("|").map((i: string) => `${i} `) : ""}
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={2}>
              <EquipmentFileInfoVertical item={item} />
            </Grid>
            <Grid item md={10}>
              <ElementSection {...{ elementsTabItems }} currentItem={currentItem} />
            </Grid>
          </Grid>
        </PageContainer>
      </FullscreenSurface>
    </>
  );
});

export default React.memo(ManageEquipmentsPage);
