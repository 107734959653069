import React from "react";
import ModalButton from "../../../../components/buttons/ModalButton";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import EquipmentSurveyGrid from "./EquipmentSurveyGrid";
import { CustomIconType } from "../../../../components/buttons/types/CustomButtonTypes";

interface Props {
  mode: "modal" | "content";
  lubricationPoint: LubricationPointInterface;
}

const EquipmentSurveyList: React.FC<Props> = ({ mode, lubricationPoint }) => {
  const { lang } = useLangLabels();
  const title = lang.messages.lastTenSurveys;
  const equipmentSurveyGridTitle = lang.messages.lastTenEquipmentSurveys;
  const icon: CustomIconType = "survey";

  return (
    <>
      {mode === "modal" && (
        <ModalButton
          fullwidth={true}
          icon={icon}
          variant="iconButton"
          popoverTitle={`${title} ${lang.messages.ofThisLubricationPoint.toLocaleLowerCase()}`}
        >
          <EquipmentSurveyGrid
            lubricationPoint={lubricationPoint}
            title={equipmentSurveyGridTitle}
          />
        </ModalButton>
      )}

      {mode === "content" && (
        <EquipmentSurveyGrid lubricationPoint={lubricationPoint} title={title} />
      )}
    </>
  );
};

export default React.memo(EquipmentSurveyList);
