import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import TgdSvgIcon from "./TgdSvgIcon";

const HelmetIcon: React.FC<any> = (props: SvgIconProps) => {
  return (
    <TgdSvgIcon {...props} style={{ color: !props.color ? "#000" : "inherit" }}>
      <path d="M5.48,17.46H22a1,1,0,0,0,.95-1v0a1,1,0,0,0-.95-1h-.53A8,8,0,0,0,17.62,8.6v1.89a1,1,0,1,1-1.94,0V7.78a7.7,7.7,0,0,0-1.94-.28,7.7,7.7,0,0,0-1.94.28v2.71a1,1,0,1,1-1.94,0V8.6A8,8,0,0,0,6,15.47H5.48a1,1,0,0,0-1,1v.06A1,1,0,0,0,5.48,17.46Z" />
    </TgdSvgIcon>
  );
};

export default HelmetIcon;
