import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { usePeriod } from "../../../../shared/date";
import { FlexContainer } from "../../../../components/containers";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useGetPendingRevisionChanges } from "../services/revisionChangeServices";
import { useRevisionChangeGridModel } from "../lang/useRevisionChangeGridModel";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { RevisionChangeInterface } from "../types/revisionChangeType";
import RevisionChangeSupervisionForm from "./RevisionChangeSupervisionForm";
import RevisionChangeStatus from "./RevisionChangeStatus";

const PendingRevisionChanges = () => {
  const { lang } = useLangLabels();
  const gridModel = useRevisionChangeGridModel();
  const entityName = lang.revisionChange.pendingRevisionChanges;

  const { period, setPeriod } = usePeriod(7, entityName);

  const { data: revisionChanges, isFetching } = useGetPendingRevisionChanges();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.revisionChange.supervision,
      component: (item: RevisionChangeInterface) => (
        <RevisionChangeSupervisionForm item={item} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <FlexContainer>
      <VirtualGrid
        entityName={entityName}
        gridModel={gridModel}
        items={revisionChanges || []}
        title={entityName}
        carrousellComponents={carrousellComponents}
        period={period}
        periodChange={setPeriod}
        renderOptionColumn={{
          headerName: lang.revisionChange.state,
          renderOption: item => <RevisionChangeStatus item={item} />,
        }}
        cellFormatter={{
          supervisionReason: value => (value ? value : lang.revisionChange.unsupervised),
          supervisionDate: value =>
            new Date(value).getFullYear() !== 2999 ? value : lang.revisionChange.unsupervised,
          supervisionUser: value => (value ? value : lang.revisionChange.unsupervised),
        }}
      />
    </FlexContainer>
  );
};

export default PendingRevisionChanges;
