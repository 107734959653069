import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import theme from "../../../../assets/theme";
import CustomButton from "../../../../components/buttons/CustomButton";
import ModalButton from "../../../../components/buttons/ModalButton";
import { ComponentUIModes } from "../../../../components/componentMode/types/componentModesInterfaces";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { CategoriesAndSubCategoriesInterface } from "../CategoriesIinterface";
import { useUpdateCategory } from "../service.category";

interface Props {
  mode: ComponentUIModes;
  defaultValue: CategoriesAndSubCategoriesInterface;
}

const UpdateCategoryForm: React.FC<Props> = ({ defaultValue, mode }) => {
  const { lang } = useLangLabels();
  const { updateCategory, query: updateResult } = useUpdateCategory();
  const { status, message, error, reset: ClearStatus } = useStatusProcessor(updateResult);
  const { register, setValue, errors, watch, handleSubmit } = useForm();
  const title = `${lang.actionTypes.update} ${lang.stock.category}: ${defaultValue.category}`;

  useEffect(() => {
    if (defaultValue) {
      Object.entries(defaultValue).map(([key, value]: [string, any]) => {
        if (!Array.isArray(value))
          register(
            { name: key },
            { required: { value: true, message: lang.formMessages.required } }
          );
        setValue(key, value);
      });
    }
  }, []);

  const submit = (item: any) => {
    updateCategory(item);
  };

  const form = (
    <>
      <TextField
        inputRef={register()}
        name="category"
        size="small"
        variant="outlined"
        value={watch("category")}
        label={errors?.category?.message || lang.lubricationSection.categoryName}
        color={errors?.category?.message ? "primary" : "secondary"}
      />
      <CustomButton status={status} action={handleSubmit(submit)}>
        {lang.crudActions.update}
      </CustomButton>
    </>
  );

  const content = (
    <>
      <StatusAndMessage status={status} message={message} error={error} reset={ClearStatus} />

      {mode === "modal" && <FlexContainer flexDirection="column">{form}</FlexContainer>}

      {mode === "content" && (
        <FlexContainer padding={`${theme?.spacing(3)}px 0px`}>{form}</FlexContainer>
      )}
    </>
  );

  return (
    <>
      {mode === "content" && content}

      {mode === "modal" && (
        <ModalButton icon="edit" color="primary" variant={"iconButton"} title={title}>
          {content}
        </ModalButton>
      )}
    </>
  );
};

export default React.memo(UpdateCategoryForm);
