import React, { useMemo, useState } from "react";
import TabToolbar from "./components/TabToolbar";

interface TabContainerProps {
  children?: (item: any) => React.ReactNode;
  tabs: any[];
  labelSelector: string;
  panelSelector: string;
}
const TabContainer: React.FC<TabContainerProps> = ({
  children,
  tabs,
  labelSelector,
  panelSelector,
}) => {
  const [current, setCurrent] = useState(0);

  const labels = useMemo(() => tabs.map(item => item[labelSelector]), [labelSelector, tabs]);
  const panels = useMemo(() => tabs.map(item => item[panelSelector]), [panelSelector, tabs]);

  return (
    <>
      <TabToolbar tabs={labels} onSelectedTab={setCurrent} />
      {panelSelector && panels[current]}
      {children && children(tabs[current])}
    </>
  );
};

export default TabContainer;
