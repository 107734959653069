import React from "react";
import { usePurchaseRequestsByTagFP } from "../../../../domains/stock/purchase-requests/service.purchaseRequests";

import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { PurchaseGridModel } from "../../../../domains/stock/purchase-requests/lang/usePurchaseRequestGridModel";
import CreatePurchaseRequestForm from "../../../../domains/stock/purchase-requests/components/CreatePurchaseRequestForm";
import SetPurchaseRequestStatus from "../../../../domains/stock/purchase-requests/components/SetPurchaseRequestStatus";
import UpdatePurchaseRequestForm from "../../../../domains/stock/purchase-requests/components/UpdatePurchaseRequestForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  tagFP?: string;
}

const PurchaseRequestsGrid: React.FC<Props> = ({ tagFP }) => {
  const { currentPlant } = useSessionContext();
  const componentTagFP = tagFP || currentPlant?.tagFP;
  const { data: purchaseRequests } = usePurchaseRequestsByTagFP(componentTagFP);

  const { lang } = useLangLabels();
  const title = lang.lubricationSection.purchaseRequests;

  return (
    <>
      <VirtualGrid
        {...{
          tagFP,
          title: title,
          items: purchaseRequests,
          gridModel: PurchaseGridModel,
          headerActions: () => <CreatePurchaseRequestForm mode="modal" />,
          renderOptionColumn: {
            headerName: "More",
            renderOption: item => <SetPurchaseRequestStatus item={item} />,
          },
        }}
      >
        {(items: any) => (
          <>
            <UpdatePurchaseRequestForm items={items} />
          </>
        )}
      </VirtualGrid>
    </>
  );
};

export default React.memo(PurchaseRequestsGrid);
