import { PrivateQuery, PrivateQueryTagFPInterface } from "../globals/utilsGlobalTypes";
import { apiCall } from "./axios-config";

export const getAllResourcesByEmail = (
  endpoint: string,
  email: string,
  { token }: PrivateQuery
) => {
  return apiCall({
    method: "GET",
    url: `/${endpoint}/${email}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllResources = (endpoint: string, { token }: PrivateQuery) => {
  return apiCall({
    method: "GET",
    url: `/${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getResourceBytagFP = (
  endpoint: string,
  { tagFP, token }: PrivateQueryTagFPInterface
) => {
  return apiCall({
    method: "GET",
    url: `/${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};
