export enum EnumSupplyTypes {
  tool = "HERRAMIENTA",
  consumable = "CONSUMIBLE",
  protection = "PROTECCION",
}

export type SupplyType =
  | EnumSupplyTypes.tool
  | EnumSupplyTypes.consumable
  | EnumSupplyTypes.protection
  | "OBSERVACION";
export type NeededThingsToSurveyType = SupplyType | "LUBRICANTE" | "PERMISO";

//---------------------------
//interfaces
//---------------------------

export interface SupplyInterface {
  id: number;
  type: NeededThingsToSurveyType;
  supply: string;
  tagFP: string;
  description: string;
  /*     lubricatonPoints?:LubricationPointInterface
   */
}

export interface AssignCancelSupplyInterface {
  supply: string;
  equipment: string;
  state: "C" | "A";
  tagFP: string;
}

export enum SupplyStateDimention {
  needed = "FALTANTE",
  used = "UTILIZADO",
}

export type LocalStateSupply = {
  supply: string;
  type: SupplyType;
  state: SupplyStateDimention | undefined;
};
