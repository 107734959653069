import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { useCallback, useEffect } from "react";
import { QueryStatus } from "react-query";
import { CustomIcon } from "../../../components/buttons/CustomIcon";
import FlexContainer from "../../../components/containers/FlexContainer";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";

interface CollectorDbStatus {
  mode?: "snackbar" | "alert";
  status: QueryStatus;
  isLoading: boolean;
  message?: string;
}

const CollectorDBSnackbarStatus: React.FC<CollectorDbStatus> = ({
  mode,
  status,
  isLoading,
  message,
}) => {
  const { isOpen, openModal, closeModal } = useModal(false);

  const handleSnackbarOpen = useCallback(() => {
    if (status === "success" || status === "error") {
      !isLoading && openModal();
    }
  }, [isLoading, openModal, status]);

  const handleSnackbarClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    handleSnackbarOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, message]);

  const alert = (
    <Alert icon={<></>} onClose={handleSnackbarClose} severity={status as Color} variant="filled">
      <FlexContainer align="center">
        <>
          {status === "success" && <CustomIcon icon="readyToSync" />}
          {status === "error" && <CustomIcon icon="notReadyToSync" />}
        </>
        {message}
      </FlexContainer>
    </Alert>
  );

  return mode === "alert" ? (
    isOpen ? (
      alert
    ) : (
      <></>
    )
  ) : (
    <Snackbar
      style={{
        top: "50px",
        right: "40px",
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleSnackbarClose}
      open={isOpen}
      key={"top right"}
    >
      {alert}
    </Snackbar>
  );
};

export default CollectorDBSnackbarStatus;
