import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";
import TgdSvgIcon from "./TgdSvgIcon";

const PermissionsIcon: React.FC<any> = (props: SvgIconProps) => {
  return (
    <TgdSvgIcon {...props} style={{ color: !props.color ? "#000" : "inherit" }}>
      <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
    </TgdSvgIcon>
  );
};

export default PermissionsIcon;
