import React, { useMemo } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import HandleUndefined from "../../../components/containers/handleUndefined/HandleUndefined";
import { flattenObjAllLevels } from "../../globals/utils/dataFiltering";
import { GridModelInterface } from "../../gridModel/gridModelEntity";
import { useExportXlsx } from "../../xlsx/useExportXlsx";
import { useDate } from "../../date";

interface DownloadXLSXProps {
  items: any[] | undefined;
  documentTitle?: string;
  gridModel: GridModelInterface;
  visibleColumns: string[];
}

const filterObjectAttr = (object: any, showColumns: string[]): any => {
  return showColumns.reduce((acc, key) => {
    return {
      ...acc,
      [key]: object[key],
    };
  }, {});
};

const DownloadXlsx: React.FC<DownloadXLSXProps> = ({
  items,
  documentTitle,
  gridModel,
  visibleColumns,
}) => {
  const flatted = useMemo(
    () => (!!items ? items.map(item => flattenObjAllLevels(item)) : []),
    [items]
  );
  const { isValidDateFormat, formatISOToLocalDateTime } = useDate();

  const _items = useMemo(
    () =>
      flatted.map(item => {
        const filteredItem = filterObjectAttr(item, visibleColumns);
        Object.entries(filteredItem).forEach(([key, value]) => {
          const { isFullDateFormat } = isValidDateFormat(value as string);
          if (isFullDateFormat) {
            filteredItem[key] = formatISOToLocalDateTime(value as string, true) + "+00:00";
          }
        });
        return filteredItem;
      }),
    [flatted, visibleColumns, isValidDateFormat, formatISOToLocalDateTime]
  );

  const { downloadFile } = useExportXlsx(_items, documentTitle, gridModel);

  return (
    <>
      <HandleUndefined showError={!(items && items.length > 0)} callback={downloadFile}>
        {callback => (
          <CustomButton icon="download" action={callback} variant="iconButton" color="secondary" />
        )}
      </HandleUndefined>
    </>
  );
};

export default React.memo(DownloadXlsx);
