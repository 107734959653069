import {
  Checkbox,
  createStyles,
  Divider,
  ListItem,
  ListItemIcon,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/buttons/CustomButton";
import { CustomIcon } from "../../../components/buttons/CustomIcon";
import { FlexContainer } from "../../../components/containers";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import SelectCompanyService from "../../cluster-companies/selectCompanyService";
import { useToken } from "../../session/store/sessionStore";
import { PersonInterface } from "../models/PersonInterface";
import { useUpdatePerson } from "../services/personServices";
import AssignOperatorNumber from "./AssignOperatorNumber";

const useStyles = makeStyles(() =>
  createStyles({
    formGrid: {
      display: "flex",
      flexDirection: "column",
    },
    input: {
      width: "300px,",
    },
  })
);
interface Props {
  person: PersonInterface;
  tagFP?: string;
  enableFunction?: boolean;
}

const UpdatePersonForm: React.FC<Props> = ({ person, tagFP, enableFunction }) => {
  const token = useToken();
  const classes = useStyles();
  const { mutate, status: PasswordStatus } = useUpdatePerson();
  const { register, watch, errors, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const { lang } = useLangLabels();

  const submit = () => {
    mutate({
      data: {
        email: person.email,
        password: watch("password"),
      },
      token: token!,
    });
  };

  return (
    <OnlineOfflineMode reverse={enableFunction}>
      <div
        style={{
          padding: "24px",
          marginBottom: "16px",
          border: "solid 1px #d3d3d3",
        }}
      >
        {PasswordStatus === "success" ? (
          <>
            <Alert severity="success">{lang.sidebar.config.userUpdatedSuccessFully}</Alert>
            <br />
          </>
        ) : null}

        <br />
        <FlexContainer width={"100%"} flexDirection="column">
          <FlexContainer width={"100%"} flexDirection="column">
            <ListItem style={{ padding: "0 4px" }}>
              <ListItemIcon>
                <CustomIcon icon="person" />
              </ListItemIcon>
              <Typography>{person && person.name}</Typography>
            </ListItem>
            <ListItem style={{ padding: "0 4px" }}>
              <ListItemIcon>
                <CustomIcon icon="email" />
              </ListItemIcon>
              <Typography variant={"button"}>{person && person.email}</Typography>
            </ListItem>
          </FlexContainer>
          <Divider />
          {tagFP && person.role === "Operario" && (
            <AssignOperatorNumber person={person} tagFP={tagFP} />
          )}
          <SelectCompanyService person={person} />
          <ListItem style={{ marginTop: "16px" }}>
            <form onSubmit={handleSubmit(submit)}>
              <Typography variant="subtitle1" style={{ marginBottom: "16px" }}>
                {lang.sidebar.config.changePassword}
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                inputRef={register({
                  required: { value: true, message: lang.validations.messages.required },
                  min: { value: 6, message: lang.sidebar.config.atLeastSix },
                })}
                className={classes.input}
                variant="outlined"
                size="small"
                name="password"
                onFocus={() => sessionStorage.setItem("passwordConfirmation", watch("password"))}
                label={errors?.password?.message || lang.sidebar.config.newPassword}
                color={errors?.password ? "primary" : "secondary"}
              />
              <br />
              <br />
              <TextField
                type={showPassword ? "text" : "password"}
                inputRef={register({
                  required: { value: true, message: lang.validations.messages.required },
                  validate: {
                    equal: () => watch("newPassword") === watch("password"),
                  },
                })}
                className={classes.input}
                variant="outlined"
                size="small"
                label={
                  errors?.newPassword
                    ? lang.sidebar.config.doesNotMatch
                    : lang.sidebar.config.repeatPassword
                }
                color={errors?.newPassword ? "primary" : "secondary"}
                name="newPassword"
              />

              <br />

              <FlexContainer align="center" margin={"0 0 16px 0"}>
                <Checkbox
                  style={{ padding: "12px 4px 12px 0px" }}
                  color="secondary"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
                <Typography variant="body2">{lang.sidebar.config.showPassword}</Typography>
              </FlexContainer>

              <CustomButton
                status={PasswordStatus}
                type="submit"
                color="secondary"
                styleProps={{ width: "100%" }}
              >
                {lang.crudActions.save}
              </CustomButton>
            </form>
          </ListItem>
          {/* </Grid> */}
        </FlexContainer>
      </div>
    </OnlineOfflineMode>
  );
};

export default UpdatePersonForm;
