import { useEffect, useMemo, useState } from "react";
import { QueryStatus } from "react-query";
import { mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { useEquipmentsByCriticality } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { useToken } from "../../session/store/sessionStore";
import { CriticalityInterface, CriticalityTypes } from "../models/criticalityInterface";

export const criticalityCRUDDependencies = ["PlantAux", "CriticalitiesByPlant"];

export const CreateCriticality = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/CreateCriticality",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const UpdateCriticality = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/UpdateCriticality",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DeleteCriticality = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    url: "/DeleteCriticality",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const usePlantCriticality = () => {
  return useGetAllAuxByPlantData("criticalities");
};

export const useCriticalityByTypeByTagFP = (criticaityType: CriticalityTypes) => {
  const query = usePlantCriticality();

  const criticalityByType = useMemo(
    () => query.data?.filter(c => c.criticalityType === criticaityType),
    [criticaityType, query.data]
  );

  return {
    ...query,
    data: criticalityByType,
  };
};

export const useUpdateCriticality = () => {
  const token = useToken();
  const query = useMutation(UpdateCriticality, {
    onSuccess: () => {
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const status = useStatusProcessor(query);

  const update = (data: CriticalityInterface) => {
    return query.mutate({
      token,
      data,
    });
  };

  return {
    update,
    updateResult: {
      ...query,
      ...status,
    },
  };
};

export const useDeleteCriticality = () => {
  return useMutation(DeleteCriticality, {
    onSuccess: () =>
      criticalityCRUDDependencies.forEach(d => {
        queryClient.invalidateQueries(d);
      }),
  });
};

interface DeleteComponentErrors {
  equipments?: CriticalityInterface[] | undefined | null;
}

export const useDeleteCriticalityVerification = () => {
  const token = useToken();
  const query = useDeleteCriticality();
  const { mutate: deleteItem } = query;
  const [status, setStatus] = useState<QueryStatus>("idle");
  const [validationElement, setValidationElement] = useState<CriticalityInterface | undefined>();

  const {
    data: equipmentsByComponents,
    status: equipmentsByComponentStatus,
    remove: removeEquipmentsByCriticality,
  } = useEquipmentsByCriticality(validationElement);

  const [errors, setErrors] = useState<DeleteComponentErrors>();

  const handleRemoveValidations = () => {
    removeEquipmentsByCriticality();
  };

  const validate = (item: CriticalityInterface) => {
    setStatus("loading");
    setErrors({});
    handleRemoveValidations();
    setValidationElement(item);
  };

  const handleDelete = () => {
    if (equipmentsByComponentStatus === "success") {
      if (equipmentsByComponents?.length > 0) {
        setErrors({
          equipments: equipmentsByComponents,
        });
        setStatus("error");
        setValidationElement(undefined);
      } else {
        deleteItem(
          {
            data: validationElement,
            token,
          },
          {
            onSuccess: () => {
              setStatus("success");
              setValidationElement(undefined);
            },
            onError: err => console.log(err),
          }
        );
      }
    }
  };

  useEffect(() => {
    validationElement && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentsByComponentStatus]);

  return {
    errors,
    status,
    validate,
    query,
  };
};
