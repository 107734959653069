import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryDataTagFPInterface,
  PrivateQueryTagFPInterface,
} from "../../../shared/globals/utilsGlobalTypes";
import { InfoLubricationPointInterface } from "../../infoLubricationPoints/model/InfoLubricationPointInterface";
import { Plant } from "../../plants/model/PlantInterface";
import { LubricationPointInterface } from "../model/lubricationPointsInterface";

export const GetAllLubricationPointsAndInfoByTagFP = ({
  token,
  tagFP,
}: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/AllLubricationPointsAndInfoByTagFP",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: tagFP!,
    },
  });
};

export const LubricationPointsAndInfo = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsAndInfo",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DaoEquipmentsByTagFP = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/DaoEquipmentsByTagFP",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: tagFP!,
    },
  });
};

export const DaoEquipmentsElementsByTagFP = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/DaoEquipmentsElementsByTagFP",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: tagFP!,
    },
  });
};

export const GetEquipmentsbyTagFP = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/EquipmentsByTagFP",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP: tagFP!,
    },
  });
};

export const EquipmentsByLubricantType = ({ data: type, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByLubricantType",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: type.tagFP,
      name: type.name,
    },
  });
};

export const EquipmentsByEquipmentType = ({ data: type, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByEquipmentType",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: type.tagFP,
      name: type.name,
    },
  });
};

export const EquipmentsByPermission = ({ data: permission, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByPermission",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: permission.tagFP,
      name: permission.permissionName,
    },
  });
};

export const EquipmentsByMeasureUnit = ({ data: type, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByMeasureUnit",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: type.tagFP,
      name: type.name,
    },
  });
};

export const EquipmentsByComponent = ({ data: component, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByComponent",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: component.tagFP,
      component: component.component,
    },
  });
};

export const EquipmentsByElement = ({ data: element, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByElement",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: element.tagFP,
      element: element.element,
    },
  });
};

export const EquipmentsBySectorName = ({ data: sector, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsBySectorName",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tagFP: sector.tagFP,
      sectorName: sector.sectorName,
    },
  });
};

export const EquipmentsByLubricant = ({ data: lubricant, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByLubricant",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      type: lubricant.type,
      tagFP: lubricant.tagFP,
      lubricant: lubricant.lubricant,
    },
  });
};

export const EquipmentsBySupply = ({ data: supply, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsBySupply",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      supply: supply.supply,
      tagFP: supply.tagFP,
    },
  });
};

export const EquipmentsByCriticality = ({ data: criticality, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByCriticality",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      criticality: criticality.description,
      tagFP: criticality.tagFP,
    },
  });
};

export const EquipmentsByRoute = ({ data: route, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentsByRoute",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      route: route.routeName,
      tagFP: route.tagFP,
    },
  });
};

export const EquipmentVerification = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/EquipmentAlgorithms",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const CreateEquipment = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/CreateEquipment",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const CreateLubricationPoint = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/CreateLubricationPoint",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const UpdateLubricationPointNoImpact = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/UpdateEquipmentNoImpact",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
export const UpdateLubricationPoint = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/UpdateEquipmentsWithImpact",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const UpdateHumanErrors = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/UpdateHumanErrors",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DeleteEquipment = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/DeleteEquipments",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const LubricationPointByTagTGD = ({ data, token }: PrivateQueryData<{ tagTGD: string }>) => {
  return apiCall({
    method: "POST",
    url: "/LubricationPointByTagTGD",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const LubricationPointAndInfoByTagTGD = ({
  token,
  tagFP,
  tagTGD,
}: PrivateQueryTagFPInterface & { tagTGD: string }) => {
  return apiCall({
    method: "GET",
    url: "/LubricationPointByTagTGD/" + tagTGD,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { tagFP },
  });
};

export const LubricationPointReviewsByTagTGDAndTagFP = ({
  tagTGD,
  tagFP,
  token,
}: PrivateQueryTagFPInterface & { tagTGD: string }) => {
  return apiCall({
    method: "GET",
    url: "/LubricationPointReviewsByTagTGDAndTagFP",
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: {
      tagTGD,
      tagFP,
    },
  });
};

export const UpdateEquipmentsState = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/UpdateEquipmentsState",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const DayPendingLubricationPoints = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "POST",
    url: "/DayPending",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export interface UpdateReview {
  tagTGD: LubricationPointInterface["tagTGD"];
  updateReview: InfoLubricationPointInterface["updateReview"];
  tagFP: Plant["tagFP"];
}

export const UpdateReview = ({ data, token }: PrivateQueryData<UpdateReview>) => {
  return mutateApiCall({
    method: "POST",
    url: "/UpdateReviewState",
    headers: {
      authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const LabelReplacementByRoute = ({ tagFP, token, data }: PrivateQueryDataTagFPInterface) => {
  const { route } = data;

  return apiCall({
    method: "GET",
    url: "/Equipment/LabelReplacementByRoute",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
      route,
    },
  });
};

export const EquipmentAttrValues = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/EquipmentAttrValues",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};
