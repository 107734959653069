import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { EnumSupplyTypes, SupplyType } from "../model/SupplyInterface";

type SelectSupplyTypesProps = {
  onChange: (value: SupplyType) => void;
  defaultValue?: SupplyType;
  error?: string;
  disabled?: boolean;
};

const Supplies = [
  { label: EnumSupplyTypes.tool },
  { label: EnumSupplyTypes.protection },
  { label: EnumSupplyTypes.consumable },
];

const SelectSupplyType: React.FC<SelectSupplyTypesProps> = ({
  onChange,
  defaultValue,
  error,
  disabled,
}) => {
  const showTitle = "label";
  const { lang } = useLangLabels();
  const label = lang.supplies.title.form.label.type;

  return (
    <SimpleAutocompleteSelect
      {...{
        items: Supplies,
        error,
        label,
        defaultValue,
        onChange,
        showTitle,
        disabled,
      }}
    />
  );
};

export default SelectSupplyType;
